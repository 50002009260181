import React, { useState } from "react";
import { Select } from "antd";
import { observer } from "mobx-react";

import useRootStore from "../../../store/useRootStore";

const { Option } = Select;

interface SelectAuthorProps {
    onSelect: (val: string[]) => void;
    value: string[];
    placeholderText: string;
}

const SelectAuthor = observer(({ value, onSelect, placeholderText }: SelectAuthorProps) => {
	const { getAuthors } = useRootStore().shelfStore;

	const authors = getAuthors();

	return (
		<Select 
			mode="tags" 
			onChange={val => {
				onSelect(val);
			}} 
			value={value}
			placeholder={placeholderText} 
		>
			{authors.map((author, i) => (
				<Option key={i} value={author}>{author}</Option>
			))}
		</Select>
	);
});

export default SelectAuthor;