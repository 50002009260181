import React from "react";
import { makeAutoObservable, observable, toJS, computed } from "mobx";
import { Node, Range } from "slate";
import { generate as generateRandomString } from "randomstring";
import { debounce, findIndex, indexOf, unionBy, remove, forEach } from "lodash";
import { message, Modal} from "antd";

//IndexedDB
import { db } from "../db/bookDb";

//helpers
import { removeKey, countWords, doSearchReplace, doKeywordCount } from "../utils/helper";
import { initBody, getChapterById } from "../utils/initials";

// types
import { Chapter, SectionType } from "../types/chapter";
import { SyncStatus } from "../types/sync";

import { IChapterTemplateBase } from "../types/chapter";
// API
import { AtticusClient, ExportResponse } from "../api/atticus.api";

import { GetBookFromDB, GetChapterFromDB, UpdateChapterInDB, UpdateBookInDB, UpdateChapterMeta,
  GetChapterTemplates, UpdateChapterTemplateMeta, UpdateChapterTemplateInDB,
  SaveErrorBook, GetErrorBook, SaveErrorBookChapter, GetErrorChapters } from "../utils/offline.book.helpers";
//SaveErrorBook, GetErrorBook, SaveErrorBookChapter, GetErrorChapters --- error visualixation
import { syncBook } from "../utils/sync";
import { getBookEndnotes } from "../components/Shared/helpers/get-book-endnotes";
import { PdfSlateEndnote } from "../components/Previewer/print/types";

export class BookStore {
  chapterLoading = false;
  syncing = false;
  writing = true;
  saving = SyncStatus.saved;
  themeview = false;
  buildThemeView = false;
  bookEdit = true;
  customThemeBuilderView = "customThemeStep1";
  failedBookLoading = false;
  toggleName = "";
  searchLevel = "chapter";
  extras: IChapterStore.ChapterExtra[] = [];
  search : IBookStore.SearchParams = {
      q: "",
      caseSensitive: false,
      wholeWord: false
  };
  searchStep = 0;
  searchMatchedRanges: IBookStore.DecoratedRange[] = [];
  syncClose = true;
  allBody = {
    active: false,
    numbered: false,
    beginOn: ""
  }
  chapterOffContentMap = new Map();
  book: IBookStore.ExpandedBook = {
    _id: "",
    chapterIds: [],
    chapters: [],
    frontMatterIds: [],
    frontMatter: [],
    backMatterIds: [],
    backMatter: [],
    language: "en",
    isbn: "",
    printISBN: "",
    ebookISBN: "",
    publisherName: "",
    publisherLogoURL: "",
    publisherLink: "",
    coverImageUrl: "",
    title: "",
    subtitle: "",
    project: "",
    author: [],
    modifiedAt: new Date()
  }
  chapter: IChapterStore.ChapterMeta = {
    _id: "",
    bookId: "",
    image: "",
    subtitle: "",
    title: "",
    startOn: "any",
    type: "chapter",
    index: 0,
  }
  chapterTemplate: IChapterTemplateBase ={
    _id: "",
    bookId: "",
    motherChapterId: "",
    type: "chapter",
    title: "",
    subtitle: "",
    section: "",
    image: "",
    index: 0,
    numbered: false,
    children: []
    // children: SlateParentBlockNode[];
  }
  body = initBody;
  changeCount = 0;
  words = 0;
  errorBookId: string[] = [];
  errorChapterId: string[] = [];
  errorBooks: IBookStore.ErrorBook[] = [];
  errorChapters: IBookStore.ErrorChapter[] = [];

  constructor() {
    makeAutoObservable(this, {
      body: observable.ref,
      chapter: observable.ref,
      words: observable.struct,
      search: observable.deep,
      search_r: computed
    });
  }

  get search_r() {
    return this.searchMatchedRanges[this.searchStep];
  } 
  
  setSyncing = (syncing: boolean): void => {
    this.syncing = syncing;
  }

  setBook = (book: IBookStore.ExpandedBook): void => {
    this.book = toJS(book);
  }

  setSearch = (s: IBookStore.SearchParams): void => {
    this.search = s;
  }

  setSearcLevel = (s: string): void => {
    this.searchLevel = s;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setSearchMatchedRanges = (r: IBookStore.DecoratedRange[]) => {
    this.searchMatchedRanges = r;
  }

  setExtras = (list: IChapterStore.ChapterExtra[]) => {
    this.extras = list; 
  }

  handleSearchNext = () => {
    if (this.searchStep >= this.searchMatchedRanges.length - 1) {
        this.setSearchStep(0);
    } else {
        this.setSearchStep(this.searchStep + 1);
    }
  };

  handleSearchPrevious = () => {
    if (this.searchStep === 0) {
        this.setSearchStep(this.searchMatchedRanges.length - 1);
    } else {
        this.setSearchStep(this.searchStep - 1);
    }
  };

  handleReplaceForBooks = async (term: string) => {
    const arrs =  this.book.chapterIds;
    const chaps = await this.getChapterBodyById(arrs);

    const promises: Promise<unknown>[] = [];

    chaps.forEach((d, i) => {
        const c = doSearchReplace(d.children, this.search, term);
        promises.push(this.saveChapterBodyUpdates(d._id, c));
    });

    await Promise.all(promises).then(res => {
        console.log({res});
    });
    this.setExtras([]);
  }

  doSearchQuery = async () => {
    const chaps = await this.getChapterBodyById(this.book.chapterIds);
    const list = chaps.map(d => ({id: d._id, extra: doKeywordCount(d.children, this.search)}));
    this.setExtras(list);
  }

  doSearchSetCount = () => {
    if(this.searchLevel === "book" && this.search.q.length > 0){
        this.debounceSearchQuery();
    } else {
        this.setExtras([]);
    }
  };

  debounceSearchQuery =  debounce(this.doSearchQuery, 1500);

  setSearchStep = (step: number) => {
    this.searchStep = step;
  }

  debounceSearch =  debounce(this.setSearch, 1000);

  // Error Visualization
  setErrorBook = async (book_id: string): Promise<void> => {
    this.errorBookId.push(book_id);
    await SaveErrorBook(this.errorBookId);
  };

  // Error Visualization
  setHomeErrorBook = (err: IBookStore.ErrorBook[]): void => {
    this.errorBooks = err;
  }

  // Error Visualization
  setFailedBookLoading = (loading: boolean): void => {
    this.failedBookLoading = loading;
  }

  // Error Visualization
  getErrorBook = async () : Promise<IBookStore.ErrorBook[] | undefined> => {
    this.setFailedBookLoading(false);
    const failedBook = await GetErrorBook();

    if(failedBook) {
      this.setFailedBookLoading(true);
      this.setHomeErrorBook(failedBook);
    }
    return failedBook;
  }

  // Error Visualization
  setErrorChapter = async (chapter_id: string, book_id: string): Promise<void> => {
    this.errorChapterId.push(chapter_id);
    const allE = {
      _chapterId: chapter_id,
      _bookId: book_id
  };
    await SaveErrorBookChapter(allE);
  }

  // Error Visualization
  setHomeErrorChapter = (err: IBookStore.ErrorChapter[]): void => {
    this.errorChapters = err;
  }

  // Error Visualization
  getErrorChapter = async () : Promise<IBookStore.ErrorChapter[] | undefined> => {
    this.setFailedBookLoading(false);
    const failedChapters = await GetErrorChapters();

    if(failedChapters) {
      this.setFailedBookLoading(true);
      this.setHomeErrorChapter(failedChapters);
    }

    return failedChapters;
  }

  setAllBody = (allb: IChapterStore.AllBodyChapter) : void => {
    this.allBody = allb;
  }

  setChapter = (chapter: IChapterStore.ChapterMeta): void => {
    this.chapter = chapter;
  }

  setBookEdit = (edit: boolean): void => {
    this.bookEdit = edit;
  }

  setBody = (body: Node[]): void => {
    this.body = body;
  }

  setWords = (words: number): void => {
    this.words = words;
  }

  setSaving = (saving: SyncStatus): void => {
    this.saving = saving;
  }

  setChapterLoading = (loading: boolean): void => {
    this.chapterLoading = loading;
  }

  setMode = (writing: boolean): void => {
    this.writing = writing;
  }

   // goal settiing
   setToggleButtonName = ( name: string): void => {
    this.toggleName = name;
   }

  setView = (view: boolean): void => {
    this.themeview = view;
  }

  setCustomThemeBuilderView = (view: string): void => {
    this.customThemeBuilderView = view;
  }

  // custom theme builder
  setCustomBuildView = (view: boolean): void => {
    this.buildThemeView = view;
  }

  setSyncErrorMessage = (close: boolean): void => {
    this.syncClose = close;
  }

  setChangeCount = (): void => {
    this.changeCount = this.changeCount + 1;
  }

  getCurrentBookId = (): string => {
    return this.book._id;
  }

  chapterSetOfflineContent = (chapterId: string, hasOfflineContent: boolean) => {
    this.chapterOffContentMap.set(chapterId, hasOfflineContent);
  }

  chapterHasOfflineContent = (chapterId: string): undefined | boolean => {
    return this.chapterOffContentMap.get(chapterId);
  }

  // chapter template library
  setChapterTemplate = (chapterTemplate: IChapterStore.IChapterTemplateBase): void => {
    this.chapterTemplate = chapterTemplate;
  }

  next = (id: string, chapters: IChapterStore.ChapterMeta[]): void => {
    const index = chapters.map((d) => d._id).indexOf(id);
    if (index < chapters.length - 1)
      this.setChapter(chapters[index + 1]);
    else {
      this.setChapter(chapters[index - 1]);
    }
  }

  getChapterMatterById = (id: string): "frontMatter" | "backMatter" | "body" => {
    if (this.book.frontMatterIds.includes(id))
      return "frontMatter";

    if (this.book.backMatterIds.includes(id))
      return "backMatter";

    return "body";
  }

  getAndSetCurBook = async (bookId: string): Promise<IBookStore.Book | undefined> => {
    this.setChapterLoading(true);
    const book = await GetBookFromDB(bookId, { chapterMeta: true });

    this.setChapterLoading(false);

    if (book) {
      const bookWithChapterMeta: IBookStore.ExpandedBook = {
        ...book,
        frontMatter: book.frontMatter as IChapterStore.ChapterMeta[],
        chapters: book.chapters?.map((a, i) => ({ ...a, index: i })) as IChapterStore.ChapterMeta[],
        backMatter: book.backMatter as IChapterStore.ChapterMeta[],
        // showBackMatterTOC: true
      };
      this.setBook(bookWithChapterMeta);
    }

    return book;
  }

  getAndSetCurChapter = async (chapterId: string): Promise<void> => {
    const chapter = await GetChapterFromDB(chapterId);
    if (chapter) {
      this.setBookEdit(false);
      this.setBody(chapter.children);
      this.setChapter(removeKey(chapter, "children") as IChapterStore.ChapterMeta);
    }
  }

  // Chapter Template Library
  getAndSetChapterTemplate = async (templateId: string): Promise<void> => {
    const template = await GetChapterTemplates(templateId);
    if(template) {
      this.setBody(this.chapterTemplate.children);
      this.setChapterTemplate(template);
    }
  }

  getChapterBodyById = async (chapterIds:string[]) :Promise<IChapterStore.Chapter[]> =>{
      const chapters:IChapterStore.Chapter[]=[];
    for(const chapterId of chapterIds){
      const chapter= await GetChapterFromDB(chapterId);
      if(chapter) chapters.push(chapter);
    }
    return chapters;
  };

  // Atomic sync functions
  syncChapterChangesToServer = async (bookId: string, chapterId: string, updates: Partial<IChapterStore.Chapter>): Promise<void> => {
    const newUpdates = { ...updates };

    if (newUpdates._id) delete newUpdates["_id"];
    if (newUpdates.bookId) delete newUpdates["bookId"];
    if (newUpdates.allChangesSynced !== undefined) delete newUpdates["allChangesSynced"];
    if (newUpdates.lastSuccessfulSync) delete newUpdates["lastSuccessfulSync"];

    try {
      this.setSaving(SyncStatus.saving);
      const lastSyncAt = await AtticusClient.PatchChapter(bookId, chapterId, newUpdates);

      const batchPromise: Promise<unknown>[] = [];
      batchPromise.push(UpdateChapterMeta(chapterId, {
        lastSuccessfulSync: lastSyncAt.timestamp,
        allChangesSynced: true,
      }));

      batchPromise.push(UpdateChapterInDB(chapterId, {
        lastSuccessfulSync: lastSyncAt.timestamp,
        allChangesSynced: true,
      }));

      await Promise.all(batchPromise);
      this.setSaving(SyncStatus.saved);
    } catch (e) {
      this.setSaving(SyncStatus.failed);
      if (e.message !== "Network Error"){
        this.saveSpanshot(bookId);
        Modal.destroyAll();
        // this.setErrorBook(bookId); <-----error vizualisation
        // this.setErrorChapter(chapterId, bookId);  <-----error vizualisation
        if(this.syncClose == true)
        this.countDown();
      }
      console.log(e);
    }
  }

  // Chapter Template Library
  syncChapterTemplateChangesToServer = async (templateId: string, updates: Partial<IChapterStore.IChapterTemplateBase>): Promise<void> => {
    const newUpdates = { ...updates };

    try {
      this.setSaving(SyncStatus.saving);
      const lastSyncAt = await AtticusClient.PatchChapterTemplate(templateId, updates);

      const batchPromise: Promise<unknown>[] = [];

      batchPromise.push(UpdateChapterTemplateInDB(templateId, { ...updates, allChangesSynced: false }));

      await Promise.all(batchPromise);

      this.setSaving(SyncStatus.saved);
    } catch (e) {
      this.setSaving(SyncStatus.failed);
      if (e.message !== "Network Error"){
        this.saveSpanshot(templateId);
        Modal.destroyAll();
        // this.setErrorBook(bookId); <-----error vizualisation
        // this.setErrorChapter(chapterId, bookId);  <-----error vizualisation
        if(this.syncClose == true)
        this.countDown();
      }
      console.log(e);
    }
  }

  countDown = () => {
    this.setSyncErrorMessage(true);
    const secondsToGo = 4;
    const modal = Modal.error({
      title: "Oh no! We werent able to save your recent work to our server.",
      closable: true,
      okButtonProps: {
        style: {
          display: "none",
        },
      },
      width: 600,
      content: (
        <div>
        <h5>Use the link below to learn more about why this might happen and what you can do to resolve the error and save your work.</h5>
        <a target="_blank" href='https://www.atticus.io/troubleshooting-synching-errors/' rel="noreferrer">
            https://www.atticus.io/troubleshooting-synching-errors/
        </a>
        </div>
        ),
    });

    setTimeout(() => {
      modal.destroy();
      this.setSyncErrorMessage(false);
    }, secondsToGo * 1000);
  }

  syncNewChapterToServer = async (newChapter: IChapterStore.Chapter): Promise<void> => {
    try {
      const lastSyncAt = await AtticusClient.PutChapter(newChapter);

      await UpdateChapterMeta(newChapter._id, {
        lastSuccessfulSync: lastSyncAt.timestamp,
        allChangesSynced: true,
      });

    } catch (e) {
      console.log(e.message);
    }
  }

  syncDeleteChapterToServer = async (bookId: string, chapterId: string): Promise<void> => {
    try {
      const lastSyncAt = await AtticusClient.DeleteChapter(bookId, chapterId);
      await UpdateBookInDB(bookId, {
        lastSuccessfulSync: lastSyncAt.timestamp,
        allChangesSynced: true,
      });
    } catch (e) {
      console.log(e.message);
    }
  }

  syncBookChangesToServer = async (bookId: string, changes: Partial<IBookStore.Book>): Promise<void> => {
    try {
      delete changes["__v"];
      delete changes["createdAt"];
      delete changes["lastUpdateAt"];

      const lastSyncAt = await AtticusClient.PatchBook(bookId, changes);
      await UpdateBookInDB(bookId, {
        lastSuccessfulSync: lastSyncAt.timestamp,
        allChangesSynced: true,
      });
    } catch (e) {
      console.log("Book could not be synced to server", e.message);
    }
  }

  saveChapterBodyUpdates = async (id: string, body: Node[]): Promise<void> => {
    this.setBody(body);

    // Persist changes
    this.saveChapterBodyToLocal(id, body);

    this.debouncedSyncChapterChangesToServer(this.book._id, id, { children: body });
  }

  saveChapterBodyToLocal = async (id: string, body: Node[], remote?: boolean): Promise<void> => {
    // Persist changes
    const allPromises: Promise<void>[] = [];
    const chapterUpdate: Partial<Chapter> = { children: body };
    if(!remote) chapterUpdate.allChangesSynced = false;
    allPromises.push(UpdateChapterInDB(
        id,
        chapterUpdate
    ));
    allPromises.push(UpdateBookInDB(this.book._id, { modifiedAt: new Date() }));
    await Promise.all(allPromises);
    this.setBody(body);
  }

  updatelocalChapterLastSyncTime = async (id: string, lastSyncTime: Date | undefined): Promise<void> => {
    if(lastSyncTime){
      const allPromises: Promise<void>[] = [];
      allPromises.push(UpdateChapterMeta(
          id,
          {
              allChangesSynced: true,
              lastSuccessfulSync: lastSyncTime,
          }
      ));
      allPromises.push(UpdateChapterInDB(
          id,
          {
              allChangesSynced: true,
              lastSuccessfulSync: lastSyncTime
          }
      ));
      await Promise.all(allPromises);
    }
  }

  // Chapter Template Library Debounced atomic sync functions
  debouncedSyncChapterTemplateChangesToServer = debounce(this.syncChapterTemplateChangesToServer, 1000);

  // Chapter Template Library
  saveChapterTemplateBodyUpdates = async (id: string, body: Node[]): Promise<void> => {
    this.setBody(body);

    console.log("Chapter Template started");
    // Persist changes
    const allPromises: Promise<void>[] = [];
    allPromises.push(UpdateChapterTemplateInDB(id, { children: body, allChangesSynced: false }));
    await Promise.all(allPromises);

    console.log("Chapter Template stopped");

    //ADD CHAPTER TEMPLATE SYNC
    this.debouncedSyncChapterTemplateChangesToServer(this.chapterTemplate._id, { children:  body });

  }

  saveChapterMetaUpdates = async (meta: IChapterStore.ChapterMeta, updateChapter = true): Promise<void> => {
    const fm: IChapterStore.ChapterMeta[] = this.book.frontMatter.map(d => d._id === meta._id ? meta : d);
    const ch: IChapterStore.ChapterMeta[] = this.book.chapters.map(d => d._id === meta._id ? meta : d);
    const bm: IChapterStore.ChapterMeta[] = this.book.backMatter.map(d => d._id === meta._id ? meta : d);

    const book: IBookStore.ExpandedBook = {
      ...this.book,
      frontMatter: toJS(fm),
      frontMatterIds: toJS(this.book.frontMatterIds),

      chapters: toJS(ch),
      chapterIds: toJS(this.book.chapterIds),

      backMatter: toJS(bm),
      backMatterIds: toJS(this.book.backMatterIds),

      modifiedAt: new Date()
    };

    // Persist changes
    const allPromises: Promise<void>[] = [];
    allPromises.push(UpdateChapterMeta(meta._id, {
      title: meta.title,
      subtitle: meta.subtitle,
      image: meta.image,
      type: meta.type,
      numbered: meta.numbered,
      includeIn: meta.includeIn,
      startOn: meta.startOn,
      templateId: meta.templateId,
      allChangesSynced: false,
      fullpageImage: toJS(meta.fullpageImage),
    }));

    allPromises.push(UpdateBookInDB(this.book._id, {
      modifiedAt: new Date(),
      frontMatterIds: book.frontMatterIds,
      chapterIds: book.chapterIds,
      backMatterIds: book.backMatterIds,
      allChangesSynced: false,
    }));

    await Promise.all(allPromises);

    this.setBook(book);

    // updateChapter Flag is set to untrue to retain state getting updated in a loop -- not an ideal solution, only a workaround
    if(updateChapter){
        this.setChapter(meta);
    }

    const tmpMeta = { ...meta };
    delete tmpMeta["__v"];
    delete tmpMeta["children"];
    delete tmpMeta["createdAt"];
    delete tmpMeta["lastUpdateAt"];

    this.debouncedSyncChapterChangesToServer(book._id, meta._id, { ...tmpMeta });
  }

  // Chapter template library
  // saveChapterTemplateMetaUpdate  = async (meta: IChapterStore.IChapterTemplateBase): Promise<void> => {
  //   // const fm: IChapterStore.ChapterMeta[] = this.book.frontMatter.map(d => d._id === meta._id ? meta : d);
  //   const ch: IChapterStore.IChapterTemplateBase[] = this.chapterTemplate.children.map(d => d._id === meta._id ? meta : d);
  //   // const bm: IChapterStore.ChapterMeta[] = this.book.backMatter.map(d => d._id === meta._id ? meta : d);

  //   const book: IBookStore.ExpandedBook = {
  //     ...this.book,
  //     // frontMatter: toJS(fm),
  //     // frontMatterIds: toJS(this.book.frontMatterIds),

  //     chi: toJS(ch),
  //     // chapterIds: toJS(this.book.chapterIds),

  //     // backMatter: toJS(bm),
  //     // backMatterIds: toJS(this.book.backMatterIds),

  //     modifiedAt: new Date()
  //   };

  //   // Persist changes
  //   const allPromises: Promise<void>[] = [];
  //   allPromises.push(UpdateChapterTemplateMeta(meta._id, {
  //     title: meta.title,
  //     subtitle: meta.subtitle,
  //     image: meta.image,
  //     type: meta.type,
  //     numbered: meta.numbered,
  //     includeIn: meta.includeIn,
  //     startOn: meta.startOn,
  //     templateId: meta.templateId,
  //     allChangesSynced: false,
  //     fullpageImage: toJS(meta.fullpageImage),
  //   }));

  //   // allPromises.push(UpdateBookInDB(this.book._id, {
  //   //   modifiedAt: new Date(),
  //   //   frontMatterIds: book.frontMatterIds,
  //   //   chapterIds: book.chapterIds,
  //   //   backMatterIds: book.backMatterIds,
  //   //   allChangesSynced: false,
  //   // }));

  //   await Promise.all(allPromises);

  //   // this.setBook(book);
  //   this.setChapter(meta);

  //   const tmpMeta = { ...meta };
  //   delete tmpMeta["__v"];
  //   delete tmpMeta["children"];
  //   delete tmpMeta["createdAt"];
  //   delete tmpMeta["lastUpdateAt"];

  //   this.debouncedSyncChapterChangesToServer(book._id, meta._id, { ...tmpMeta });
  // }

  addNewChapter = async (
    section: SectionType,
    type: IChapterStore.ChapterType,
    chapter?: Partial<IChapterStore.ChapterMeta>,
    body?: Node[],
    insertAtEnd?: boolean
  ): Promise<IChapterStore.ChapterMeta | void> => {
    if (!this.book._id) return;
    const _b = body ? body : initBody;
    const chapterId = chapter?._id ? chapter._id : generateRandomString(16);
    const newChapterBody: IChapterStore.ChapterBody = {
      _id: chapterId,
      bookId: this.book._id,
      children: _b,
    };
    let chps: Array<string> = [];

    if (section === "frontMatter") {
      chps = this.book.frontMatterIds;
    }

    if (section === "body") {
      chps = this.book.chapterIds;
    }

    if (section === "backMatter") {
      chps = this.book.backMatterIds;
    }

    let indx = insertAtEnd ? chps.length : (indexOf(chps, this.chapter._id) + 1);

    if (indx < 1)
      indx = chps.length + 1;

    const _c : IChapterStore.ChapterMeta = {
      _id: chapterId,
      bookId: this.book._id,
      title: type === "chapter" ? (chapter?.title? chapter.title : `Chapter ${indx}`) : getChapterById(type).name,
      subtitle: "",
      image: "",
      type: type,
      index: indx,
      startOn: "any",
      allChangesSynced: false,
    };

    const newChapterMeta: IChapterStore.ChapterMeta = chapter ? {
      ..._c,
      ...chapter
    } : _c;


    let frontMatter = {
      all: toJS(this.book.frontMatter),
      ids: toJS(this.book.frontMatterIds)
    };

    let chapters = {
      all: toJS(this.book.chapters),
      ids: toJS(this.book.chapterIds)
    };

    let backMatter = {
      all: toJS(this.book.backMatter),
      ids: toJS(this.book.backMatterIds)
    };


    if (section === "frontMatter") {
      frontMatter = {
        all: this.addAfter(frontMatter.all, indx, newChapterMeta),
        ids: this.addAfter(frontMatter.ids, indx, newChapterMeta._id),
      };
    }

    if (section === "body") {
      chapters = {
        all: this.addAfter(chapters.all, indx, newChapterMeta),
        ids: this.addAfter(chapters.ids, indx,  newChapterMeta._id)
      };
    }

    if (section === "backMatter") {
      backMatter = {
        all: this.addAfter(backMatter.all, indx, newChapterMeta),
        ids: this.addAfter(backMatter.ids, indx, newChapterMeta._id),
      };
    }

    const newBook = {
      ...this.book,
      frontMatter: frontMatter.all,
      chapters: chapters.all,
      backMatter: backMatter.all,
      frontMatterIds: frontMatter.ids,
      chapterIds: chapters.ids,
      backMatterIds: backMatter.ids,
      modifiedAt: new Date(),
    };

    this.setBook(newBook);
    /**
     *  avoid focusing the newly created chapter for offline chapters
     */
    if(chapterId.indexOf("offline") === -1){
      this.setBody(_b);
      this.setChapter(newChapterMeta);
    }

    const allPromises: Promise<unknown>[] = [];
    allPromises.push(db.chapterMetas.add(newChapterMeta));
    allPromises.push(db.chapterBodies.add(newChapterBody));
    allPromises.push(UpdateBookInDB(newBook._id, {
      frontMatterIds: newBook.frontMatterIds,
      chapterIds: newBook.chapterIds,
      backMatterIds: newBook.backMatterIds,
      allChangesSynced: false,
    }));

    await Promise.all(allPromises);

    await this.syncNewChapterToServer({
      ...newChapterMeta,
      ...newChapterBody
    } as IChapterStore.Chapter);

    await this.syncBookChangesToServer(newBook._id, {
      frontMatterIds: newBook.frontMatterIds,
      chapterIds: newBook.chapterIds,
      backMatterIds: newBook.backMatterIds,
    });

    return newChapterMeta;
  }
  addAfter = (array, index, newItem) => {
    return [
        ...array.slice(0, index),
        newItem,
        ...array.slice(index)
    ];
  }
  // unite = (arr: any[], chp: any, id: string) => {
  //   return unionBy(arr, [arr], "_id");
  // }

  deleteChapter = async (id: string, next?: boolean): Promise<void> => {
    if (!this.book) return;

    if (next) {
      this.next(id, toJS(this.book.chapters));
    }
    const newBook: IBookStore.ExpandedBook = {
      ...this.book,
      ...({ frontMatterIds: toJS(this.book.frontMatterIds.filter(c => c !== id)) }),
      ...({ frontMatter: toJS(this.book.frontMatter.filter(c => c._id !== id)) }),
      ...({ chapterIds: toJS(this.book.chapterIds.filter(c => c !== id)) }),
      ...({ chapters: toJS(this.book.chapters.filter(c => c._id !== id)) }),
      ...({ backMatterIds: toJS(this.book.backMatterIds.filter(c => c !== id)) }),
      ...({ backMatter: toJS(this.book.backMatter.filter(c => c._id !== id)) }),
      modifiedAt: new Date(),
    };

    const partialBookUpdates: Partial<IBookStore.Book> = {
      frontMatterIds: newBook.frontMatterIds,
      chapterIds: newBook.chapterIds,
      backMatterIds: newBook.backMatterIds,
      allChangesSynced: false
    };

    try {
      await this.syncDeleteChapterToServer(newBook._id, id);

      this.setBook(newBook);

      const allPromises: Promise<unknown>[] = [];
      allPromises.push(db.chapterMetas.where("_id").equals(id).delete());
      allPromises.push(db.chapterBodies.where("_id").equals(id).delete());
      allPromises.push(UpdateBookInDB(newBook._id, partialBookUpdates));

      await Promise.all(allPromises);
    } catch (e) {
      console.log(e);
      alert("Error deleting chapter");
    }
  }

  sortChapters = async (frontMatter: IChapterStore.ChapterMeta[], chapters: IChapterStore.ChapterMeta[], backMatter: IChapterStore.ChapterMeta[]): Promise<void> => {
    const b = {
      ...this.book,
      // frontmatter

      frontMatter: toJS(frontMatter),
      frontMatterIds: toJS(frontMatter.map(d => d._id)),

      // body
      chapters: toJS(chapters),
      chapterIds: toJS(chapters.map(d => d._id)),

      // backmatter
      backMatter: toJS(backMatter),
      backMatterIds: toJS(backMatter.map(d => d._id)),
    };
    this.setBook(b);

    await UpdateBookInDB(b._id, {
      frontMatterIds: b.frontMatterIds,
      chapterIds: b.chapterIds,
      backMatterIds: b.backMatterIds,
      allChangesSynced: false,
    });
    await this.syncBookChangesToServer(b._id, {
      frontMatterIds: b.frontMatterIds,
      chapterIds: b.chapterIds,
      backMatterIds: b.backMatterIds,
    });
  }

  orderByIndex = (book: IChapterStore.ChapterMeta[]) => {
    return toJS(book).sort((a, b) => a.index - b.index).map((d, i) => ({
      ...d,
      index: i
    }));
  };

  mergeChapter = async (section: SectionType, id: string): Promise<void> => {
    let index = -1;
    let c: IChapterStore.ChapterMeta | null = null;
    let cn: IChapterStore.ChapterMeta | null = null;

    if (section === "frontMatter") {
      index = findIndex(this.book.frontMatter, { "_id": id });
      c = this.book.frontMatter[index];
      cn = this.book.frontMatter[index + 1];
    }

    if (section === "body") {
      index = findIndex(this.book.chapters, { "_id": id });
      c = this.book.chapters[index];
      cn = this.book.chapters[index + 1];
    }

    if (section === "backMatter") {
      index = findIndex(this.book.backMatter, { "_id": id });
      c = this.book.backMatter[index];
      cn = this.book.backMatter[index + 1];
    }

    if (c && cn) {
      const ab = await db.chapterBodies.get(c._id);
      const bb = await db.chapterBodies.get(cn._id);

      if (ab && bb) {
        const titleNodes = [{ type: "h2", children: [{ text: cn.title }] }];
        if (cn.subtitle && cn.subtitle.length > 0) titleNodes.push({ type: "h2", children: [{ text: cn.subtitle }] });

        const body = [
          ...(ab.children),
          ...titleNodes,
          ...(bb.children)
        ];

        await this.saveChapterBodyUpdates(id, body);
        await this.deleteChapter(cn._id);
        this.setChangeCount();
      }
    } else {
      alert("No next Chapter");
    }
  }
  getIdsbyMatter = (m: Array<"back" | "front" | "body">) => {
    let ids : Array<string> = [];
    if (m.includes("front"))
      ids = [...ids, ...this.book.frontMatterIds];
    if (m.includes("back"))
      ids = [...ids, ...this.book.backMatterIds];
    if (m.includes("body"))
      ids = [...ids, ...this.book.chapterIds];

    return ids;
  }
  getBookBodies = async () => {
    const ids = this.book.chapterIds.filter(d => d !== this.chapter._id);

    const promises: any = [];

    ids.forEach(function (id) {
      promises.push(
        db.chapterBodies.get(id).then((data) => data?.children)
      );
    });

    const dd = await Promise.all(promises).then((files) => files.reduce((words: number, body) => {
      return words = words + countWords(body);
    }, 0));

    return {
      words: dd,
      ids: this.book.chapterIds
    };
  }

  debouncedSyncChapterChangesToServer = debounce(this.syncChapterChangesToServer, 1000);
  /**
   * same as debouncedSyncChapterChangesToServer but has a longer debounce period
   * saved the chapter body in mongodb when its too large to be sent over sockets
   */
  debouncedSyncChapterBodyToServer = debounce(this.syncChapterChangesToServer, 3000);
  debouncedSaveChapterBodyLocal = debounce(this.saveChapterBodyToLocal, 1000);
  debounceUpdatelocalChapterLastSyncTime = debounce(this.updatelocalChapterLastSyncTime, 1000);
  debouncedSaveChapterMetaUpdates = debounce(this.saveChapterMetaUpdates, 400);
  debouncedSaveChapterTemplateBodyUpdates = debounce(this.saveChapterTemplateBodyUpdates, 600);
  // debouncedSaveChapterTemplateUpdates = debounce(this.saveChapterTemplateMetaUpdate, 400);

  putOfflineChapter = async (id: string, title: string, body: Node[]) => {
    const originChp = await GetChapterFromDB(id);
    const _m = this.getChapterMatterById(id);
    if(originChp !== null && ["uncategorized", "chapter", "custom"].includes(originChp.type)) {
        await this.addNewChapter(
            _m,
            originChp.type,
            {
                _id: id + `_offline_${Date.now()}`,
                title,
            },
            body
        );
    }
  }

  putOfflineChapterRemote = async(chapterId: string) => {
    const chapter = await GetChapterFromDB(chapterId);
    if(chapter){
      const resp = await AtticusClient.SaveConflictChapter(chapter, "OFFLINE");
      return resp;
    }
  }

  exportBook = async (bookId: string, type: "pdf" | "epub" | "docx"): Promise<ExportResponse> => {
    await syncBook(bookId);
    const resp = await AtticusClient.ExportBook(bookId, type);
    return resp;
  }

  exportSpanshot = async (bookId: string): Promise<IBookStore.Book | undefined> => {
    const fullOfflineBook = await GetBookFromDB(bookId, { chapterBodies: true, chapterMeta: true });

    if (fullOfflineBook) {
      fullOfflineBook.chapters = [
        ...(fullOfflineBook.chapters ? fullOfflineBook.chapters : []),
        ...(fullOfflineBook.frontMatter ? fullOfflineBook.frontMatter : []),
        ...(fullOfflineBook.backMatter ? fullOfflineBook.backMatter : []),
      ];
      delete fullOfflineBook["frontMatter"];
      delete fullOfflineBook["backMatter"];
    }

    return fullOfflineBook;
  }

  saveSpanshot = async(bookId: string): Promise<boolean> => {
    const snapshot = await this.exportSpanshot(bookId);

    if (snapshot) {
      await AtticusClient.SaveSnapshot(bookId, JSON.stringify(snapshot));
    }

    return true;
  }

  addNewChapterFromTemplate = async (
    chapterTemplate: IChapterTemplateBase,
    type: IChapterStore.ChapterType,
    section?: string
  ): Promise<void> => {
    if (!this.book._id) return;
    const chapterId = generateRandomString(16);
    const newChapterBody: IChapterStore.ChapterBody = {
      _id: chapterId,
      bookId: this.book._id,
      children: chapterTemplate.children,
    };
    let chps: Array<string> = [];

    console.log({type, section, chapterTemplate});
    if (section === "frontMatter") {
      chps = this.book.frontMatterIds;
    }

    if (section === "body") {
      chps = this.book.chapterIds;
    }

    if (section === "backMatter") {
      chps = this.book.backMatterIds;
    }

    let indx = indexOf(chps, this.chapter._id) + 1;

    if (indx < 1)
      indx = chps.length + 1;

    const _c = {
      _id: chapterId,
      bookId: this.book._id,
      title: chapterTemplate.title,
      subtitle: chapterTemplate.subtitle,
      image: chapterTemplate.image,
      type: type,
      index: indx,
      startOn: chapterTemplate.startOn || "any",
      allChangesSynced: false,
      templateId: chapterTemplate._id
    };

    const newChapterMeta: IChapterStore.ChapterMeta = _c;


    let frontMatter = {
      all: toJS(this.book.frontMatter),
      ids: toJS(this.book.frontMatterIds)
    };

    let chapters = {
      all: toJS(this.book.chapters),
      ids: toJS(this.book.chapterIds)
    };

    let backMatter = {
      all: toJS(this.book.backMatter),
      ids: toJS(this.book.backMatterIds)
    };


    if (section === "frontMatter") {
      frontMatter = {
        all: this.addAfter(frontMatter.all, indx, _c),
        ids: this.addAfter(frontMatter.ids, indx, _c._id),
      };
    }

    if (section === "body") {
      chapters = {
        all: this.addAfter(chapters.all, indx, _c),
        ids: this.addAfter(chapters.ids, indx,  _c._id)
      };
    }

    if (section === "backMatter") {
      backMatter = {
        all: this.addAfter(backMatter.all, indx, _c),
        ids: this.addAfter(backMatter.ids, indx, _c._id),
      };
    }

    const newBook = {
      ...this.book,
      frontMatter: frontMatter.all,
      chapters: chapters.all,
      backMatter: backMatter.all,
      frontMatterIds: frontMatter.ids,
      chapterIds: chapters.ids,
      backMatterIds: backMatter.ids,
      modifiedAt: new Date(),
    };

    this.setBody(chapterTemplate.children);
    this.setBook(newBook);
    this.setChapter(newChapterMeta);

    const allPromises: Promise<unknown>[] = [];
    allPromises.push(db.chapterMetas.add(newChapterMeta));
    allPromises.push(db.chapterBodies.add(newChapterBody));
    allPromises.push(UpdateBookInDB(newBook._id, {
      frontMatterIds: newBook.frontMatterIds,
      chapterIds: newBook.chapterIds,
      backMatterIds: newBook.backMatterIds,
      allChangesSynced: false,
    }));

    await Promise.all(allPromises);

    await this.syncNewChapterToServer({
      ...newChapterMeta,
      ...newChapterBody
    } as Chapter);

    await this.syncBookChangesToServer(newBook._id, {
      frontMatterIds: newBook.frontMatterIds,
      chapterIds: newBook.chapterIds,
      backMatterIds: newBook.backMatterIds,
    });
  }

  getCurrentStoredBook = (): IBookStore.ExpandedBook => {
    return this.book;
  }

  getAllEndNotesOfBook = async (): Promise<PdfSlateEndnote[]> => {
    const { frontMatterIds, chapterIds, backMatterIds } = this.getCurrentStoredBook();
    const allChapterIds = [...frontMatterIds, ...chapterIds, ...backMatterIds];
    const chapterData = await this.getChapterBodyById(allChapterIds);
    return getBookEndnotes(chapterData);
  };

}

export default new BookStore();
