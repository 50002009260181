import React, {useState} from "react";
import { observer } from "mobx-react";
import { Row, Col, Radio, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import querystring from "querystring";

const BookToolbar = observer(() => {
	const { push } = useHistory();
	const { search } = useLocation();

	const queryObj = querystring.parse(search.replace("?", ""));

	const [tab, setTab] = useState(queryObj.tab || "recent");
	const [searchTerm, setSearchTerm] = useState(queryObj.searchTerm || "");
	const [sort, setSort] = useState(queryObj.sort || "modified");

	return (
    <div style={{ width: "100%" }}>
      <div style={{ height: "2em"}}></div>
      <Row justify="space-between">
        <Col>
          <Input
            size="middle"
            prefix={<SearchOutlined />}
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              push({
                search: querystring.stringify({
                  searchTerm: e.target.value,
                  tab: tab,
                  sort: sort
                }),
              });
            }}
            style={{ width: 400 }}
          />
        </Col>
        <Col>
          {!searchTerm ? (
            <>
              <label className="toolbar-label">Display by</label>
              <Radio.Group
                size="middle"
                className="white"
                defaultValue="recent"
                style={{
                  marginRight: "1rem",
                }}
                value={tab}
                onChange={(e) => {
                  setTab(e.target.value);
                  push({
                    search: querystring.stringify({
                      searchTerm: searchTerm,
                      tab: e.target.value,
                      sort: sort
                    }),
                  });
                  // setTab(e.target.value);
                }}
                buttonStyle="solid"
              >
                <Radio.Button value="recent">Recent</Radio.Button>
                <Radio.Button value="project">Projects</Radio.Button>
                <Radio.Button value="author">Author</Radio.Button>
                <Radio.Button value="tags">Version</Radio.Button>
                {/* <Radio.Button value="templates">Templates</Radio.Button> */}
              </Radio.Group>
              <label className="toolbar-label">Sort by</label>
              <Radio.Group
                size="middle"
                className="white"
                defaultValue="modified"
                value={sort}
                onChange={(e) => {
                  setSort(e.target.value);
                  push({
                    search: querystring.stringify({
                      searchTerm: searchTerm,
                      tab: tab,
                      sort: e.target.value,
                    }),
                  });
                }}
                buttonStyle="solid"
              >
                <Radio.Button value="modified">Date Modified</Radio.Button>
                <Radio.Button value="alpha">A-Z</Radio.Button>
              </Radio.Group>
              <label className="toolbar-label"></label>
              <Radio.Group
                size="middle"
                className="white"
                defaultValue="recent"
                style={{
                  marginRight: "1rem",
                }}
                value={tab}
                onChange={(e) => {
                  setTab(e.target.value);
                  push({
                    search: querystring.stringify({
                      searchTerm: searchTerm,
                      tab: e.target.value,
                      sort: sort
                    }),
                  });
                  // setTab(e.target.value);
                }}
                buttonStyle="solid"
              >
                <Radio.Button value="templates">Templates</Radio.Button>
              </Radio.Group>
            </>
          ) : null}
        </Col>
      </Row>
    </div>
  );
});

export default BookToolbar;
