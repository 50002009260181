import { Node } from "slate";

import {
  DEFAULTS_ALIGN,
  DEFAULTS_BLOCKQUOTE,
  DEFAULTS_BOLD,
  DEFAULTS_CODE,
  DEFAULTS_CODE_BLOCK,
  DEFAULTS_HEADING,
  //DEFAULTS_HIGHLIGHT,
  DEFAULTS_IMAGE,
  DEFAULTS_ITALIC,
  DEFAULTS_KBD,
  DEFAULTS_LINK,
  DEFAULTS_LIST,
  DEFAULTS_MEDIA_EMBED,
  DEFAULTS_MENTION,
  DEFAULTS_PARAGRAPH,
  //DEFAULTS_SEARCH_HIGHLIGHT,
  DEFAULTS_STRIKETHROUGH,
  DEFAULTS_SUBSUPSCRIPT,
  DEFAULTS_TABLE,
  DEFAULTS_TODO_LIST,
  DEFAULTS_UNDERLINE,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  isBlockAboveEmpty,
  isSelectionAtBlockStart,
  ResetBlockTypePluginOptions,
  getSelectableElement,
  setDefaults,
  isNodeType,
} from "@udecode/slate-plugins";

import { DragIndicator } from "@styled-icons/material/DragIndicator";

import { DEFAULTS_ORNAMENTAL_BREAK } from "../plugins/OrnamentalBreak/defaults";
import { DEFAULTS_SMALLCAPS } from "../plugins/Smallcaps";
import { DEFAULTS_MONOSPACE } from "../plugins/Monospace";
import { DEFAULTS_SANSSERIF } from "../plugins/SansSerif";

import React, { ReactNode } from "react";

export const headingTypes = [
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
];

const defaultOptions = {
  ...setDefaults(DEFAULTS_PARAGRAPH, {}),
  ...setDefaults(DEFAULTS_MENTION, {}),
  ...setDefaults(DEFAULTS_BLOCKQUOTE, {}),
  ...setDefaults(DEFAULTS_CODE_BLOCK, {}),
  ...setDefaults(DEFAULTS_LINK, {}),
  ...setDefaults(DEFAULTS_IMAGE, {}),
  ...setDefaults(DEFAULTS_MEDIA_EMBED, {}),
  ...setDefaults(DEFAULTS_TODO_LIST, {}),
  ...setDefaults(DEFAULTS_TABLE, {}),
  ...setDefaults(DEFAULTS_LIST, {}),
  ...setDefaults(DEFAULTS_HEADING, {}),
  ...setDefaults(DEFAULTS_ALIGN, {}),
  ...setDefaults(DEFAULTS_BOLD, {}),
  ...setDefaults(DEFAULTS_ITALIC, {}),
  ...setDefaults(DEFAULTS_UNDERLINE, {}),
  ...setDefaults(DEFAULTS_STRIKETHROUGH, {}),
  ...setDefaults(DEFAULTS_CODE, {}),
  ...setDefaults(DEFAULTS_KBD, {}),
  ...setDefaults(DEFAULTS_SMALLCAPS, {}),
  ...setDefaults(DEFAULTS_MONOSPACE, {}),
  ...setDefaults(DEFAULTS_SANSSERIF, {}),
  ...setDefaults(DEFAULTS_SUBSUPSCRIPT, {}),
  //...setDefaults(DEFAULTS_HIGHLIGHT, {}),
  //...setDefaults(DEFAULTS_SEARCH_HIGHLIGHT, {}),
  ...setDefaults(DEFAULTS_ORNAMENTAL_BREAK, {}),
};

export const inlineTypes = [defaultOptions.mention.type, defaultOptions.link.type];

const resetBlockTypesCommonRule = {
  types: [defaultOptions.bold.type, defaultOptions.code_block.type, defaultOptions.todo_li.type],
  defaultType: defaultOptions.p.type,
};

export const optionsResetBlockTypes: ResetBlockTypePluginOptions = {
  rules: [
    {
      ...resetBlockTypesCommonRule,
      hotkey: "Enter",
      predicate: isBlockAboveEmpty,
    },
    {
      ...resetBlockTypesCommonRule,
      hotkey: "Backspace",
      predicate: isSelectionAtBlockStart,
    },
  ],
};

// Draggable Componets

export const draggableComponentOptionList = [
  { ...defaultOptions.p, level: 1 },
  defaultOptions.blockquote,
  defaultOptions["ornamental-break"],
  { ...defaultOptions.h2, level: 1 },
  defaultOptions.img,
  defaultOptions.link,
  { ...defaultOptions.ol, level: 1 },
  { ...defaultOptions.ul, level: 1 },
  defaultOptions.table,
  defaultOptions.media_embed,
  defaultOptions.code_block,
].map(
  ({
    type,
    level,
    component,
    ...defaultOptions
  }: {
    type: string;
    level?: number;
    component: ReactNode;
  }) => [
      type,
      {
        ...defaultOptions,
        component: getSelectableElement({
          component,
          level,
          dragIcon: (
            <DragIndicator
              style={{
                left: 0,
                width: 18,
                height: 18,
                color: "rgba(55, 53, 47, 0.3)",
              }}
            />
          ),
          styles: {
            blockAndGutter: {
              padding: "4px 0",
            },
            blockToolbarWrapper: {
              height: "1.5em",
            },
          },
        }),
        rootProps: {
          styles: {
            root: {
              margin: 0,
              lineHeight: "1.5",
            },
          },
        },
      },
    ]
);

export const options = {
  ...defaultOptions,
  //...Object.fromEntries(draggableComponentOptionList),
};

// States

export const emptySlateState = [
  {
    children: [
      {
        type: "p",
        children: [
          {
            text: "",
          },
        ],
      },
    ],
  },
] as Node[];

export const setNodeId = (nodes: Node[]): void => {
  let id = 10000;
  nodes.forEach((node) => {
    const children = node.children as Node[];
    children?.forEach((block) => {
      block.id = id;
      id++;
    });
  });
};

setNodeId(emptySlateState);