import React, { useState } from "react";
import { Drawer, Card } from "antd";
import { MenuOutlined } from "@ant-design/icons";

interface LayoutProps {
    editor: any,
    previewer?: any,
    list: any
}

export default function Book({ list, previewer, editor }: LayoutProps) {
	const [visible, setVisible] = useState(false);

	const showDrawer = () => setVisible(true);

	const onClose = () => setVisible(false);

	return (
		<div style={{ display: "flex" }}>
			<div className="default__menu--view">{list}</div>			
			<Drawer
				width={400}
				placement="left"
				closable={false}
				onClose={onClose}
				visible={visible}
				bodyStyle={{
					backgroundColor: "#e7f1fd"
				}}
			>
				{list}
			</Drawer>			
			<MenuOutlined className="drawer__menu--icon" onClick={showDrawer} style={{ fontSize: 15, paddingTop: 20, marginRight: 10, marginLeft: 10 }} />
			{editor}
			{
				previewer && (
					<div className="previewer">
						{previewer}
					</div>
				)
			}
		</div>
	);
}
