import React, { FunctionComponent, useEffect, useState } from "react";
import { Checkbox, Form, Radio, RadioChangeEvent } from "antd";
import { PicCenterOutlined, PicLeftOutlined, PicRightOutlined } from "@ant-design/icons";
import { ImageFlow } from "../types";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

interface ImageFlowComponentProps {
    flowValue?: ImageFlow;
    wrapValue?: boolean;
    disableWrap?: boolean;
    separatePageValue?: boolean;
    flowChange?: (flow?: ImageFlow, wrap?: boolean) => void
    separatePageChange: (separateValue?: boolean | undefined) => void
}

export const ImageFlowComponent: FunctionComponent<ImageFlowComponentProps> = ({ flowValue, wrapValue, disableWrap, separatePageValue, flowChange, separatePageChange }) => {
    const [flow, setFlow] = useState<ImageFlow>();
    const [wrap, setWrap] = useState<boolean>();

    const handleFlowChange = (event: RadioChangeEvent) => {
        const milddleSelected = (event.target.value as ImageFlow) === "middle";
        if (milddleSelected) setWrap(false);
        setFlow(event.target.value);
        if (flowChange) flowChange(event.target.value, milddleSelected ? false : wrap);
    };

    const handleWrapCheckboxChange = (event: CheckboxChangeEvent) => {
        setWrap(event.target.checked);
        if (flowChange) flowChange(flow, event.target.checked);
    };

    const handleSeparatePageChange = (event: CheckboxChangeEvent) => {
        if(event.target.checked !== undefined) separatePageChange(event.target.checked);
    };

    useEffect(() => {
        if (flowValue) setFlow(flowValue);
        if (wrapValue) setWrap(disableWrap ? false : wrapValue);
    }, [flowValue, wrapValue, disableWrap]);

    return (
        <Form.Item label="Alignment" labelAlign="left" colon={false}>
            <div className="flow-control-container">
                <div className="flow-radio-button-container">
                    <Radio.Group
                        value={flow}
                        onChange={handleFlowChange}
                        optionType="button"
                        buttonStyle="solid"
                    >
                        <Radio.Button value="left">
                            <PicLeftOutlined />
                        </Radio.Button>
                        <Radio.Button value="middle">
                            <PicCenterOutlined />
                        </Radio.Button>
                        <Radio.Button value="right">
                            <PicRightOutlined />
                        </Radio.Button>
                    </Radio.Group>
                </div>
                <div className="wrap-check-box-container">
                    <Checkbox checked={wrap} onChange={handleWrapCheckboxChange} disabled={flow === "middle" || disableWrap}>Wrap image</Checkbox>
                    <Checkbox checked={separatePageValue} onChange={handleSeparatePageChange}>Separate Page</Checkbox>
                </div>
            </div>
        </Form.Item>
    );
};