import React, {useEffect} from "react";

import useLocalStorage from "../../utils/hooks/useLocalStorage";

import useRootStore from "../../store/useRootStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";

const EditorWrapper = observer(({ children } : any) => {
  const {patchEditor, editor} = useRootStore().appStore;
  const [_conf, setConf] = useLocalStorage("atticusEdtrConf", "");
  const {font, fontsize, indentation, justified, linespacing} = toJS(editor);

  useEffect(() => {
    if(_conf){
      patchEditor(JSON.parse(_conf) as IAppStore.EditorConfigs);
    }
  }, []);

  const styles = `
    .editor-area .slate-p {
      ${font ? "font-family: " + font + ";" : ""}
      font-size: ${fontsize}px;
      line-height: ${linespacing};
      text-indent: ${indentation !== "indent" ? 0 : (fontsize * 2) + "px"};
      margin-bottom: ${indentation !== "indent" ? fontsize + "px" : "0.125em"};
      ${justified ? "text-align: justify; text-justify: inter-word;" : ""}
    }
  `;

  return <style>{styles}</style>;
});

export default EditorWrapper;