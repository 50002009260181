import React from "react";
import { observer } from "mobx-react";
import { Radio, Row, Modal } from "antd";

import useRootStore from "../../store/useRootStore";

const ThemeSwitcher = observer(() => {
	const { writing, themeview, setView, setCustomThemeBuilderView, customThemeBuilderView} = useRootStore().bookStore;

	const setCustomThemeBuilder = (e) => {
		if (customThemeBuilderView === "customThemeStep2" && e.target.value === "content") {
			onBackPress();
		} else {
			setView(e.target.value === "theme" ? true : false);
			setCustomThemeBuilderView("customThemeStep1");
			// setSprintTime(0);
		}
	};

	const onBackPress = () => {
		Modal.confirm({
			icon: null,
			title: <h2 className="section-heading">Leave the custom theme builder?</h2>,
			centered: true,
			onOk: () => {
				// setCustomThemeBuilderView("customThemeStep1");
			},
			okText: "Continue working",
			okButtonProps: {
				type: "primary",
				danger: true,
				style: {
					flex: 1,
				},
			},
			cancelText: "Leave without saving",
			onCancel: () => {
				setView(false);
			},
			cancelButtonProps: {
				className: "btn-a",
				style: {
					flex: 1,
				},
			},
		});
	};

	return !writing ? (
		<div className="view-switch">
			<Radio.Group defaultValue={themeview ? "theme" : "content"} size="middle" buttonStyle="solid" onChange={setCustomThemeBuilder}>
				<Radio.Button value="content">Content</Radio.Button>
				<Radio.Button value="theme">Theme</Radio.Button>
			</Radio.Group>
		</div>
	) : <div style={{ height: 75 }}></div>;
});

export default ThemeSwitcher;