import React, { FunctionComponent } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";

//Provider 
import Provider from "./Provider";

/* PARTIALS */
//Layout
import LayoutWithAuth from "./components/Shared/Layouts/WithAuth";

/* PAGES */
// Auth
import SignIn from "./components/Auth/SignIn";
import SignUp from "./components/Auth/SignUp";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ResetPassword from "./components/Auth/ResetPassword";
import SignOut from "./pages/SignOut";
import MyProfile from "./components/Profile/MyProfile";
import ChangePassword from "./components/Profile/ChangePassword";
import ProfileLayout from "./components/Shared/Layouts/ProfileLayout";
import { BookDebugger } from "./components/Debug/BookDebugger";

// Shelf
import { MyBooks } from "./components/Books/MyBooks";
import { Welcome } from "./components/Welcome/Welcome";

// Backup
import Backup from "./pages/Backup";

// Editor
import Editor from "./components/Editor/EditorView";
import ServiceWorkerWrapper from "./ServiceWorkerWrapper";
import RendererCacheInitiator from "./components/Shared/RendererCacheInitiator/RendererCacheInitiator";
import { Maintenance } from "./components/Maintenance/Maintenance";

const Routes: FunctionComponent = () => {

	// set the REACT_APP_MAINTENANCE_MODE=true to enable the Maintenance Mode
	const MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE_MODE === "true";

	if(MAINTENANCE_MODE){
		return <Maintenance />;
	}
	
	return (
		<Provider>
			<>
				<Router>
					<Switch>
						<Route path="/auth/sign-in" component={SignIn} />
						<Route path="/auth/create-account" component={SignUp} />
						<Route path="/auth/forgot-password" component={ForgotPassword} />
						<Route path="/auth/reset-password" component={ResetPassword} />
						<Route path="/auth/sign-out" component={SignOut} />
						<Route>
							<LayoutWithAuth>
								<Switch>
									<Route path="/books/:bookId/:mode?" exact>
										<RendererCacheInitiator/>
										<Editor />
									</Route>
									<Route path="/chapter-template/:bookId/:mode?" exact>
										<RendererCacheInitiator/>
										<Editor />
									</Route>
									<Route path="/" exact>
										<MyBooks />
									</Route>
									<Route path="/welcome" exact>
										<Welcome />
									</Route>
                                    <Route path="/debug" exact>
                                        <BookDebugger />
                                    </Route>
                                    <Route path="/backup" exact component={Backup} />
									<Route path="/profile/*" exact>
										<ProfileLayout>
											{props => (
												<>
													<Route path="/profile/change-password" component={ChangePassword} exact />
													<Route path="/profile/my-profile" render={routeProps => <MyProfile {...props} {...routeProps} />} />
												</>
											)}
										</ProfileLayout>
									</Route>		
								</Switch>
							</LayoutWithAuth>
						</Route>
					</Switch>
				</Router>
				<ServiceWorkerWrapper></ServiceWorkerWrapper>

			</>
		</Provider>
	);
};

export default Routes;