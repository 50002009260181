import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Button, Modal, Progress } from "antd";
import {
  CloseOutlined,
  DownloadOutlined,
  DownOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import moment from "moment";
import useRootStore from "../../../store/useRootStore";
import "./PDFExporterStyles.scss";
import { Task, useTaskQueueAPI } from "../../../workers";
import { observer } from "mobx-react";
import { find } from "lodash";

type GenerateBookTaskData = { progress?: number; url?: string };
type GenerateBookTaskMetadata = { bookId: string; bookName: string };
interface ExportItemProps {
  task: Task<GenerateBookTaskData, GenerateBookTaskMetadata>;
  onRemove?: () => void;
}

/**
 * Reference https://github.com/eligrey/FileSaver.js/blob/master/src/FileSaver.js
 * @param uri
 * @param fileName
 */
const downloadFile = (uri: string, fileName: string) => {
  const linkElement = document.createElement("a");
  linkElement.download = `${fileName}.pdf`;
  linkElement.href = uri;
  linkElement.rel = "noopener";
  linkElement.target = "_blank";
  try {
    linkElement.dispatchEvent(new MouseEvent("click"));
  } catch (error) {
    const evt = document.createEvent("MouseEvents");
    evt.initMouseEvent(
      "click",
      true,
      true,
      window,
      0,
      0,
      0,
      80,
      20,
      false,
      false,
      false,
      false,
      0,
      null
    );
    linkElement.dispatchEvent(evt);
  }
};

const ExportItem: FunctionComponent<ExportItemProps> = ({ task, onRemove }) => {
  const { createdAt, data, status, metadata } = task;
  // const createdTimeString = useMemo(() => moment(createdAt).fromNow(), [createdAt]);
  const [createdTimeString, setCreatedTimeString] = useState(() =>
    moment(createdAt).fromNow()
  );
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    let destroyed = false;
    const interval = setInterval(() => {
      if (!destroyed) {
        setCreatedTimeString(moment(createdAt).fromNow());
      }
    }, 1000);
    return () => {
      destroyed = true;
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setPercentage(Math.max((data?.progress as number) * 100 - 1, 0));
    if (status === "completed") {
      setPercentage(100);
    }
  }, [status, data?.progress]);

  const isLoading = () => {
    return ["pending", "queued"].includes(status);
  };

  const handleCloseButtonPress = () => {
    if (!onRemove) return;
    onRemove();
  };

  const handleDownloadButtonPress = () => {
    if (!data?.url || !metadata?.bookName) return;
    downloadFile(data.url, metadata?.bookName);
  };

  return (
    <div className="export-item-container">
      <div className="left">
        <div className="text-container">
          <div className="name">{metadata?.bookName}</div>
          <div className="time-description">Created {createdTimeString}</div>
        </div>
      </div>
      <div className="right">
        <Progress
          type="circle"
          percent={percentage}
          width={40}
          format={() => {
            return (
              <>
                <div className="inner-progress-button">
                  {isLoading() && (
                    <LoadingOutlined
                      style={{ color: "#ff9966" }}
                      className="loading-indicator"
                    />
                  )}
                  {isLoading() && (
                    <Button
                      className={`download-cancel-button ${isLoading() && "loading"
                        }`}
                      type="primary"
                      shape="circle"
                      icon={<CloseOutlined />}
                      size="middle"
                      onClick={handleCloseButtonPress}
                    />
                  )}
                  {!isLoading() && percentage >= 100 && (
                    <Button
                      className="download-button"
                      type="primary"
                      shape="circle"
                      icon={<DownloadOutlined />}
                      size="middle"
                      onClick={handleDownloadButtonPress}
                    />
                  )}
                </div>
              </>
            );
          }}
        />
      </div>
    </div>
  );
};

const confirmCloseExporter = () => {
  return new Promise<boolean>((resolve) => {
    Modal.confirm({
      icon: null,
      title: <h2 className="section-heading">Close PDF Exports</h2>,
      content: "Closing this tab will remove all completed and pending PDF files. If you have not downloaded the PDF, you will need to re-export the file before downloading.",
      centered: true,
      onOk: () => {
        resolve(true);
      },
      onCancel: () => {
        resolve(false);
      },
      okText: "Close",
      okButtonProps: {
        type: "primary",
        danger: true,
        style: {
          flex: 1,
        },
      },
      cancelText: "Back",
      cancelButtonProps: {
        className: "btn-a",
        style: {
          flex: 1,
        },
      },
    });
  });
};

type LoadingTask = GenerateBookTaskData & GenerateBookTaskMetadata & { createdAt: Date }

const PDFExporter: FunctionComponent = () => {
  const { pdfExporterOptions } = useRootStore().appStore;
  const { getCacheStoreSnapshot } = useRootStore().pdfCacheStore;
  const { book, customThemeBuilderView, getChapterBodyById, getAllEndNotesOfBook } = useRootStore().bookStore;
  const { theme: themeConfig, customThemeHeaders, style, images, individualChapterImage } = useRootStore().themeStore;
  const [state, setState] = useState<"expanded" | "minimized">("minimized");
  const [closed, setClosed] = useState<boolean>(true);
  const [loadingTask, setLoadingTask] = useState<LoadingTask[]>([]);

  const { currentTasks, queueTask, dropTask, forceReset } = useTaskQueueAPI<
    GenerateBookTaskData,
    GenerateBookTaskMetadata
  >();

  const addLoadingTask = (data: LoadingTask) => {
    setLoadingTask((currentTasks) => {
      return [...currentTasks, data];
    });
  };

  const removeLoadingTask = (bookId: string) => {
    setLoadingTask((currentTasks) => {
      const index = currentTasks.findIndex(data => data.bookId === bookId);
      const _currentTasks = currentTasks;
      _currentTasks.splice(index, 1);
      return _currentTasks;
    });
  };

  const isTasksInExecuting = () => {
    return currentTasks.filter(task => ["executing", "status", "completed", "queued"].includes(task.status)).length > 0;
  };

  const handleCloseButtonPress = async () => {
    if (isTasksInExecuting()) {
      const canContinue = await confirmCloseExporter();
      if (!canContinue) return;
    }
    setState("minimized");
    forceReset();
    setClosed(true);
  };

  const handleWindowButtonPress = () => {
    setState(state === "expanded" ? "minimized" : "expanded");
  };

  const handleOnExportItemRemove = useCallback((task: Task) => {
    dropTask(task);
  }, []);

  const getBookData = async () => {
    const bookForGenerating = book;
    const bookEndnotes = await getAllEndNotesOfBook();
  
    if (bookEndnotes.length == 0) {
      const endnotesChapter = find(book.chapters, ["type", "endnotes"]);
      const bookChapterIds = [...bookForGenerating.chapterIds];
      const bookBackMatterIds = [...bookForGenerating.backMatterIds];
      if (endnotesChapter) {
        bookForGenerating.chapterIds = bookChapterIds.filter(e => e !== endnotesChapter._id);
        bookForGenerating.backMatterIds = bookBackMatterIds.filter(e => e !== endnotesChapter._id);
      }
    }

    const allChapters = await getChapterBodyById([...book.frontMatterIds, ...book.chapterIds, ...book.backMatterIds]);
    return {
      book: { ...bookForGenerating, chapters: allChapters },
      themeConfig,
      styles: style,
    };
  };

  const handleRenderBook = async (bookId: string, bookName: string) => {
    try {
      if (!bookId) return;

      setClosed(false);
      setState("expanded");

      addLoadingTask({ bookId, bookName, createdAt: new Date() });

      const bookData = await getBookData();
      const bookCacheStoreSnapshot = getCacheStoreSnapshot(bookId);
      const additionalData = {
        customThemeBuilderView,
        individualChapterImage,
        images,
        customThemeHeaders
      };

      removeLoadingTask(bookId);

      const parameters = JSON.stringify({
        bookData,
        bookCacheStoreSnapshot,
        additionalData,
      });

      await queueTask(
        "generate:book",
        parameters,
        {
          bookId: bookData.book._id,
          bookName: bookData.book.title,
        }
      );

    } catch (error) {
      if (error instanceof Error) {
        console.error(
          `[Local PDF Exporter]: Couldn't queue task\n${error.message}`
        );
      }
    }
  };

  useEffect(() => {
    pdfExporterOptions.renderBook = handleRenderBook;
  }, [pdfExporterOptions, handleRenderBook]);

  return (
    <>
      {!closed && (
        <div className={`pdf-exporter-container ${state}`}>
          <div className="header-container">
            <div className="left">
              <div className="text">Exports</div>
            </div>
            <div className="right">
              <Button
                className="btn-a"
                type="primary"
                shape="circle"
                icon={
                  <DownOutlined
                    className="window-state-button-icon"
                    rotate={state === "expanded" ? 0 : -180}
                  />
                }
                size="middle"
                onClick={handleWindowButtonPress}
              />
              <Button
                className="btn-a"
                type="primary"
                shape="circle"
                icon={<CloseOutlined />}
                size="middle"
                onClick={handleCloseButtonPress}
              />
            </div>
          </div>
          <div className="export-item-list-container">
            {currentTasks.map((task, index) => (
              <ExportItem
                key={`${task.taskId}:${index}`}
                task={task}
                onRemove={handleOnExportItemRemove.bind(this, task)}
              />
            ))}
            {loadingTask.map((task) => (
              <ExportItem
                key={`${task.bookId}:${task.createdAt.getTime()}`}
                task={{ status: "pending", createdAt: task.createdAt, metadata: { bookId: task.bookId, bookName: task.bookName } } as any}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default observer(PDFExporter);
