import React, { FunctionComponent, useState, useEffect } from "react";
import { Row, Col, Typography, Form, Modal, InputNumber, Button, Checkbox } from "antd";

// Icons
import { SprintTimerIcon } from "../Editor/partials/Icon";
import useRootStore from "../../store/useRootStore";

import { db } from "../../db/bookDb";

import SprintOnly from "./SprintOnly";
import SprintWithBreak from "./SprintBreak";

import SprintTimerPopup from "./SprintTimerPopup";

import "./sprint.scss";

const { Text } = Typography;

const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 24 },
};

const SprintTimer: FunctionComponent = () => {
    const { getCurrentBookId } = useRootStore().bookStore;
    const [form] = Form.useForm();

    const [sprintValues, setSprintValues] = React.useState<ISprintTimerStore.SprintTimer | null>();
    const [show, toggleShow] = useState(false);
    const [popup, togglePopup] = useState(false);
    const [showBreak, setShowBreak] = useState(false);
    const [cancel, setSprintCancel] = useState(false);


    const bkkId = getCurrentBookId();
    const statWritingTime = sprintValues?.statTime ? sprintValues?.statTime/60000 : null;
    const statBreakingTime = sprintValues?.statBreakTime ? sprintValues?.statBreakTime/60000 : null;

    const setToggleViews = () => {
        togglePopup(true);
        toggleShow(true);
    };


    const getSprintData = async () => {
        const spVal = await db.sprintTimer.where("_bookId").equals(bkkId).first();
        if (spVal) {
            setSprintValues(spVal);
        }
    };

    const onCancel = async () => {
        toggleShow(false);
    };

    const handleCancel = async () => {
        setSprintCancel(true);
    };

    const sprintTimeSubmit = async (vals) => {

        if(showBreak) {

            const tData = {
                _bookId: bkkId,
                writingTime: (vals.writingTime * 60000),
                statTime: (vals.writingTime * 60000),
                breakTime: !vals.breakTime ? 0 : (vals.breakTime * 60000),
                statBreakTime: !vals.breakTime ? 0 : (vals.breakTime * 60000),
                status: "active"
            };
    
            const timeData = await db.sprintTimer.put(tData);

        } else {

            const tData = {
                _bookId: bkkId,
                writingTime: (vals.writingTime * 60000),
                statTime: (vals.writingTime * 60000),
                // breakTime: !vals.breakTime ? 0 : (vals.breakTime * 60000),
                statBreakTime: !vals.breakTime ? 0 : (vals.breakTime * 60000),
                status: "active"
            };
    
            const timeData = await db.sprintTimer.put(tData);
        }
        

        getSprintData();
        togglePopup(false);
        toggleShow(false);
        setSprintCancel(false);
        form.resetFields();
    };
    useEffect(() => {
        getSprintData();
    }, []);

    useEffect(() => {
        getSprintData();
        if(statBreakingTime) {
            setShowBreak(true);
        }
    }, [show]);

    useEffect(() => {
       form.setFieldsValue({
         writingTime: statWritingTime,
         breakTime: statBreakingTime
        });        
    }, [show]);


    return (
        <>
            <Row>
                <Col>
                    <div >
                        <div className="sprint-timer-setting-toolbar">
                            <div className="sprint-timer-stat">
                                {!sprintValues?.writingTime && !sprintValues?.breakTime || cancel  ? (
                                    <><SprintTimerIcon /><Text onClick={() => setToggleViews()} style={{ paddingLeft: 2 }} strong>Timer</Text></>
                                ) : null}
                                {sprintValues?.writingTime && !sprintValues?.breakTime && !cancel   ? (<SprintOnly sp={sprintValues} toggle={setToggleViews} cancel={handleCancel}/>) : null}
                                {sprintValues?.breakTime && !cancel ? (<SprintWithBreak sp={sprintValues} toggle={setToggleViews} cancel={handleCancel} />) : null}
                            </div>
                        </div>
                    </div>
                    {/* <SprintTimerPopup
                        show={popup}
                        toggle={togglePopup}
                    /> */}
                    <Modal
                        visible={show}
                        onCancel={onCancel}
                        footer={null}
                        width={400}
                        title={<h2 className="section-heading">Sprint Timer</h2>}
                        bodyStyle={{
                            backgroundColor: "white"
                        }}
                    >
                        <Form {...layout}
                            className="setting-area-form"
                            form={form}
                            onFinish={sprintTimeSubmit}
                            layout="vertical"
                            initialValues={{ writingTime: statWritingTime, breakTime: statBreakingTime }}
                        >
                            <div className="book-form">
                                <Row align="stretch">
                                    <Col span={24}>
                                        <Form.Item
                                            name="writingTime"
                                            label="Writing Time"
                                            validateTrigger="onChange"
                                            rules={[{ required: true, message: "Please input a sprint time!" },
                                            () => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                    return Promise.reject();
                                                    }
                                                    if (value < 0) {
                                                    return Promise.reject("Invalid sprint time!");
                                                    }
                                                    return Promise.resolve();
                                                },
                                            })
                                        ]}
                                           
                                        >
                                            <InputNumber
                                                style={{ width: "100%", height: 40, backgroundColor: "#f7f7f7" }}
                                                type="number"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                bordered={false}
                                                placeholder="minutes"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Checkbox checked={showBreak ? showBreak : false} onChange={(c) => setShowBreak(c.target.checked)}>Set Break</Checkbox>
                                </Row>
                                <div className="spacer"></div>
                                <Row style={{ display: showBreak ? "block" : "none" }}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="breakTime"
                                            label="Break Time"
                                            validateTrigger="onChange"
                                            rules={[{ required: showBreak ? true : false, message: "Please input a break time!" },
                                            () => ({
                                                validator(_, value) {
                                                    if (value < 0) {
                                                    return Promise.reject("Invalid sprint time!");
                                                    }
                                                    return Promise.resolve();
                                                },
                                            })
                                        ]}
                                        >
                                            <InputNumber
                                                style={{ width: "100%", height: 40, backgroundColor: "#f7f7f7" }}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                bordered={false}
                                                placeholder="minutes"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Button style={{ width: "100%" }} type="primary" htmlType="submit">Start</Button>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </Modal>
                </Col>
            </Row>
        </>
    );
};

export default React.memo(SprintTimer);
