import React from "react";
import {
	Row,
	Col,
  Divider,
} from "antd";
import { observer } from "mobx-react";

import EditBookForm from "../Shared/Forms/EditBook";
import BookStats from "./BookStat";
import ExportContainer from "./ExportContainer";
import SnapshotContainer from "./SnapshotContainer";

import useRootStore from "../../store/useRootStore";

const EditBook = observer(() => {
	const { book, writing } = useRootStore().bookStore;
    const { editor_setting } = useRootStore().appStore;
	const b = book as IBookStore.Book;

	return (
		<div style={{
			width: "100%"
		}}>
			<div className="spacer"></div>
			<div
				//className={writing ? 'area book-ext-details' : "area book-details"}
				className="area"
				style={{ overflowY: "scroll", height: "82vh", padding: "1rem 2rem", minWidth: 700 }}
			>
				<Row style={{
					flexFlow: "inherit"
				}}>
					<Col flex={1} className="book-details">
						<h2>Book Details</h2>
						<EditBookForm onCancel={null} book={b} />
						<ExportContainer />
            <Divider style={{ marginBottom: 0 }} />
            <SnapshotContainer />
					</Col>
					{writing && !editor_setting.show? (
						<Col className="book-stat">
							<BookStats book={b} />
						</Col>
					) : null}
				</Row>
			</div>
		</div>
	);
});

export default EditBook;