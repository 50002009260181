import React, { FunctionComponent } from "react";
import { observer } from "mobx-react";

// 3rd party Components
import { Formik } from "formik";
import { Form, Input, Button, message } from "antd";
import TextField from "@material-ui/core/TextField";
import { useHistory, Redirect } from "react-router-dom";

// Data fetching
import { AtticusClient } from "../../api/atticus.api";

// Store
import useRootStore from "../../store/useRootStore";

import miniLogo from "../../components/Shared/assets/miniLogo.png";
import "./auth.scss";

const styles = {
	miniLogo: {
		height: 100,
		width: 100,
	},
};
// types
interface FormErrors {
  email?: string;
  password?: string;
}

const ForgotPassword: FunctionComponent = () => {
	
	const { token } = useRootStore().authStore;

	const history = useHistory();

	if (token) return <Redirect to="/" />;

	return (
		<div className="center-body">
			<Formik
				initialValues={{
					email: "",
				}}
				validate={(values) => {
					const errors: FormErrors = {};
					if (!values.email) {
						errors.email = "Required";
					}
					return errors;
				}}
				onSubmit={async (values, { setSubmitting, setFieldError }) => {
					try {
						await AtticusClient.ForgotPassword(values.email);

						message.success(`Email sent to ${values.email}`);
						history.replace("/");
					} catch (e) {
						if (e.response.status === 406) {
							const validationErrors = e.response.data.errors;
							const validationKeys = Object.keys(validationErrors);

							for (const k of validationKeys) {
								setFieldError(k, validationErrors[k].message);
							}

							message.error("Please fix the highlighted fields", 4);
						} else {
							message.error("Error Resetting your password");
						}
						setSubmitting(false);
					}
				}}
			>
				{({
					values,
					errors,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
				}) => (

					<Form
						onSubmitCapture={handleSubmit}
						colon={false}
						layout={"vertical"}
					>
						<div className="auth-logo__container">
							<img style={styles.miniLogo} src={miniLogo} />
						</div>
						<div className="card auth-card">
							<div className="margin-bottom">
								<h2 className="section-heading">{"Reset Password"}</h2>
							</div>
							<div className="form-field">
								<TextField
									error={errors.email ? true : false}
									type="email"
									name="email"
									fullWidth
									label="Email"
									value={values.email}
									helperText={errors.email}
									onChange={handleChange}
								/>
							</div>
							<div className="form-field">
								<Button 
									block 
									type="primary" 
									htmlType="submit" 
									size="large"
									loading={isSubmitting} 
									className="auth-btn btn-a"
								>
									{"Reset Password"}
								</Button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default observer(ForgotPassword);