import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import * as workers from "./workers";
import reportWebVitals from "./reportWebVitals";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import App from "./App";

// test out auth store
import authStore from "./store/Auth";

// styles
import "./ImportAnt.less";
import "./Global.scss";
import "./App.scss";

const main = async () => {
	// Wait for the token to be synced with the store
	const token = await localStorage.getItem("atticus-auth-token");
	authStore.token = token;
	Bugsnag.start({
		apiKey: "2cc50e49ea8135dd42fb091fbec15eb5",
		plugins: [new BugsnagPluginReact()],
	});
	const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);

	ReactDOM.render(
		<ErrorBoundary>
			<React.StrictMode>
				<App />
			</React.StrictMode>
		</ErrorBoundary>,
		document.getElementById("root"),
	);
};

main();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

/**
 * Register task queue worker
 */
workers.registerTaskExecutor();

/**
 * Register chapter generator worker
 */
workers.registerChapterGenerator();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
