import {
  StyledLeaf
} from "@udecode/slate-plugins";

export const DEFAULTS_SMALLCAPS = {
  smallcaps: {
    component: StyledLeaf,
    type: "smallcaps",
    rootProps: {
      className: "slate-smallcaps",
      as: "span",
      styles: {
        root: {
          fontVariant: "all-small-caps"
        }
      },
    },
  }
};