import React, { FunctionComponent } from "react";
import { useEditor } from "slate-react";

import {
  ToolbarElement,
} from "@udecode/slate-plugins";

// icons
import { UndoIcon } from "../../partials/Icon";


export const UndoButton: FunctionComponent = () => {
  const editor = useEditor();

  const validate = () => Boolean((editor.history ? ((editor.history as { undos: Array<any>}).undos ? (editor.history as { undos: Array<any>}).undos : []) : []).length);

  return (
    <ToolbarElement
      type={"undo"}
      icon={<UndoIcon active={validate()} />}
      onMouseDown={async () => {
        const ed = editor as any;
        const st = editor.history ? ((editor.history as { undos: Array<any>}).undos ? (editor.history as { undos: Array<any>}).undos : []) : [];

        if(st && st.length > 0 && editor.redo){
          ed.undo();
        }
        }
      }
    />
  );
};