import {
  PdfSlateEndnote,
  PdfSlateBaseParentBlockNode,
} from "../../Previewer/print/types";
import { getAllEndNotesOfChapter } from "./get-chapter-endnotes";
export function getBookEndnotes(chapters: IChapterStore.Chapter[]) {
  const endNotes: PdfSlateEndnote[] = [];
  for (const chapter of chapters) {
    const endNodes = getAllEndNotesOfChapter(
      chapter.children as PdfSlateBaseParentBlockNode[]
    );
    endNotes.push(...endNodes);
  }
  return endNotes;
}
