import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { Radio, Avatar, Row, Col, Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { SvgIcon } from "@material-ui/core";

import useRootStore from "../../store/useRootStore";
import { AtticusClient } from "../../api/atticus.api";

import miniLogo from "./assets/miniLogo.png";
import wordLogo from "./assets/wordLogo.png";
import backupLogo from "./assets/snapshot.svg";

import { ProfileProps } from "../../types/profile";
import SprintOnly from "../SprintTimer/SprintOnly";
import { SyncStatus } from "../../types/sync";
import InstallPWA from "../Topbar/InstallPWA";

const styles = {
  logo: {
    display: "flex",
    alignItems: "center",
  },
  miniLogo: {
    height: 35,
    width: "auto",
    marginRight: "0.6rem",
  },
  wordLogo: {
    height: 20,
    width: "auto",
  }
};

const Header = observer(({ profile }: ProfileProps) => {
  const { writing, saving, setMode, setView } = useRootStore().bookStore;
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [profilePictureURL, setProfilePictureURL] = useState("");
  // const [show, setshow] = useState(false);
  // const reloadPage = () => window.location.reload();

  const handleChange = e => {
    setMode(e.target.value === "writing" ? true : false);
    setView(e.target.value === "formatting" ? true : false);
  };

  const homeChange = e => {
    push("/");
  };

  // const bookActive = book._id ? `/books/${book._id}` : "";
  const isBookPath = pathname.match(new RegExp(/\/books\/(.+)/, ""));

  const clearCache = () => {
    if ("serviceWorker" in navigator) {
      caches.keys().then(cacheNames => cacheNames.forEach(cacheName => caches.delete(cacheName)));
    }
    window.location.reload();
  };

  useEffect(() => {
    const myProfile = async () => {
      const profileResponse = await AtticusClient.GetProfile();
      (profileResponse.profilePictureURL) && setProfilePictureURL(profileResponse.profilePictureURL);
    };
    myProfile();
  }, []);

  return (
    <header className="header">
      <Row gutter={16} align="middle">
        <Col>
          <Link to="/">
            <div style={styles.logo}>
              <img onClick={clearCache} style={styles.miniLogo} src={miniLogo} />
              <img style={styles.wordLogo} src={wordLogo} />
            </div>
          </Link>
        </Col>
      </Row>
      <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
        <Radio.Group value={pathname === "/" ? "home" : null} className="radio-group-without-divider">
          <Radio.Button value="home" onClick={homeChange}>Home</Radio.Button>
        </Radio.Group>
        <span style={{ color: "#343C43", borderLeft: "1px solid #343C43", margin: "0rem 1rem", opacity: 0.6 }}></span>
        <Radio.Group defaultValue="writing" value={writing ? "writing" : "formatting"} onChange={handleChange} disabled={!isBookPath} className="radio-group-without-divider dark">
          <Radio.Button value="writing">Writing</Radio.Button>
          <Radio.Button value="formatting">Formatting</Radio.Button>
        </Radio.Group>
        {/*}
					<Menu theme="dark" mode="horizontal" defaultSelectedKeys={[location.pathname]}>
						<Menu.Item key="/">
							<Link to="/">
								Menu
							</Link>
						</Menu.Item>
						{bookActive ? (
							<Menu.Item key={bookActive}>
								<Link to={bookActive}>
									Writing
								</Link>
							</Menu.Item>
						) : <Menu.Item disabled>Writing</Menu.Item>}
					</Menu>
				*/}
      </div>
      <div>
        <div>
        </div>
        <Row gutter={16} align="middle" justify="end">
          <Col style={{ minWidth: 70 }}>
            <span className={saving === SyncStatus.saving ? "save-indicator saving" : "save-indicator saved"}>{saving}</span>
          </Col>
          <Col>
            <InstallPWA />
          </Col>
          <Col>
          <Tooltip title="Backup Content">
            <Link to="/backup">
              <img src={backupLogo} style={{ width: 35, filter: "brightness(0) invert(1)" }} />
            </Link>
          </Tooltip> 
          </Col>
          <Col>
          <Tooltip title="Contact Support">
            <a target="_blank" href="https://atticus.io/support" rel="noreferrer">
              <SvgIcon x="0px" y="0px" style={{ color: "white", fontSize: 32, marginTop: 6 }} viewBox="0 0 122.88 122.88">
                <g>
                  <path d="M109.72,61.44v-0.01h0.02c0-13.31-5.42-25.38-14.17-34.13c-8.75-8.74-20.81-14.16-34.1-14.16v0.02h-0.03h-0.01v-0.02 c-13.3,0-25.38,5.42-34.13,14.17c-8.74,8.75-14.16,20.81-14.16,34.11h0.02v0.03v0.01h-0.02c0,8.88,2.41,17.22,6.59,24.36 c8.38,14.31,23.92,23.92,41.68,23.93v-0.02h0.03h0.01v0.02c13.31,0,25.38-5.42,34.13-14.17c1.44-1.44,2.78-2.95,4-4.52 C105.93,82.88,109.72,72.6,109.72,61.44L109.72,61.44z M68.38,73.86H51.86v-1.65c0-2.8,0.3-5.09,0.94-6.85 c0.64-1.77,1.59-3.35,2.85-4.81c1.27-1.45,4.11-4,8.51-7.66c2.34-1.92,3.52-3.67,3.52-5.25c0-1.61-0.48-2.84-1.41-3.73 c-0.94-0.88-2.37-1.33-4.3-1.33c-2.07,0-3.76,0.69-5.1,2.04c-1.35,1.36-2.21,3.75-2.58,7.14l-16.89-2.1 c0.58-6.2,2.84-11.2,6.76-14.98C48.1,30.89,54.12,29,62.24,29c6.32,0,11.43,1.33,15.32,3.95c5.29,3.57,7.92,8.33,7.92,14.26 c0,2.48-0.69,4.86-2.04,7.15c-1.36,2.3-4.15,5.09-8.36,8.39c-2.92,2.34-4.77,4.19-5.54,5.6C68.77,69.76,68.38,71.6,68.38,73.86 L68.38,73.86z M51.27,78.26h17.72v15.61H51.27V78.26L51.27,78.26z M122.85,61.43h0.03v0.01c0,14.19-4.82,27.27-12.92,37.68 c-1.59,2.04-3.29,3.97-5.08,5.76c-11.1,11.1-26.45,17.97-43.43,17.98v0.03h-0.01h-0.03v-0.03c-22.59-0.01-42.34-12.22-52.99-30.4 c-5.33-9.1-8.39-19.7-8.39-31H0v-0.01v-0.03h0.03C0.03,44.44,6.9,29.09,18,18C29.09,6.9,44.45,0.03,61.43,0.03V0h0.01h0.03v0.03 c16.97,0.01,32.32,6.87,43.41,17.97C115.98,29.09,122.85,44.45,122.85,61.43L122.85,61.43z" />
                </g>
              </SvgIcon>
            </a>
          </Tooltip>
          </Col>
          <Col>
            <Link to="/profile/my-profile">{(profile || profilePictureURL) ? <Avatar src={profile && profile.profilePictureURL ? profile.profilePictureURL : profilePictureURL} icon={<UserOutlined />} /> : <Avatar icon={<UserOutlined />} />}</Link>
          </Col>
        </Row>
      </div>
    </header>
  );
});

export default Header;
