import React, { FunctionComponent } from "react";

import List from "./List";
import ActionBar from "./ActionBar";
import ThemeSwitcer from "./ThemeSwitcher";
import { BookTitleCard } from "./BookTitleCard";
import { FullScreen } from "../Shared/Layouts/FullScreen";
import ChapterTemplateList from "./ChapterTemplateList";

import { getEditorType } from "../../utils/helper";

interface SiderProps {
	chapterId: string | null;
}

const Sider: FunctionComponent<SiderProps> = ({ chapterId }: SiderProps) => {
  const { type } = getEditorType(location.pathname);

	const isChapterTemplate = type === "chapter-template";

	return (
		<FullScreen className="sidebar-scroll-section" >
			<div>
				<ThemeSwitcer />
			</div>
			<>
				{!isChapterTemplate ? (<BookTitleCard chapterId={chapterId} />) : null}
			</>
			<div style={{
				overflowY: "scroll",
				flex: 1,
				backgroundColor: "#e7f1fd",
			}}>
				{!isChapterTemplate ? <List />
					: <ChapterTemplateList />}
			</div>
			<div>
				{!isChapterTemplate ? (<ActionBar />) : null}
			</div>
		</FullScreen>
	);
};

export default Sider;
