import React, { FunctionComponent, useState } from "react";
import { Menu, Dropdown, Modal, Tooltip, message } from "antd";
import { MoreOutlined, DeleteOutlined, FontSizeOutlined, WarningOutlined, CopyOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";

// helpers

import EditBookPopup from "./EditBookPopup";
import { useOnlineStatus } from "../../utils/isOffline";
import useRootStore from "../../store/useRootStore";

// styles
import "./index.scss";

// constants
const TooltipColor = "#3568BA";

interface BookListItemProps {
    book: IBookStore.Book;
    deleteBook?: (bookId: string) => Promise<void>;
    duplicateBook?: (bookId: string) => Promise<void>;
}

const BookListItem: FunctionComponent<BookListItemProps> = ({ book, deleteBook }: BookListItemProps) => {
    const [id, setId] = useState("");
    const [error, setError] = React.useState(false);
    const [errorPopUp, toggleErrorPopup] = useState(false);
    const { setChapterTemplateView }  = useRootStore().appStore;
	const { duplicateBook } = useRootStore().shelfStore;
	const title = book.title.length > 20 ? `${book.title.slice(0, 20)}...` : book.title;
    setChapterTemplateView(false); ///////// chapter template library
    const isOnline = useOnlineStatus();
    const { getErrorBook } = useRootStore().bookStore;

    const onDeleteBook = async () => {
        if (deleteBook) await deleteBook(book._id);
    };

    const onDuplicateBook = async () => {
        try {
			await duplicateBook(book._id);
		} catch (e) {
			message.error(e.message, 4);
		}
    };

    const loadFailBooks = async () => {
        const aa = await getErrorBook();
        if (aa) {
            const checkBook = aa.map((a) => {
                if (a._bookId === book._id) {
                    setError(true);
                }
            });
        }
    };

    React.useEffect(() => {
        loadFailBooks();
    }, [book]);

  
    return (
        <div className="book-list-item">
            <Link to={`/books/${book._id}`}>
                <div className="book-cover">
                    <h3>{title}</h3>
                    {book.coverImageUrl && (
                        <div className="book-cover-image" style={{
                            backgroundImage: `url('${book.coverImageUrl}')`,
                            backgroundSize: "cover",
                            backgroundPositionY: "center",
                            backgroundPositionX: "center",
                        }}></div>
                    )}
                </div>
            </Link>
            <div className="meta">
                <Tooltip title={book.title} color={TooltipColor} arrowPointAtCenter>
                    <h4 className="title">{book.title}</h4>
                </Tooltip>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Tooltip title={book.author.join(", ")} color={TooltipColor} arrowPointAtCenter>
                        <span className="authors">By {book.author.join(", ")}</span>
                    </Tooltip>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span className="edited-date">Edited {book.modifiedAt ? moment(book.modifiedAt).format("MMM D, YYYY") : "No date"}</span>
                    <Dropdown
                        trigger={["click"]}
                        placement="topCenter"
                        overlay={
                            <Menu>
                                <Menu.Item
                                    onClick={() => setId(book._id)}
                                    icon={<FontSizeOutlined />}
                                >
                                    Edit Details
                                </Menu.Item>
                                {isOnline && (
                                    <Menu.Item
                                        onClick={() => {
                                            Modal.confirm({
                                                icon: null,
                                                title: <h2 className="section-heading">Delete Book</h2>,
                                                content: `Are you sure you want to delete "${book.title}"?`,
                                                centered: true,
                                                onOk: onDeleteBook,
                                                okText: "Yes",
                                                okButtonProps: {
                                                    type: "primary",
                                                    danger: true,
                                                    style: {
                                                        flex: 1,
                                                    },
                                                },
                                                cancelText: "No",
                                                cancelButtonProps: {
                                                    className: "btn-a",
                                                    style: {
                                                        flex: 1,
                                                    },
                                                },
                                            });
                                        }}
                                        icon={<DeleteOutlined />}
                                    >
                                        Delete
                                    </Menu.Item>
                                )}
                                {isOnline && (
                                    <Menu.Item
                                        onClick={() => {
                                            Modal.confirm({
                                                icon: null,
                                                title: <h2 className="section-heading">Duplicate Book</h2>,
                                                content: `Are you sure you want to duplicate "${book.title}"?`,
                                                centered: true,
                                                onOk: onDuplicateBook,
                                                okText: "Yes",
                                                okButtonProps: {
                                                    type: "primary",
                                                    danger: true,
                                                    style: {
                                                        flex: 1,
                                                    },
                                                },
                                                cancelText: "No",
                                                cancelButtonProps: {
                                                    className: "btn-a",
                                                    style: {
                                                        flex: 1,
                                                    },
                                                },
                                            });
                                        }}
                                        icon={<CopyOutlined />}
                                    >
                                        Duplicate
                                    </Menu.Item>
                                )}
                            </Menu>
                        }
                    >
                        <MoreOutlined style={{ cursor: "pointer" }} />
                    </Dropdown>
                    {error ? (
                        <WarningOutlined style={{ color: "red" }}/>
                    ) : null}
                </div>
            </div>
            <EditBookPopup
                id={id}
                toggle={setId}
            />
            <Modal
                visible={errorPopUp}
                onCancel={() => toggleErrorPopup(false)}
                footer={null}
                width={500}
                title={<h2 className="section-heading">Resolve  Error ?</h2>}
                bodyStyle={{
                    backgroundColor: "white"
                }}
		>
                Remove Sync Error
            </Modal>
        </div>
    );
};

export default BookListItem;
