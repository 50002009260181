import { find, debounce, findIndex } from "lodash";
import { makeAutoObservable, observable } from "mobx";
import { AtticusClient } from "../api/atticus.api";

//IndexedDB
import { db } from "../db/bookDb";

// sync functions
import { themeDataSync, SaveTemplateToDB } from "../utils/sync";
import { UpdateThemeInDB, GetThemeFromDB, SaveAllThemes, SavePreviewDevice, GetPreviewDeviceDB, GetAllPreviewDevices, GetAllTheme } from "../utils/offline.book.helpers";

import { deviceSpec } from "../../src/components/Shared/helpers/deviceConfigs";
import { availableFontStylesForFont } from "../components/Shared/helpers/fontStyle";

const inits: IThemeStore.ThemeConfig = {
  themeId: "finch",
  baseFontSize: 10,
  printBaseFont: "LibreCaslon", layout: "layout6",
  titleCard: {
    chapterNumber: true,
    title: true,
    subtitle: false,
    image: false,
  },
  coloredImg: "all",
  allChangesSynced: false,
  beginFirstSentence: "chapter",
  bookId: "26f7352f-6626-4c28-b1c3-85e1dfd0d64a",
  chapterNumbering: "chapter+number",
  createdAt: "2021-06-10T14:12:28.325Z",
  firstParagraph: {
    uppercaseFourWords: false,
    indent: 1, 
    dropcap:false
  },
  lastSuccessfulSync: undefined,
  lastUpdateAt: "2021-08-30T08:48:44.699Z",
  margin: { bottom: 0.8, top: 0.8, outer: 0.875, inner: 0.5 },
  ornamentalBreakImage: "", //https://atticus-content.s3.amazonaws.com/ornamental-breaks/Black-11.png
  ornamentalBreakWidth: 50,
  startPage:"",
  paragraph: { paragraphSpacing: 1.5, indent: 2, hyphens: true, justify: true },
  titleAlignment: "left",
  trim: { height: 20.32, width: 12.7},
  notesMode: "FOOTNOTE",
  ePubNotesMode: "END_OF_CHAPTER",
  dynamicPageBreaks: {
    breakSubheadings: false,
    breakOrnamentalBreaks: false,
  },
  _id: ""
};

export class ThemeStore {
	theme: IThemeStore.ThemeConfig | null = inits
	style: IThemeStore.ThemeBase | null = null
	images: IThemeStore.CustomImages = {
        chapterId: "",
        url: "",
        background: false,
        colored: "all",
        placement: "below-subtitle",
        alignment: "left",
        width: 100,
        printExtent: "margins",
    }  
	customCss = ""
    individualChapterImage = false
	userThemes: IThemeStore.ThemeBase[] = []
	editTheme: IThemeStore.ThemeBase | null = null
	theme_styles: IThemeStore.ThemeBase[] = []
	loading = true;
	saving = false;
	folder?: string;
	themeCategories: IThemeStore.ThemeCategory[] = [];
	savedNewCustomTheme = false
	customThemeHeaders: IThemeStore.CustomThemeHeader | null = null;

	constructor() {
		makeAutoObservable(this, {
			style: observable.ref,
      //userThemes: observable.deep
			//theme: observable.ref
		});
	}
    headingFontsStyle = () => {
        return this.customThemeHeaders ? {
            chpTitleStyle: availableFontStylesForFont(this.customThemeHeaders.chapterTitle.font),
            chpSubtitleStyle: availableFontStylesForFont(this.customThemeHeaders.chapterSubtitle.font),
            chpNumStyle: availableFontStylesForFont(this.customThemeHeaders.chapterNo.font)
        } as IThemeStore.CustomThemeHeadingStyle: null;
    }


	setThemeStyles = (styles: any): void => {
		this.theme_styles = styles;
	}

	setTheme = (theme: IThemeStore.ThemeConfig): void => {
		this.theme = theme;
	}

	setUserThemes = async (userTheme: any) => {
		this.userThemes = userTheme;
	}

	setThemeCategories = (categories: any): void => {
		this.themeCategories = categories;
	}

    setIndividualChapterImage = async (is: boolean) => {
        this.individualChapterImage = is;
    }

	// getUserThemes = async () => {
	// 	const userThemes = await AtticusClient.GetCustomTheme();
	//   console.log({userThemes});
	// 	this.setUserThemes(userThemes);
	// }

	setEditTheme = (t: IThemeStore.ThemeBase | null) => {
		this.editTheme = t;
	}

	setCustomCss = (c: string): void => {
		this.customCss = c;
	}

	setStyle = (theme: IThemeStore.ThemeBase): void => {
		this.style = theme;
	}

	setImages = (imgs: IThemeStore.CustomImages): void => {
		this.images = imgs;
	}

	setCustomThemeHeaders = (headers: IThemeStore.CustomThemeHeader): void => {
		this.style = {...this.style, properties: headers} as IThemeStore.ThemeBase;
		this.customThemeHeaders = headers;
	}

	getEditTheme = async (id: string) => {
		if (id) {
			const editTheme = find(this.userThemes, { "_id": id });

			if (editTheme && editTheme._id) {
				this.setEditTheme(editTheme);
				return editTheme;
			}
		}
		return null;
	}

	duplicateUserTheme = async (themeId: string): Promise<void> => {
		try {
			const duplicateTheme = await AtticusClient.DuplicateUserTheme(themeId);
		} catch (e) {
			console.log("duplicate user theme error", e);
		}

		await this.loadThemes();
	}

	deleteUserTheme = async (themeId: string): Promise<void> => {
		try {
			const delThemes = await AtticusClient.DeleteUserTheme(themeId);
      this.setClosesStyle(themeId);
		} catch (e) {
			console.log("delete user theme error", e);
		}

		await this.loadThemes();
	}

  setClosesStyle = (id: string) => {
    if(this.style?._id && id){
      if(this.userThemes.length > 1) {
        const ind = findIndex(this.userThemes, {_id: id});
        this.setStyle(this.userThemes[ind == 0 ? ind + 1 : ind - 1]);
      } else {
        this.setStyle(this.theme_styles[0]);
      }
    }
  }

	setSaving = (saving: boolean): void => {
		this.saving = saving;
	}

	setLoading = (loading: boolean): void => {
		this.loading = loading;
	}

	loadThemes = async (): Promise<void> => {
		const themes = await AtticusClient.GetThemes();

		const userThemes = await AtticusClient.GetCustomTheme();
		await SaveAllThemes([...themes, ...userThemes]);
		this.setThemeStyles(themes);
		this.setUserThemes(userThemes);
	}

	getAllDevices = async () => {
		const all = await GetAllPreviewDevices();
		if (all) {
			all.forEach((face) => {
				const img = new Image();
				img.src = face;
			});
		}
		return all;
	}

	// getAllThemeCategories = async () => {
	// 	const allCategories = await AtticusClient.LoadAllThemeCategories();
	// 	this.setThemeCategories(allCategories);
	// }

	saveDevicePreview = async (): Promise<void> => {
		const devices = deviceSpec.devices;
		await SavePreviewDevice(devices);
	};

	saveCustomTheme = async (params: IThemeStore.ThemeBaseParams) => {
		try {
			// this.setLoading(true);
			const data = await AtticusClient.UpdateTheme(params);
			this.setUserThemes([...this.userThemes, params]);
			return data;
		} catch (e) {
			console.log("Save Theme error", e.message);
			throw e;
		}
	}

	putCustomTheme = async (params: IThemeStore.ThemeBaseParams) => {
		try {
			// this.setLoading(true);
			const data = await AtticusClient.UpdateTheme(params);
			this.setUserThemes(this.userThemes.map(t => t._id === params._id ? params : t));
			return data;
		} catch (e) {
			console.log("Edit Theme error", e.message);
			throw e;
		}
	}

	getDevicePreview = async (deviceName: string) => {
		try {
			// this.setLoading(true);
			const devPreview = await GetPreviewDeviceDB(deviceName);
			return devPreview;
		} catch (e) {
			console.log("Previewer error", e.message);
			throw e;
		}
	}

	getBookTheme = async (book_id: string, recursion?: boolean) => {
		try {
			const theme = await GetThemeFromDB(book_id);
			if (theme) {
				this.setTheme(theme);
				const bkTheme = await GetAllTheme(theme.themeId);
				this.setStyle(bkTheme || this.theme_styles[0]); //set style solution				
				return theme;
			} else if (!recursion) {
				await themeDataSync(book_id); //sync theme to the indexedDB
				await this.getBookTheme(book_id, true); //re call after syncing the theme
			}
		} catch (e) {
			console.log("Get Theme Error", e.message);
			throw e;
		}
	}

	putBookTheme = async (book_id: string, theme: IThemeStore.ThemeConfig, style: IThemeStore.ThemeBase) => {
		try {
			
			this.setTheme(theme);
			this.setStyle(style);

			const allPromises: Promise<void>[] = [];
			allPromises.push(UpdateThemeInDB(theme._id, { ...theme, allChangesSynced: false }));
			await Promise.all(allPromises);

			this.syncThemeChangesToServer(book_id, theme);
			this.debouncedSyncChapterChangesToServer(book_id, theme);

		} catch (e) {
			console.log("Update Theme Error", e.message);
			throw e;
		}

	}
	syncThemeChangesToServer = async (bookId: string, theme: IThemeStore.ThemeConfig): Promise<void> => {
		const newUpdates = theme;
		if (newUpdates.allChangesSynced !== undefined) delete newUpdates["allChangesSynced"];
		if (newUpdates.lastSuccessfulSync) delete newUpdates["lastSuccessfulSync"];

		try {
			this.setSaving(true);
			delete theme["modifiedAt"];
			delete theme["allChangesSynced"];
			const lastSyncAt = await AtticusClient.PutBookTheme(bookId, newUpdates);

			const batchPromise: Promise<unknown>[] = [];

			batchPromise.push(UpdateThemeInDB(theme._id, {
				...theme,
				lastSuccessfulSync: lastSyncAt.timestamp,
				allChangesSynced: true,
			}));

			await Promise.all(batchPromise);
			this.setSaving(false);
		} catch (e) {
			this.setSaving(false);
			console.log(e.message);
		}
	}

	debouncedSyncChapterChangesToServer = debounce(this.syncThemeChangesToServer, 500);

	saveThemeUpdates = async (): Promise<void> => {
		const dbBooks = await db.books.toArray();
		await SaveTemplateToDB(); // saves the chapter templates
		for (const book of dbBooks) {
			await themeDataSync(book._id);
		}
	};

	saveNewCusTheme = (state) => {
		this.savedNewCustomTheme = state;
	}

}

export default new ThemeStore();
