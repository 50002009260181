import React from "react";
import { Form, Row, Col } from "antd";
import { observer } from "mobx-react";

import { countWords } from "../../utils/helper";

const BookStat = ({ book }: { book: IBookStore.Book }) => {
	return (
		<div>
			<h2>Book Stats</h2>
			<Row style={{ flexDirection: "column" }}>
				<label>Chapters</label>
				<h3><b>{book.chapterIds.length}</b></h3>
			</Row>
			{/* <Row style={{ flexDirection: "column" }}>
                <label>Words</label>
                <h3><b>{countWords(body)}</b></h3>
            </Row> */}
		</div>
	);
};

export default BookStat;