import { withReact } from "slate-react";
import { withHistory } from "slate-history";

import {
  SlatePlugin,
  // basic plugins
  ParagraphPlugin,
  BoldPlugin,
  ItalicPlugin,
  UnderlinePlugin,
  StrikethroughPlugin,
  SuperscriptPlugin,
  SubscriptPlugin,
  HeadingPlugin,

  // level 2
  ListPlugin,

  // Blocks
  AlignPlugin,
  //BlockquotePlugin,
  CodeBlockPlugin,

  // breaks and resets
  ResetBlockTypePlugin,
  SoftBreakPlugin,
  ExitBreakPlugin,

  // with plugins
  withDeserializeHTML,
  withAutoformat,
  withList,
  withInlineVoid,
  withNodeID,
  withTrailingNode,

  // types
  SlateDocumentFragment,
} from "@udecode/slate-plugins";

import { options, optionsResetBlockTypes, headingTypes } from "./default";
import { autoformatRules } from "./autoformatRules";

// Custom Plugins
import { OrnamentalBreakPlugin, withOrnamentalBreak } from "../plugins/OrnamentalBreak";
import { ImageUploadPlugin, withImageUpload } from "../plugins/ImageUpload";
import { LinkPlugin } from "../plugins/Link";
import { SmallcapsPlugin } from "../plugins/Smallcaps";
import { MonospacePlugin } from "../plugins/Monospace";
import { SansSerifPlugin } from "../plugins/SansSerif";
import { EndnotePlugin, withEndnote } from "../plugins/Endnote";
import { SplitChapterPlugin, withSplitChapter } from "../plugins/SplitChapter";
import { withAlignPlugin } from "../plugins/Align";
import { withParagraphs } from "../plugins/Paragraph";
import { ProfilesPlugin, withProfiles } from "../plugins/Profiles";
import { PageBreakPlugin, withPageBreak } from "../plugins/PageBreak/PageBreakComponent";
import { BlockquotePlugin } from "../plugins/Blockquote";

// plugins
const plugins: SlatePlugin[] = [];

// basic plugins
plugins.push(ParagraphPlugin(options));
plugins.push(BoldPlugin(options));
plugins.push(ItalicPlugin(options));
plugins.push(UnderlinePlugin(options));
plugins.push(StrikethroughPlugin(options));
plugins.push(SuperscriptPlugin(options));
plugins.push(SubscriptPlugin(options));
plugins.push(HeadingPlugin(options));
plugins.push(SmallcapsPlugin(options));
plugins.push(MonospacePlugin(options));
plugins.push(SansSerifPlugin(options));

// level 2
plugins.push(LinkPlugin());
plugins.push(ListPlugin());

// blocks
plugins.push(BlockquotePlugin());
plugins.push(AlignPlugin(options));
plugins.push(CodeBlockPlugin(options));

// custom plugins
plugins.push(OrnamentalBreakPlugin());
plugins.push(ImageUploadPlugin());
plugins.push(ProfilesPlugin());
plugins.push(PageBreakPlugin());
// Endnote plugins
plugins.push(EndnotePlugin());

// Split Chapter Plugins 
plugins.push(SplitChapterPlugin());

// Breaks and resets
plugins.push(ResetBlockTypePlugin(optionsResetBlockTypes));

/*
  
 Schema for soft break and exitbrek since there is no more documentation online

  {
      rules: [{
          hotkey: string;
          query? : {
              * enter an array of component types to allow or exclude I.e : options.code_block.type * 
            allow: [] 
            exclude: [] 
          }
      }] 
  }
*/

//Softbreak
plugins.push(
  SoftBreakPlugin({
    rules: [
      {
        hotkey: "shift+enter",
      },
    ],
  })
);

//Exit break
plugins.push(
  ExitBreakPlugin({
    rules: [
      {
        hotkey: "enter",
        query: {
          start: true,
          end: true,
          allow: [
            ...headingTypes,
            options.code_block.type,
            options.blockquote.type,
          ],
        },
      },
    ],
  })
);

/**
 * Sanitize extra white spaces in rich texts
 */
const sanitizeWhiteSpace = (fragment: SlateDocumentFragment): SlateDocumentFragment => {

  // remove space at the begining when pasting
  if (fragment[0].text === "\n\n") {
    fragment.shift();
  }

  // remove multiple spaces at the end and add only one whitespace
  const firstLine = fragment[fragment.length - 1].text;
  if (typeof firstLine === "string" && firstLine.length > 0 && firstLine.replace(/\s/g, "").length == 0) {
    fragment[fragment.length - 1].text = " ";
  }

  fragment.map(line => {
    // left trim every line's starting text
    if (line?.type === "p" && Array.isArray(line.children) && line.children.length) {
      if (typeof line.children[0].text === "string") {
        line.children[0] = { ...line.children[0], text: line.children[0].text.trimStart() };
      }

      //remove unwanted characters in the line children texts
      line.children.map(child => {
        if (typeof child.text === "string") {
          child.text = child.text.replace(/\n/g, " ");
          child.text = child.text.replace(/\f/g, "");
          child.text = child.text.replace(/\u00A0/g, " ");
          child.text = child.text.replace(/\s/g, " ");
        }
      });
    }

    //remove unwanted characters in the line texts
    if (line.text && typeof line.text === "string") {
      line.text = line.text.replace(/\n/g, " ");
    }

    if (line.text && typeof line.text === "string") {
      line.text = line.text.replace(/\f/g, " ");
    }

    if (line.text && typeof line.text === "string") {
      line.text = line.text.replace(/\u00A0/g, " ");
    }

    if (line.text && typeof line.text === "string") {
      line.text = line.text.replace(/\s/g, " ");
    }

    if (line?.type === "p")
      return line;
  });
  return fragment;
};


// withPlugins
const withPlugins = [
  withReact,
  withHistory,
  withParagraphs,
  withDeserializeHTML({ plugins, preInsert: sanitizeWhiteSpace }),
  withList(options),
  // withMarks(),
  withAutoformat({ rules: autoformatRules }),
  withNodeID(),
  withInlineVoid({ plugins }),
  withOrnamentalBreak(),
  withPageBreak(),
  withProfiles(),
  withImageUpload(),
  withEndnote(),
  withSplitChapter(),
  withAlignPlugin(),
  withTrailingNode({ type: options.p.type, level: 1 }),
  //withSelectOnBackspace({ allow: [ 'ornamental-break', 'image' ] }),
] as const;

export {
  plugins,
  withPlugins,
};