import React, { FunctionComponent } from "react";
import { SvgIcon } from "@material-ui/core";

interface IconProps {
	size?: number;
	active?: boolean;
}

export const ProfileIcon: FunctionComponent<IconProps> = ({ size = 25 }: IconProps) => (
	<SvgIcon viewBox="0 0 22 22" style={{ width: size }} className="slate-ToolbarButton">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10h5v-2h-5c-4.34 0-8-3.66-8-8s3.66-8 8-8 8 3.66 8 8v1.43c0 .79-.71 1.57-1.5 1.57s-1.5-.78-1.5-1.57V12c0-2.76-2.24-5-5-5s-5 2.24-5 5 2.24 5 5 5c1.38 0 2.64-.56 3.54-1.47.65.89 1.77 1.47 2.96 1.47 1.97 0 3.5-1.6 3.5-3.57V12c0-5.52-4.48-10-10-10zm0 13c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z" />
	</SvgIcon>
);

export const RemoveIcon: FunctionComponent<IconProps> = ({ size = 15 }: IconProps) => (
	<SvgIcon viewBox="0 0 32 32" style={{ width: size }} className="slate-ToolbarButton">
		<g><rect height="12" width="2" x="15" y="12"/><rect height="12" width="2" x="19" y="12"/><rect height="12" width="2" x="11" y="12"/><path d="M20,6V5a3,3,0,0,0-3-3H15a3,3,0,0,0-3,3V6H4V8H6V27a3,3,0,0,0,3,3H23a3,3,0,0,0,3-3V8h2V6ZM14,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H14ZM24,27a1,1,0,0,1-1,1H9a1,1,0,0,1-1-1V8H24Z"/></g>
	</SvgIcon>
);
