import React from "react";

//components
import FindReplace from "../Search/FindReplace";
import Goals from "../Goals";
import WritingSettings from "../Formatting/WritingSettings";

interface EditorSiderProps {
    view: IAppStore.SettingView
    doReplace: (replace: IBookStore.ReplaceParams) => void
    //search?: SearchProps
}

function EditorSider({view, doReplace}: EditorSiderProps) {

    let c  = <WritingSettings />;

    if(view === "goals") c = <Goals />; 

    if(view === "search") c = <FindReplace doReplace={doReplace} />; 

    return (
        <div className="setting-area">
            {c}
        </div>
    ); 

}
export default EditorSider;