import React, { useState } from "react";
import { Row, Col, Button, message, Modal } from "antd";

import useRootStore from "../../store/useRootStore";
import { db } from "../../db/bookDb";

export default function ExportContainer() {
	const { exportBook, book, setErrorBook, setErrorChapter } = useRootStore().bookStore;
	const { setPDFExproterOptions } = useRootStore().appStore;

	const [ pdfExporting, setPdfExporting ] = useState(false);
	const [ epubExporting, setEpubExporting ] = useState(false);
	const [ docxExporting, setDocxExporting ] = useState(false);
    
	const removeErrorBooks = (bookId: string) => {
		const delErrorChapter =  db.failedChapters.where("_bookId").anyOf(bookId).delete();
		const delErrorBook =  db.failedBooks.where("_bookId").anyOf(bookId).delete();
	};

	const exportEpub = async () => {
		setEpubExporting(true);
		try {
			const resp = await exportBook(book._id, "epub");
			window.open(resp.url, "_blank");
			removeErrorBooks(book._id);

		} catch (e: any) {
			if(e.response?.data.message != "Request failed with status code 406") {
				setErrorBook(book._id);
				setErrorChapter(e.response?.data.errors.chapterID?.message, book._id);
			} 

			Modal.confirm({
				icon: null,
				title: <h2 className="section-heading">Export Failed</h2>,
				content: (
					<div>
						<p style={{ textAlign: "justify" }}>Sorry about that! Your book wasn&apos;t able to be exported due to an error in the document.
						Use the link below to learn more about why this might happen and what you can do to resolve the error.
				</p>
						<a target="_blank" href="https://www.atticus.io/troubleshooting-export-errors/" rel="noreferrer">https://www.atticus.io/troubleshooting-export-errors/</a>
					</div>
				),
				centered: true,
				// onOk: onDeleteBook,
				okText: "Ok",
				okButtonProps: {
					type: "primary",
					danger: true,
					style: {
						flex: 1,
					},
				},
				cancelText: false,
				cancelButtonProps: {
					className: "btn-a",
					style: {
						display: "none",
					},
				},
			});
		}
		setEpubExporting(false);
	};


	const exportDocx = async () => {
		setDocxExporting(true);
		try {
			const resp = await exportBook(book._id, "docx");
			window.open(resp.url, "_blank");
		} catch (e) {
			Modal.confirm({
				icon: null,
				title: <h2 className="section-heading">Export Failed</h2>,
				content: (
					<div>
						<p style={{ textAlign: "justify" }}>Sorry about that! Your book wasn&apos;t able to be exported due to an error in the document.
						Use the link below to learn more about why this might happen and what you can do to resolve the error.
				</p>
						<a href="https://www.atticus.io/troubleshooting-export-errors/">https://www.atticus.io/troubleshooting-export-errors/</a>

					</div>
				),
				centered: true,
				// onOk: onDeleteBook,
				okText: "Ok",
				okButtonProps: {
					type: "primary",
					danger: true,
					style: {
						flex: 1,
					},
				},
				cancelText: false,
				cancelButtonProps: {
					className: "btn-a",
					style: {
						display: "none",
					},
				},
			});
		}
		setDocxExporting(false);
		// setErrorBook(book._id);
	};

	const exportPdf = async () => {
		setPdfExporting(true);
		try {
			await exportBook(book._id, "pdf");
			message.success("We'll email you your PDF when it's ready", 4);
		} catch (e) {
			Modal.confirm({
				icon: null,
				title: <h2 className="section-heading">Export Failed</h2>,
				content: (
					<div>
						<p style={{ textAlign: "justify" }}>Sorry about that! Your book wasn&apos;t able to be exported due to an error in the document.
						Use the link below to learn more about why this might happen and what you can do to resolve the error.
				</p>
						<a target="_blank" href="https://www.atticus.io/troubleshooting-export-errors/" rel="noreferrer">https://www.atticus.io/troubleshooting-export-errors/</a>
					</div>
				),
				centered: true,
				// onOk: onDeleteBook,
				okText: "Ok",
				okButtonProps: {
					type: "primary",
					danger: true,
					style: {
						flex: 1,
					},
				},
				cancelText: false,
				cancelButtonProps: {
					className: "btn-a",
					style: {
						display: "none",
					},
				},
			});
		}
		setPdfExporting(false);
		// setErrorBook(book._id);
	};

	const exportLocalPdf = () => {
		setPDFExproterOptions(book._id, book.title);
	};

	return (
		<div className="inner area-b">
			<h2>Export</h2>
			<div style={{ height: "1em" }}></div>
			<Row gutter={16}>
				<Col span={6}>
					<Button onClick={exportEpub} type="primary" block size="large" loading={epubExporting} className="btn-b">ePub</Button>
				</Col>
				<Col span={6}>
					<Button onClick={exportLocalPdf} type="primary" block size="large" loading={pdfExporting} className="btn-b">PDF</Button>
				</Col>
				<Col span={6}>
					<Button onClick={exportDocx} type="primary" block size="large" loading={docxExporting} className="btn-b">docx</Button>
				</Col>
				{/*
                <Col span={6}>
                    <Button disabled type="primary" block size="large">Print</Button>
                </Col>
                */}
			</Row>
		</div>
	);
}
