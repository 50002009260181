import React, { useState, FunctionComponent, ReactNode } from "react";
import { Transforms, Range } from "slate";
import { ReactEditor, useEditor, useSelected } from "slate-react";
import { SlatePlugin } from "@udecode/slate-plugins-core";
import { getRenderElement, ToolbarElement } from "@udecode/slate-plugins";
import { UploadOutlined, LoadingOutlined } from "@ant-design/icons";
import { DeleteOutlined } from "@ant-design/icons";
// components
import { Button, Modal, Form, Input, Radio, Popconfirm, FormItemProps} from "antd";

// types
import { ImageNode, ImageSize, ImageLink, ImageFlow } from "./types";

// custom components
import FileUpload from "../../../Shared/Forms/FileUpload";
import ImageGallery from "../../../Shared/Forms/ImageGallery";

// icons
import {
  ImageUploadIcon,
  ImageSettingsIcon,
  RemoveIcon,
} from "./partials/Icon";

// styles
import "./styles.scss";
import { ImageLinkComponent } from "./partials/imageLink";
import { ImageSizeComponent } from "./partials/ImageSize";
import { ImageFlowComponent } from "./partials/ImageFlow";
import { checkHttps } from "../../../../utils/helper";
const { TextArea } = Input;
const commonFormItemProps = {
	labelAlign: "left",
	colon: false,
} as FormItemProps;

// Form layout
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 30 },
};

export type ImageUploadType = "image";

export interface ImageUploadElement {
  type: ImageUploadType;
  children: [];
  id?: string;
}

export const withImageUpload =
  () =>
  <T extends ReactEditor>(editor: T): ReactEditor => {
    const { isVoid, isInline } = editor;
    editor.isVoid = (element) =>
      element.type === "image" ? true : isVoid(element);
    editor.isInline = (element) =>
      element.type === "image" ? false : isInline(element);

    return editor;
  };

interface ImageUploadComponentProp {
  element: ImageNode;
  children: ReactNode;
  className?: string;
}

interface FileUploadProps {
  transformFile: (f: File) => Promise<string>;
}

interface EditImageDialogProps {
  element: ImageNode;
  onClose: () => void;
  onUpdate: (update: ImageNode) => void;
}

const EditImageDialog: FunctionComponent<EditImageDialogProps> = ({
  element,
  onClose,
  onUpdate,
}: EditImageDialogProps) => {
  const [caption, setCaption] = useState(element.caption);
  const [flow, setFlow] = useState(element.flow);
  const [wrap, setWrap] = useState(element.wrap);
  const [size, setSize] = useState(element.size);
  const [separatePage, setSeparatePage] = useState<boolean>(!!element.separatePage);
  const [imageLink, setImageLink] = useState<ImageLink | undefined>(element.link);
  
  const disableWrap = (typeof size === "number" && size > 50) || (size === "large");

  const onSubmit = async () => {
    if (imageLink && imageLink.webLink && !checkHttps(imageLink.webLink)) {
      (document.getElementById("errorText") as HTMLSpanElement).textContent = "Should be a valid link starting with https:// or mailto:";
		} else {
      onUpdate({
        ...element,
        size,
        flow,
        caption,
        link: imageLink,
        wrap: disableWrap? false : wrap,
        separatePage,
      });    
      onClose();
    }
  };

  const handleImageFlowChange = (flowValue?: ImageFlow, wrapValue?: boolean) => {        
    if(flowValue !== undefined) setFlow(flowValue);
    if(wrapValue !== undefined) setWrap(disableWrap? false : wrapValue);
  };

  const handleImageSizeChange = (value:ImageSize) => {
    setSize(value);
    setWrap(disableWrap? false : wrap);
  };

  const handleSeparatePageChange = (separateValue: boolean | undefined | null) => {
    if (separateValue !== null && separateValue !== undefined) setSeparatePage(separateValue);
  };


  return (
    <>
      <Modal
        visible={true}
        onCancel={() => {
          onClose();
        }}
        cancelText="Cancel"
        title="Edit Image"
        footer={
          <Button type="primary" block size="large" onMouseDown={onSubmit}>
            Update Image
          </Button>
        }
      >
        <Form {...layout}>
          <Form.Item label="Caption" {...commonFormItemProps}>
            <TextArea
              rows={2}
              value={caption}
              onChange={(e) => {
                setCaption(e.target.value);
              }}
            />
          </Form.Item>
          <ImageFlowComponent 
            flowValue={flow}
            wrapValue={wrap}
            disableWrap={disableWrap}
            separatePageValue={separatePage}
            flowChange={handleImageFlowChange}
            separatePageChange={handleSeparatePageChange}
          />
          <ImageSizeComponent size={size} onSizeChange={handleImageSizeChange} />
          <ImageLinkComponent link={imageLink} setLink={setImageLink} />
        </Form>
      </Modal>
    </>
  );
};

export const ImageUploadComponent: FunctionComponent<ImageUploadComponentProp> =
  ({ children, element }: ImageUploadComponentProp) => {
    const editor = useEditor();
    const selected = useSelected();

    const [editImage, setEditImage] = useState(false);

    return (
      <div className="edt-img">
        {editImage ? (
          <EditImageDialog
            element={element}
            onClose={() => {
              setEditImage(false);
            }}
            onUpdate={(updatedImageNode: ImageNode) => {
              const path = ReactEditor.findPath(editor, element);
              Transforms.setNodes(editor, updatedImageNode, { at: path });
            }}
          />
        ) : null}
        <div contentEditable={false}>
          <div className="edt-img-action">
            <Button
              size="small"
              onMouseDown={() => {
                setEditImage(true);
              }}
            >
              <ImageSettingsIcon />
            </Button>
            <Popconfirm
              title="Are you sure to delete this Image?"
              onConfirm={() => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.removeNodes(editor, { at: path });
              }}
              okText="Delete"
              cancelText="Cancel"
              okButtonProps={{
                danger: true,
              }}
            >
              <Button size="small">
                <RemoveIcon />
              </Button>
            </Popconfirm>
          </div>
          <div className="imge">
            <div>
              <img src={element.url} />
            </div>
            <div className="caption">{element.caption}</div>
          </div>
        </div>
        {children}
      </div>
    );
  };

const renderImageUpload = () => {
  const ImageUpload = {
    component: ImageUploadComponent,
    type: "image",
    hotkey: "",
    defaultType: "image",
    rootProps: {
      className: "slate-image",
    },
  };

  return getRenderElement(ImageUpload);
};

/**
 * Enables support for ornamental breaks.
 */
export const ImageUploadPlugin = (): SlatePlugin => {
  return {
    renderElement: renderImageUpload(),
    voidTypes: ["image"],
  };
};

export const ImageUploadToolbarButton: FunctionComponent<FileUploadProps> =
  () => {
    const editor = useEditor();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [editorSelection, setEditorSection] = useState<Range | null>(null);
    const [uploading, setUploading] = useState(false);
    const [caption, setCaption] = useState("");
    const [imageURL, setImageURL] = useState<string | null>(null);
    const [size, setSize] = useState<ImageSize>("medium");
    const [wrap, setWrap] = useState(false);
    const [flow, setFlow] = useState<ImageFlow>("left");
    const [imageLink, setImageLink] = useState<ImageLink | undefined>(undefined);
    const [separatePage, setSeparatePage] = useState<boolean>(false);

    const clearForm = () => {
      setCaption("");
      setImageURL(null);
      setEditorSection(null);
      setDialogOpen(false);
      setSeparatePage(false);
    };

    const handleSeparatePageChange = (separateValue: boolean | undefined | null) => {
      if (separateValue !== null && separateValue !== undefined) setSeparatePage(separateValue);
    };  

    const onSubmit = async () => {
        if (imageLink && imageLink.webLink && !checkHttps(imageLink.webLink)) {
          (document.getElementById("errorText") as HTMLSpanElement).textContent = "Should be a valid link starting with https:// or mailto:";
        } else {
          if (imageURL) {
            Transforms.insertNodes(
              editor,
              {
                type: "image",
                url: imageURL,
                caption,
                size,
                flow,
                link: imageLink,
                children: [{ text: "" }],
                wrap,
                separatePage
              },
              {
                at: editorSelection ? editorSelection : undefined,
              }
            );

            if (editorSelection) Transforms.setSelection(editor, editorSelection);
            clearForm();
          }
      }
    };

    const disableWrap = (typeof size === "number" && size > 50) || (size === "large");

    const handleImageFlowChange = (flowValue?: ImageFlow, wrapValue?: boolean) => {        
      if(flowValue !== undefined) setFlow(flowValue);
      if(wrapValue !== undefined) setWrap(disableWrap? false : wrapValue);
    };
  
    const handleImageSizeChange = (value:ImageSize) => {
      setSize(value);
      setWrap(disableWrap? false : wrap);
    };

    const getIGURL = (link) => {
      setImageURL(link);
    };
  

    return (
      <>
        {dialogOpen ? (
          <Modal
            onCancel={() => {
              if (editorSelection)
                Transforms.setSelection(editor, editorSelection);
              clearForm();
              setDialogOpen(false);
            }}
            visible={dialogOpen}
            title="Add an Image"
            footer={
              imageURL ? (
                <Button
                  type="primary"
                  block
                  size="large"
                  onMouseDown={() => onSubmit()}
                >
                  Upload Image
                </Button>
              ) : null
            }
          >
            {!imageURL && <ImageGallery changeURL={getIGURL} />}
            
            <Form {...layout}>
              {!imageURL &&
                <Form.Item name="upload" {...commonFormItemProps}>
                <h3 className="imgUploadTitle">Upload a New Image</h3>
                  <FileUpload
                    fileType="image/png, image/jpg, image/jpeg"
                    onFileUpload={(fileUrl) => {                    
                      setImageURL(fileUrl);
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      {uploading ? <LoadingOutlined /> : <UploadOutlined />}
                    </p>
                    <p className="ant-upload-text">
                      {uploading
                        ? "Uploading"
                        : "Click or drag file to this area to upload"}
                    </p>
                  </FileUpload>
                </Form.Item>
              }

              {imageURL && <div className="fileUploadImgPrevMainDiv">
                <div className="fileUploadImgPre">
                  <img src={imageURL ? imageURL : ""} style={{ width: "70%" }} />
                </div>
                
                <div className="fileUploadImgPre">
                  <Button
                      size="small"
                      danger
                      type="primary"
                      block
                    onClick={() => setImageURL(null)}
                    className="fileUploadRemoveBtn"
                    >
                      Remove
                  </Button>
                </div>
                  
              </div>}
              

              {imageURL ? (
                <>
                  <Form.Item label="Caption" {...commonFormItemProps}>
                    <TextArea
                      rows={2}
                      value={caption}
                      onChange={(e) => {
                        setCaption(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <ImageFlowComponent
                    flowValue={flow}
                    wrapValue={wrap}
                    disableWrap={disableWrap}
                    separatePageValue={separatePage}
                    flowChange={handleImageFlowChange}
                    separatePageChange={handleSeparatePageChange} 
                  />
                  <ImageSizeComponent size={size} onSizeChange={handleImageSizeChange} />
                  <ImageLinkComponent link={imageLink} setLink={setImageLink} />
                </>
              ) : null}
            </Form>
          </Modal>
        ) : null}
        <ToolbarElement
          type={"image"}
          icon={<ImageUploadIcon />}
          onMouseDown={(e) => {
            e.preventDefault();
            setEditorSection(editor.selection);
            setDialogOpen(true);
          }}
        />
      </>
    );
  };
