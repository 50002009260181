import React, { useState } from "react";
import { Modal, Button } from "antd";
import { DeleteOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";

import useRootStore from "../../../../store/useRootStore";

const AllProfiles = observer(() => {
  const {
    userSMProfiles,
    smProfileToInsert,
    setModalState,
    deleteSMProfileFromDB,
    setSelectedSocialProfileId,
    setSMProfileToInsert,
    smProfilePathToReplace
  } = useRootStore().socialProfileStore;

  const [deleting, setDeleting] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
	const [profileIdToDelete, setProfileIdToDelete] = useState<string | null>(null);

  const onDeleteProfile = async () => {
    setShowConfirmDeleteModal(false);
    if(profileIdToDelete){
      setDeleting(true);
      await deleteSMProfileFromDB(profileIdToDelete);
      setDeleting(false);
      onCloseDeleteModal();
    }
  };

  const onClickDelete = (profileId: string) => {
    setProfileIdToDelete(profileId);
    setShowConfirmDeleteModal(true);
  };

  const onEditProfile = (profileId: string) => {
    setSelectedSocialProfileId(profileId);
    setModalState("editProfile");
  };

  const onCloseDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setProfileIdToDelete(null);
  };

  return (
    <div>
      <Modal
					visible={showConfirmDeleteModal}
					onCancel={() => setShowConfirmDeleteModal(false)}
					closable={false}
					footer={[
						<Button key="back" type="primary" onClick={() => onCloseDeleteModal()}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" danger onClick={onDeleteProfile}>
							Remove
						</Button>
					]}
				>
					<div className="delete-profile-modal-body">
						<p>Are you sure you want to remove this Profile from your account?</p>
					</div>
				</Modal>
      {
        userSMProfiles.map(profile => {
          const isSelected = smProfileToInsert?._id === profile?._id;
          return (
            <div
              key={profile._id}
              onClick={() => { setSMProfileToInsert(profile); }}
              className={isSelected ? "all-prof selected" : "all-prof"}
            >
              <div>
                <p className={isSelected ? "profile_name selected" : "profile_name"}>{profile.profileName}</p>
              </div>
              <div>
                <EditOutlined className={isSelected ? "edit-profile-modal-icon selected" : "edit-profile-modal-icon"} onClick={() => onEditProfile(profile._id)} />{" "}
                {
                  !smProfilePathToReplace && (
                    (deleting && profileIdToDelete === profile._id) ? <LoadingOutlined className={isSelected ? "edit-profile-modal-icon selected" : "edit-profile-modal-icon"} /> : 
                    <DeleteOutlined className={isSelected ? "edit-profile-modal-icon selected danger" : "edit-profile-modal-icon danger"} onClick={() => onClickDelete(profile._id)} />)
                }
              </div>
            </div>
          );
        })
      }
    </div>
  );
});

export default AllProfiles;