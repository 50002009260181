import React, { FunctionComponent } from "react";
import { Row, Col } from "antd";
import "react-calendar-heatmap/dist/styles.css";
import { BookGoal } from "./BookGoal";
import { WritingHabit } from "./WritingHabit";
import { Awards } from "./Awards";

const Goals: FunctionComponent = () => {
    return (
        <>
            <div className="setting-area-col">
                <BookGoal />
            </div>
            <div className="setting-area-col">
                <WritingHabit />
            </div>
            {/* 
            <div>
                <Awards />
            </div> 
            */}
        </>
    );
};

export default Goals;