import { union, isEqual, isEmpty } from "lodash";
import { generate as generateRandomString } from "randomstring";
import { notification } from "antd";

//IndexedDB
import { db } from "../db/bookDb";

// API
import { AtticusClient } from "../api/atticus.api";
import { Book } from "../types/book";
import { Chapter } from "../types/chapter";
import { ThemeConfig, ThemeBase, ThemeFields } from "../types/theme";

// Offline helpers
import { GetBookFromDB, SaveBookToDB, UpdateBookInDB, DeleteChaptersFromDB,
	UpdateChapterInDB, SaveThemeToDB, GetThemeFromDB, UpdateThemeInDB, SaveChapterTemplateToDB } from "./offline.book.helpers";
// GetErrorBook <--- error visuzalition

async function SaveBookToServer(book: Book): Promise<void> {
	try {
		const res = await AtticusClient.PutBook({
			...book,
			chapters: [
				...(book.chapters || []),
				...(book.frontMatter || []),
				...(book.backMatter || []),
			]
		});
		await UpdateBookInDB(book._id, {
			lastSuccessfulSync: res.timestamp,
			allChangesSynced: true,
		});
	} catch (e) {
		console.log(`ERROR SAVING BOOK ${book._id} - ${book.title}`, e);
		throw e;
	}
}

async function PatchBook(bookId: string, updates: Partial<Book>): Promise<void> {
	try {
    delete updates["__v"];
    delete updates["createdAt"];
    delete updates["lastUpdateAt"];

		const res = await AtticusClient.PatchBook(bookId, updates);
		await UpdateBookInDB(bookId, {
			lastSuccessfulSync: res.timestamp,
			allChangesSynced: true,
		});
	} catch (e) {
		console.log(`ERROR PATCHING BOOK ${bookId}`, e);
		throw e;
	}
}

async function PatchChapter(bookId: string, chapter: Chapter): Promise<boolean> {
	try {
		const parsedUpdates: Partial<Chapter> = { ...chapter };
		delete parsedUpdates["_id"];
		delete parsedUpdates["allChangesSynced"];
		delete parsedUpdates["bookId"];
		delete parsedUpdates["lastSuccessfulSync"];

		const res = await AtticusClient.PatchChapter(bookId, chapter._id, parsedUpdates);

		// db.failedChapters.delete(bookId);

		await UpdateChapterInDB(chapter._id, {
			lastSuccessfulSync: res.timestamp,
			allChangesSynced: true,
		});
		return true;
	} catch (e) {
		console.log({ e });
    return false;
	}
}

async function PutChapter(chapter: Chapter): Promise<boolean> {
	try {
		const res = await AtticusClient.PutChapter(chapter);
		await UpdateChapterInDB(chapter._id, {
			lastSuccessfulSync: res.timestamp,
			allChangesSynced: true,
		});
		return true;
	} catch (e) {
		console.log({ e });
		throw e;
	}
}

export async function SaveOfflineBookToServer(bookId: string): Promise<boolean> {
	const fullOfflineBook = await GetBookFromDB(bookId, { chapterBodies: true, chapterMeta: true });
	// remove the failed book from the db to remove error
	// console.log("remove failed book because sync successdul");
	// db.failedBooks.delete(bookId);

	if (!fullOfflineBook) return false;

	await SaveBookToServer(fullOfflineBook);

	return true;
}

export async function SaveServerBookToDB(bookId: string): Promise<boolean> {
	const serverBook = await AtticusClient.GetBook(bookId);

	if (!serverBook) return false;

	await SaveBookToDB(serverBook);

	return true;
}

export async function syncBook(bookId: string): Promise<boolean> {
	const allPromises: Promise<Book | undefined>[] = [];
	const serverBook = await AtticusClient.GetBook(bookId);
    const offlineBook = await GetBookFromDB(bookId, { chapterMeta: true, chapterBodies: true });
	if (!serverBook || !offlineBook) return false;

	let updateServer = false;
	let updateLocal = false;
	let newBook: Book | null = null;

	if (offlineBook.allChangesSynced === undefined) offlineBook.allChangesSynced = true;

	// Filter out deleted chapters from offlineBook chapterIds
	if (serverBook.deletedChapterIds) {
    const isValidChapter = (id) => serverBook.deletedChapterIds?.indexOf(id) === -1 && serverBook.chapters && serverBook.chapters.findIndex((chap) => chap._id === id) > -1;

		offlineBook.chapterIds = offlineBook.chapterIds.filter(isValidChapter);
		offlineBook.frontMatterIds = offlineBook.frontMatterIds.filter(isValidChapter);
		offlineBook.backMatterIds = offlineBook.backMatterIds.filter(isValidChapter);
	}

	if (serverBook.lastUpdateAt !== undefined && offlineBook.lastSuccessfulSync !== undefined) {

		if (serverBook.lastUpdateAt === offlineBook.lastSuccessfulSync && offlineBook.allChangesSynced) {
			// No changes
		}

		// The local copy is more recent
		if (offlineBook.lastSuccessfulSync === serverBook.lastUpdateAt) {
			updateServer = true;
			newBook = offlineBook;

			// db.failedBooks.delete(offlineBook._id);
			// db.failedChapters.clear();

		}
		// server copy is more recent
		else if (serverBook.lastUpdateAt > offlineBook.lastSuccessfulSync) {
			updateLocal = true;
			newBook = serverBook;
			if (!offlineBook.allChangesSynced) {
				updateServer = true;
			}
		}
	} else {
		// there are first-time unsynced changes on the client, have to procede to conflict resolution
		if (offlineBook.lastSuccessfulSync === undefined && !offlineBook.allChangesSynced) {
			updateServer = true;
			updateLocal = true;

			// the selection of server book for thie scenario was a bit arbitary. Thinking being the server should take precendence over client when there's ambiguity
			newBook = serverBook;
		}
	}

    //inconsistency with chapters and chaptersIds - set chapter ids from chapters to chapterIds
    offlineBook.chapterIds = offlineBook.chapters?.map(d => d._id) || [];

    const sameIds = isEqual(offlineBook.chapterIds, serverBook.chapterIds);
	let didChapterSync= false;
	if ((updateLocal && updateServer) || !sameIds) {
		didChapterSync=true;
		await syncChapters(offlineBook, serverBook, Boolean(newBook));
	} else if (updateLocal && newBook && offlineBook.allChangesSynced) {
		// Save copy of the server book to the server
		await SaveBookToDB(newBook);
	} else if (updateServer && newBook && !offlineBook.allChangesSynced) {
		// Save copy of the client book to the server
		await SaveBookToServer(newBook);
	}
	if(!didChapterSync){
		await syncChapters(offlineBook,serverBook, Boolean(newBook));
	}

	// This is a one-way sync issue since offline deletes are not supported
	// clean-up deleted chapters
	if (serverBook.deletedChapterIds) await DeleteChaptersFromDB(serverBook.deletedChapterIds);

	return true;
}

async function syncChapters(offlineBook: Book, serverBook: Book, newBook: boolean) {
    let mergedChapterIds: string[] = [];

    const offlineBookAllChapterIds = [
      ...offlineBook.frontMatterIds,
      ...offlineBook.chapterIds,
      ...offlineBook.backMatterIds
    ];

    const serverBookAllChapterIds = [
      ...serverBook.frontMatterIds,
      ...serverBook.chapterIds,
      ...serverBook.backMatterIds
    ];

    const withoutDeeleteChapterIDs = offlineBookAllChapterIds.filter(id => !(serverBook.deletedChapterIds || []).includes(id));

    mergedChapterIds = union(withoutDeeleteChapterIDs, serverBookAllChapterIds);

    const waitforAllChanges: Promise<unknown>[] = [];
    const newChapterIds: string[] = [];

    if (offlineBook.chapters && serverBook.chapters) {

        for (const chapterId of mergedChapterIds) {
            const offlineChapter = offlineBook.chapters?.find(chapter => chapter._id === chapterId);
            const serverChapter = serverBook.chapters?.find(chapter => chapter._id === chapterId);

            // no offline chapter, save server chapter
            if (!offlineChapter && serverChapter) {
                newChapterIds.push(serverChapter._id);
                waitforAllChanges.push(UpdateChapterInDB(serverChapter._id, serverChapter));
                continue;
            }

            // no server chapter, sync chapter to server
            if (!serverChapter && offlineChapter) {
				newChapterIds.push(offlineChapter._id);
				waitforAllChanges.push(PutChapter(offlineChapter));
				continue;
            }

            // chapter is available on both server and offline
            if (serverChapter && offlineChapter) {
                // make sure the allChangesSynced variable has a value
                if (offlineChapter.allChangesSynced === undefined) offlineChapter.allChangesSynced = true;

                // all changes on the client are synced and the server's version is more recent
                if (offlineChapter.allChangesSynced && serverChapter.lastUpdateAt !== offlineChapter.lastSuccessfulSync) {
                    newChapterIds.push(chapterId);
                    waitforAllChanges.push(UpdateChapterInDB(chapterId, serverChapter));
                    continue;
                }

                if (!offlineChapter.allChangesSynced && serverChapter.lastUpdateAt && offlineChapter.lastSuccessfulSync) {
                    // no changes on the server beyond the last sync
                    if (serverChapter.lastUpdateAt === offlineChapter.lastSuccessfulSync) {
                        newChapterIds.push(chapterId);
                        waitforAllChanges.push(PatchChapter(offlineBook._id, offlineChapter));
                        continue;
                    }

                    // both the server and the client has changes, the chapter has a conflict
                    // go to conflict resolution mode
                    if (serverChapter.lastUpdateAt > offlineChapter.lastSuccessfulSync) {
                        newChapterIds.push(chapterId);
						//	avoid conflict chapter creation for conflict chapters
						if(chapterId.includes("_conflict")) continue;
						//	check if a conflict chapter already exists for chapter */
						const conflictChapterId = chapterId + "_conflict";
						const conflictChapterTitle = `${offlineChapter.title} (CONFLICT)`;
						const hasConflictChapterInServer = serverBook.chapterIds.some((chapterId) => chapterId === conflictChapterId);
						const hasConflictChapterInLocal = withoutDeeleteChapterIDs.some((chapterId) => chapterId === conflictChapterId);
						if(hasConflictChapterInServer){
							//	if a conflict chapter exists in the server book, replace the content of the conflict chapter in server book with
							//	content from the original chapter from the offline book and update the server book
							waitforAllChanges.push(UpdateChapterInDB(conflictChapterId, {...offlineChapter, _id: conflictChapterId, title: conflictChapterTitle}));
							waitforAllChanges.push(PatchChapter(offlineBook._id, {...offlineChapter, _id: conflictChapterId, title: conflictChapterTitle}));
						}else if(hasConflictChapterInLocal){
							//	if a conflict chapter exists in the offline book, replace the content of the conflict chapter in the offline book
							//	with the content of the original chapter in the offline book and update the server book
							waitforAllChanges.push(UpdateChapterInDB(conflictChapterId, {...offlineChapter, _id: conflictChapterId, title: conflictChapterTitle}));
							waitforAllChanges.push(PutChapter({...offlineChapter, _id: conflictChapterId, title: conflictChapterTitle}));
						}else{
							/** temporary commented new conflict chapter creation */
							// create new conflict chapter and sync with server
							// const conflictChapter: Chapter = {
							// 	...offlineChapter,
							// 	_id: conflictChapterId,
							// 	title: `${offlineChapter.title} (CONFLICT)`
							// };
							// newChapterIds.push(conflictChapterId);
							// waitforAllChanges.push(PutChapter(conflictChapter));
						}
						// replace content of the offline chapter with content from the server -
                        waitforAllChanges.push(UpdateChapterInDB(chapterId, {...serverChapter, lastSuccessfulSync: serverChapter.lastUpdateAt, allChangesSynced: true}));
						// always save the offline chapter being replaced in the conflict collection to minimize data loss
						waitforAllChanges.push(AtticusClient.SaveConflictChapter(offlineChapter, "CONFLICT"));
                        continue;
                    }
                }
            }
            newChapterIds.push(chapterId);
        }

        // Save the new chapter structure online
        if (newBook) {
            await Promise.all(waitforAllChanges);
            if (!isEqual(newChapterIds, serverBookAllChapterIds) || (!isEqual(newChapterIds, offlineBookAllChapterIds))) {
                const chapterDeltas: Partial<Book> = {
                    frontMatterIds: [],
                    chapterIds: [],
                    backMatterIds: [],
                };

                newChapterIds.forEach((newChapterId) => {
                    if (
                      serverBook.frontMatterIds.includes(newChapterId) ||
                      offlineBook.frontMatterIds.includes(newChapterId)
                    ) {
                      if (chapterDeltas.frontMatterIds !== undefined) {
                        chapterDeltas.frontMatterIds.push(newChapterId);
                      } else {
                        chapterDeltas.frontMatterIds = [newChapterId];
                      }
                    } else if (
                      serverBook.backMatterIds.includes(newChapterId) ||
                      offlineBook.backMatterIds.includes(newChapterId)
                    ) {
                      if (chapterDeltas.backMatterIds !== undefined) {
                        chapterDeltas.backMatterIds.push(newChapterId);
                      } else {
                        chapterDeltas.backMatterIds = [newChapterId];
                      }
                    } else {
                      if (chapterDeltas.chapterIds !== undefined) {
                        chapterDeltas.chapterIds.push(newChapterId);
                      } else {
                        chapterDeltas.chapterIds = [newChapterId];
                      }
                    }
                });

                await PatchBook(offlineBook._id, chapterDeltas);
                await SaveServerBookToDB(offlineBook._id);
            }
        }
    }
}

function allSkippingErrors(promises) {
    const handleErr = (err) => {
        if(err && err.response.status === 500){
            notification.error({
                message: "Book couldn't be loaded",
                //description: "The book you are trying to load seems to exceed the limit",
            });
        }
        console.log({err});
        return null;
    };
    return Promise.all(
        promises.map(p => p.catch(handleErr))
    );
}

export async function syncData(): Promise<void> {

	const offlineBooks = await db.books.toArray();
	const bookResponse = await AtticusClient.GetBooks();

	const serverBooks = bookResponse ? bookResponse.books : [];

	await db.books.bulkDelete(bookResponse.deletedBookIds);

	const waitForPromises: Promise<boolean>[] = [];

	// TODO: Handle Deleted Books

	// Scenario 1: Check for books present on the client is not present on the server
	for (const book of offlineBooks as Book[]) {
		if (serverBooks.findIndex((cur) => cur._id === book._id) === -1) {
			waitForPromises.push(SaveOfflineBookToServer(book._id));
		}
	}

	// Scenario 2: Check for books present on the server but not present on clients
	for (const book of serverBooks as Book[]) {
		if (offlineBooks.findIndex((cur) => cur._id === book._id) === -1) {
			waitForPromises.push(SaveServerBookToDB(book._id));
		}
	}

	// Scenario 3: The books are present on both server and client
	for (const book of serverBooks as Book[]) {
		const index = offlineBooks.findIndex((cur) => cur._id === book._id);
		if (index !== -1) {
            waitForPromises.push(syncBook(book._id));
		}
	}

	await allSkippingErrors(waitForPromises);
}

// Chapter Template Library
export async function SaveTemplateToDB(): Promise<boolean> {
	const templates = await AtticusClient.GetChapterTemplates();

	if (!templates) return false;

	for(const temp of templates) {
		await SaveChapterTemplateToDB(temp);
	}

	return true;
}

// Theme
export async function ThemeLevelSync(bookId: string): Promise<boolean> {
	const allPromises: Promise<ThemeConfig | undefined>[] = [];
	allPromises.push(AtticusClient.GetBookTheme(bookId));

	allPromises.push(GetThemeFromDB(bookId));

	const [serverTheme, offlineTheme] = await Promise.all(allPromises);

	if (!serverTheme || !offlineTheme) return false;

	let updateServer = false;
	let updateLocal = false;
	let newTheme: ThemeConfig | null = null;

	if (offlineTheme.allChangesSynced === undefined) offlineTheme.allChangesSynced = true;

	if (serverTheme.lastUpdateAt != undefined && offlineTheme.lastSuccessfulSync !== undefined) {
		if (serverTheme.lastUpdateAt === offlineTheme.lastSuccessfulSync && offlineTheme.allChangesSynced) {
			//none
		}

		//if the local copy is more recent
		if (offlineTheme.lastSuccessfulSync === serverTheme.lastUpdateAt) {
			updateServer = true;
			newTheme = offlineTheme;
		}
		//if the server copy is more recent
		else if (serverTheme.lastUpdateAt > offlineTheme.lastSuccessfulSync) {
			updateLocal = true;
			newTheme = serverTheme;
			if (!offlineTheme.allChangesSynced) {
				updateServer = true;
			}
		}

	} else {
		// first time unsynced changes on the client, resolve conflicts
		if (offlineTheme.lastSuccessfulSync === undefined && !offlineTheme.allChangesSynced) {
			updateServer = true;
			updateLocal = true;

			newTheme = serverTheme;

		}
	}

	if (updateLocal && newTheme && offlineTheme.allChangesSynced) {
		// Save copy of the server book to the server
		await SaveThemeToDB(newTheme);
	} else if (updateServer && newTheme && !offlineTheme.allChangesSynced) {
		// Save copy of the client book to the server
		await SaveThemeToServer(bookId, newTheme);
	}

	return true;
}

async function SaveThemeToServer(bookId: string, theme: ThemeConfig): Promise<void> {
	try {
		delete theme["modifiedAt"];
		delete theme["allChangesSynced"];

		const res = await AtticusClient.PutBookTheme(bookId, theme);
		await UpdateThemeInDB(bookId, {
			//lastSuccessfulSync: res.timestamp,
			allChangesSynced: true,
		});
	} catch (e) {
		console.log(`ERROR SAVING THEME ${theme._id}`, e);
		throw e;
	}
}

export async function SaveOfflineThemeToServer(bookId: string): Promise<boolean> {

	const offlineTheme = await GetThemeFromDB(bookId);

	if (!offlineTheme) return false;

	await SaveThemeToServer(bookId, offlineTheme); //CHECK
	// await SaveThemeToServer(offlineTheme) <---- should be
	return true;
}

export async function SaveServerThemeToDB(bookId: string): Promise<boolean> {
	const serverTheme = await AtticusClient.GetBookTheme(bookId);
	if (!serverTheme) return false;
	await SaveThemeToDB(serverTheme);
	return true;
}


export async function SaveAllthemeConfigsToDB(bookIds:string[]):Promise<boolean>{

	const themes = await AtticusClient.getThemesForBooks(bookIds);
	if(!themes || themes.length===0) return false;
	for(const theme of themes){
		await SaveThemeToDB(theme);
	}
	return true;

}


export async function UpdateTheme(bookId: string, theme: ThemeConfig): Promise<boolean> {
	try {

		delete theme["modifiedAt"];
		delete theme["allChangesSynced"];

		const res = await AtticusClient.PutBookTheme(bookId, theme);
		await UpdateThemeInDB(theme._id, {
			...theme,
			//lastSuccessfulSync: res.timestamp,
			allChangesSynced: true
		});
		return true;
	} catch (e) {
		console.log({ e });
		throw e;
	}
}

//Theme Sync
export async function themeDataSync(book_id: string): Promise<void> {

	const offlineTheme = await db.themeConfig.get({bookId: book_id});
	const serverTheme = await AtticusClient.GetBookTheme(book_id);

	if(!offlineTheme && serverTheme){
		await SaveServerThemeToDB(serverTheme.bookId);
	}else if(!serverTheme && offlineTheme){
		await SaveOfflineThemeToServer(offlineTheme.bookId);
	}else if(serverTheme && offlineTheme){
		await ThemeLevelSync(offlineTheme.bookId);
	}

}

