import React, { FunctionComponent, useEffect, useState } from "react";
import { ToolbarElement } from "@udecode/slate-plugins";
import { observer } from "mobx-react";
import useRootStore from "../../../../store/useRootStore";
import { SmartQuoteDetectionError, SmartQuoteDetectionOK, SmartQuoteDetectionWaiting } from "../../partials/Icon";
import { TooltipIcon } from "../../partials/TooltipIcon";
import { GetBookFromDB } from "../../../../utils/offline.book.helpers";
import { Chapter } from "../../../../types/chapter";
import { uniq } from "lodash";
import { setInterval } from "timers";
import { isEmpty } from "../../../../utils/strings";
import { Button, Modal, Table } from "antd";
import { Book } from "../../../../types/book";

type ChapterQuotesResultType = "Straight" | "Curly" | "Mix" | "N/A";
type ChapterQuoteStyleRecord = Record<string, ChapterQuotesResultType>;

interface ChapterErrorsObject {
  _id: string;
  title: string;
  quotesType: ChapterQuotesResultType;
}

const regularQuotes = /["']/;
const smartQuotes = /[“”″‘’′]/;

const getAllTableRowsForErrors = async (bookId: string, results: ChapterQuoteStyleRecord | null): Promise<ChapterErrorsObject[]> => {
  if (results === null) return [];

  const book = await GetBookFromDB(bookId, {
    chapterMeta: true,
    chapterBodies: true,
  });

  if (book === null || book === undefined) return [];

  const data: ChapterErrorsObject[] = [];

  const allChapters = allBookChapters(book);

  allChapters.forEach((chapter) => {
    data.push({
      _id: chapter._id,
      title: chapter.title,
      quotesType: results[chapter._id] ?? "N/A",
    });
  });

  return data;
};

const allBookChapters = (book: Book): Chapter[] => {
  return [
    ...(book.frontMatter || []),
    ...(book.chapters || []),
    ...(book.backMatter || []),
  ];
};

const analyzeQuoteConsistencyInChapter = (
  content: any,
  results: ChapterQuotesResultType[] = []
): ChapterQuotesResultType => {
  for (let i = 0; i < content.length; i++) {
    const { text = null, children = [] } = content[i];

    if (children.length > 0) {
      results.push(analyzeQuoteConsistencyInChapter(children, results));
    } else {
      if (isEmpty(text)) {
        continue;
      }

      const coercedText = text as string;

      const smartQuotesOnly = smartQuotes.test(coercedText);
      const regularQuotesOnly = regularQuotes.test(coercedText);

      if (smartQuotesOnly && regularQuotesOnly) {
        results.push("Mix");
        continue;
      }

      if (smartQuotesOnly === false && regularQuotesOnly === false) {
        results.push("N/A");
        continue;
      }

      results.push(smartQuotesOnly ? "Curly" : "Straight");
    }
  }

  const uniqueResults = uniq<ChapterQuotesResultType>(results.filter(result => result !== "N/A"));

  if (uniqueResults.length === 0) return "N/A";

  if (uniqueResults.length === 1) return uniqueResults[0];

  return "Mix";
};

const analyzeQuoteConsistencyInBook = async (
  bookId: string
): Promise<ChapterQuoteStyleRecord | null> => {
  const book = await GetBookFromDB(bookId, {
    chapterMeta: true,
    chapterBodies: true,
  });

  if (book === null || book === undefined) return null;

  const chapterConsistency: ChapterQuoteStyleRecord = {};

  const chapters = allBookChapters(book);

  chapters.forEach((chapter: Chapter) => {
    chapterConsistency[chapter._id] = analyzeQuoteConsistencyInChapter(
      chapter.children ?? []
    );
  });

  return chapterConsistency;
};

const validateAnalyzedResults = (chapters: ChapterQuoteStyleRecord | null): boolean | null => {
  if (chapters === null) return null;

  const chapterQuoteTypes = uniq(
    Object.values(chapters)
  ).filter((type) => type !== "N/A");

  if (chapterQuoteTypes.length === 1) {
    if (chapterQuoteTypes[0] !== "Mix") return true;
  }

  return false;
};

export const SmartQuotesConsistencyButton: FunctionComponent = observer(() => {
  const { book } =
    useRootStore().bookStore;

  const [analyzedResults, setAnalyzedResults] =
    useState<ChapterQuoteStyleRecord | null>(null);

  const [rows, setRows] = useState<ChapterErrorsObject[]>([]);

  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async() => {
      const results = await analyzeQuoteConsistencyInBook(book._id);
      setAnalyzedResults(
        results
      );
      setRows(
        await getAllTableRowsForErrors(book._id, results)
      );
    };

    const interval = setInterval(async () => {
      await fetchData();
    }, 5000);

    return () => clearInterval(interval);
  }, [book]);

  const quotesConsistent = validateAnalyzedResults(analyzedResults);

  return (
    <>
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        title="Smart Quotes Inconsistencies"
        footer={
          <Button
            type="primary"
            block
            size="large"
            style={{ borderRadius: 0, height: "auto", padding: "1rem 0rem" }}
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
        }
      >
        <Table
          dataSource={rows}
          pagination={{
            pageSize: 5,
          }}
          columns={[
            {
              title: "Chapter Title",
              dataIndex: "title",
              key: "title",
            },
            {
              title: "Quotes Type",
              dataIndex: "quotesType",
              key: "quotesType",
            },
          ]}
        />
      </Modal>

      <TooltipIcon
        title={
          analyzedResults === null
            ? "Loading..."
            : quotesConsistent
            ? "Awesome! Your quotes are consistent."
            : "Oops! Click here to see inconsistencies."
        }
      >
        <ToolbarElement
          type={"smartquotes-consistency"}
          icon={
            analyzedResults === null ? (
              <SmartQuoteDetectionWaiting />
            ) : quotesConsistent ? (
              <SmartQuoteDetectionOK />
            ) : (
              <SmartQuoteDetectionError />
            )
          }
          onMouseDown={() => {
            if (quotesConsistent === null) return;

            setShowModal(true);
          }}
        />
      </TooltipIcon>
    </>
  );
});
