import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Row,
  Select,
  Col,
  Divider,
  Input,
  Radio,
  Slider,
  Modal,
  Switch,
  Popconfirm
} from "antd";
import { observer } from "mobx-react";

import { find } from "lodash";

import useRootStore from "../../../store/useRootStore";

// Partials
import CustomThemeImageLibrary from "../Uploads/CustomThemeImageLibrary";
import CustomThemeBackgroundLibrary from "../Uploads/CustomThemeBackgroundLibrary";

// helpers
import { printFonts, editorFonts } from "../helpers/printConfigs";
import { makeid } from "../../../utils/helper";
import { useOnlineStatus } from "../../../utils/isOffline";

import SingleTheme from "../SingleTheme";
import PlacementTheme from "../PlacementTheme";

import "../styles/theme.scss";
import { CustomImages } from "../../../types/theme";
import { DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;

const getPlacementOrder = (t: "above-chapter-no" | "above-chapter-title" | "below-chapter-title" | "below-subtitle") => {
  if (t === "above-chapter-no") return 1;
  if (t === "above-chapter-title") return 2;
  if (t === "below-chapter-title") return 3;
  return 4;
};

const fontStyleGenerator = (style: IThemeStore.ChapterTitleFontStyle) => {
    let s = "";

    if(style === "underlined")
        s = "text-decoration: underline;";

    if(style === "small-caps")
        s = "font-variant: small-caps;";

    if(style === "bold")
        s = "font-weight: 900!important;";

    if(style === "italic")
        s = "font-style: italic;";
    
    if(style === "bold-italic")
        s = "font-weight: 900!important; font-style: italic;"; 

    return s;
};

const doCss = (v: IThemeStore.CustomThemeFields, id: string) => {
  const style = `
    .chp_bg{
      background-color: rgba(255,255,255, ${1.0 - (v.imgOpacity/100)});
      background-blend-mode: lighten;
      opacity: ${v.imgOpacity};
    }
    .${id} .chapter-title-card .chapter-title h2{
      color: ${v.textLight === "light" ? "white" : "black"};
    }
    .${id} .chapter-title-card .chapter-number{
      color: ${v.textLight === "light" ? "white" : "black"};
    }
    .${id} .chapter-title-card .chapter-subtitle h3{
      color: ${v.textLight === "light" ? "white" : "black"};
    }
    .${id} .chapter-title-card, .${id} .title-card, .epub-toc-title-card{
      display: flex;
      flex-direction: column;
    }
    .${id} .chapter-title-card, .epub-toc-title-card{
      position: relative;
      min-height: 13em;
      padding-top: 1em;
      padding-bottom: 1em;
      display: flex;
    }
    .chp_clr_none img, .chp_clr_none{
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
    }
    .chp_clr_all img, .chp_clr_all{
      filter: grayscale(0%);
      -webkit-filter: grayscale(0%);
      -moz-filter: grayscale(0%);
      -ms-filter: grayscale(0%);
      -o-filter: grayscale(0%);
    }
    .${id} .chapter-title-card .chp_img{
      order: ${getPlacementOrder(v.imgPlacement)};
      text-align: ${v.imgAlignment};
      padding-top: 0.3em;
    }
    .${id} .chapter-title-card .chp_img img{
      width: ${v.imgWidth}%;
    }
    .${id} .chapter-title-card .chapter-number, .${id} .chapter-title-card .chapter-title, .${id} .chapter-title-card .chapter-subtitle, .epub-toc-title-card h2{
      padding-left: 3%;
      width: 94%;
      z-index: 10;
      display: block;
    }
    .${id} .chapter-number{
      text-align: ${v.chpNumAlign}!important;
      order: 2;
      text-transform: capitalize;
    }
    .${id} .chapter-title{
      text-align: ${v.chpTitleAlign}!important;
      order: 3;
    }
    .${id} .chapter-subtitle{
      text-align: ${v.chpSubtitleAlign}!important;
      order: 4;
    }
    .${id} .chapter-number span, .${id} .chapter-title h2, .epub-toc-title-card h2, .${id} .chapter-subtitle h3 {
        font-weight: 400;
    }
    .${id} .chapter-number span{
      font-family: '${v.chpNumFont}';
      font-size: ${(2 + v.chpNumSize) / 3}em;
      text-align: ${v.chpNumAlign};
      line-height: 1.${v.chpNumSize};
      width: ${v.chpNumWidth}%;
      ${fontStyleGenerator(v.chpNumStyle)}
      display: inline-block;
    }
    .${id} .chapter-title h2, .epub-toc-title-card h2{
      font-family: '${v.chpTitleFont}';
      font-size: ${(4 + v.chpTitleSize) / 3}em;
      text-align: ${v.chpTitleAlign}!important;
      line-height: 1.${v.chpTitleSize};
      width: ${v.chpTitleWidth}%;
      ${fontStyleGenerator(v.chpTitleStyle)}
      display: inline-block;
    }
    .${id} .chapter-subtitle h3{
      font-family: '${v.chpSubtitleFont}';
      font-size: ${(3 + v.chpSubtitleSize) / 3}em;
      text-align: ${v.chpSubtitleAlign};
      line-height: 1.${v.chpSubtitleSize};
      width: ${v.chpSubtitleWidth}%;
      ${fontStyleGenerator(v.chpSubtitleStyle)}
      display: inline-block;
    }
    .${id} header .meta{
      font-size: ${4 + (2 * v.headerSize)}pt;
      font-family: ${v.headerFont};
    }
    .${id} footer .meta{
      font-size: ${4 + (2 * v.headerSize)}pt;
      font-family: ${v.footerFont};
    }
    .${id} .title-card {
      font-family: '${v.chpTitleFont}';
    }
    .${id} .title-card h1{
      font-size: 44px;
      padding: 0.6em 0em;
      font-weight: 600;
    }
    .${id} .title-card h2{
      font-size: 22px;
      padding: 1em 0em;
    }
    .${id} .title-card h3{
      font-size: 20px;
      color:#666;
      padding: 1em 0em;
    }
    .${id} .publisher-details{
      font-family: '${v.chpNumFont}';
    }
  `;

  return style;
};


const widthMarks = {
  20: {
    label: "20%"
  },
  40: {
    label: "40%"
  },
  60: {
    label: "60%"
  },
  80: {
    label: "80%"
  },
  100: {
    label: "100%"
  },
};

const opacityMarks = {
  0: {
    label: "0%"
  },
  20: {
    label: "20%"
  },
  40: {
    label: "40%"
  },
  60: {
    label: "60%"
  },
  80: {
    label: "80%"
  },
  100: {
    label: "100%"
  },
};


const sizeMarks = {
  1: {
    label: "xs"
  },
  2: {
    label: "s"
  },
  3: {
    label: "m"
  },
  4: {
    label: "l"
  },
  5: {
    label: "xl"
  },
};

const initConfig: IThemeStore.CustomThemeFields = {
  name: "",
  image: "",
  layout: "no-image",
  individualChapterImage: false,
  coloredImg: "all",
  bgPrintExtent: "margins",
  imgWidth: 100,
  imgAlignment: "center",
  imgOpacity: 100,
  textLight: "default",
  imgPlacement: "below-subtitle",
  chpNumFont: editorFonts[0]._id,
  chpNumStyle: "normal",
  chpNumSize: 2,
  chpNumAlign: "left",
  chpNumWidth: 100,
  chpTitleFont: editorFonts[0]._id,
  chpTitleStyle: "normal",
  chpTitleSize: 2,
  chpTitleAlign: "left",
  chpTitleWidth: 100,
  chpSubtitleFont: editorFonts[0]._id,
  chpSubtitleStyle: "normal",
  chpSubtitleSize: 2,
  chpSubtitleAlign: "left",
  chpSubtitleWidth: 100,
  headerFont: printFonts[0]._id,
  headerSize: 1,
  footerFont: printFonts[0]._id,
  footerSize: 1
};

const defImgs : IThemeStore.CustomImages= {
  chapterId: "",
  url: "",
  background: false,
  colored: "all",
  placement: "below-subtitle",
  alignment: "left",
  width: 100,
  printExtent: "margins",
};

const formatProps = (props: IThemeStore.CustomThemeFields) => {
  return {
    layout: props.layout,
    individualChapterImage: props.individualChapterImage,
    textLight: props.textLight,
    image: {
      url: props.layout !== "no-image" ? props.image : "",
      background: props.layout === "background-image" ? true : false,
      width: props.imgWidth,
      colored: props.coloredImg,
      printExtent: props.bgPrintExtent,
      placement: props.imgPlacement,
      alignment: props.imgAlignment,
      opacity: props.imgOpacity
    },
    chapterNo: {
      font: props.chpNumFont,
      style: props.chpNumStyle,
      size: props.chpNumSize,
      align: props.chpNumAlign,
      width: props.chpNumWidth,
    },
    chapterTitle: {
      font: props.chpTitleFont,
      style: props.chpTitleStyle,
      size: props.chpTitleSize,
      align: props.chpTitleAlign,
      width: props.chpTitleWidth,
    },
    chapterSubtitle: {
      font: props.chpSubtitleFont,
      style: props.chpSubtitleStyle,
      size: props.chpSubtitleSize,
      align: props.chpSubtitleAlign,
      width: props.chpSubtitleWidth
    },
    header: {
      font: props.headerFont,
      size: props.headerSize,
    },
    footer: {
      font: props.footerFont,
      size: props.footerSize
    }
  } as IThemeStore.CustomThemeHeader;
};

const formatEditTheme = ({name, properties}: IThemeStore.ThemeBase) => {
  if(!properties)
    return initConfig;

  const { image } = properties;
  const params : IThemeStore.CustomThemeFields = {
    name: name,
    image: image.url || "",
    layout: properties.layout,
    individualChapterImage: properties.individualChapterImage,
    bgPrintExtent: image.printExtent || "margins",
    coloredImg: image.colored,
    imgWidth: image.width || 1,
    imgAlignment: image.alignment || "left",
    imgOpacity: image.opacity || 100,
    textLight: properties.textLight,
    imgPlacement: image.placement || "below-subtitle",
    chpNumFont: properties.chapterNo.font,
    chpNumStyle: properties.chapterNo.style,
    chpNumSize: properties.chapterNo.size,
    chpNumAlign: properties.chapterNo.align,
    chpNumWidth: properties.chapterNo.width,
    chpTitleFont: properties.chapterTitle.font,
    chpTitleStyle: properties.chapterTitle.style,
    chpTitleSize: properties.chapterTitle.size,
    chpTitleAlign: properties.chapterTitle.align,
    chpTitleWidth: properties.chapterTitle.width,
    chpSubtitleFont: properties.chapterSubtitle.font,
    chpSubtitleStyle: properties.chapterSubtitle.style,
    chpSubtitleSize: properties.chapterSubtitle.size,
    chpSubtitleAlign: properties.chapterSubtitle.align,
    chpSubtitleWidth: properties.chapterSubtitle.width,
    headerFont: properties.header.font,
    headerSize: properties.header.size,
    footerFont: properties.footer.font,
    footerSize: properties.footer.size,
  };
  return params;
};

const BuildCustomTheme = observer(({edit, onSave}: {edit?: IThemeStore.ThemeBase, onSave: () => void}) => {
  const [form] = Form.useForm();
  const isOnline = useOnlineStatus();
  const { setCustomThemeBuilderView, book } = useRootStore().bookStore;
  const { refreshCache } = useRootStore().pdfCacheStore;
  const { setCustomCss, setCustomThemeHeaders, saveCustomTheme, style, headingFontsStyle, putCustomTheme, setImages, images, editTheme, individualChapterImage, setIndividualChapterImage, setEditTheme, setStyle, saveNewCusTheme } = useRootStore().themeStore;
  const [loading, setLoading] = useState(false);
  const [isImg, setImg] = useState(edit && edit.properties?.layout !== "no-image" ? true : false);
  const [isImageDeleted,setImageDelete] = useState(false);
  const [headingStyles, setHeadingStyles] = useState<IThemeStore.CustomThemeHeadingStyle | null>(null);

  const {chpNumStyle, chpTitleStyle, chpSubtitleStyle} = headingStyles || {};
  
  const handleSubmit = (values) => {
    setLoading(true);
    const id = edit && edit._id ? edit._id : makeid(8);
    const csss = doCss(values, id);
    const fonts = Array.from(new Set([
      values.chpNumFont,
      values.chpTitleFont,
      values.chpSubtitleFont
    ]));
    const fontscss = fonts.reduce((acc, v) => {
      const f = find([...printFonts, ...editorFonts], { _id: v });
      return acc = acc + (f && f.css ? "\n" + f.css : "");
    }, "");
    const finalcss = `${fontscss} ${csss}`;
    const params = {
      _id: id,
      name: values.name,
      fonts,
      css: finalcss,
      printCss: finalcss,
      epubCss: finalcss,
      properties: formatProps(values),
    };
    if(style?._id === params._id){
      setImages(params.properties.image);
    }
    if(edit){ 
      putCustomTheme(params).then(() => {
        setLoading(false);
        setCustomThemeBuilder();
      });
    } else {
      if(params.properties.layout !== "no-image"){
        onSave();
      }
      saveCustomTheme(params).then(() => {
        setCustomThemeBuilder();
        saveNewCusTheme(true);
        setLoading(false);
        setStyle(params);
        setImages(params.properties.image);
      });
    }
    refreshCache(book._id, "theme-change");
  };

  const handleChange = (values, allvs) => {
    const finalcss = doCss(
      {
        ...allvs,
        ...values,
      },
      "customed"
    );

    setCustomCss(finalcss);    
    setCustomThemeHeaders(formatProps(allvs));
    refreshCache(book._id, "theme-change");

    if("chpNumFont" in values)
        setChpHeadingStyle("chpNumStyle");

    if("chpTitleFont" in values)
        setChpHeadingStyle("chpTitleStyle");

    if("chpSubtitleFont" in values)
        setChpHeadingStyle("chpSubtitleStyle");

  };

  const setChpHeadingStyle = (key: string) => {
    const h = headingFontsStyle();

    const v = form.getFieldValue(key);
    const arr = Object.keys(h ? h[key]: "");

    if(!arr.includes(v)){
        form.setFieldsValue({
            [key]: arr[0]
        });
    }

    setHeadingStyles(h);
  };

  const setCustomThemeBuilder = (backPressed? : boolean) => {
    setImages({...images, url: ""});
    if(backPressed) {
      onBackPress();
    } else {
      setCustomThemeBuilderView("customThemeStep1");
    }
  };

  const onBackPress = () => {
    Modal.confirm({
      icon: null,
      title: <h2 className="section-heading">Leave the custom theme builder?</h2>,
      centered: true,
      onOk: () => {
        // setCustomThemeBuilderView("customThemeStep1");
      },
      okText: "Continue working",
      okButtonProps: {
        type: "primary",
        danger: true,
        style: {
          flex: 1,
        },
      },
      cancelText: "Leave without saving",
      onCancel: () => {
        setCustomThemeBuilderView("customThemeStep1");
      },
      cancelButtonProps: {
        className: "btn-a",
        style: {
          flex: 1,
        },
      },
    });
  };

  const openImageOptions = (layout) => {
    const imgs = getImages(layout);
    setImages(imgs);
    setImg(layout === "no-image" ? false : true);
  };

  const setFormFields = (props: Partial<IThemeStore.CustomThemeFields>) => {
    const keys = Object.keys(props);
    const fields = keys.map(v => ({
      name: `${v}`,
      value: props[v]
    }));    
    form.setFields(fields);
  };
  
  const getImages = (layout: "no-image" | "background-image" | "image-element") => {
    let imgs = {
      chapterId: "",
      url: form.getFieldValue("image"),
      background: false,
      colored: form.getFieldValue("coloredImg"),
      placement: form.getFieldValue("imgPlacement"),
      alignment: form.getFieldValue("imgAlignment"),
      width: form.getFieldValue("imgWidth"),
    } as CustomImages;

    switch (layout) {
      case "background-image" : imgs = {...imgs, background: true,printExtent:form.getFieldValue("bgPrintExtent")};
        break;
      case "image-element" : imgs = {...imgs, background: false};
        break;
      case  "no-image" : imgs = {...imgs, url: ""};
        break;
    }
    return imgs;
  };

  const onUpload = (url: string) => {
    setFormFields({ image: url });
    const fields= form.getFieldsValue();
    handleChange({image: url}, fields);
    if(images) {
      setImages({
        ...images,
        url
      });
    }
  };

  useEffect(() => {
    let cssd = doCss(initConfig, "customed");
    let imgs : IThemeStore.CustomImages = defImgs;
    if(edit && edit.properties){
      cssd = doCss(formatEditTheme(edit), "customed");

      const editT = edit.properties;
      if(editT && editT.image && editT.layout !== "no-image"){
        imgs = editT.image;
      }
      setIndividualChapterImage(edit.properties?.individualChapterImage || false);
      setCustomThemeHeaders(edit.properties);
    }
    setImages(imgs);
    setCustomCss(cssd);
    
    setHeadingStyles(headingFontsStyle());
    
    return () => {
      setEditTheme(null);
    };
  }, []);

  return (
    <div className="area-container">
      <Form
        form={form}
        layout="vertical"
        className="fullWidth"
        onFinish={handleSubmit}
        onValuesChange={(v, s) => handleChange(v, s)}
        initialValues={edit ? formatEditTheme(edit) : initConfig}
        //initialValues={el}
      >
        <Row className="inner-s" justify="space-between" align="middle">
          <h2>Build Custom Theme</h2>
        </Row>
        <div className="content theme-config">
          <div>
            <div className="thm-section">
              <Row gutter={16} justify="space-between">
                <Col>
                <h2>Step 1: Choose a General Layout</h2>
                </Col>
                <Col>
                <Button className="btn-a" type="primary" value="build-custom-theme" onClick={() => setCustomThemeBuilder(true)}>Back</Button>
                </Col>
              </Row>
              <Row className="theme-layout-btns">
                <Form.Item
                  name="layout"
                >
                  <Radio.Group defaultValue="no-image">
                    <Radio value="no-image" onClick={(c) => openImageOptions("no-image")}>
                      <SingleTheme.A />
                    </Radio>
                    <Radio value="background-image" onClick={(c) => openImageOptions("background-image")}>
                      <SingleTheme.B />
                    </Radio>
                    <Radio value="image-element" onClick={(c) => openImageOptions("image-element")}>
                      <SingleTheme.C />
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Row>
              {/* </div> */}
            </div>
            <Row>
              <Divider />
              <h2  style={{ color: isImg ? "" : "grey" }}>Step 2: Select an Image</h2>
            </Row>
            <div className="thm-section image" style={{ display: isImg ? "block" : "none" }}>
              <h5 className="inner-s">Based on the selected image, our recommended image size is 600x400px</h5>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="individualChapterImage"
                    label="Use Individual Chapter Images"
                    valuePropName="checked"
                  >
                    <Switch onChange={(a) => setIndividualChapterImage(a)} />
                  </Form.Item>
                </Col>
              </Row>
              {!individualChapterImage && images && images.url ? (
                <Row className="inner">
                  <div className="thm-img">
                    <img src={images.url} alt="Custom theme buidler - Image" />
                  </div>
                </Row>
              ) : null}
              <div id="background-image" style={{ display: images && !images.background ? "none" : "block" }}>
                <Row>
                  <Col style={{ display: individualChapterImage ? "none" : ""}}>
                    <Form.Item
                      name="image"
                      className="ob-btns"
                    >
                      <Row>

                      <CustomThemeBackgroundLibrary
                        onUpdate={url => {
                          form.setFieldsValue({ image: url });
                          onUpload(url);
                          setImageDelete(false);

                        }}
                        isImageDeleted={isImageDeleted}
                      />
                      {form.getFieldValue("image")?(
                    <Popconfirm
                      title="Are you sure you want to delete this image?"
                      onConfirm={() => {
                      form.setFieldsValue({image:""});
                      onUpload("");
                      setImageDelete(true);
                    }}
                    okText="Yes"
                    cancelText="No"
                    >
                    <Button type="link" icon={<DeleteOutlined />}  />
                    </Popconfirm>
                      ):null}
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
                <h4>Background Image Options</h4>
               
                <Row gutter={32}>
                  <Col span={10}>
                    <Form.Item
                      name="imgOpacity"
                      label="Image Opacity"
                      tooltip="Set background image opacity."
                    >
                       <Slider marks={opacityMarks} min={0} max={100} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={32}>
                  <Col span={10}>
                    <Form.Item
                      name="textLight"
                      label="Text Color"
                      tooltip="Set text color"
                    >
                      <Radio.Group defaultValue="default">
                        <Radio value="default">Default</Radio>
                        <Radio value="light">Light</Radio>
                      </Radio.Group>

                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col>
                    <Form.Item
                      name="bgPrintExtent"
                      label="Print Extent"
                      tooltip="Full Bleed only supports on print version (PDF)."
                    >
                      <Radio.Group defaultValue="margins">
                        <Radio value="margins">Margins</Radio>
                        <Radio value="full-bleed">Full Bleed</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div id="image-options" style={{ display: images && images.background ? "none" : "block" }}>
                <Row style={{ display: individualChapterImage ? "none" : ""}}>
                  <Form.Item
                    name="image"
                    shouldUpdate={true}
                    className="ob-btns"
                  >
                    <Row>

                    <CustomThemeImageLibrary
                      selected={form.getFieldValue("image")}
                      onUpdate={url => {
                        onUpload(url);
                        setImageDelete(false);

                      }}
                      isImageDeleted={isImageDeleted}
                      />
                    {form.getFieldValue("image")?(
                    <Popconfirm
                      title="Are you sure you want to delete this image?"
                      onConfirm={() => {
                      form.setFieldsValue({image:""});
                      onUpload("");
                      setImageDelete(true);

                    }}
                    okText="Yes"
                    cancelText="No"
                    >
                    <Button type="link" icon={<DeleteOutlined />}  />
                    </Popconfirm>
                      ):null}
                      </Row>
                  </Form.Item>
                </Row>
                <h4>Image Element Options</h4>
                <Row gutter={32}>
                  <Col span={12}>
                    <Form.Item
                      name="imgWidth"
                      label="Width Percentage"
                    >
                      <Slider marks={widthMarks} min={20} max={100} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="imgAlignment" label="Alignment">
                      <Select placeholder="Select">
                        {/* className={theme.alignment} */}
                        <Option value="left" key={1}>Left</Option>
                        <Option value="center" key={3}>Center</Option>
                        <Option value="right" key={2}>Right</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row className="theme-layout-btns">
                      <Form.Item
                        name="imgPlacement"
                        label="Placement"
                      >
                        <Radio.Group>
                          <Radio value="above-chapter-no">
                            <PlacementTheme.A />
                          </Radio>
                          <Radio value="above-chapter-title">
                            <PlacementTheme.B />
                          </Radio>
                          <Radio value="below-chapter-title">
                            <PlacementTheme.C />
                          </Radio>
                          <Radio value="below-subtitle">
                            <PlacementTheme.D />
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
              </div>
              {/* Moved field to theme config */}
              {/* <Row gutter={16}>
                <Col>
                  <Form.Item
                    name="coloredImg"
                    label="Color"
                  >
                    <Radio.Group defaultValue="none">
                      <Radio value="all">All</Radio>
                      <Radio value="print">Print Only</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row> */}
            </div>
            <Divider />
            <div className="thm-section well">
              <h2>Step 3: Chapter Heading Settings</h2>
              <h3>Chapter Number</h3>
              <br />
              <Row gutter={32}>
                <Col span={8}>
                  <Form.Item name="chpNumFont" label="Font">
                    <Select placeholder="Select">
                      {editorFonts.map((font, i) => (
                        <Option className={font._id} value={font._id} key={i}>{font.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="chpNumAlign" label="Align">
                    <Select placeholder="Select">
                      <Option value="left" key={1}>Left</Option>
                      <Option value="center" key={3}>Center</Option>
                      <Option value="right" key={2}>Right</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="chpNumStyle" label="Style">
                    <Select placeholder="Select">
                        {chpNumStyle && Object.keys(chpNumStyle).map((key, i) => (
                            <Option value={key} key={`${key}-${i}`}> {chpNumStyle[key]} </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="chpNumSize" label="Size">
                    <Slider tooltipVisible={false} marks={sizeMarks}  min={1} max={5} />
                    {/* <Select placeholder="Select">
                      {printFonts.map((font, i) => (
                        <Option className={font._id} value={font._id} key={i}>{font.name}</Option>
                      ))}
                    </Select> */}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="chpNumWidth" label="Width Percentage">
                    <Slider marks={widthMarks} min={20} max={100} />
                  </Form.Item>
                </Col>
              </Row>
              <h3>Chapter Title</h3>
              <br />
              <Row gutter={32}>
                <Col span={8}>
                  <Form.Item name="chpTitleFont" label="Font">
                    <Select placeholder="Select">
                      {/* className={theme.chapterTitleFont} */}
                      {editorFonts.map((font, i) => (
                        <Option className={font._id} value={font._id} key={i}>{font.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="chpTitleAlign" label="Align">
                    <Select placeholder="Select">
                      <Option value="left" key={1}>Left</Option>
                      <Option value="center" key={3}>Center</Option>
                      <Option value="right" key={2}>Right</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="chpTitleStyle" label="Style">
                    <Select placeholder="Select">
                        {chpTitleStyle && Object.keys(chpTitleStyle).map((key, i) => (
                            <Option value={key} key={`${key}-${i}`}> {chpTitleStyle[key]} </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="chpTitleSize" label="Size">
                    <Slider tooltipVisible={false}  marks={sizeMarks}  min={1} max={5} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <h4>Width Percentage</h4>
                  <Form.Item name="chpTitleWidth">
                    <Slider marks={widthMarks} min={20} max={100} />
                  </Form.Item>
                </Col>
              </Row>
              <h3>Chapter Subtitle</h3>
              <br />
              <Row gutter={32}>
                <Col span={8}>
                  <Form.Item name="chpSubtitleFont" label="Font">
                    {/* className={theme.chaptersubtitleFont} */}
                    <Select placeholder="Select">
                      {editorFonts.map((font, i) => (
                        <Option className={font._id} value={font._id} key={i}>{font.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="chpSubtitleAlign" label="Align">
                    <Select placeholder="Select">
                      <Option value="left" key={1}>Left</Option>
                      <Option value="center" key={3}>Center</Option>
                      <Option value="right" key={2}>Right</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="chpSubtitleStyle" label="Style">
                    <Select placeholder="Select">
                        {chpSubtitleStyle && Object.keys(chpSubtitleStyle).map((key, i) => (
                            <Option value={key} key={`${key}-${i}`}> {chpSubtitleStyle[key]} </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="chpSubtitleSize" label="Size">
                    <Slider tooltipVisible={false} marks={sizeMarks} min={1} max={5} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="chpSubtitleWidth" label="Width Percentage">
                    <Slider marks={widthMarks} min={20} max={100} />
                  </Form.Item>
                </Col>
              </Row>
              <h3>Header</h3>
              <br />
              <Row gutter={32}>
                <Col span={12}>
                  <Form.Item name="headerFont" label="Font">
                    <Select placeholder="Select">
                      {printFonts.map((font, i) => (
                        <Option className={font._id} value={font._id} key={i}>{font.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="headerSize" label="Size">
                    <Slider tooltipVisible={false} marks={sizeMarks} min={1} max={5} />
                  </Form.Item>
                </Col>
              </Row>
              <h3>Footer</h3>
              <br />
              <Row gutter={32}>
                <Col span={12}>
                  <Form.Item name="footerFont" label="Font">
                    <Select placeholder="Select">
                      {printFonts.map((font, i) => (
                        <Option className={font._id} value={font._id} key={i}>{font.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="footerSize" label="Size">
                    <Slider marks={sizeMarks}  min={1} max={5} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <Divider />
            <div className="thm-section">
              <h2>Step 4: Name Your Theme</h2>
              <Row>
                <Col span={24}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Theme Name is required"
                      }
                    ]}
                    name="name"
                    label="Theme Name"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className="thm-section">
              {isOnline ? (
                <Row gutter={[16, 16]}>
                  <Col>
                    {
                      loading ? (
                        <Button type="text">Saving...</Button>
                      ) : (
                        <Button type="primary" htmlType="submit">{editTheme != null ? "Update" : "Save"} Theme</Button>
                      )
                    }
                  </Col>
                  {/* <Col>
                  <div className="thm-section" style={{ padding: "1em 0em 2em 0rem" }}>
                    <Button className="btn-a">Update Existing Theme</Button>
                  </div>
                </Col> */}
                </Row>
              ) : null}

            </div>
          </div>
        </div>
      </Form>
    </div>
  );
});

export default BuildCustomTheme;
