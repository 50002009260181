import React, { useState, useEffect } from "react";
import { Select, Row, Col, Radio, Divider } from "antd";
import { findIndex } from "lodash";

const { Option, OptGroup } = Select;
import useRootStore from "../../store/useRootStore";
import { ConsoleSqlOutlined } from "@ant-design/icons";

// Moves the menu below the select input
const menuProps = {
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "left",

	},
	transformOrigin: {
		vertical: "top",
		horizontal: "left",
	},
	getContentAnchorEl: null
};

const fnts = [
	{
		device: "iphone",
		fonts: [
			"Athelas",
			"Charter",
			"Palatino",
			"Georgia",
			"San Francisco",
			"Seravek",
			"Iowan"
		]
	},
	{
		device: "ipad",
		fonts: [
			"Palatino",
			"Georgia",
			"San Franciso",
			"Athleas",
			"Iowan",
			"New York",
			"Seravek",
			"Charter"
		]
	},
	{
		device: "fire",
		fonts: [
			"Bookerly",
			"Baskerville",
			"Caecilia",
			"Palatino",
			"Georgia",
			"Helvetica",
			"Open Dyslexic"
		]
	},
	{
		device: "paperwhite",
		fonts: [
			"Bookerly",
			"Baskerville",
			"Georgia",
			"Helvetica",
			"Palatino",
			"Open Dyslexic"
		]
	},
	{
		device: "oasis",
		fonts: [
			"Bookerly",
			"Caecilia",
			"Droid Serif",
			"Lucida",
			"Georgia",
			"Helvetica",
			"Open Dyslexic"
		]
	},
	{
		device: "libra",
		fonts: [
			"Avenir Next",
			"Georgia",
			"Gill Sans",
			"Droid Sans Serif",
			"Droid Serif",
			"Open Dyslexic"
		]
	},
	{
		device: "clara",
		fonts: [
			"Avenir Next",
			"Georgia",
			"Gill Sans",
			"Droid Sans Serif",
			"Droid Serif",
			"Open Dyslexic"
		]
	},
	{
		device: "forma",
		fonts: [
			"Avenir Next",
			"Georgia",
			"Gill Sans",
			"Droid Sans Serif",
			"Droid Serif",
			"Open Dyslexic"
		]
	},
	{
		device: "nia",
		fonts: [
			"Avenir Next",
			"Georgia",
			"Gill Sans",
			"Droid Sans Serif",
			"Droid Serif",
			"Open Dyslexic"
		]
	},
	{
		device: "glowlight",
		fonts: [
			"Baskerville",
			"Helvetica Neue",
			"Trebuchet MS",
			"Gill Sans",
			"Open Dyslexic"
		]
	},
	{
		device: "glowlight_plus",
		fonts: [
			"Helvetica Neue",
			"Trebuchet MS",
			"Gill Sans",
			"Open Dyslexic"
		]
	},
	{
		device: "galaxy_s21",
		fonts: [
			"Droid Sans"
		]
	},
	{
		device: "galaxy_tab_7",
		fonts: [
			"Droid Sans"
		]
	},
	{
		device: "print",
		fonts: [
			"inherit"
		]
	},
];

const getFirstFont = (i: number) => fnts[i].fonts[0];

function Toolbar(props) {
	const { ondeviceselect, device, previewBackground, previewFontSize, previewFont, devicePreviews } = props;
	const { getDevicePreview } = useRootStore().themeStore;

	const fntindex = findIndex(fnts, ["device", device]);
	const [font, setFont] = useState(fnts[fntindex].fonts[0]);
	//before offline for device preview
	// useEffect(() => {
	// 	setFont(getFirstFont(findIndex(fnts, ["device", device])));
	// 	previewFont(getFirstFont(findIndex(fnts, ["device", device])));
	// }, [device]);

	useEffect(() => {
		getDevicePreview(device).then((dv) => {
			setFont(dv ? dv.fonts[0] : "");
			previewFont(dv ? dv.fonts[0] : "");
		});

	}, [device]);

	const handleDeviceSelect = (device) => {
		ondeviceselect(device);
	};

	return fntindex !== -1 ? (
		<Row gutter={16}>
			<Col>
				<Select defaultValue="ipad" className="a-select" placeholder="Device" style={{ width: 180 }} onChange={handleDeviceSelect}>
					<Option value="print">Print</Option>
					<OptGroup label="Apple">
						<Option value="iphone">Iphone</Option>
						<Option value="ipad">Ipad</Option>
					</OptGroup>
					<OptGroup label="Android">
						<Option value="galaxy_tab_7">Galaxy Tab 7</Option>
						<Option value="galaxy_s21">Galaxy S21</Option>
					</OptGroup>
					<OptGroup label="Kindle">
						<Option value="fire">Fire</Option>
						<Option value="paperwhite">Paperwhite</Option>
						<Option value="oasis">Oasis</Option>
					</OptGroup>
					<OptGroup label="Nook">
						<Option value="glowlight">Glowlight 3</Option>
						<Option value="glowlight_plus">Glowlight Plus</Option>
					</OptGroup>
					<OptGroup label="Kobo">
						<Option value="forma">Forma</Option>
						<Option value="libra">Libra H20</Option>
						<Option value="clara">Clara</Option>
						<Option value="nia">Nia</Option>
					</OptGroup>
				</Select>
			</Col>
			{device !== "print" ? (
				<Col>
					<Select
						className="a-select"
						style={{ width: 180 }}
						value={font}
						onChange={(v) => {
							setFont(v);
							previewFont(v);
						}}
						dropdownRender={el => (
							<>
								<Radio.Group defaultValue={1} className="a-radio" onChange={(e) => previewFontSize(e.target.value)}>
									{[8, 9, 11, 12, 13, 14].map(v => (
										<Radio.Button key={`fnt_${v}`} value={v} style={{ fontSize: v + (v / 3) }}>A</Radio.Button>
									))}
								</Radio.Group>
								<Divider style={{ margin: "0.5em" }} />
								<div>
									{el}
								</div>
							</>
						)}
					>
						{fnts[fntindex].fonts.map((v) => (
							<Option key={v} value={v} style={{ fontFamily: v }}>{v}</Option>
						))}
					</Select>
				</Col>
			) : null}
		</Row>
		// <Grid item>
		//     {
		//         device === 'iphone' ? (
		//             <Select
		//                 disableUnderline
		//                 classes={{ root: classes.selectFont }}
		//                 MenuProps={menuProps}
		//                 onChange={(e) => props.previewFont(e.target.value)}
		//             >
		//                 <ButtonGroup className={classes.btnMenu} variant="text" size="small" aria-label="text primary button group">
		//                     <Button style={{ fontSize: 10 }} onClick={(c) => props.previewFontSize(10)}>A</Button>
		//                     <Button style={{ fontSize: 12 }} onClick={(c) => props.previewFontSize(12)}>A</Button>
		//                     <Button style={{ fontSize: 13 }} onClick={(c) => props.previewFontSize(13)}>A</Button>
		//                     <Button style={{ fontSize: 14 }} onClick={(c) => props.previewFontSize(14)}>A</Button>
		//                     <Button style={{ fontSize: 15 }} onClick={(c) => props.previewFontSize(15)}>A</Button>
		//                     <Button style={{ fontSize: 16 }} onClick={(c) => props.previewFontSize(16)}>A</Button>
		//                 </ButtonGroup>
		//                 <Divider />
		//                 <MenuItem value="Palatino">Palatino</MenuItem>
		//                 <MenuItem value="Georgia">Georgia</MenuItem>
		//                 <MenuItem value="Comic Sans MS">Comic Sans MS</MenuItem>
		//             </Select>
		//         ) : null
		//     }
		//     {
		//         device === 'ipad' ? (
		//             <Select
		//                 disableUnderline
		//                 classes={{ root: classes.selectFont }}
		//                 MenuProps={menuProps}
		//                 onChange={(e) => props.previewFont(e.target.value)}
		//             >
		//                 <ButtonGroup className={classes.btnMenu} variant="text" size="small" aria-label="text primary button group">
		//                     <Button style={{ fontSize: 10 }} onClick={(c) => props.previewFontSize(10)}>A</Button>
		//                     <Button style={{ fontSize: 12 }} onClick={(c) => props.previewFontSize(12)}>A</Button>
		//                     <Button style={{ fontSize: 13 }} onClick={(c) => props.previewFontSize(13)}>A</Button>
		//                     <Button style={{ fontSize: 14 }} onClick={(c) => props.previewFontSize(14)}>A</Button>
		//                     <Button style={{ fontSize: 15 }} onClick={(c) => props.previewFontSize(15)}>A</Button>
		//                     <Button style={{ fontSize: 16 }} onClick={(c) => props.previewFontSize(16)}>A</Button>
		//                 </ButtonGroup>
		//                 <Divider />
		//                 <MenuItem value="Athleas">Athleas</MenuItem>
		//                 <MenuItem value="Charter">Charter</MenuItem>
		//                 <MenuItem value="Georgia">Georgia</MenuItem>
		//                 <MenuItem value="Iowan">Iowan</MenuItem>
		//                 <MenuItem value="Palatino">Palatino</MenuItem>
		//                 <MenuItem value="San Franciso">San Franciso</MenuItem>
		//                 <MenuItem value="New York">New York</MenuItem>
		//                 <MenuItem value="Seravek">Seravek</MenuItem>
		//             </Select>
		//         ) : null
		//     }
		// </Grid>
		// <Grid item>
		//     <Paper className={classes.paper}>
		//         <ButtonGroup classes={classes.btnColor} variant="text" size="small" aria-label="primary button group">
		//             <Button value='black' style={{ fontSize: 10 }} onClick={(c) => props.previewBackground('black')}><span style={{ backgroundColor: 'black' }} className={classes.dot} /></Button>
		//             <Button id="beige" style={{ fontSize: 10 }} onClick={(c) => props.previewBackground('#D1C6A3')}><span style={{ backgroundColor: '#D1C6A3' }} className={classes.dot} /></Button>
		//             <Button value="white" style={{ fontSize: 10 }} onClick={(c) => props.previewBackground('white')}><span style={{ backgroundColor: 'white' }} className={classes.dot} /></Button>
		//         </ButtonGroup>
		//     </Paper>
		// </Grid>
	) : null;
}

export default Toolbar;
