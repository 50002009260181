import React, { FunctionComponent, useRef } from "react";
import {
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  MARK_STRIKETHROUGH,
  MARK_SUPERSCRIPT,
  MARK_SUBSCRIPT,
  ELEMENT_ALIGN_LEFT,
  ELEMENT_ALIGN_CENTER,
  ELEMENT_ALIGN_RIGHT,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,

  BalloonToolbar,
  ToolbarMark,
  ToolbarList,
  ToolbarElement,
  ToolbarAlign,
} from "@udecode/slate-plugins";

import { Divider, Row, Col, Dropdown, Menu } from "antd";
import { DownOutlined, AimOutlined } from "@ant-design/icons";
import { TooltipIcon } from "./partials/TooltipIcon";

import { useOnlineStatus } from "../../utils/isOffline";

import SprintTimerToolbar from "../SprintTimer/SprintTimerToolbar";

// Icons
import {
  BoldIcon, ItalicIcon, UnderlineIcon,
  StrikethroughIcon, SuperscriptIcon, SubscriptIcon,
  SubheadingIcon, UnorderedListIcon, OrderedListIcon,
  LeftAlignIcon, CenterAlignIcon, RightAlignIcon, BlockquoteIcon, VerseIcon, SearchIcon
} from "./partials/Icon";

import { options } from "./config/default";

import { OrnamentalBreakToolbarButton } from "./plugins/OrnamentalBreak";
import { ImageUploadToolbarButton } from "./plugins/ImageUpload";
import { LinksToolbarButton } from "./plugins/Link";
import { EndnoteToolbarButton } from "./plugins/Endnote";
import { SplitChapterButton } from "./plugins/SplitChapter";
import { RedoButton, UndoButton } from "./plugins/Undo";
import ProfilesToolbarButton from "./plugins/Profiles";
import { SmartQuotesButton } from "./plugins/SmartQuotes";
import { SmartQuotesConsistencyButton } from "./plugins/SmartQuotes/SmartQuotesConsistency";
import useRootStore from "../../store/useRootStore";
import { PageBreakIcon } from "./plugins/PageBreak";
import { PageBreakButton } from "./plugins/PageBreak/PageBreakComponent";

const toBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    resolve(reader && reader.result ? reader.result as string : "");
  };
  reader.onerror = error => reject(error);
});

export const CommonToolbar: FunctionComponent = () => {
  return (
    <>
      <TooltipIcon title="Bold (⌘B)">
        <ToolbarMark
          reversed
          type={MARK_BOLD}
          icon={<BoldIcon />}
        />
      </TooltipIcon>
      <TooltipIcon title="Italic (⌘I)">
        <ToolbarMark
          reversed
          type={MARK_ITALIC}
          icon={<ItalicIcon />}
        />
      </TooltipIcon>
      <TooltipIcon title="Underline (⌘U)">
        <ToolbarMark
          reversed
          type={MARK_UNDERLINE}
          icon={<UnderlineIcon />}
        />
      </TooltipIcon>
    </>
  );
};

const FontVariationToolbar = () => {
  return (
    <Dropdown
      overlay={
        <Menu className="tlb-dropdown">
          <Menu.Item>
            <ToolbarMark
              reversed
              type="monospace"
              clear={["smallcaps", "sansserif"]}
              icon={
                <div className="tlb-monospace">Monospace</div>
              }
            >
            </ToolbarMark>
          </Menu.Item>
          <Menu.Item>
            <ToolbarMark
              reversed
              type="smallcaps"
              clear={["monospace", "sansserif"]}
              icon={
                <div className="tlb-smallcaps">Smallcaps</div>
              }
            >
            </ToolbarMark>
          </Menu.Item>
          <Menu.Item>
            <ToolbarMark
              reversed
              type="sansserif"
              clear={["smallcaps", "monospace"]}
              icon={
                <div className="tlb-sansserif">Sans Serif</div>
              }
            >
            </ToolbarMark>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item>
            <ToolbarMark
              reversed
              type={MARK_SUBSCRIPT}
              clear={[MARK_SUPERSCRIPT]}
              icon={
                <div>Subscript <sub>2</sub></div>
              }
            >
            </ToolbarMark>
          </Menu.Item>
          <Menu.Item>
            <ToolbarMark
              reversed
              type={MARK_SUPERSCRIPT}
              clear={[MARK_SUBSCRIPT]}
              icon={
                <div>Superscript <sup>2</sup></div>
              }
            >
            </ToolbarMark>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item>
            <ToolbarMark
              reversed
              type={MARK_STRIKETHROUGH}
              icon={
                <div className="tlb-strikethrough">Strikethrough</div>
              }
            >
            </ToolbarMark>
          </Menu.Item>
        </Menu>
      }
    >
      <DownOutlined />
    </Dropdown>
  );
};

const BlockToolbar: FunctionComponent = () => {
  const isOnline = useOnlineStatus();

  return (
    <>
      <TooltipIcon title="Subheading">
        <ToolbarElement
          type={options.h2.type}
          icon={<SubheadingIcon />}
        />
      </TooltipIcon>
      <TooltipIcon title="Unordered List">
        <ToolbarList
          typeList={options.ul.type}
          icon={<UnorderedListIcon />}
        />
      </TooltipIcon>
      <TooltipIcon title="Ordered List">
        <ToolbarList
          typeList={options.ol.type}
          icon={<OrderedListIcon />}
        />
      </TooltipIcon>
      <TooltipIcon title="Link">
        <LinksToolbarButton />
      </TooltipIcon>
      <TooltipIcon title="Ornamental Break">
        <OrnamentalBreakToolbarButton />
      </TooltipIcon>
      {/*<TooltipIcon title="Image">*/}
      {isOnline ? (
        <TooltipIcon title="Image">
            <ImageUploadToolbarButton
                transformFile={async (file) => {
                    const transformedURL = await toBase64(file);
                    return transformedURL;
                }}
            />
        </TooltipIcon>
      ) : null}
      {isOnline ? (
        <TooltipIcon title="Profiles">
          <ProfilesToolbarButton />
        </TooltipIcon>
      ) : null}
    </>
  );
};

const AlignmentAndBlockToolbar: FunctionComponent = () => (
  <>
    <TooltipIcon title="Left Align">
      <ToolbarAlign
        icon={<LeftAlignIcon />}
      />
    </TooltipIcon>
    <TooltipIcon title="Center Align">
      <ToolbarAlign
        type={ELEMENT_ALIGN_CENTER}
        icon={<CenterAlignIcon />}
      />
    </TooltipIcon>
    <TooltipIcon title="Right Align">
      <ToolbarAlign
        type={ELEMENT_ALIGN_RIGHT}
        icon={<RightAlignIcon />}
      />
    </TooltipIcon>
    <TooltipIcon title="Blockquote">
      <ToolbarElement
        type={ELEMENT_BLOCKQUOTE}
        icon={<BlockquoteIcon />}
      />
    </TooltipIcon>
    <TooltipIcon title="Verse">
      <ToolbarElement
        type={ELEMENT_CODE_BLOCK}
        icon={<VerseIcon />}
      />
    </TooltipIcon>
  </>
);

const EndnoteToolbar: FunctionComponent = () => {
  const { theme } = useRootStore().themeStore;

  return (
    <>
       <TooltipIcon title={theme?.notesMode==="FOOTNOTE" ? "Footnote" : "Endnote"}>
        <EndnoteToolbarButton />
      </TooltipIcon>
    </>
  );
};

const SplitChapterToolbar: FunctionComponent = () => {
  return (
    <>
      <TooltipIcon title="Split Chapter">
        <SplitChapterButton />
      </TooltipIcon>
    </>
  );
};

const PageBreakToolBar: FunctionComponent = () => {
  return (
    <TooltipIcon title="Page Break">
      <PageBreakButton />
    </TooltipIcon>
  );
};

const UndoToolbar: FunctionComponent = () => {
  return (
    <>
      <TooltipIcon title="Undo">
        <UndoButton />
      </TooltipIcon>
    </>
  );
};

const RedoToolbar: FunctionComponent = () => {
  return (
    <>
      <TooltipIcon title="Redo">
        <RedoButton />
      </TooltipIcon>
    </>
  );
};

const SmartQuotesToolbar: FunctionComponent = () => {
  return (
    <TooltipIcon title="Apply Smart Quotes">
      <SmartQuotesButton />
    </TooltipIcon>
  );
};

const SmartQuotesConsistencyToolbar: FunctionComponent = () => {
  // Tool tip needs dynamic text change, hence it is inside the Button definition.
  return (
    <SmartQuotesConsistencyButton />
  );
};

interface ToolbarProps {
  showBalloonToolbar?: boolean;
}


const Toolbar: FunctionComponent<ToolbarProps> = (props: ToolbarProps) => {
  // const spT = useRef(null);
  return (
    <>
      <Row gutter={15}>
        <Col>
          <div className="toolbar">
            <CommonToolbar />
            <Divider type="vertical" className="divider" />
            <FontVariationToolbar />
            <Divider type="vertical" className="divider" />
            <BlockToolbar />
            <Divider type="vertical" className="divider" />
            <AlignmentAndBlockToolbar />
            <Divider type="vertical" className="divider" />
            <EndnoteToolbar />
            <Divider type="vertical" className="divider" />
            <SplitChapterToolbar />
            <Divider type="vertical" className="divider" />
            <PageBreakToolBar />
            <Divider type="vertical" className="divider" />
            <UndoToolbar />
            <RedoToolbar />
            <Divider type="vertical" className="divider" />
            <SmartQuotesToolbar />
            <SmartQuotesConsistencyToolbar />
            </div>
        </Col>
        <SprintTimerToolbar />
      </Row>
    </>
  );
};

export default Toolbar;