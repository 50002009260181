import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "antd";
import TextArea, { TextAreaRef } from "antd/lib/input/TextArea";
import useRootStore from "../../../store/useRootStore";

interface EndNotesUpsertProps {
  isInsert?: boolean;
  currentNote?: string;
  visible: boolean;
  addNote?: (note: string) => void;
  onCancel: () => void;
  updateNote?: (note: string) => void;
}

/**
 * Modal used to add or edit endnotes
 * @param {string} props - props passed to the component
 * @param {string} [currentNote] - current note to edit
 * @param {boolean} [isInsert] - if the note is being inserted or edited
 * @param {boolean} [visible] - if the modal is visible, default is false
 * @param {function} [addNote] - function to add a note, only used when isInsert is true
 * @param {function} onCancel - function to close the modal
 * @param {function} [updateNote] - function to update a note, only used when isInsert is false 
 */
const EndnotesUpsertModal: React.FC<EndNotesUpsertProps> = ({
  currentNote,
  isInsert,
  visible,
  addNote,
  onCancel,
  updateNote,
}) => {
  const { theme } = useRootStore().themeStore;

  const [note, setNote] = useState<string>(currentNote || "");
  const textArea = useRef<TextAreaRef>(null);

  const onClick = () => {
    if (isInsert) {
      if (addNote === undefined) {
        console.error("addNote is undefined");
        return;
      }
      addNote(note);
    } else {
      if (updateNote === undefined) {
        console.error("updateNote is undefined");
        return;
      }
      updateNote(note);
    }
    setNote("");
  };

  const footer = (
    <>
      <Button
        type="primary"
        block
        size="large"
        style={{ borderRadius: 0, height: "auto", padding: "1rem 0rem" }}
        onClick={onClick}
        disabled={note.trim().length === 0}
      >
        {isInsert ? "Insert" : "Update"}
      </Button>
    </>
  );

  const onClose = () => {
    setNote("");
    onCancel();
  };

  /* 
    this is a workaround to auto focus the textarea on modal load.
    antd is following a similar approach for async modals.
  */
  useEffect(() => {
    setTimeout(() => {
      textArea?.current?.focus({cursor: "end"});
    }, 0);
  }, [textArea]);

  return (
    <Modal
      title={`${isInsert ? "Insert" : "Update"} ${theme?.notesMode==="FOOTNOTE" ? "Footnote" : "Endnote"}`}
      visible={visible}
      footer={footer}
      onCancel={onClose}
    >
      <TextArea
        ref={textArea}
        rows={4}
        maxLength={1000}
        showCount
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
    </Modal>
  );
};

export default EndnotesUpsertModal;
