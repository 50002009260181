import React, { FunctionComponent, useCallback } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { MoreHoriz } from "@material-ui/icons";
import { Collapse, Divider } from "antd";
import { observer } from "mobx-react";
import { find } from "lodash";
//store
import useRootStore from "../../store/useRootStore";

// Defaults
import { isMergable } from "./defaults";

// types
import { ChapterMeta } from "../../types/chapter";

// styles
import "./index.scss";

//partials
import ChapterBlockItem from "./ListItem";
import BodyDropdown from "./BodyDropdown";
import FileUpload from "../Shared/Forms/FileUpload";
import { UploadOutlined } from "@ant-design/icons";
import { AtticusClient } from "../../api/atticus.api";
import { SaveServerBookToDB } from "../../utils/sync";
import { isValidChapter } from "../Shared/helpers/toc";

const { Panel } = Collapse;


const reorder = (list: IChapterStore.ChapterMeta[], sourceIndex: number, destinationIndex: number) => {
	const result = Array.from(list);
	const [removed] = result.splice(sourceIndex, 1);
	result.splice(destinationIndex, 0, removed);
	return result;
};

const removeItem = (list: IChapterStore.ChapterMeta[], index: number) => {
	return [
		...list.slice(0, index),
		...list.slice(index + 1, list.length),
	];
};

const addItem = (list: IChapterStore.ChapterMeta[], index: number, newItem: IChapterStore.ChapterMeta) => {
	return [
		...list.slice(0, index),
		newItem,
		...list.slice(index, list.length),
	];
};

const getBookMetaSummaryForCache = (frontMatter: ChapterMeta[], backMatter: ChapterMeta[], body: ChapterMeta[]) => {
	const chapterCacheData = [
		...frontMatter,
		...body,
		...backMatter,
	].map(({ _id, type, startOn}) => ({ chapterId: _id, chapterType: type, startOn } as IPDFCacheStore.ChapterCacheMetaData));
	const backMatterIds = backMatter.map(({_id}) => _id);
	const frontMatterIds = frontMatter.map(({_id}) => _id);

	return {chapterCacheData, backMatterIds, frontMatterIds};
};

const Chapters = observer(() => {
    const {
        chapter, 
        book,
        extras, 
        sortChapters, 
        getAndSetCurChapter, 
        mergeChapter, 
        deleteChapter, 
        debouncedSaveChapterMetaUpdates, 
        getCurrentStoredBook, 
        getChapterBodyById 
    } = useRootStore().bookStore;	
    const { refreshCache } = useRootStore().pdfCacheStore;

	if (!book) return null;

	let frontMatter = book.frontMatter;
	let chapters = book.chapters;
	let backMatter = book.backMatter;

	const curChapterId = chapter._id;

	const onDragEnd = async (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		if (result.source.droppableId === result.destination.droppableId && result.source.index === result.destination.index) {
			return;
		}
    
    if (chapter.type === "endnotes" && result.destination.droppableId === "frontmatter") {
      return;
    }

		// Inside the same section
		if (result.source.droppableId === "frontmatter" && result.source.droppableId === result.destination.droppableId) {
			frontMatter = reorder(frontMatter, result.source.index, result.destination.index);
		}

		if (result.source.droppableId === "body" && result.source.droppableId === result.destination.droppableId) {
			chapters = reorder(chapters, result.source.index, result.destination.index);
		}

		if (result.source.droppableId === "backmatter" && result.source.droppableId === result.destination.droppableId) {
			backMatter = reorder(backMatter, result.source.index, result.destination.index);
		}

		// between sections body <=> frontmatter

		if (result.source.droppableId === "body" && result.destination.droppableId === "frontmatter") {
			const movedItem = chapters[result.source.index];
			chapters = removeItem(chapters, result.source.index);
			frontMatter = addItem(frontMatter, result.destination.index, movedItem);
		}

		if (result.source.droppableId === "frontmatter" && result.destination.droppableId === "body") {
			const movedItem = frontMatter[result.source.index];
			frontMatter = removeItem(frontMatter, result.source.index);
			chapters = addItem(chapters, result.destination.index, movedItem);
		}

		// between sections body <=> backmatter

		if (result.source.droppableId === "body" && result.destination.droppableId === "backmatter") {
			const movedItem = chapters[result.source.index];
			chapters = removeItem(chapters, result.source.index);
			backMatter = addItem(backMatter, result.destination.index, movedItem);
		}

		if (result.source.droppableId === "backmatter" && result.destination.droppableId === "body") {
			const movedItem = backMatter[result.source.index];
			backMatter = removeItem(backMatter, result.source.index);
			chapters = addItem(chapters, result.destination.index, movedItem);
		}

		// between sections frontmatter <=> backmatter

		if (result.source.droppableId === "frontmatter" && result.destination.droppableId === "backmatter") {
			const movedItem = frontMatter[result.source.index];
			frontMatter = removeItem(frontMatter, result.source.index);
			backMatter = addItem(backMatter, result.destination.index, movedItem);
		}

		if (result.source.droppableId === "backmatter" && result.destination.droppableId === "frontmatter") {
			const movedItem = backMatter[result.source.index];
			backMatter = removeItem(backMatter, result.source.index);
			frontMatter = addItem(frontMatter, result.destination.index, movedItem);
		}

		await sortChapters(frontMatter, chapters, backMatter);


		const { chapterCacheData, backMatterIds, frontMatterIds } = getBookMetaSummaryForCache(frontMatter, backMatter, chapters);
		refreshCache(book._id, "chapter-index-change", { "chapter-index-change": { chapters: chapterCacheData, backMatterIds, frontMatterIds }});
	};

	const getChapterNumber = (arr: ChapterMeta[], index: number) => {
		let chapterNumber = 0;
		for (let i = 0; i <= index; i += 1) {
			if (arr[i] && (arr[i].type === "chapter" || arr[i].type === undefined || arr[i].type === "uncategorized" || arr[i].type === "custom") && arr[i].includeIn !== "none") {
				if (arr[i].numbered === undefined || arr[i].numbered === true) chapterNumber += 1;
			}
		}

		if (arr[index] && (arr[index].type === "chapter" || arr[index].type === undefined || arr[index].type === "uncategorized" || arr[index].type === "custom") && arr[index].includeIn !== "none") {
			if (arr[index].numbered === undefined || arr[index].numbered === true) return chapterNumber;
		}
		return undefined;
	};

	const handleDeleteChapter = useCallback(async (chapterId:string) => {
		const chap = book.chapters.find(chap => chap._id === chapterId);
		await deleteChapter(chapterId);

		const { chapterCacheData } = getBookMetaSummaryForCache(frontMatter, backMatter, chapters);
		if (chap && chap.type === "image") {
			refreshCache(book._id, "full-page-image-chapter-delete", { "full-page-image-chapter-delete":{ chapterId, chapters: chapterCacheData }});
		} else {
			refreshCache(book._id, "chapter-delete", { "chapter-delete": { chapterId, chapters: chapterCacheData }});
		}
	}, [book]);

  const [showFileDrop, setShowFileDrop] = React.useState(false);

	return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        {/* Book Front-matter */}
        <Collapse defaultActiveKey={[]} ghost className="sidebar-collapse">
          <Panel
            header="Front Matter"
            key="front-matter"
            className="sidebar-collapse-custom-panel"
          >
            <Droppable droppableId="frontmatter">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <div style={{ minHeight: "2rem" }}>
                    {book.frontMatter.map((chapter, i) => {
                      if(!isValidChapter(chapter._id, book)) return;
                      let mergeSupported = false;
                      const isTOC = chapter.type === "toc" ? true : false;
                      if (
                        isMergable(chapter.type) &&
                        i < book.frontMatter.length - 1 &&
                        isMergable(book.frontMatter[i + 1].type)
                      ) {
                        mergeSupported = true;
                      }
                      return (
                        <ChapterBlockItem
                          setChapter={getAndSetCurChapter}
                          chapter={chapter}
                          index={i}
                          beginOn={!isTOC}
                          key={i}
                          section="frontMatter"
                          isBody={false}
                          active={curChapterId === chapter._id}
                          deleteChapter={handleDeleteChapter}
                          mergeChapter={
                            mergeSupported ? mergeChapter : undefined
                          }
                          updateChapterMeta={async (meta) => {
                            await debouncedSaveChapterMetaUpdates(meta);
                            const { _id, type, startOn, includeIn } = meta;
                            refreshCache(
                              meta.bookId,
                              "chapter-properties-change",
                              {
                                "chapter-properties-change": {
                                  chapter: {
                                    chapterId: _id,
                                    chapterType: type,
                                    startOn: startOn || "any",
                                    includeIn: includeIn || "all",
                                  },
                                },
                              }
                            );
                          }}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </Panel>
        </Collapse>
        <div className="section-divider">
          <Divider />
        </div>

        {/* Book body */}
        <Collapse
          defaultActiveKey={["body"]}
          ghost
          className="sidebar-collapse"
        >
          <Panel
            header="Body"
            key="body"
            collapsible="header"
            extra={<BodyDropdown />}
            className="sidebar-collapse-custom-panel"
          >
            <Droppable droppableId="body">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setShowFileDrop(true);
                  }}
                  onDragEnter={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setShowFileDrop(true);
                  }}
                  onDragLeave={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setShowFileDrop(false);
                  }}
                >
                  {showFileDrop ? (
                    <FileUpload
                      fileType=".docx"
                      onFileUpload={async (fileUrl, fileType) => {

                        try {
                          const response = await AtticusClient.ImportChapters({
                            url: fileUrl ?? "",
                            bookId: book._id,
                          });
                          await SaveServerBookToDB(book._id);
                          location.reload();
                          return response.bookId;
                        } catch (e) {
                          console.log(e.message);
                          throw e;
                        } finally {
                          setShowFileDrop(false);
                        }
                      }}
                      onFileUploadStart={() => console.log("onFileUploadStart")}
                      onFileUploadEnd={() => console.log("onFileUploadEnd")}
                    >
                      <p className="ant-upload-drag-icon">
                        <UploadOutlined />
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ fontSize: "0.8rem" }}
                      >
                        <b>Choose a file</b> or drag and drop it here
                      </p>
                    </FileUpload>
                  ) : (
                    <div style={{ minHeight: "2rem" }}>
                      {book.chapterIds.map((c, i) => {
                        let mergeSupported = false;
                        const chapter = find(book.chapters, { _id: c });
                        if(!isValidChapter(chapter?._id, book) || !chapter) return;
                        
                        const extra = find(extras, {"id": c});

                        if (
                          isMergable(chapter.type) &&
                          i < book.chapters.length - 1 &&
                          isMergable(book.chapters[i + 1].type)
                        ) {
                          mergeSupported = true;
                        }

                        return (
                          <ChapterBlockItem
                            chapter={chapter}
                            index={i}
                            key={i}
                            beginOn={i == 0 ? false : true}
                            number={getChapterNumber(book.chapters, i)}
                            isBody={true}
                            extra={extra?.extra}
                            section="body"
                            active={curChapterId === chapter._id}
                            deleteChapter={handleDeleteChapter}
                            setChapter={getAndSetCurChapter}
                            mergeChapter={
                              mergeSupported ? mergeChapter : undefined
                            }
                            updateChapterMeta={async (meta) => {
                              await debouncedSaveChapterMetaUpdates(meta);
                              const { _id, type, startOn, includeIn } = meta;
                              refreshCache(
                                meta.bookId,
                                "chapter-properties-change",
                                {
                                  "chapter-properties-change": {
                                    chapter: {
                                      chapterId: _id,
                                      chapterType: type,
                                      startOn: startOn || "any",
                                      includeIn: includeIn || "all",
                                    },
                                  },
                                }
                              );
                            }}
                          />
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </div>
              )}
            </Droppable>
          </Panel>
        </Collapse>
        <div className="section-divider">
          <Divider />
        </div>

        {/* Book Back matter */}

        <Collapse defaultActiveKey={[]} ghost className="sidebar-collapse">
          <Panel
            header="Back Matter"
            key="backmatter"
            className="sidebar-collapse-custom-panel"
          >
            <Droppable droppableId="backmatter">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <div style={{ minHeight: "2rem" }}>
                    {book.backMatter.map((chapter, i) => {
                      if(!isValidChapter(chapter._id, book)) return;
                      let mergeSupported = false;
                      if (
                        isMergable(chapter.type) &&
                        i < book.backMatter.length - 1 &&
                        isMergable(book.backMatter[i + 1].type)
                      ) {
                        mergeSupported = true;
                      }

                      return (
                        <ChapterBlockItem
                          chapter={chapter}
                          index={i}
                          key={chapter._id}
                          section="backMatter"
                          beginOn={true}
                          isBody={false}
                          active={curChapterId === chapter._id}
                          deleteChapter={handleDeleteChapter}
                          setChapter={getAndSetCurChapter}
                          mergeChapter={
                            mergeSupported ? mergeChapter : undefined
                          }
                          updateChapterMeta={async (meta) => {
                            await debouncedSaveChapterMetaUpdates(meta);
                            const { _id, type, startOn, includeIn } = meta;
                            refreshCache(
                              meta.bookId,
                              "chapter-properties-change",
                              {
                                "chapter-properties-change": {
                                  chapter: {
                                    chapterId: _id,
                                    chapterType: type,
                                    startOn: startOn || "any",
                                    includeIn: includeIn || "all",
                                  },
                                },
                              }
                            );
                          }}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </Panel>
        </Collapse>
        <div className="section-divider">
          <Divider />
        </div>
      </DragDropContext>
    </div>
  );
});

export default Chapters;
