import React, { FunctionComponent, useCallback, useEffect } from "react";
import { Divider, Menu, Dropdown, Row, Col, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

// store
import useRootStore from "../../store/useRootStore";
import { AtticusClient } from "../../api/atticus.api";

// types
import { ChapterType, IChapterTemplateBase, SectionType } from "../../types/chapter";
import { Node } from "slate";

// Defaults
import { sectionTypes, getLabel } from "./defaults";
import ImportChaptersModal from "../Books/ImportChaptersModal";
import { SaveServerBookToDB } from "../../utils/sync";
import { copyrightTemplates } from "../../utils/initials";

const { SubMenu } = Menu;

const ActionBar: FunctionComponent = () => {
	const { 
    addNewChapter,
    deleteChapter,
    addNewChapterFromTemplate,
    chapter,
    book,
    getCurrentStoredBook,
    getChapterBodyById,
    debouncedSaveChapterMetaUpdates
  } = useRootStore().bookStore;
	const { refreshCache } = useRootStore().pdfCacheStore;
	const { theme } = useRootStore().themeStore;
	const [chapterTemplates, setChapterTemplates] = React.useState<IChapterTemplateBase[]>();

	const [loading, setLoading] = React.useState(false);
  const [showImportChaptersModal, setShowImportChaptersModal] = React.useState(false);

	const renderTemplates = async () => {
		const resp = await AtticusClient.GetChapterTemplates();
		if (resp) {
			setChapterTemplates(resp);
		}
	};

	React.useEffect(() => {
		renderTemplates();
	}, []);

	React.useEffect(() => {
		renderTemplates();
	}, [loading, chapter]);


	const deleteTemplate = async (templateId) => {
		try {
			setLoading(true);
			await AtticusClient.DeleteChapterTemplate(templateId);
		} catch (e) {
			console.log(e);
			throw e;
		}
		setLoading(false);
		renderTemplates();
	};

	const refreshPDFCache = useCallback(async (type: ChapterType) => {
		const { frontMatterIds, chapterIds, backMatterIds } = getCurrentStoredBook();
		const allChapterIds = [ ...frontMatterIds, ...chapterIds, ...backMatterIds];
		const chapterData = await getChapterBodyById(allChapterIds);
		const chapterCacheData = chapterData.map(({ _id, type, startOn }) => ({ chapterId: _id, chapterType: type, startOn } as IPDFCacheStore.ChapterCacheMetaData));
		if (type === "image") {
			refreshCache(book._id, "full-page-image-chapter-add", { "full-page-image-chapter-add": { chapters: chapterCacheData }});
		}else {
			refreshCache(book._id, "chapter-add", { "chapter-add": { chapters: chapterCacheData }});
		}
	}, [book, getCurrentStoredBook, getChapterBodyById, refreshCache]);

	const handleAddChapterFromTemplate = useCallback(async (chapterTemplate: IChapterTemplateBase, type: IChapterStore.ChapterType, section?: string) => {
		await addNewChapterFromTemplate(chapterTemplate, type, section);
		await refreshPDFCache(type);
	}, [addNewChapterFromTemplate, refreshPDFCache]);

  /**
   * This code segment is to handle books with endnotes chapters without `includeIn` specification 
   * Here we check the endnotes chapter against the theme config and remove the chapter if 
   * no longer needed or update `includeIn` property as necessary 
   * 
   * This segment can be removed after migrating from the ghost endnotes chapters 
   * 
   * See more at: https://surgeglobal.atlassian.net/browse/AT-533 
   */
  useEffect(() => {
    const checkExistingEndnotesChapter = async () => {
      const {frontMatter, chapters, backMatter} = getCurrentStoredBook();
      const endnotesChapter = [...frontMatter, ...chapters, ...backMatter].find(chapter => chapter.type === "endnotes");

      if (endnotesChapter && !endnotesChapter.includeIn && theme) {
        let includeIn : "all" | "ebook" | "print" | "none" = "none";
        if(theme.ePubNotesMode === "END_OF_BOOK" && theme.notesMode === "END_OF_BOOK"){
          includeIn = "all";
        } else if(theme.ePubNotesMode === "END_OF_BOOK"){
          includeIn = "ebook";
        } else if(theme.notesMode === "END_OF_BOOK"){
          includeIn = "print";
        }

        if (includeIn === "none") {
          await deleteChapter(endnotesChapter._id);

          // Update PDF cache
          const allChapterIds = [ ...book.frontMatterIds, ...book.chapterIds, ...book.backMatterIds];
          const chapterData = await getChapterBodyById(allChapterIds);
          const chapterCacheData = chapterData.map(({ _id, type, startOn, includeIn }) => ({ chapterId: _id, chapterType: type, startOn, includeIn } as IPDFCacheStore.ChapterCacheMetaData));
          refreshCache(book._id, "chapter-delete", { "chapter-delete": { chapterId: endnotesChapter._id, chapters: chapterCacheData }});
        } else {
          await debouncedSaveChapterMetaUpdates({ ...endnotesChapter, includeIn });
          refreshCache(book._id, "chapter-properties-change", {"chapter-properties-change": {chapter: {
            chapterId: endnotesChapter._id,
            chapterType: endnotesChapter.type,
            startOn: endnotesChapter.startOn,
            includeIn: includeIn, // updated includeIn
          }}});
        }
      }
    };

    checkExistingEndnotesChapter();
  }, []);

	const handleMenuClick = useCallback( async (section: SectionType, type: ChapterType, body?: Node[]) => {
		await addNewChapter(section, type, undefined, body);
		await refreshPDFCache(type);
	},[book.chapterIds]);

	return (
    <>
      <div className="action-bar">
        <Dropdown.Button
          className="btn-a"
          size="middle"
          trigger={["click"]}
          onClick={() => handleMenuClick("body", "chapter")}
          overlay={
            <Menu>
              
              <SubMenu key="CopyrightsSubMenu" title="Copyright Templates" level={1}>
                {copyrightTemplates.map((elem, i) => (
                  <Menu.Item className="copyright-templates-submenu" key={`copyright-${i}`} onClick={ () => { handleMenuClick("frontMatter", "copyrights", elem.children as Node[]);}}>
                    {elem.title}
                  </Menu.Item>
                ))}
              </SubMenu>
              {sectionTypes.frontMatter.map((elem, i) => (
							<Menu.Item key={`frontMatter-${i}`} onClick={() => { handleMenuClick("frontMatter", elem as ChapterType); }}>{getLabel(elem)}</Menu.Item>
              ))}
              <Divider className="action-bar-divider" />
              {sectionTypes.body.map((elem, i) => (
							<Menu.Item key={`body-${i}`} onClick={() => { handleMenuClick("body", elem as ChapterType); }}>{getLabel(elem)}</Menu.Item>
              ))}
              <Divider className="action-bar-divider" />
              {sectionTypes.backMatter.map((elem, i) => (
							<Menu.Item key={`backMatter-${i}`} onClick={() => { handleMenuClick("backMatter", elem as ChapterType); }}>{getLabel(elem)}</Menu.Item>
              ))}
              {(() => {
                if (chapterTemplates) {
                  return (
                    <Divider className="action-bar-divider" />
                  );
                }
              })()}
              {(() => {
                if (chapterTemplates) {
                  return (
                    <SubMenu key="SubMenu" title="From Template">
                      {chapterTemplates.map((elem, i) => (
                        <>
                          <Row className="submenu-row">
                            <Col flex="auto">
                              <div className="chapter-template-align chapter-template-align-break" key={`template-${i}`} onClick={ () => handleAddChapterFromTemplate(elem, elem.type, elem.section)}> {elem.title}</div>
                            </Col>
                            <Col flex="10px">
                              <Menu.Item
                                key={`template-${elem._id}`}
                                onClick={() => {
                                  Modal.confirm({
                                    icon: null,
																		title: <h2 className="section-heading">Delete Chapter Template</h2>,
																		content: "Are you sure you want to delete the chapter template?",
                                    centered: true,
                                    onOk: () => {
                                      return new Promise((resolve, reject) => {
                                        deleteTemplate(elem._id);
																				setTimeout(loading ? resolve : reject, 5000);
                                      }).catch(() => console.log("Delete"));
                                    },
                                    okText: "Yes",
                                    okButtonProps: {
                                      type: "primary",
                                      danger: true,
                                      style: {
                                        flex: 1,
                                      },
                                    },
                                    cancelText: "No",
                                    cancelButtonProps: {
                                      className: "btn-a",
                                      style: {
                                        flex: 1,
                                      },
                                    },
                                  });
                                }}
                                icon={<div className="chapter-template-delete-icon"><DeleteOutlined /></div>}
															>
															</Menu.Item>
                            </Col>
                          </Row>

                        </>
                      ))}
                    </SubMenu>
                  );
                }
              })()}
              <Menu.Item
                key={"import-chapters"}
                onClick={() => setShowImportChaptersModal(true)}
              >
                Import Chapters
              </Menu.Item>
            </Menu>
          }
        >
          Add Chapter
        </Dropdown.Button>
      </div>
      {showImportChaptersModal ? (
        <ImportChaptersModal
          onUpload={async (params: IShelfStore.ChapterImportForm): Promise<string | undefined> => {
            try {
              const response = await AtticusClient.ImportChapters(
                {
                  url: params.fileURL,
                  bookId: book._id,
                },
              );
              await SaveServerBookToDB(book._id);
              location.reload();
              return response.bookId;
            } catch (e) {
              console.log(e.message);
              throw e;
            } finally {
              setShowImportChaptersModal(false);
            }
          }}
          onClose={() => {
            setShowImportChaptersModal(false);
          }}
          bookId={book._id}
        />
      ) : null}
    </>
  );
};

export default ActionBar;
