import React, { FunctionComponent, useMemo, useEffect, useRef, useState, useCallback } from "react";
import { createEditor, Node, Text, Range, NodeEntry, Operation, Transforms, Editor as Ed, Path } from "slate";
import { Slate, ReactEditor, RenderLeafProps } from "slate-react";
import { pipe, EditablePlugins } from "@udecode/slate-plugins";
import { Row, Col } from "antd";
import { observer} from "mobx-react";
import { toJS} from "mobx";
import { cloneDeep, debounce, throttle, isEqual } from "lodash";

//helpers
import {
    applySmartQuotes, 
    countWords, 
    getAllSearchRanges,
    getNextSearchMatchStep,
    getSearchRanges,
    replaceOne,
    replaceAll, 
} from "../../utils/helper";
import { plugins, withPlugins } from "./config/plugins";
import { copyrightTemplates } from "../../utils/initials";
import useRootStore from "../../store/useRootStore";
import Queue from "../../utils/queue";
import { SyncStatus } from "../../types/sync";
import useWebsocket from "../../utils/hooks/useWebsocket";

//components
import Toolbar from "./Toolbar";
import ChapterTemplateTitlebar from "./partials/ChapterTemplateTitlebar";
import SettingsToolbar from "./SettingsToolbar";
import Titlebar from "./partials/Titlebar";
import WithEditorStyle from "./Wrapper";
import EditorSider from "./EditorSider";
import RootEditorModal from "./modals/RootEditorModal";
import groupedShortcuts, { GroupedShortCut } from "./config/groupedShortcuts";


const aws_io = process.env.REACT_APP_SYNC_AWS || "";

interface EditorProps {
    customTitle: boolean,
}

interface WebSocketPayload {
    action: string,
    id: string,
    children?: Node[],
    ops: any[],
    sessionId: string
}

interface nDashDetectionBufferMeta {
	offset: number;
	path: Path;
}

let nDashDetectionBuffer: nDashDetectionBufferMeta[] = [];

let lastKey: string | null = null;
let repeatCount = 0;
let lastOffset: number | null = null;
let lastPath: Path | null = null;

const _Editor: FunctionComponent<EditorProps> = ({
    customTitle,
}: EditorProps) => {
    const { debouncedRefreshCache } = useRootStore().pdfCacheStore;
    const {
        sessionId,
        chapterTemplateView,
        editor_setting,
        setMainChapterTemplate,
    } = useRootStore().appStore;

    const {
        writing,
        book,
        chapter,
        search,
        setSearch,
        search_r,
        searchMatchedRanges,
        searchStep,
        setSearchStep,
        setSearchMatchedRanges,
        changeCount,
        debouncedSaveChapterBodyLocal,
        debounceUpdatelocalChapterLastSyncTime,
        debouncedSaveChapterTemplateBodyUpdates,
        debouncedSyncChapterBodyToServer,
        setWords,
        body: b = [],
        doSearchSetCount,
        putOfflineChapterRemote,
        setSaving,
        chapterHasOfflineContent,
        chapterSetOfflineContent,
        chapterTemplate: chapTemplate,
    } = useRootStore().bookStore;

    //search vars
    const term = useRef(search);
    const range = useRef(search_r);
    const [counter, setCounter] = useState(0);

    const [body, saveBody] = useState<Node[]>([
        {
          children: [{ type: "p", children: [{ text: "" }] }],
        },
    ]);

    const [isOfflineChapter, setIsOfflineChapter] = useState<boolean>(false);

    useEffect(() => {
        if(chapter._id.indexOf("offline") !== -1){
            setIsOfflineChapter(true);
        }
    }, [chapter]);

     // Chapter Template Library
     useEffect(() => {
        if (chapterTemplateView || !chapter._id) {
            saveBody(toJS(chapTemplate.children));
        }
        return () => {
            setMainChapterTemplate({
                _id: "",
                type: "uncategorized",
                title: "",
                image: "",
                children: []
            });
        };
    }, [chapTemplate._id]);

    // Chapter template library
	useEffect(() => {
		if(chapTemplate && chapTemplate._id) {
			Transforms.deselect(editor);
			chapterAutofocus = !chapTemplate.title || chapTemplate.title.length === 0 ? true : false;
			if (edtRef.current) {
				edtRef.current.scrollTo({
					top: 0,
					left: 0
				});
			}
		}
	}, [chapTemplate]);

    useEffect(() => {
      if (!chapterTemplateView) {
        saveBody(b);
      }
    }, [book._id, chapter._id, changeCount]);

	useEffect(() => {
		Transforms.deselect(editor);
		chapterAutofocus = !chapter.title || chapter.title.length === 0 ? true : false;

		if (edtRef.current) {
			edtRef.current.scrollTo({
				top: 0,
				left: 0
			});
		}
	}, [chapter._id]);

    const v = body.length > 0 ? body : copyrightTemplates[0].children;

	// Decide which field should be focused on initial load
	let chapterAutofocus = false;
	const editor = useMemo(() => pipe(createEditor(), ...withPlugins), []) as ReactEditor;
	const edtRef = useRef<HTMLDivElement>(null);
    const selectRef = useRef<HTMLSpanElement>(null);

    const remote = useRef(false);
    const sendOperationQueue = useRef(new Queue());

    const websocket = useWebsocket(`${aws_io}?chapterId=${chapter._id}`);

    if(websocket){
        websocket.onmessage = (event) => {
            if(event.type === "message" && event.data){
                const data = JSON.parse(event.data);
                handleOperations(data.ops, data.lastUpdateAt);
            }
        };
    }

    const handleOperations = async(operations: Operation[], lastUpdatedAt: Date | undefined) => {
        if(operations){
            remote.current = true;
            await applyOperations(operations);
            remote.current = false;
            debounceUpdatelocalChapterLastSyncTime(chapter._id, lastUpdatedAt);
        }
    };

    const applyOperations = (operations: Operation[]) => {
        if(editor){
            Ed.withoutNormalizing(editor, () => {
                operations.map(operation => {
                    if(operation.sessionId !== sessionId){
                        editor.apply(operation);
                    }
                });
            });
        }
        return Promise.resolve();
    };

    const isValidOperation = (operation?: Operation) => {
        if(operation){
            return operation.type !== "set_selection" && !operation.data;
        }
    };
    
    const handleEditorOnChange = (e) => {
        const event = cloneDeep(e);

        // Disabled AUTOMATIC Smartquotes From AT-168
        // if (event && event.length > 0) {
        //   event = applySmartQuotes(event);
        // }

        const d = (event[0] as Node).children as Node[];
        saveBody(d);
        const { operations }  = editor;
        const firstOperation = operations[0];

        if ( firstOperation && (firstOperation.type === "insert_text" || firstOperation.type === "remove_text")) {
            const ranges = getAllSearchRanges(editor, term.current);
            setSearchMatchedRanges(ranges);
        }

        doSearchSetCount();

        //check if it's chapter template
        if(chapterTemplateView && chapTemplate._id){
            //save chapter template
            debouncedSaveChapterTemplateBodyUpdates(chapTemplate._id, d);
        } else {
            debouncedRefreshCache(chapter.bookId, "chapter-contents-change", { "chapter-contents-change": { chapterId: chapter._id } });
            //save chapter otherwise
            debouncedSaveChapterBodyLocal(chapter._id, d, remote.current);
            if(!remote.current){
                const timedOps = operations.map(operation => ({...operation, timestamp: Date.now(), sessionId}));
                // syncContent(timedOps, d, chapter._id);
                sendOperationQueue.current.enqueue(timedOps);
                throttledSyncContent(d, chapter._id);
            }

            //skip offline chapter
            if(isOfflineChapter){
                return;
            }

            if(websocket?.readyState === WebSocket.OPEN && chapterHasOfflineContent(chapter._id)){
                // putOfflineChapter(chapter._id, `${chapter.title} - OFFLINE`, v);
                putOfflineChapterRemote(chapter._id);
                chapterSetOfflineContent(chapter._id, false);
            }
            if(websocket?.readyState !== WebSocket.OPEN && !chapterHasOfflineContent(chapter._id)){
                const hasValidOperations = operations.filter(isValidOperation).length > 0;
                if(hasValidOperations){
                    chapterSetOfflineContent(chapter._id, true);
                }
            }
        }
    };

    const syncContent = (children: Node[], id: string) => {
        const ops = sendOperationQueue.current.dequeueMultiple(sendOperationQueue.current.getSize());
        const filteredOps = ops.filter(isValidOperation).sort(sortOpsByTimeStamp);
        const payload: WebSocketPayload = {
            action: "onmessage",
            id,
            children,
            ops: filteredOps,
            sessionId
        };
        const payloadSize = (new TextEncoder().encode(JSON.stringify(payload)).length)/1024;
        if(payloadSize > 120){
            debouncedSyncChapterBodyToServer(book._id, id, { children: children });
            delete payload.children;
        }
        if(websocket?.readyState === WebSocket.OPEN){
            websocket.send(JSON.stringify(payload));
            debouncedSetSaving();
        }
    };

    const throttledSyncContent = useCallback(throttle(syncContent, 3000), [sendOperationQueue.current, websocket]);

    const sortOpsByTimeStamp = (a, b) => a.timestamp - b.timestamp;

    /**
     *  A function to mimic the funcationality of 'saving' 'saved' messages
     *  showed in the editor when HTTPS was used to sync editor content instead
     *  of WSS
     *
     *  A setTimeout is used to mimic 'saving in progress' as WS.send is not async
     *  and returns a void
     */
    const debouncedSetSaving = useCallback(
        debounce(() => {
            if(websocket?.readyState === WebSocket.OPEN){
                setSaving(SyncStatus.saving);
                setTimeout(() => {
                    setSaving(SyncStatus.saved);
                }, 1000);
            }
        }, 2000),[websocket?.readyState]);

	const applyGroupedShortcuts = (
		event: React.KeyboardEvent<HTMLDivElement>
	) => {
		const { key } = event;

		const offset = editor?.selection?.anchor?.offset || 0;
		const path = editor?.selection?.anchor?.path || null;

		if (key !== lastKey) {
			lastKey = key;
			repeatCount = 1;
			lastOffset = offset;
			lastPath = path;

			return;
		}

		repeatCount++;

		const relevantShortcut: GroupedShortCut | null =
			groupedShortcuts.filter(
				(shortcut) =>
					shortcut.character === key && shortcut.count === repeatCount
			)[0] || null;

		if (relevantShortcut === null) {
			if (
				isEqual(lastPath, path) &&
				lastOffset !== null &&
				lastOffset + 1 === offset
			) {
				lastOffset = offset;
			}

			return;
		}

		if (
			isEqual(lastPath, path) &&
			lastOffset !== null &&
			(lastOffset + 1 === offset || offset === lastOffset)
		) {
			event.preventDefault();

			for (let i = 0; i < relevantShortcut.count - 1; i++) {
				editor.deleteBackward("character");
			}

			editor.insertText(relevantShortcut.replaceWith);
			repeatCount = 0;
			lastKey = null;
			lastOffset = null;
			lastPath = null;
		}
	};

	const dashInsertion = (event) => {
		if (event.key !== " " && event.key !== "-") {
			nDashDetectionBuffer = [];
		}

		const offset = editor?.selection?.anchor?.offset || 0;
		const path = editor?.selection?.anchor?.path || null;

		if (path === null) return;

		if (event.key === " ") {
			switch (nDashDetectionBuffer.length) {
				case 0:
					nDashDetectionBuffer.push({
						offset, path
					});
					break;
				case 1:
					nDashDetectionBuffer = [];
					break;
				case 2:
					if (
						isEqual(
							nDashDetectionBuffer[0].path,
							nDashDetectionBuffer[1].path
						) &&
						isEqual(nDashDetectionBuffer[1].path, path) &&
						(nDashDetectionBuffer[0].offset ===
							nDashDetectionBuffer[1].offset ||
							nDashDetectionBuffer[0].offset + 1 ===
							nDashDetectionBuffer[1].offset) &&
						(nDashDetectionBuffer[1].offset ===
							offset ||
							nDashDetectionBuffer[1].offset + 1 ===
							offset)
					) {
						event.preventDefault();
						editor.deleteBackward("character");
						editor.insertText("– ");
					}
					nDashDetectionBuffer = [];
					break;
				default:
					nDashDetectionBuffer = [];
			}
		}

		if (event.key === "-") {
			if (nDashDetectionBuffer.length !== 1) {
				nDashDetectionBuffer = [];
			} else {
				nDashDetectionBuffer.push({
					offset, path
				});
			}
		}
	};

    const handleReplace = (r: IBookStore.ReplaceParams) => {
        if (r.all) {
            replaceAll(editor, r.text, searchMatchedRanges);
        } else {
            replaceOne(editor, r.text, search_r);
        }
    };
    
    const decorate = React.useCallback(
        ([node, path]: NodeEntry<Node>) => getSearchRanges(node, path, term.current, toJS(range.current)) as unknown as Range[],
        [search, counter, chapter]
    );

    const resetSearch = () => {
        setSearchStep(0);
        setSearchMatchedRanges([]);
        setSearch({
            q: "",
            caseSensitive: false,
            wholeWord: false,
        });
    };

    const renderLeaf = React.useCallback(
        ({ attributes, children, leaf }: RenderLeafProps) => {            
            let styles = {};
            if (leaf.select_highlight) {
                styles = {
                    padding: "0.25em",
                    borderRadius: 5,
                    backgroundColor: "#a0a4f0",
                    color: "#3a29b0",
                };          
            } else if (leaf.highlight) {
                styles = {
                    padding: "0.25em",
                    borderRadius: 5,
                    backgroundColor: "#f6e58d",
                    color: "#eb4d4b",
                };
            }

            useEffect(() => {
                if(leaf.select_highlight){
                    selectRef.current?.scrollIntoView({
                        block: "end",
                        inline: "center"
                    });
                }
            }, []);

            return (
                <span
                    ref={leaf.select_highlight ? selectRef : null}
                    {...attributes}
                    {...(leaf.isFocusedSearchHighlight && {
                        "data-testid": "search-focused",
                    })}
                    style={styles}
                >
                    {children}
                </span>
            );
        },
    []);

    //get and set all search ranges
    useEffect(() => {
        const ranges = getAllSearchRanges(editor, search);
        setSearchMatchedRanges(ranges);

        const step = getNextSearchMatchStep(editor, ranges);
        setSearchStep(step);

        term.current = search;
        range.current = searchMatchedRanges[searchStep];
    }, [search, editor, chapter]);

     //get and set all search ranges and update counter for re-rendering
     useEffect(() => {
        range.current = searchMatchedRanges[searchStep];
        setCounter(counter + 1);
    }, [searchMatchedRanges, searchStep]);
     

    //Save body on change of chapter, book or chapter count
    useEffect(() => { 
        saveBody(b);
    }, [book._id, chapter._id, changeCount]);

    // Do autofocus and ScrollTo on Chapter change
	useEffect(() => {      
		Transforms.deselect(editor);
		chapterAutofocus = !chapter.title || chapter.title.length === 0 ? true : false;

		if (edtRef.current) {
			edtRef.current.scrollTo({
				top: 0,
				left: 0
			});
		}
	}, [chapter._id]);

    // set offline chapter 
    useEffect(() => {
        if(chapter._id.indexOf("offline") !== -1){
            setIsOfflineChapter(true);
        }
    }, [chapter]);

     // Chapter Template Library
     useEffect(() => {
        if (chapterTemplateView || !chapter._id) {
            saveBody(toJS(chapTemplate.children));
        }
        return () => {
            setMainChapterTemplate({
                _id: "",
                type: "uncategorized",
                title: "",
                image: "",
                children: []
            });
        };
    }, [chapTemplate._id]);

    // Chapter template library
	useEffect(() => {
		if(chapTemplate && chapTemplate._id) {
			Transforms.deselect(editor);
			chapterAutofocus = !chapTemplate.title || chapTemplate.title.length === 0 ? true : false;
			if (edtRef.current) {
				edtRef.current.scrollTo({
					top: 0,
					left: 0
				});
			}
		}
	}, [chapTemplate]);

    useEffect(() => {
        return () => {
            resetSearch();
        };
    }, []);
    
    return (
        <>
        <div className="atticus-editor-container">
                <Slate
                    editor={editor}
                    spellcheck={false}
                    onChange={handleEditorOnChange}
                    value={[{ children: v }]}
                >
                    <RootEditorModal />
                    <Row className="editor-header">
                        <Col>
                            <Toolbar />
                        </Col>
                        <Col>
                            {!chapterTemplateView && writing ?  (
                                <SettingsToolbar />
                            ): null}
                        </Col>
                    </Row>
                    <WithEditorStyle />
                    <div className="editor-col">
                        <div className="editor-area" ref={edtRef}>
                            {chapterTemplateView ? (
                                <ChapterTemplateTitlebar
                                    onEnter={() => {
                                        ReactEditor.focus(editor);
                                        //enter click
                                    }}
                                    customTitle={customTitle}
                                    placeholder="Chapter Title"
                                    autofocus={chapterAutofocus}
                                />
                            ): (
                                <Titlebar
                                    onEnter={() => {
                                        ReactEditor.focus(editor);
                                        //enter click
                                    }}
                                    customTitle={customTitle}
                                    placeholder="Chapter Title"
                                    autofocus={chapterAutofocus}
                                />
                            )}
                            <EditablePlugins
                                className="editor-textarea"
                                decorate={[decorate]}
                                renderLeaf={[renderLeaf]}
                                onSelect={(props) => {
                                    const vl = editor.getFragment();
                                    if (vl && vl.length > 0 && vl[0].children) {
                                        setWords(countWords(vl[0].children));
                                    }
                                    /**
                                     * Chrome doesn't scroll at bottom of the page. This fixes that.
                                     */
                                    if (!(window as any).chrome) return;
                                    if (editor.selection == null) return;
                                    try {
                                        /**
                                         * Need a try/catch because sometimes you get an error like:
                                         *
                                         * Error: Cannot resolve a DOM node from Slate node: {"type":"p","children":[{"text":"","by":-1,"at":-1}]}
                                         */
                                        const domPoint = ReactEditor.toDOMPoint(
                                            editor,
                                            editor.selection.focus
                                        );
                                        const node = domPoint[0];
                                        if (node == null) return;
                                        const element = node.parentElement;
                                        if (element == null) return;
                                        element.scrollIntoView(
                                            { behavior: "smooth", block: "nearest" }
                                        );
                                        } catch (e) {
                                            /**
                                             * Empty catch. Do nothing if there is an error.
                                             */
                                        }
                                }}
                                onBlur={() => {
                                    setWords(0);
                                }}
                                onKeyDownCapture={(event) => {
                                    dashInsertion(event);
                                    applyGroupedShortcuts(event);
                                }}
                                plugins={plugins}
                                readOnly={false}
                                placeholder="Start Writing"
                                spellCheck
                            />
                        </div>
                        {
                            writing && editor_setting.show ? <EditorSider view={editor_setting.view} doReplace={handleReplace} /> : null
                        }
                    </div>
                </Slate>
        </div>
        </>
    );
};

export const Editor = observer(_Editor);
