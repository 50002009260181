import React, { FunctionComponent, useState } from "react";
import axios from "axios";
import { generate } from "randomstring";
import { getExtension } from "mime";
import Compressor from "compressorjs";
import { db } from "../../../db/bookDb";
import { AtticusClient } from "../../../api/atticus.api";
import { v4 } from "uuid";
import { cloneDeep } from "lodash";

// components
import { Upload, message, notification } from "antd";

// types
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";

const { Dragger } = Upload;

const S3_PRESIGNED_URL = "https://curt3jhx1m.execute-api.us-east-1.amazonaws.com/dev/uploader";

interface onSuccessType {
  (event: { percent: number }): void
}

interface FileUploadProps {
  fileType: string;
  folder?: string;
  children?: any;
  componentWidth?: number;
  background?: string;
  shape?: "square" | "rect" | "none";
  onFileUpload: (url: string | null, type?: string) => void;
  onFileUploadStart?: () => void;
  onFileUploadEnd?: () => void;
  isImageDeleted?:boolean
}

const getExtensionLocal = (type) => {
  if (type === "application/epub+zip") return "epub";
  if (type === "application/x-mobipocket-ebook") return "mobi";
  if (type === "text/rtf") return "rtf";
  return "docx";
};

const FileUpload: FunctionComponent<FileUploadProps> = ({ shape, onFileUpload, onFileUploadStart, onFileUploadEnd, fileType, folder, children, background, componentWidth,isImageDeleted }: FileUploadProps) => {

  const addImgToGallery = async (id, link, igFolder) => {
    const profileResponse = await AtticusClient.GetProfile();
    const IDBImgG = {
      userId: profileResponse._id,
      _imgId: id,
      link: link,
      folderName: igFolder,
      date: new Date()
    };
    // const saveImgIDB = await db.imageGallery.add(IDBImgG);
    const img = await AtticusClient.AddImgToGallery(IDBImgG);
    console.log({img});
    
  };
  
  const customRequest = async (options: RcCustomRequestOptions) => {
    
    const { onSuccess, onError } = options;
    const file: { name: string, type: string } = options.file as { name: string, type: string };
    let fileTypeHelper;
    console.log({file});

    if (!file.type) {
      // used the following variable to easily extend to multiple types in future
      const extension = file.name.split(".").pop();
      if (extension === "mobi") {
        fileTypeHelper = "application/x-mobipocket-ebook";
      }
    }

    const fileName: any = `${file.name
      .replace(/[\W_]+/g, "_")
      .slice(0, 6)}_${generate(12)}_ce.${getExtension(file.type) || getExtensionLocal(fileTypeHelper)}`;
    
    
    if (file) {
      if (onFileUploadStart) onFileUploadStart();
      try {
        const folderName = folder ? folder : "files";
        const payload = {
          fileBinary: options.file as Blob,
          path: `${folderName}/`,
          fileName,
          contentType: file.type || fileTypeHelper, 
          fileId: v4()
        };
        /** handle image upload */
        if (file.type.includes("image")) {
          if (!navigator.onLine) {
            notification["warning"]({
              message: "Image won't be added to the gallery when you upload offline",
            });
            return;
          }
          const compressedPayload = {
            fileBinary: options.file as Blob,
            path: "compressed-images/",
            fileName,
            contentType: file.type || fileTypeHelper, 
            fileId: v4()
          };
          /** a compressed version of the image is upload in addition to the uncompressed version */
          new Compressor(options.file as Blob, {
            quality: 0.6,
            success: compressedResult => {
              compressedPayload.fileBinary = compressedResult;
            },
            error(err) {
              console.log("Failed to compress image",err.message);
            },
          });
          const compressedImageLinkMap = await AtticusClient.UploadFile([compressedPayload]);
          await addImgToGallery(fileName, compressedImageLinkMap.get(compressedPayload.fileId), folderName);
        }

        const hostedLinkMap = await AtticusClient.UploadFile([payload]);
        const hostedLink = hostedLinkMap.get(payload.fileId);

        const fileExtension = getExtensionLocal(file.type || fileTypeHelper);

        if (onSuccess) (onSuccess as onSuccessType)({ percent: 100 });
        onFileUpload(hostedLink!, fileExtension);
        if (onFileUploadEnd) onFileUploadEnd();
      } catch (err) {
        if (onError) onError(err);
        console.log(err);
        message.error(err.message, 4);
        if (onFileUploadEnd) onFileUploadEnd();
      }
    }
  };

  const uploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    //fileList: fileList,
    accept: fileType,
    onChange: (info: { fileList: Array<unknown> }) => {
      if (info.fileList.length === 0) onFileUpload(null);
    },
    
    showUploadList:isImageDeleted?false:{showRemoveIcon:false}
  };

  return shape === "none" ? (
    <Upload
      {...uploadProps}
      customRequest={customRequest}
    >
      {children}
    </Upload>
  ) : (
    <Dragger
      {...uploadProps}
      customRequest={customRequest}
      //fileList
      style={{
        border: 0,
        borderRadius: 10,
        padding: shape === "square" ? "0.8rem" : "0.6rem 1rem",
        height: shape === "square" ? 60 : undefined,
        backgroundColor: background,
        width: `${componentWidth} px`,
      }}>
      {children}
    </Dragger>
  );
};

export default FileUpload;
