import React, { FunctionComponent, ReactNode } from "react";
import { ReactEditor, useEditor, useSelected } from "slate-react";
import { SlatePlugin } from "@udecode/slate-plugins-core";
import {
  getRenderElement,
  ToolbarElement,
} from "@udecode/slate-plugins";
import { Transforms, Node } from "slate";

import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";

// icons
import { OrnamentalBreakIcon } from "./partials/Icon";

// styles
import "./styles.scss";

export type OrnamentalBreakType = "ornamental-break";

export interface OrnamentalBreakElement {
  type: OrnamentalBreakType;
  children: [];
  id?: string;
}

export const withOrnamentalBreak = () => <T extends ReactEditor>(editor: T): ReactEditor => {
  const { isVoid } = editor;
  editor.isVoid = (element) => (element.type === "ornamental-break" ? true : isVoid(element));

  return editor;
};

interface OrnamentalBreakComponentProp {
  children: ReactNode;
  element: Node
}

export const OrnamentalBreakComponent: FunctionComponent<OrnamentalBreakComponentProp> = ({ children, element }: OrnamentalBreakComponentProp) => {
  const selected = useSelected();
	const editor = useEditor();

  let className = "slate-ornamental-break";
  className = `${className}${selected ? " selected" : ""}`;

  return (
    <div className={className} >
      <div></div>
      <div contentEditable={false}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <span>{"***"}</span>
      </div>
      {children}
      <div>
        <Popconfirm
          title="Are you sure want to delete this Ornamental Break?"
          onConfirm={() => {
            const path = ReactEditor.findPath(editor, element);
            Transforms.removeNodes(editor, { at: path });
          }}
          okButtonProps={{
            danger: true
          }}
          okText="Delete"
          cancelText="Cancel"
        >
          <Button>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
};

const renderOrnamentalBreak = () => {
  const ornamentalBreak = {
    component: OrnamentalBreakComponent,
    type: "ornamental-break",
    hotkey: "",
    defaultType: "ornamental-break",
    rootProps: {
    },
  };

  return getRenderElement(ornamentalBreak);
};

/**
 * Enables support for ornamental breaks.
 */
export const OrnamentalBreakPlugin = (): SlatePlugin => {
  return {
    renderElement: renderOrnamentalBreak(),
    voidTypes: ["ornamental-break"],
  };
};

export const OrnamentalBreakToolbarButton: FunctionComponent = () => {
  const editor = useEditor();

  return (
    <ToolbarElement
      type={"ornamental-break"}
      icon={<OrnamentalBreakIcon />}
      onMouseDown={() => {
        Transforms.insertNodes(editor, {
          type: "ornamental-break",
          children: [
            {
              text: "",
            }
          ]
        });
      }}
    />
  );
};