import React, { useState } from "react";
import { Modal, Button, Popconfirm, Popover, message } from "antd";
import { PlusOutlined, ArrowLeftOutlined, SwapOutlined, DeleteOutlined } from "@ant-design/icons";
import { getRenderElement, ToolbarElement } from "@udecode/slate-plugins";
import { observer } from "mobx-react";

import { ReactEditor, useEditor } from "slate-react";

import AllProfiles from "./AllProfiles";
import NewProfile from "./NewProfile";
import { ProfileIcon } from "./partials/icon";
import useRootStore from "../../../../store/useRootStore";
import { getSocialMediaIcons } from "../../../Previewer/print/configs/social-media-icons";

export const withProfiles = () => <T extends ReactEditor>(editor: T): ReactEditor => {
	const { isVoid, isInline } = editor;
	editor.isVoid = (element) => (element.type === "profile" ? true : isVoid(element));
	editor.isInline = (element) => (element.type === "profile" ? false : isInline(element));
	return editor;
};

export const ProfilesComponent: any = ({ element, children }: any) => {

	const editor = useEditor();
	const { 
		showEditProfileModalFromEditor,
		setSMProfilePathToReplace,
		setShowProfileModal,
		removeElementAtPath,
	} = useRootStore().socialProfileStore;

	const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

	// const handleEditFromEditor = () => {
	// 	const path = ReactEditor.findPath(editor, element);
	// 	const node = Node.get(editor, path);
	// 	if(node._id){
	// 		showEditProfileModalFromEditor(node._id as string);
	// 	}else{
	// 		message.error("Failed to find profile");
	// 	}
	// };

	const handleEditFromEditor = () => {
		const path = ReactEditor.findPath(editor, element);
		setSMProfilePathToReplace(path);
		setShowProfileModal(true);
	};


	//renders profiles on the editor
	const SMRenderer = observer((iconName) => {
		return (
			<div>
				{
					iconName.iconName.url.map(prof => {
						const { name } = prof;
						const iconNameWSize = name + "-Small";

						const getSM = getSocialMediaIcons(iconNameWSize);
						const smName = getSM?.smLink;
						const smID = getSM?.id;

						return (
							<div key={smID} className="social-profile-icons">
								<img style={{ width: 30, height: 30 }} src={smName} />
							</div>
						);
					})
				}
			</div>
		);
	});

	const editProfilePopover = () => {
		return (
			<div>
				<Button type="primary" onClick={() => handleEditFromEditor()}>
					Change Profile
				</Button>
			</div>
		);
	};

	const deleteProfilePopover = () => {
		return (
			<div>
				<Button type="primary" danger onClick={() => setShowConfirmDeleteModal(true)}>
					Delete
				</Button>
			</div>
		);
	};

	const removeProfileFromChapter = () => {
		const path = ReactEditor.findPath(editor, element);
		removeElementAtPath(path, editor);
		setShowConfirmDeleteModal(false);
	};

	return (
		<div className="slate-ornamental-break" >
			<div></div>
			<div contentEditable={false}
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center"
				}}>
				<SMRenderer iconName={element} />
				{children}
			</div>
			<div>
				<Popover placement="top" content={editProfilePopover}>
					<SwapOutlined 
						className="edit-profile-icon"
						onClick={() => handleEditFromEditor()} 
					/>
				</Popover>
				<Popover placement="top" content={deleteProfilePopover}>
					<DeleteOutlined 
						className="edit-profile-icon danger"
						onClick={() => setShowConfirmDeleteModal(true)} 
					/>
				</Popover>

				{/* <Popconfirm
					title="Are you sure you want to delete this Profile?"
					onConfirm={() => {
						const path = ReactEditor.findPath(editor, element);
						Transforms.removeNodes(editor, { at: path });
					}}
					okText="Delete"
					cancelText="Cancel"
					okButtonProps={{
						danger: true,
					}}
				>
					<DeleteOutlined className="edit-profile-icon" />
				</Popconfirm> */}

				<Modal
					visible={showConfirmDeleteModal}
					onCancel={() => setShowConfirmDeleteModal(false)}
					closable={false}
					footer={[
						<Button key="back" type="primary" onClick={() => setShowConfirmDeleteModal(false)}>
							Cancel
						</Button>,
						<Button key="submit" type="primary" danger onClick={removeProfileFromChapter}>
							Remove
						</Button>
					]}
				>
					<div className="delete-profile-modal-body">
						<p>Are you sure you want to remove this Profile from the chapter?</p>
					</div>
				</Modal>
			</div>
		</div>
	);
};

const renderProfile = () => {
	const profileLinks = {
		component: ProfilesComponent,
		type: "profile",
		hotkey: "",
		defaultType: "profile",
		rootProps: {
			className: "slate-image"
		},
	};
	return getRenderElement(profileLinks);
};

export const ProfilesPlugin = (): any => {
	return {
		renderElement: renderProfile(),
		voidTypes: ["profile"],
	};
};

const ProfilesToobarButton = observer(() => {

	const editor = useEditor();

	const { 
		showProfileModal, 
		setShowProfileModal,
		resetAndHideProfileModal,
		modalState, 
		setModalState,
		resetModal,
		setEditorSelection,
		insertAccountsIntoEditor,
		smProfilePathToReplace,
		smAccountsToInsert
	} = useRootStore().socialProfileStore;

	const handleCancel = () => {
		resetAndHideProfileModal();
	};

	return (
		<div>
			<ToolbarElement
				type={"profile"}
				icon={<ProfileIcon />}
				onMouseDown={(e) => {
					e.preventDefault();
					setShowProfileModal(true);
					setEditorSelection(editor.selection);
				}}
			/>
			<Modal
				visible={showProfileModal}
				title={ smProfilePathToReplace ? "Change Profile" : "Your Profiles"}
				onCancel={handleCancel}
				footer={ modalState === "default" ? [
					<Button key="back" type="primary" danger onClick={handleCancel}>
						Cancel
					</Button>,
					<Button disabled={smAccountsToInsert.length === 0} key="submit" type="primary" onClick={() => insertAccountsIntoEditor(editor)}>
						{ smProfilePathToReplace ? "Change" : "Add" }
					</Button>
				] : []}
			>
				{ 
					modalState === "default" && (
						<>
							{!smProfilePathToReplace && 
									<div className="create-prof-btn-div">
									<Button
										icon={<PlusOutlined />}
										onClick={() => setModalState("addNewProfile")}>
										Create new profile
									</Button>
								</div>
							}
							<AllProfiles />
						</>
					)
				}
				{
					( modalState === "addNewProfile" || modalState === "editProfile" ) && (
						<>
							<div className="create-prof-btn-div">
								<Button
									icon={<ArrowLeftOutlined />}
									onClick={() => resetModal(true)}
								>
									Go Back
								</Button>
							</div>
							<NewProfile />
						</>
					)
				}
			</Modal>
		</div>
	);
});

export default ProfilesToobarButton;