import React, { FunctionComponent } from "react";
import { SvgIcon } from "@material-ui/core";

interface IconProps {
    size?: number;
    active?: boolean;
}

export const EditIcon: FunctionComponent = () => (
	<SvgIcon width="224.000000pt" height="212.000000pt" viewBox="0 0 224.000000 212.000000">
		<g transform="translate(0.000000,212.000000) scale(0.100000,-0.100000)"
			fill="#000000" stroke="none">
			<path d="M1289 2017 c-20 -8 -51 -24 -68 -36 -37 -26 -171 -196 -171 -217 0
-7 55 -57 122 -111 183 -146 198 -162 198 -213 0 -55 -42 -100 -93 -100 -37 0
-43 4 -232 155 -100 81 -127 97 -141 87 -11 -8 -57 -67 -422 -538 -289 -375
-291 -378 -298 -440 -6 -47 1 -83 72 -387 25 -105 13 -101 262 -94 178 6 221
10 256 26 45 20 90 67 204 216 37 50 178 232 312 405 134 173 273 353 309 400
36 47 99 128 140 180 41 52 83 115 94 139 38 87 18 203 -50 286 -41 49 -240
204 -293 227 -57 26 -154 33 -201 15z"/>
			<path d="M1344 320 c-79 -32 -86 -155 -10 -195 27 -14 70 -16 368 -13 l336 3
26 24 c51 48 42 135 -18 172 -29 18 -55 19 -356 18 -179 0 -335 -4 -346 -9z"/>
		</g>
	</SvgIcon>
);