import {
	AutoformatRule,
	MARK_BOLD,
	MARK_CODE,
	MARK_ITALIC,
	MARK_STRIKETHROUGH,
	toggleList,
	unwrapList,
} from "@udecode/slate-plugins";
import { Editor } from "slate";
import { options } from "./default";

const preFormat = (editor: Editor): void => unwrapList(editor, options);

export const autoformatRules: AutoformatRule[] = [
	{
		type: options.h2.type,
		markup: "#",
		preFormat,
	},
	{
		type: options.h2.type,
		markup: "##",
		preFormat,
	},
	{
		type: options.h2.type,
		markup: "###",
		preFormat,
	},
	{
		type: options.h2.type,
		markup: "####",
		preFormat,
	},
	{
		type: options.h2.type,
		markup: "#####",
		preFormat,
	},
	{
		type: options.h2.type,
		markup: "######",
		preFormat,
	},
	{
		type: options.li.type,
		markup: ["*", "-"],
		preFormat,
		format: (editor: Editor): void => {
			toggleList(editor, { ...options, typeList: options.ul.type });
		},
	},
	{
		type: options.li.type,
		markup: ["1.", "1)"],
		preFormat,
		format: (editor: Editor): void => {
			toggleList(editor, { ...options, typeList: options.ol.type });
		},
	},
	{
		type: options.todo_li.type,
		markup: ["[]"],
	},
	{
		type: options.blockquote.type,
		markup: [">"],
		preFormat,
	},
	{
		type: MARK_BOLD,
		between: ["**", "**"],
		mode: "inline",
		insertTrigger: true,
	},
	{
		type: MARK_BOLD,
		between: ["__", "__"],
		mode: "inline",
		insertTrigger: true,
	},
	{
		type: MARK_ITALIC,
		between: ["*", "*"],
		mode: "inline",
		insertTrigger: true,
	},
	{
		type: MARK_ITALIC,
		between: ["_", "_"],
		mode: "inline",
		insertTrigger: true,
	},
	{
		type: MARK_CODE,
		between: ["`", "`"],
		mode: "inline",
		insertTrigger: true,
	},
	{
		type: MARK_STRIKETHROUGH,
		between: ["~~", "~~"],
		mode: "inline",
		insertTrigger: true,
	},
	{
		trigger: "`",
		type: options.code_block.type,
		markup: "``",
		mode: "inline-block",
		preFormat: (editor: Editor): void => unwrapList(editor, options),
	},
];