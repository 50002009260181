import React, { FunctionComponent } from "react";
import { SvgIcon } from "@material-ui/core";

interface IconProps{
  size?: number;
  active?: boolean;
}

export const EndnoteIcon: FunctionComponent<IconProps> = ({ size = 15 }: IconProps) => (
	<SvgIcon viewBox="0 0 150 150" style={{ width: size }} className="slate-ToolbarButton">
		<g id="_1">
			<g className="st0">
				<path className="st1" d="M71.2,109.8V34.6L55.3,51.2l-9.9-10.3l28-28.3h14.9v97.2H71.2z" />
			</g>
		</g>
		<path className="st1" d="M114.7,146.3H35.3c-5,0-9.1-4.1-9.1-9.1s4.1-9.1,9.1-9.1h79.5c5,0,9.1,4.1,9.1,9.1S119.8,146.3,114.7,146.3z" />
	</SvgIcon>
);