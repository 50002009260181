import React from "react";
import { observer } from "mobx-react";
import { Row, Col } from "antd";
import querystring from "querystring";

import { NewBookToolbar, BookList, BookToolbar } from ".";

import { AtticusLoader } from "../Shared/Loader/AtticusLoader";

import useRootStore from "../../store/useRootStore";
import ServiceWorkerWrapper from "../../ServiceWorkerWrapper";
import { useLocation } from "react-router-dom";

export const MyBooks = observer(() => {
	const { loading } = useRootStore().shelfStore;

	const { search } = useLocation();
	const queryObj = querystring.parse(search.replace("?", ""));

	const tab = queryObj.tab ? queryObj.tab : "recent";
	return (
		<>
			{loading ? <AtticusLoader loading={loading} /> :
			<div className="container" style={{ maxWidth: 1300 }}>
				<Row
					style={{
						margin: "auto",
						marginBottom: "1em",
						padding: "0px 16px",
					}}
				>
					<BookToolbar />
				</Row>
				<Row 
					wrap={false}
					gutter={32}
					style={{
						margin: "auto",
						paddingTop: "1em",
					}}
				>
					{tab!=="templates"?(
						<Col flex="none">
						<NewBookToolbar />
					</Col>
					):null}
					
					<Col flex="auto">
						<BookList />
					</Col>
				</Row>
			</div>}			
		</>
	);
});
