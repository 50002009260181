import React from "react";
import { observer } from "mobx-react";
import useRootStore from "../../../store/useRootStore";
import UpdateLink from "./UpdateLink";
import { QuoteeInputModal } from "./QuoteeInputModal";

const RootEditorModal = () => {
  const { activeModal } = useRootStore().editorModalStore;

  switch (activeModal) {
    case "update_link":
      return <UpdateLink />;
    case "blockquote":
      return <QuoteeInputModal />;
    default:
      return null;
  }
};

export default observer(RootEditorModal);
