import React, { FC } from "react";

const NotIncluded: FC = () => {
  return (
    <div style={{ marginTop: "20rem" }}>
      This chapter is not included in print
    </div>
  );
};

export default NotIncluded;
