import React, { FunctionComponent, useState, useEffect } from "react";
import { Row, Col, Card, message } from "antd";
import { useLocation } from "react-router-dom";

import { AtticusClient } from "../../../api/atticus.api";

import Header from "../Header";
import { FullScreen } from "../../../components/Shared/Layouts/FullScreen";
import ProfileLeftMenu from "../../Shared/Layouts/ProfileLeftMenu";
import { ProfileLayoutProps } from "../../../types/profile";

const ProfileLayout: FunctionComponent<ProfileLayoutProps> = props => {

	const [profile, setProfile] = useState({ firstName: "", lastName: "", profilePictureURL: "", website: "", email: "" });

	useEffect(() => {
		const myProfile = async () => {
			const profileResponse = await AtticusClient.GetProfile();
			setProfile(profileResponse);
		};
		myProfile();
	}, []);

	const { pathname } = useLocation();

	const OnSubmitHandler = async (values, { setSubmitting }) => {
		try {
			setSubmitting(true);
			const myprofile = {
				firstName: values.firstName || profile.firstName,
				lastName: values.lastName || profile.lastName,
				website: values.website || profile.website,
				profilePictureURL: profile.profilePictureURL
			};
			await AtticusClient.PatchProfile(myprofile);  
			const profileResponse = await AtticusClient.GetProfile();
			setProfile(profileResponse);
			message.success("Your profile has been updated successfully!", 3);
		} catch (e) {
			setSubmitting(false);
		}  
		setSubmitting(false);
	};

	const OnProfilePictureUploadHanlder = async fileUrl => {
		const myProfile = profile;
		myProfile.profilePictureURL = fileUrl;
		myProfile.website = "";
		await AtticusClient.PatchProfile(myProfile);  
		const profileResponse = await AtticusClient.GetProfile();
		setProfile(profileResponse);
		message.success("Your profile picture has been updated successfully!", 3);
	};

	return (
		<FullScreen>
			<Header profile={profile} />
			<div className="container my__profile--container">
				<Row>
					<Col span={18} push={6}>
						<h1>{pathname.indexOf("my-profile") > -1 ? "Profile Details" : "Change Password"}</h1>
						<Card className="profile__layout--card--container">
							{props.children({ profile, OnSubmitHandler, OnProfilePictureUploadHanlder })}
						</Card>
					</Col>
					<ProfileLeftMenu myprofile={profile} />
				</Row>
			</div>
      <small style={{ opacity: 0.01 }}>New version</small>
		</FullScreen>
	);
};

export default ProfileLayout;