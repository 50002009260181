import React, { FunctionComponent, ReactNode, useCallback } from "react";
import { ReactEditor, useEditor, useSelected } from "slate-react";
import { Editor, Range, Point, Node } from "slate";
import { SlatePlugin } from "@udecode/slate-plugins-core";

import {
  getRenderElement,
  ToolbarElement,
} from "@udecode/slate-plugins";

import { Transforms } from "slate";
import { chunk, findIndex } from "lodash";
// icons
import { ScanOutlined } from "@ant-design/icons";

import useRootStore from "../../../../store/useRootStore";

export type SplitChapterType = "split-chapter";

export interface SplitChapterElement {
  type: SplitChapterType;
  children: [];
  id?: string;
}

export const withSplitChapter = () => <T extends ReactEditor>(editor: T): ReactEditor => {
  const { isVoid } = editor;
  editor.isVoid = (element) => (element.type === "split-chapter" ? true : isVoid(element));

  return editor;
};


const renderSplitChapter = () => {
  const splitChapter = {
    component: null,
    type: "split-chapter",
    hotkey: "",
    defaultType: "split-chapter",
    rootProps: {
    },
  };


  return getRenderElement(splitChapter);
};

/**
 * Enables support for ornamental breaks.
 */
export const SplitChapterPlugin = (): SlatePlugin => {
  return {
    renderElement: renderSplitChapter(),
    voidTypes: ["split-chapter"],
  };
};

export const SplitChapterButton: FunctionComponent = () => {
  const editor = useEditor();
  const { chapter, book, addNewChapter, getChapterMatterById, saveChapterBodyUpdates, getCurrentStoredBook, getChapterBodyById } = useRootStore().bookStore;
  const { refreshCache } = useRootStore().pdfCacheStore;

  const isRangeSelected = () => {
    return editor.selection && Range.isExpanded(editor.selection) ? true : false;
  };

  const refreshPDFCache = useCallback(async () => {
    const { frontMatterIds, chapterIds, backMatterIds } = getCurrentStoredBook();
    const allChapterIds = [...frontMatterIds, ...chapterIds, ...backMatterIds];
    const chapterData = await getChapterBodyById(allChapterIds);
    const chapterCacheData = chapterData.map(({ _id, type, startOn }) => ({ chapterId: _id, chapterType: type, startOn } as IPDFCacheStore.ChapterCacheMetaData));
    refreshCache(book._id, "chapter-add", { "chapter-add": { chapters: chapterCacheData } });
  }, [book, getCurrentStoredBook, refreshCache]);

  return (
    <ToolbarElement
      type={"split-chapter"}
      icon={<ScanOutlined disabled={isRangeSelected()} />}
      onMouseDown={async () => {
        if (!(editor.getFragment().length > 0)) return;
        
        // ignore split if text selected
        if (isRangeSelected()) {
          return;
        }

        Transforms.splitNodes(editor);
        
        let index = (editor.selection?.anchor.path[1] as number);

        // start of node
        if (editor.selection && editor.selection.focus.offset > 0) {
          index += 1;
        }

        // Select from focused point to end of document
        const end = Editor.end(editor, []);

        // select area
        Transforms.setSelection(editor, {
          focus: end
        });


        const curState = editor.children[0].children as unknown as Node[];

        const keepInCurChapter = (curState).slice(0, index);
        const moveToNextChapter = (curState).slice(index, curState.length);

        // Deselect the current selection
        Transforms.deselect(editor);

        saveChapterBodyUpdates(chapter._id, keepInCurChapter);

        // add new chapter
        await addNewChapter(
          getChapterMatterById(chapter._id),
          chapter.type,
          {
            title: chapter.title + " (SPLIT)",
          },
          moveToNextChapter
        );

        await refreshPDFCache();
      }
    }/>
  );
};