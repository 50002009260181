import React, { FunctionComponent } from "react";
import { Formik, FormikErrors } from "formik";
import { Button, Modal, Form, Input, FormItemProps } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";

import FileUpload from "../Shared/Forms/FileUpload";

const commonFormItemProps = {
	labelAlign: "left",
	colon: false,
} as FormItemProps;

interface ImportChaptersModalProps {
    onUpload: (params: IShelfStore.ChapterImportForm) => Promise<unknown>;
    onClose: () => void;
    bookId: string;
}

const ImportChaptersModal: FunctionComponent<ImportChaptersModalProps> = ({ onUpload, onClose, bookId }: ImportChaptersModalProps) => {
	const layout = {
		labelCol: { span: 5 },
		wrapperCol: { span: 24 },
	};

	return (
		<>
			<Formik
				initialValues={{ bookId, fileURL: "", fileType: "" } as IShelfStore.ChapterImportForm}
				validate={() => {
					const errors: FormikErrors<IShelfStore.ChapterImportForm> = {};
					return errors;
				}}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					await onUpload(values);
					setSubmitting(false);
				}}
			>
				{({
					setFieldValue,
					submitForm,
					isSubmitting,
					setSubmitting,
				}) => (
					<Modal
						maskClosable={false}
						title={<h2 className="section-heading">Import Chapters to the Current Book</h2>}
						visible={true}
						style={{
							borderRadius: 10,
						}}
						onCancel={() => onClose()}
						footer={(
							<Button
								type="primary"
								block
								size="large"
								onMouseDown={submitForm}
								loading={isSubmitting}
							>
								{"Go Get Em Atticus!"} <ArrowRightOutlined />
							</Button>
						)}
					>
						<Form {...layout} layout="vertical">
							<div className="book-form">
								<Form.Item name="upload" {...commonFormItemProps}>
									<FileUpload
										fileType=".docx"
										onFileUpload={(fileUrl, fileType) => {
											setFieldValue("fileURL", fileUrl);
                      if (fileType) setFieldValue("fileType", fileType);
										}}
										onFileUploadStart={() => setSubmitting(true)}
										onFileUploadEnd={() => setSubmitting(false)}
									>
										<p className="ant-upload-drag-icon">
											<UploadOutlined />
										</p>
										<p className="ant-upload-text" style={{ fontSize: "0.8rem" }}>
											<b>Choose a file</b> or drag and drop it here
										</p>
									</FileUpload>
								</Form.Item>
							</div>
						</Form>
					</Modal>
				)}
			</Formik>
		</>
	);
};

export default ImportChaptersModal;
