import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import useRootStore from "../../../store/useRootStore";
import { InternalLink } from "../plugins/Link/types";
import { InternalLinkComponent } from "../plugins/Link/partials/InternalLink";
import { isNullOrUndefined } from "../../../utils/strings";
import { checkHttps } from "../../../utils/helper";
import { AtticusClient } from "../../../api/atticus.api";
import { LinkUrl } from "../../../types/editor-modal";

const getInternalLinkFromUrl = (updateLinkUrl: LinkUrl): InternalLink | undefined => {
  const {url, linkType} = updateLinkUrl;
  if (isNullOrUndefined(url)) return undefined;

  let internalLink: InternalLink = {
    type: "web-link",
    webLink: "",
  };

  if(linkType === "booklinker") {
    return internalLink = {
      type: "booklinker",
      booklinker: url!,
    };

  }

  // keeping the old logic for the backward compatibility
  if (url!.startsWith("#chapter")) {
    internalLink = {
      type: "internal-link",
      internalLink: { chapterId: url!.split("=")[1] },
    };
  } else {
    internalLink = {
      type: "web-link",
      webLink: url!,
    };
  }

  return internalLink;
};

const UpdateLink: FunctionComponent = () => {
  const { activeModal, dismissAllModals, updateLinkUrl, setNewLinkUrl } =
    useRootStore().editorModalStore;

  const [url, setUrl] = useState<string>(toJS(updateLinkUrl).url ?? ""); // TODO: Add url to MobX
  const [internalLink, setInternalLink] = useState<InternalLink | undefined>(
    getInternalLinkFromUrl(updateLinkUrl)
  );
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState<string | null>("");
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    const normalized = toJS(updateLinkUrl);

    if (url !== normalized.url) {
      setUrl(normalized.url ?? "");
      setInternalLink(getInternalLinkFromUrl({url: normalized.url}));
    }
  }, [updateLinkUrl]);

  useEffect(() => {
    handleUrlChange(url);
  }, [url]);

  const updateUrl = async () => {
    setBtnLoading(true);
    
    if (updateLinkUrl.linkType === "booklinker") {
      const res = await AtticusClient.CreateBooklinkerLink(url);
      if (res.success) {
        const booklinkerLink = res.linkResult;
        setNewLinkUrl(booklinkerLink, "update");
      } else {
        setErrorText(res.responseStatus.message);
      }
      setBtnLoading(false);
      return;
    }

    setNewLinkUrl(url, "update");
    setBtnLoading(false);
  };

  const deleteUrl = () => {
    setNewLinkUrl(url, "delete");
  };

  const handleUrlChange = (url) => {
    if (url.length === 0 || isNullOrUndefined(url) || (!checkHttps(url) && !url.startsWith("#chapter="))) {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <>
      <Modal
        visible={activeModal === "update_link"}
        onCancel={() => dismissAllModals()}
        title="Update Link"
        footer={
          <div style={{ display: "flex" }}>
            <Button
              type="primary"
              danger
              block
              size="large"
              onClick={() => deleteUrl()}
            >
              Delete
            </Button>
            <Button
              type="link"
              href={internalLink?.type === "booklinker" ? internalLink.booklinker : url}
              target="_blank"
              rel="noreferrer"
              block
              size="large"
              disabled={internalLink?.type === "booklinker" ? false : error}
            >
             Visit URL
            </Button>
            <Button
              type="primary"
              block
              size="large"
              disabled={error}
              onClick={() => updateUrl()}
              loading={btnLoading}
            >
              {internalLink?.type === "booklinker" ? "Update Universal Link": "Update"}
            </Button>
          </div>
        }
      > 
        <InternalLinkComponent
          link={internalLink}
          setLink={setInternalLink}
          url={url}
          setUrl={setUrl}
          error={errorText}
          setError={setErrorText}
          update
        />
      </Modal>
    </>
  );
};

export default observer(UpdateLink);
