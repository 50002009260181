import React, { FunctionComponent, ReactNode, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Row, Col, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import useInterval from "use-interval";

import useRootStore from "../../../store/useRootStore";

import { useOnlineStatus } from "../../../utils/isOffline";

import { printFonts, editorFonts, slateFonts, Fonts } from "../helpers/printConfigs";
import {availableFontStylesForFont} from "../helpers/fontStyle";

// sync
import { syncData } from "../../../utils/sync";

import { AtticusClient } from "../../../api/atticus.api";

import Header from "../../../components/Shared/Header";
import PDFExporter from "../PDFExporter/PDFExporter";
import { unionBy } from "lodash";

const AUTH_STATUS_CHECK_INTERVAL = 30000;

interface AppProps {
    children: ReactNode;
}

const editorFontsVariations = editorFonts.reduce((acc, f) => {
    const a = availableFontStylesForFont(f._id);
    if(a?.bold){
        acc.push({
            _id: f._id + "-Bold",
            name: f.name + " Bold",
            css: "@font-face{\n  font-family: " + f._id +  "; \n  src: url(\"fonts/" + f._id+ "-Bold" + ".ttf\");\n  src: url(\"https://atticus-content.s3.amazonaws.com/fonts/" + f._id + "-Bold" + ".ttf\");\n font-weight: bold; \n}\n." + f._id+ "-Bold" + "{\n  font-family: " + f._id + ";\n font-weight: bold; \n}"
        });
    }
    return acc;
}, [] as Fonts[]);

const unionFonts = [...unionBy(printFonts, editorFonts, "_id"), ...slateFonts, ...editorFontsVariations];

const LayoutWithAuth: FunctionComponent<AppProps> = ({ children }: AppProps) => {

    const { token } = useRootStore().authStore;
    const history = useHistory();

    const { setSyncing, syncing } = useRootStore().bookStore;
    const { loadBooks } = useRootStore().shelfStore;
    const { loadThemes, saveThemeUpdates, saveDevicePreview, getAllDevices } = useRootStore().themeStore;
    const { getSMProfilesFromDB } = useRootStore().socialProfileStore;

    const isOnline = useOnlineStatus();
    const { pathname } = useLocation();

    const [visible, setVisible] = React.useState(false);

    const sync = async () => {
        setSyncing(true);
        if (isOnline){
            await syncData();
        }
        getSMProfilesFromDB(); // load user's social media profiles in the app state
        loadBooks();
        // getErrorBook();
        loadThemes(); // saves all the themes at the start
        //getUserThemes();
        saveThemeUpdates(); // calls the themes right at the start when the books are loaded
        saveDevicePreview(); // saved the book previews at the start
        getAllDevices();
        setSyncing(false);
        setVisible(false);
    };

    useEffect(() => {
        if (isOnline) {
            sync();
        }
    }, [isOnline]);

    useInterval(() => {
        AtticusClient.Ping();
    }, AUTH_STATUS_CHECK_INTERVAL);

    if (!token) {
        history.push("/auth/sign-in");
    }

    return (
        <>
            {pathname.indexOf("profile") == -1 && <Header />}
            <div style={{ height: "calc(100vh-60px)" }}>
                {children}
            </div>
            <PDFExporter />
            <Footer />
            <Modal
                visible={visible}
                // onCancel={() => toggle(false)}
                footer={null}
                width={300}
                title={<h2 className="section-heading">Syncing updates</h2>}
                bodyStyle={{
                    backgroundColor: "white"
                }}
                 closeIcon={null}
                 closable={false}
            >
                <div style={{
                    maxWidth: 300,
                    width: 300,
                    margin: "auto",
                    alignItems: "center"
                }}>
                        <div className="sync-pop-up">
                            <LoadingOutlined style={{ fontSize: 60 }} />
                        </div>
                </div>
            </Modal>
        </>
    );
};

const Footer = () => <style>{unionFonts.reduce((styles, css) => styles + css.css, "\n")}</style>;

const LayoutWithAuthHOC = observer(LayoutWithAuth);

export default LayoutWithAuthHOC;
