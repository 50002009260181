import React, { FunctionComponent } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Col, Button, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import {useOnlineStatus} from "../../../utils/isOffline";
import { ProfileLeftMenuProps } from "../../../types/profile";

const ProfileLeftMenu: FunctionComponent<ProfileLeftMenuProps> = ({ myprofile }: ProfileLeftMenuProps) => {
    
    const history = useHistory();
    const { pathname } = useLocation();
    const isOnline = useOnlineStatus();

    return (
        <Col span={6} pull={18}>
            <div className="profile__image--wrapper">
                <div className="profile__image--wrapper--top">
                    <Avatar 
                        size="large" 
                        icon={<UserOutlined style={{ fontSize: 50 }} />} 
                        src={myprofile.profilePictureURL && myprofile.profilePictureURL} 
                        style={{ width: 80, height: 80, display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 10, backgroundColor: "#ccc" }} 
                    />
                    <h2>{`${myprofile.firstName} ${myprofile.lastName}`}</h2>
                    <span>{myprofile.email}</span>
                </div>
            </div>
            <div className="profile__left--menu--wrapper">
                <Link to="/profile/my-profile" style={{
                    color: `${pathname.indexOf("my-profile") > -1 ? "#3568ba" : "#171d21"}`
                }}>Profile</Link>
                <Link to="/profile/change-password" style={{
                    color: `${pathname.indexOf("change-password") > -1 ? "#3568ba" : "#171d21"}`
                }}>Password</Link>
                {isOnline ? (
                  <Button className="btn-a" type="primary" onClick={() => history.push("/auth/sign-out")}>Log Out</Button>
                ): (
                  <Button disabled className="btn-a" type="primary" >Logout (offline)</Button>
                )}
            </div>
        </Col>
    );
};

export default ProfileLeftMenu;