import React, { ReactNode } from "react";
import { DeserializeHtml, SlatePlugin } from "@udecode/slate-plugins-core";
import { getRenderLeaf, getLeafDeserializer, Deserialize, getOnHotkeyToggleMark, setDefaults } from "@udecode/slate-plugins";
import { MonospacePluginOptions, MonospaceLeafOptions, MonospaceDeserializeOptions, MonospaceRenderLeafOptions } from "./types";
import { DEFAULTS_MONOSPACE } from "./defaults";

export const renderLeafMonospace = (options?: MonospaceRenderLeafOptions) => {
  const keyOptions = setDefaults(options, DEFAULTS_MONOSPACE)["monospace"];
  return getRenderLeaf(keyOptions);
};

export const deserializeSmallCap = (
  options: MonospaceDeserializeOptions
): DeserializeHtml => {
  const { monospace } = setDefaults(options, DEFAULTS_MONOSPACE);
  return {
    leaf: getLeafDeserializer({
      type: monospace.type,
      rules: [
        { nodeNames: ["monospace"] },
        {
          style: {
            fontFamily: "monospace"
          },
        },
      ],
      ...options?.monospace?.deserialize,
    }),
  };
};

export const MonospacePlugin = (options: MonospacePluginOptions): SlatePlugin => {
  return {
    renderLeaf: renderLeafMonospace(options),
    deserialize: deserializeSmallCap(options),
    onKeyDown: getOnHotkeyToggleMark({ type: "monospace", hotkey: "cmd+shift+s" })
  };
};



export const MonospaceComponent = (props: any) => {
  return (
    <span {...props.attributes}>{props.children}</span>
  );
};