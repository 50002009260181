
import React, { FunctionComponent } from "react";
import { Draggable } from "react-beautiful-dnd";
import { CloseOutlined, MoreHoriz } from "@material-ui/icons";
import { Menu, Dropdown, Collapse, Button, Modal, Divider, message } from "antd";

//store
import useRootStore from "../../store/useRootStore";

import { useOnlineStatus } from "../../utils/isOffline";

import { CheckOutlined, WarningOutlined } from "@ant-design/icons";

// Defaults
import { sectionTypes, getLabel, isMergable } from "./defaults";

// types
import { ChapterType, ChapterMeta } from "../../types/chapter";


const { confirm } = Modal;
const { SubMenu } = Menu;

type SectionType = "frontMatter" | "body" | "backMatter";

interface ChapterTemplateBlockItemProps {
    chapter: IChapterStore.IChapterTemplateBase;
    index: number;
    beginOn: boolean;
    isBody?: boolean;
    number?: number;
    section: SectionType;
    active?: boolean;
    setChapter: (chapterId: string) => void;
    deleteChapter: (chapterId: string) => void;
    mergeChapter?: (section: SectionType, chapterId: string) => void;
    updateChapterMeta: (chapter: IChapterStore.ChapterMeta) => void;
}

const specialChapterTypes = ["toc"];
const nonConvertableChapterTypes = ["toc", "title", "image"];


const ChapterTemplateBlockItem: FunctionComponent<ChapterTemplateBlockItemProps> = (props: ChapterTemplateBlockItemProps) => {
    const {
        chapter,
        index,
        beginOn,
        isBody,
        number,
        section,
        active,
        setChapter,
        deleteChapter,
        mergeChapter,
        updateChapterMeta,
    } = props;

    const isOnline = useOnlineStatus();
    // const { errorChapterId, getErrorChapter, failedBookLoading } = useRootStore().bookStore;
    const { storeChapterTemplate } = useRootStore().chapterStore;
    const { saveChapterMetaUpdates } = useRootStore().bookStore;
    const [error] = React.useState(false);

    const numberSpan = isBody && number ? (<span className="number">{number}.</span>) : null;

    const draggable = specialChapterTypes.indexOf(chapter.type) === -1;
    const deletable = specialChapterTypes.indexOf(chapter.type) === -1;
    const moreOptions = nonConvertableChapterTypes.indexOf(chapter.type) === -1;

    const onDelete = () => {
        deleteChapter(chapter._id);
    };

    const frontMatterCovertOptions = sectionTypes.frontMatter.filter((type) => isMergable(type));
    const bodyCovertOptions = sectionTypes.body.filter((type) => isMergable(type));
    const backMatterCovertOptions = sectionTypes.backMatter.filter((type) => isMergable(type));

    const onConvert = async (type: string) => {
        // updateChapterMeta({
        // 	...chapter,
        // 	type: type as ChapterType
        // });
    };

    const toggleNumbered = () => {
        // updateChapterMeta({
        // 	...chapter,
        // 	numbered: chapter.numbered || chapter.numbered === undefined ? false : true,
        // });
    };

    const updateIncludeIn = (includeIn: "all" | "ebook" | "print" | "none") => {
        // updateChapterMeta({
        // 	...chapter,
        // 	includeIn,
        // });
    };

    const updateStartOn = (startOn: "right" | "left" | "any") => {
        // updateChapterMeta({
        // 	...chapter,
        // 	startOn,
        // });
    };


    return (
        <a
            onClick={() => {
                setChapter(chapter._id);
            }}
        >
            <Draggable
                draggableId={chapter._id}
                index={index}
                key={chapter._id}
                isDragDisabled={!draggable}
            >
                {(provided, snapshot) => (
                    <div
                        className={`chapter-block-item${snapshot.isDragging ? " hover" : ""}${active ? " active" : ""} ${chapter.includeIn === "none" ? " chapter-hidden" : ""}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                            ...provided.draggableProps.style,
                        }}
                    >
                        <div className="text-area">
                            {chapter.title ? (
                                <>
                                    <div>{numberSpan}</div>
                                    <div>{chapter.title}</div>
                                    {error ? (
                                        <div style={{ paddingLeft: 10 }}>
                                            <WarningOutlined style={{ color: "red" }} />
                                        </div>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    <div>{numberSpan}</div>
                                    <div>{numberSpan ? null : chapter.type === "chapter" ? "Untitled" : getLabel(chapter.type)}</div>
                                    {error ? (
                                        <div style={{ paddingLeft: 10 }}>
                                            <WarningOutlined style={{ color: "red" }} />
                                        </div>
                                    ) : null}
                                </>
                            )}
                        </div>
                        <div className="action-group">
                            {/*moreOptions ? (*/}
                            <Dropdown
                                trigger={["click"]}
                                overlay={
                                    <Menu>
                                        {mergeChapter ? (
                                            <Menu.Item onClick={() => {
                                                mergeChapter(section, chapter._id);
                                            }}>Merge with next chapter</Menu.Item>
                                        ) : null}
                                        {moreOptions ? (
                                            <SubMenu title="Convert To">
                                                {frontMatterCovertOptions.map((elem, i) => (
                                                    <Menu.Item key={`frontMatter-${i}`} onClick={() => { onConvert(elem); }}>{getLabel(elem)}</Menu.Item>
                                                ))}
                                                <Divider style={{ margin: 0, padding: 0 }} />
                                                {bodyCovertOptions.map((elem, i) => (
                                                    <Menu.Item key={`body-${i}`} onClick={() => { onConvert(elem); }}>{getLabel(elem)}</Menu.Item>
                                                ))}
                                                <Divider style={{ margin: 0, padding: 0 }} />
                                                {backMatterCovertOptions.map((elem, i) => (
                                                    <Menu.Item key={`backMatter-${i}`} onClick={() => { onConvert(elem); }}>{getLabel(elem)}</Menu.Item>
                                                ))}
                                            </SubMenu>
                                        ) : null}
                                        {chapter.type !== "chapter" && chapter.type !== "uncategorized" ? (
                                            <SubMenu title="Include In">
                                                <Menu.Item
                                                    icon={chapter.includeIn === undefined || chapter.includeIn === "all" ? <CheckOutlined /> : null}
                                                    onClick={() => { updateIncludeIn("all"); }}
                                                >All</Menu.Item>
                                                <Divider style={{ margin: 0, padding: 0 }} />
                                                <Menu.Item
                                                    icon={chapter.includeIn === "ebook" ? <CheckOutlined /> : null}
                                                    onClick={() => { updateIncludeIn("ebook"); }}
                                                >E-Book Only</Menu.Item>
                                                <Menu.Item
                                                    icon={chapter.includeIn === "print" ? <CheckOutlined /> : null}
                                                    onClick={() => { updateIncludeIn("print"); }}
                                                >Print Only</Menu.Item>
                                                <Divider style={{ margin: 0, padding: 0 }} />
                                                <Menu.Item
                                                    icon={chapter.includeIn === "none" ? <CheckOutlined /> : null}
                                                    onClick={() => { updateIncludeIn("none"); }}
                                                >None</Menu.Item>
                                            </SubMenu>
                                        ) : null}
                                        {beginOn ? (
                                            <SubMenu title="Begin On">
                                                <Menu.Item
                                                    icon={chapter.startOn === undefined || chapter.startOn === "any" ? <CheckOutlined /> : null}
                                                    onClick={() => { updateStartOn("any"); }}
                                                >Either Side</Menu.Item>
                                                <Menu.Item
                                                    icon={chapter.startOn === "right" ? <CheckOutlined /> : null}
                                                    onClick={() => { updateStartOn("right"); }}
                                                >Right-side</Menu.Item>
                                                <Menu.Item
                                                    icon={chapter.startOn === "left" ? <CheckOutlined /> : null}
                                                    onClick={() => { updateStartOn("left"); }}
                                                >Left-side</Menu.Item>
                                            </SubMenu>
                                        ) : null}
                                        {chapter.type === "chapter" || chapter.type === "uncategorized" || chapter.type === "custom" || chapter.type === undefined ? (
                                            <Menu.Item
                                                icon={chapter.numbered === undefined || chapter.numbered === true ? <CheckOutlined /> : null}
                                                onClick={() => {
                                                    toggleNumbered();
                                                }}
                                            >Numbered</Menu.Item>
                                        ) : null}
                                        {/* <Menu.Item onClick={async () => {
                                            const templateId: string = await storeChapterTemplate(chapter._id, chapter.title, section);
                                            // saveChapterMetaUpdates({
                                            // 	...chapter.children,
                                            // 	templateId: templateId
                                            // });
                                            message.success("Template created successfully", 4);
                                            //window.location.reload();	
                                        }}>Save as a template</Menu.Item> */}
                                    </Menu>
                                }
                            >
                                <Button type="text" shape="round" size="middle" className="action-btn"><MoreHoriz className="action-icon" /></Button>
                            </Dropdown>
                            {/*) : null}*/}
                            {/* {deletable && isOnline ? (
                                <Button type="text" shape="round" size="large" className="action-btn" onMouseDown={() => {
                                    confirm({
                                        icon: null,
                                        title: <h2 className="section-heading">Delete Chapter</h2>,
                                        content: `Are you sure you want to delete "${chapter.title}"`,
                                        centered: true,
                                        okType: "danger",
                                        okText: "Delete",
                                        okButtonProps: {
                                            type: "primary",
                                            danger: true,
                                            style: {
                                                flex: 1,
                                            },
                                        },
                                        onOk: () => {
                                            onDelete();
                                        },
                                        cancelButtonProps: {
                                            className: "btn-a",
                                            style: {
                                                flex: 1,
                                            },
                                        },
                                        cancelText: "Cancel",
                                    });
                                }}>
                                    <CloseOutlined className="action-icon" />
                                </Button>
                            ) : null} */}
                            {/* {draggable ? (
                  <DragIndicator 
                      className="action-icon" 
                      style={{
                      cursor: "grab",
                      }}
                  />
              ) : null} */}
                        </div>
                    </div>
                )}
            </Draggable>
        </a>
    );
};

export default ChapterTemplateBlockItem;