import React, { useState, FunctionComponent } from "react";
import { Modal, Button, Radio, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ImageGallery from "./Forms/ImageGallery";
// helpers
import { getOrnamentalBreaks } from "./helpers/ornamentalBreaks";
import FileUpload from "./Forms/FileUpload";
import BookBrush from "./Forms/BookBrush";

const ob = getOrnamentalBreaks(36);

interface OrnamentalBreakLibraryProps {
  selected: string;
  onUpdate: (url: string) => void;
  setCustom?: (url: string) => void;
}

const OrnamentalBreakLibrary: FunctionComponent<OrnamentalBreakLibraryProps> = (props: OrnamentalBreakLibraryProps) => {
  const { selected, onUpdate, setCustom } = props;
  const [modal, toggleModal] = useState(false);
  const [selectedURL, setSelectedURL] = useState<string>(selected);
  const [uploading, setUploading] = useState<boolean>(false);

  const getIGURL = (link) => {
    onUpdate(link);
  };

  return (
    <>
      <Row gutter={10}>
        <Col>
          <ImageGallery changeURL={getIGURL}/>
        </Col>
        <Col>
        <Button className="btn-a" type="primary" onClick={() => toggleModal(!modal)}>
          Atticus Images
				</Button>
        </Col>
        <Col>
        <FileUpload
          fileType="image/*"
          shape='none'
          folder='custom-ornamental-breaks'
          onFileUpload={(fileUrl) => {
            onUpdate(fileUrl || "");
          }}
          onFileUploadStart={() => setUploading(true)}
          onFileUploadEnd={() => setUploading(false)}

        >
          {!uploading ? (
            <Button
              type="primary"
              icon={<PlusOutlined />}

            >Custom Image</Button>
          ) : null}
        </FileUpload>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px"}} gutter={10}>
        <Col>
          <BookBrush/>
        </Col>
      </Row>
      <div style={{ height: 50 }}></div>
      <Modal
        visible={modal}
        okButtonProps={{
          className: "b-btn"
        }}
        cancelButtonProps={{
          className: "a-btn"
        }}
        title="Ornamental Break Library"
        onOk={() => {
          toggleModal(false);
          onUpdate(selectedURL);
        }}
        onCancel={() => toggleModal(false)}
        okText="Select"
        cancelText="Cancel"
      >
        <div>
          <div className="ob-btns">
            <Radio.Group
              value={selectedURL}
              onChange={(e) => {
                setSelectedURL(e.target.value);
              }}
            >
              {ob.map((o, i) => (
                <Radio.Button key={i} value={o}>
                  <img src={o} />
                </Radio.Button>
              ))}
            </Radio.Group>
          </div>
          {/* 
                  <Tabs defaultActiveKey="1" className="a-tabs">
                      {['Classic', 'Romance', 'Science', 'Minimal', 'Flourish', 'Shapes'].map((v, i) => (
                          <TabPane tab={v} key={`ob_${i}`}>
                              
                          </TabPane>
                      ))}
                  </Tabs>
                */}
        </div>
      </Modal>
    </>
  );
};

export default OrnamentalBreakLibrary;
