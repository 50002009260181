import React, { ReactNode, FunctionComponent, useRef, useState, useEffect } from "react";

interface FullScreenProps{
  children: ReactNode;
  className?: string;
}

export const FullScreen: FunctionComponent<FullScreenProps> = (props: FullScreenProps) => {
	const { children, className } = props;

	const containerRef = useRef<HTMLDivElement>(null);
	const [ topPosition, setTopPosition ] = useState(80);

	useEffect(() => {
		if (containerRef !== null && containerRef.current !== null) {
			const topPosition = containerRef.current.getBoundingClientRect().top;
			setTopPosition(topPosition);
		}
	}, [ containerRef.current ]);
  
	return (
		<div
			ref={containerRef}
			className={`${className} fullscreen__layer`}
			style={{ height: `calc(100vh - ${topPosition}px)` }}>
			{children}
		</div>
	);
};