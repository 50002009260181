import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

import { Divider, Row, Col } from "antd";

// assets
import { EditIcon } from "../../utils/icon";

// stores
import useRootStore from "../../store/useRootStore";

interface BookTitleCardProps{
  chapterId: string | null;
}

export const BookTitleCard = observer((props: BookTitleCardProps) => {
	const { book, bookEdit, setBookEdit } = useRootStore().bookStore;
	const { chapterTemplateView,chapTemplate } = useRootStore().appStore;

	if (!book) return null;
  
	return(
		<a onClick={() => setBookEdit(true)}>
			<div className={"book-title-card"}>
				<Row className={`book-title-card-inner${bookEdit ? " active": ""}`}>
					<Col flex={1}>
						<h3 className="book-title" >{book.title}</h3>
						<h5 className="book-subtitle">{book.author.join(", ")}</h5>
					</Col>
					<Col style={{ display: "flex", alignItems: "center" }}>
						<EditIcon />
					</Col>
				</Row>
				<Divider />
			</div>
		</a>
	);
});