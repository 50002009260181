export const MergableChapters = [
	"uncategorized", "chapter", "custom", "about-author", "epigraph", "foreword", 
	"introduction", "preface", "prologue", "epilogue", 
	"afterword", "acknowledgments", "also-by", "dedication", "blurbs"
];

export const ChapterTypeLabels = {
	"title": "Title",
	"chapter": "Chapter",
  "custom": "Custom",
	"about-author": "About Author",
	"epigraph": "Epigraph",
	"foreword": "Foreword",
	"introduction": "Introduction",
	"preface": "Preface",
	"prologue": "Prologue",
	"epilogue": "Epilogue",
	"afterword": "Afterword",
	"acknowledgments": "Acknowledgements",
	"also-by": "Also By",
	"dedication": "Dedication",
	"blurbs": "Blurbs",
	"image": "Full Page Image",
};

export const sectionTypes = {
	frontMatter: ["title", "blurbs", "dedication", "epigraph", "foreword", "introduction", "preface", "prologue"],
	body: ["chapter", "custom", "image"],
	backMatter: ["epilogue", "afterword", "acknowledgments", "about-author", "also-by"],
};

export const getLabel = (type: string): string => {
	return ChapterTypeLabels[type] ? ChapterTypeLabels[type] : "";
};

export const isMergable = (type?: string): boolean => {
	if (type === undefined) return true;
	return MergableChapters.indexOf(type) > -1;
};
