import React, { FunctionComponent } from "react";
import { observer } from "mobx-react";
import { useParams, useLocation } from "react-router-dom";

// db
import { db } from "../db/bookDb";

// stores
import useRootStore from "../store/useRootStore";
import querystring from "querystring";

// components
import { Backup, OfflineBackup } from "../components/Backup";


const SignOut: FunctionComponent = () => {
	const { updateToken } = useRootStore().authStore;
    const {search} = useLocation();
	const queryObj = querystring.parse(search.replace("?", ""));
	const expired = queryObj.expired ? true : false;

	const clearDatabase = async () => {
		await db.delete();
		await updateToken(null);
		window.location.replace("/");
	};

	return expired ? (
        <OfflineBackup 
            onComplete={() => {
                clearDatabase();
            }}
        />
    ) : (
        <Backup 
            download
            onComplete={() => {
                clearDatabase();
            }}
        />
	);
};

export default observer(SignOut);