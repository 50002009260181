import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { find } from "lodash";

import BuildCustomTheme from "../Shared/Forms/BuildCustomTheme";

import useRootStore from "../../store/useRootStore";

const ThemeBody = observer(() => {
	const { theme, theme_styles, userThemes, setTheme, editTheme, getBookTheme, setStyle } = useRootStore().themeStore;
	const { book, customThemeBuilderView } = useRootStore().bookStore;
	const themeRef = useRef(theme?.themeId);

	useEffect(() => () => {
		if (theme && theme._id)
			themeRef.current = theme._id;
	}, [theme]);

	useEffect(() => {
		getBookTheme(book._id);
		return () => {
      const th = find([...theme_styles, ...userThemes], ["_id", themeRef.current]);
			setStyle(find([...theme_styles, ...userThemes], ["_id", themeRef.current]) || theme_styles[0]);
		};
	}, []);

  // use this as a way to manually set theme configs after saving
  const onSave = () => {
    if(theme){
      setTheme(
        {
          ...theme, 
          titleCard : {
            ...theme?.titleCard,
            image: true
          }
        }
      );
    }
  };
	return (theme && theme_styles) &&
		(
			<BuildCustomTheme onSave={onSave} edit={editTheme && customThemeBuilderView === "customThemeStep2" ? editTheme : undefined} />
		);
});

export default ThemeBody;
