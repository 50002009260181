import { toWords } from "number-to-words";
import { SlateImage } from "../../types/slate";

import { TOCSummaryItem } from "../Shared/helpers/toc";
import { getSocialMediaIcons } from "../Previewer/print/configs/social-media-icons";

interface HeaderProps {
	chapter: IChapterStore.Chapter,
	config: IThemeStore.ThemeConfig,
	number?: number,
	print?: boolean,
	images?: IThemeStore.CustomImages,
	customThemeBuilderView?: string
}

const DROP_CAP_START_SYMBOLS = ["'", "\"", "“", "‘", " "];

const isParaAfterSubheading = (
	previousType: ISlateStore.SlateBaseBlockTypes,
	currentType: ISlateStore.SlateBaseBlockTypes
) => {
	if (previousType === "h2") {
		if (currentType === "p") {
			return true;
		}
	}
	return false;
};

let previousChildType: ISlateStore.SlateBaseBlockTypes;

const blockTypeToTagType = (node: ISlateStore.SlateBaseParentBlockNode, end = false) => {
	const { type } = node;

	if (type === "h2" && !end) {
		previousChildType = type;
	}

	const _previousChildType = previousChildType;
	previousChildType = type;

	const isTrue = isParaAfterSubheading(_previousChildType, type);
	if (isTrue) return !end ? "<p class=text-after-subheading>" : "</p>";

	if (type === "p") return !end ? "<p>" : "</p>";
	if (type === "h2") return !end ? "<h2>" : "</h2>";
	if (type === "align_left") return !end ? "<div class=\"align-left\">" : "</div>";
	if (type === "align_center") return !end ? "<div class=\"align-center\">" : "</div>";
	if (type === "align_right") return !end ? "<div class=\"align-right\">" : "</div>";
	if (type === "blockquote") return !end ? "<blockquote>" : (node as ISlateStore.SlateBlockquote).quotee ? "<p class='quotee'>" + (node as ISlateStore.SlateBlockquote).quotee + "</p></blockquote>" : "</blockquote>";
	if (type === "code_block") return !end ? "<div class=\"verse\">" : "</div>";
	if (type === "ornamental-break") return !end ? "<span class=\"ornamental-break\">***" : "</span>";
	if (type === "h6") return !end ? "<h6>" : "</h6>";
	if (type === "h5") return !end ? "<h5>" : "</h5>";
	if (type === "h4") return !end ? "<h4>" : "</h4>";
	if (type === "h3") return !end ? "<h3>" : "</h3>";
	if (type === "h1") return !end ? "<h1>" : "</h1>";

	return "";
};

const doInlineTagQues = (node: ISlateStore.SlateTextNode) => {
	const tagQueue: string[] = [];
	const tagStack: string[] = [];

	if (node.superscript) {
		tagQueue.push("<sup>");
		tagStack.push("</sup>");
	}

	if (node.subscript) {
		tagQueue.push("<sub>");
		tagStack.push("</sub>");
	}

	if (node.bold) {
		tagQueue.push("<b>");
		tagStack.push("</b>");
	}

	if (node.italic) {
		tagQueue.push("<em>");
		tagStack.push("</em>");
	}

	if (node.underline) {
		tagQueue.push("<u>");
		tagStack.push("</u>");
	}

	if (node.strikethrough) {
		tagQueue.push("<strike>");
		tagStack.push("</strike>");
	}

	if (node.code) {
		tagQueue.push("<code>");
		tagStack.push("</code>");
	}

	if (node.smallcaps) {
		tagQueue.push("<span class=\"smallcaps\">");
		tagStack.push("</span>");
	}

	if (node.monospace) {
		tagQueue.push("<span class=\"monospace\">");
		tagStack.push("</span>");
	}

	if (node.sansserif) {
		tagQueue.push("<span class=\"sansserif\">");
		tagStack.push("</span>");
	}
	return {
		tagQueue,
		tagStack
	};
};

const renderTextNode = (textNode: ISlateStore.SlateTextNode): string => {
	const { tagQueue, tagStack } = doInlineTagQues(textNode);
	const parsedText = textNode.text ? textNode.text : "";

	return tagQueue.join("") + parsedText + tagStack.reverse().join("");
};


const renderFirstSentence = (textNode: ISlateStore.SlateTextNode): string => {
	const wrappers: string[] = [];
	const { tagQueue, tagStack } = doInlineTagQues(textNode);
	const parsedText = textNode.text ? textNode.text : "";

	if (parsedText) {
		let dropcap = parsedText[0].toLocaleUpperCase();
		let sentence = parsedText;

		if (dropcap) {
			sentence = parsedText.substring(1);
			let charIndex = 0;

			while (DROP_CAP_START_SYMBOLS.includes(dropcap[charIndex]) && parsedText[charIndex + 1]) {
				dropcap += parsedText[charIndex + 1];
				sentence = sentence.substring(1);
				charIndex = charIndex + 1;
			}

			wrappers.push(`<span aria-hidden="true" class="dropcap">${dropcap}</span>`);
		}

		const words = sentence.split(" ");
		const parsedWords = words.map((w, i) => i < 4 ? `<span aria-hidden="true" class="lead_word">${w}</span>` : w).reduce((str, a) => str = str + " " + a);

		wrappers.push(`${parsedWords}`);
	}

	return tagQueue.join("") + wrappers.join("") + tagStack.reverse().join("");
};


const renderImage = (imageNode: ISlateStore.SlateImage): string => {

	const getSizes = () => {
		if (typeof imageNode.size === "string") {
			if (imageNode.size === "small") return "30";
			if (imageNode.size === "medium") return "45";
			if (imageNode.size === "large") return "100";
		}
		return `${imageNode.size}`;
	};

	const getImgStyle = () => {
		if (imageNode.link || imageNode.wrap) return "width:100%";
		return `width: ${getSizes()}%`;
	};

	const getImageWrapperStyles = () => {
		if (imageNode.wrap) return `float:${imageNode.flow}; width: calc(${getSizes()}% - 0.8rem); display:unset; margin:0.5rem 0 0 0; ${imageNode.flow === "left" ? "margin-right:0.8rem" : ""};${imageNode.flow === "right" ? "margin-left:0.8rem" : ""}`;
		return "margin:0 0 0.5rem 0;";
	};

	const getAnchorStyles = () => {
		if (!imageNode.wrap) return `width: ${getSizes()}%;`;
		if (imageNode.wrap) return "display:block";
		return "";
	};

	let parsedNode = `<div class="image image-flow-${imageNode.flow}" style="${getImageWrapperStyles()}">`;

	if (imageNode.link) {
		if (imageNode.link.type === "web-link") {
			parsedNode += `<a target="_blank" style="${getAnchorStyles()}" href="${imageNode.link.webLink}">`;
		}
		if (imageNode.link.type === "internal-link") {
			parsedNode += `<a style="${getAnchorStyles()}" href="#chapter=${imageNode.link.internalLink?.chapterId}">`;
		}
	}

	parsedNode += `<img src=${imageNode.url} style="display:inline-block;${getImgStyle()}" alt="${imageNode.caption}" />`;
	if (imageNode.caption) parsedNode += `<div class="caption">${imageNode.caption}</div>`;
	parsedNode += "</div>";

	if (imageNode.link) parsedNode += "</a>";

	return parsedNode;
};

const renderProfileLinks = (imageNode: ISlateStore.SlateProfileLink): string => {
	let smIconSize;
	let parsedNode = "<div class='social-profile social-profile-flow-middle profile-links'>";

	const imgIt = imageNode.url.map((urlItem) => {
		const { name, username, iconSize } = urlItem;

		if (iconSize === "medium") {
			smIconSize = name + "-Medium";
		} else if (iconSize === "small") {
			smIconSize = name + "-Small";
		} else {
			smIconSize = name + "-Large";
		}

		const getSM = getSocialMediaIcons(smIconSize);
		const getSMName = getSM?.smLink;
		const smID = getSM?.id;

		if (iconSize === "small") {
			parsedNode += `<a target="_blank" href="${username}"><img src=${getSMName} style="display:inline-block;"  class="social-profile-icon-small"/></a>`;

		} else if (iconSize === "medium") {
			parsedNode += `<a target="_blank" href="${username}"><img src=${getSMName} style="display:inline-block;" class="social-profile-icon-medium" /></a>`;
		} else {
			parsedNode += `<a target="_blank" href="${username}"><img src=${getSMName} style="display:inline-block;" class="social-profile-icon-large" /></a>`;
		}
	});
	parsedNode += "</div>";
	return parsedNode;
};

const isAllSpecialCharacters = (text) => {
	for (const c of text) {
		if (!DROP_CAP_START_SYMBOLS.includes(c)) return false;
	}
	return true;
};

const renderBaseParentBlock = (block: ISlateStore.SlateBaseParentBlockNode, endnotes: ISlateStore.SlateEndnote[], wrap?: boolean): string => {
	let parsedNode = blockTypeToTagType(block);
	let dropSafe = false; // override this with true for special cases in drop cap rendering method

	for (let n = 0; n < block.children.length; n += 1) {
		const node = block.children[n];
		// parse link node
		if ((node as ISlateStore.SlateLink).type === "a") {
			const linkNode = node as ISlateStore.SlateLink;
			if (linkNode.url?.startsWith("#chapter")) {
				parsedNode += `<a href="${linkNode.url}">`;
			} else {
				parsedNode += `<a target='_blank' href="${linkNode.url}">`;
			}
			for (const linkChild of linkNode.children) {
				parsedNode += renderTextNode(linkChild);
			}
			parsedNode += "</a>";
			continue;
		}

		// parse endnote node
		if ((node as ISlateStore.SlateEndnote).type === "endnote") {
			endnotes.push(node as ISlateStore.SlateEndnote);
			parsedNode += `<a href="#endnote-${node.id}-text" class="endnote-link" id="#endnote-${node.id}" role="doc-noteref" epub:type="noteref"><sup>${endnotes.length}</sup></a>`;
		}

		// parse a parent node
		if (["p", "h2", "blockquote", "code_block", "ornamental-break", "h6", "h5", "h4", "h3", "h1"].indexOf((node as ISlateStore.SlateBaseParentBlockNode).type) > -1) {
			parsedNode += renderBaseParentBlock(node as ISlateStore.SlateBaseParentBlockNode, endnotes, wrap);
		}

		if (["align_left", "align_center", "align_right"].indexOf((node as ISlateStore.SlateBaseParentBlockNode).type) > -1) {
			parsedNode += renderBaseParentBlock(node as ISlateStore.SlateBaseParentBlockNode, endnotes, wrap);
		}

		// parse a list
		if (["ol", "ul"].indexOf((node as ISlateStore.SlateListBlock).type) > -1) {
			parsedNode += "<div class=\"list-parent\">";
			parsedNode += parseList((node as ISlateStore.SlateListBlock), endnotes);
			parsedNode += "</div>";
		}


		if ((node as SlateImage).type === "image") {
			const imageHtml = renderImage(node as ISlateStore.SlateImage);
			parsedNode += imageHtml;
		}

		// parse profile link
		if ((node as ISlateStore.SlateProfileLink).type === "profile") {
			const imageHtml = renderProfileLinks(node as ISlateStore.SlateProfileLink);
			parsedNode += imageHtml;
		}

		// finally parse text node
		const textNode = node as ISlateStore.SlateTextNode;
		let dropcapline = true;
		//check for line breaks
		const lines = textNode.text ? textNode.text.split("\n") : []; //.replace(/(?:\r\n|\r|\n)/g, "<br>") : ""};
		lines.forEach((t, i) => {
			const txt = {
				...textNode,
				text: t.replaceAll("<", "&lt;").replaceAll(">", "&gt;"),
			};
			if (((n === 0 && wrap) || dropSafe) && dropcapline) {
				// handle quotes in single text by doing conditional rendering
				if (isAllSpecialCharacters(txt.text))
					dropSafe = true;
				else
					dropSafe = false;

				dropcapline = false;
				//render dropcap and
				parsedNode += renderFirstSentence(txt);
			} else {
				parsedNode += renderTextNode(txt);
			}
			//affirm that line break exist
			if (lines.length > 1 && textNode.text && lines.length !== (i + 1)) {
				parsedNode += "<br>";
			}
		});
	}

	parsedNode += blockTypeToTagType(block, true);

	return parsedNode;
};


const parseList = (listBlock: ISlateStore.SlateListBlock, endnotes: ISlateStore.SlateEndnote[], olStyleLevel?: number): string => {
	const olStyles = ["list-style-type: decimal;", "list-style-type: lower-alpha;", "list-style-type: lower-roman;"];  /* 1, a, i */
	let parsedHtml = "";

	if (listBlock.type == "ol") {
		/* Calculate the olStyleLevel */
		if (typeof olStyleLevel == "number") {
			if (olStyleLevel === olStyles.length - 1) {
				olStyleLevel = 0; /* Jump back to the first olStyleLevel */
			} else {
				olStyleLevel++; /* Increase the olStyleLevel */
			}
		} else {
			olStyleLevel = 0; /* Set olStyleLevel for the first level item */
		}

		const orderedListStyle = `margin-left:10px; ${olStyles[olStyleLevel]}`;
		parsedHtml += `<ol style="${orderedListStyle}">`;
	}

	if (listBlock.type == "ul") parsedHtml += "<ul>";

	for (const listChild of listBlock.children) {
		if (listChild.type === "li") {
			parsedHtml += "<li>";
			for (const l2Child of listChild.children) {
				if (l2Child.type === "ol") {
					parsedHtml += parseList(l2Child, endnotes, olStyleLevel);
				} else if (l2Child.type === "ul") {
					parsedHtml += parseList(l2Child, endnotes);
				} else {
					parsedHtml += renderBaseParentBlock(l2Child as ISlateStore.SlateBaseParentBlockNode, endnotes, false);
				}
			}
			parsedHtml += "</li>";
		}
	}

	if (listBlock.type == "ol") parsedHtml += "</ol>";
	if (listBlock.type == "ul") parsedHtml += "</ul>";
	return parsedHtml;
};

export const header = {
	title: (t: string) => `<div class="chapter-title"><h2>${t}</h2></div>`,
	subtitle: (t: string) => `<div class="chapter-subtitle"><h3>${t}</h3></div>`,
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	number: (type: IThemeStore.ThemeConfig["chapterNumbering"], number?: number) => {
		let html = "";
		if (number) {
			switch (type) {
				case "number":
					html = `<span>${number}</span>`;
					break;
				case "word":
					html = `<span>${toWords(number)}</span>`;
					break;
				case "chapter+number":
					html = `<span>Chapter ${number}</span>`;
					break;
				case "chapter+word":
					html = `<span>Chapter ${toWords(number)}</span>`;
			}
		}
		return `<div class="chapter-number">${html}</div>`;
	},
	imageEl: (url: string) => `<div class="chp_img chp_clr_all"><img src="${url}" /></div>`,
	bgEl: (url: string, type: "margins" | "full-bleed") => `<div class="chp_bg ${type === "full-bleed" ? "chp_fb_bg" : ""} chp_clr_all" style="background-image: url(${url})"></div>`,
};

const parseChapterHeader = (props: HeaderProps) => {
	const { config, chapter, images, print, number, customThemeBuilderView } = props;
	const { titleCard, chapterNumbering } = config;
	const isImg = Boolean(images && images.url && titleCard.image); //check if images exist/enabled

	let html = "";
	html += `<div class="chapter-title-card ${config.titleAlignment}">`;

	if (customThemeBuilderView === "customThemeStep2") {
		html += header.number(chapterNumbering, number);
		html += header.title(chapter.title || "Title");
		html += header.subtitle(chapter.subtitle || "Subtitle");
		if (images && images.url) {
			if (images.background)
				html += header.bgEl(images.url, images.printExtent || "margins");
			else
				html += header.imageEl(images.url);
		}
	} else {
		if (titleCard.chapterNumber && number)
			html += header.number(chapterNumbering, number);

		if (titleCard.title && chapter.title)
			html += header.title(chapter.title);

		if (titleCard.subtitle && chapter.subtitle)
			html += header.subtitle(chapter.subtitle);

		if (isImg && images) {
			if (images.background)
				html += header.bgEl(images.url, images.printExtent || "margins");
			else
				html += header.imageEl(images.url);
		}
	}
	return html += "</div>";
};

export const parseChapter = (chapter: IChapterStore.Chapter, config: IThemeStore.ThemeConfig, number?: number, print?: boolean, images?: IThemeStore.CustomImages, customThemeBuilderView?: string) => {
	let html = `<div class="${chapter.type}">`;

	if (chapter.type !== "custom") {
		//render chapter header
		html += parseChapterHeader({
			config,
			chapter,
			print,
			number,
			images,
			customThemeBuilderView
		});
	}
	html += "<div class=\"chapter-body\">";
	html += "<div class=\"wrapper\">";
	const endnotes: ISlateStore.SlateEndnote[] = [];

	let firstPara = true;

	for (const block of chapter.children) {

		//don't show drop cap for align_center or align_right
		if (block.type === "align_center" || block.type === "align_right" || block.type === "h2" || block.type === "blockquote" || block.type === "code_block"
			|| block.type === "h6" || block.type === "h5" || block.type === "h4" || block.type === "h3" || block.type === "h1") {
			html += renderBaseParentBlock(block as unknown as ISlateStore.SlateBaseParentBlockNode, endnotes, false);
		}

		//apply drop cap in the next paragraph if type is ornamental break
		if (block.type === "ornamental-break") {
			html += `<span class="ornamental-break">${config.ornamentalBreakImage ? `<img src="${config.ornamentalBreakImage}" >` : "***"}</span>`;
			if (config.beginFirstSentence === "break") {
				firstPara = true;
			}
		}

		// parse image node
		if (block.type === "image") {
			const imageHtml = renderImage(block as unknown as ISlateStore.SlateImage);
			html += imageHtml;
		}

		// parse profile links
		if (block.type === "profile") {
			const profileHtml = renderProfileLinks(block as unknown as ISlateStore.SlateProfileLink);
			html += profileHtml;
		}

		// parse list nodes
		if (block.type === "ul" || block.type === "ol") {
			html += "<div class=\"list-parent\">";
			html += parseList(block as unknown as ISlateStore.SlateListBlock, endnotes);
			html += "</div>";
		}
		//render blocks
		if (block.type === "p" || block.type === "align_left") {
			html += renderBaseParentBlock(block as unknown as ISlateStore.SlateBaseParentBlockNode, endnotes, firstPara);
			firstPara = false;
		}
	}

	html += "</div>";

	// render endnotes
	if (endnotes.length > 0 && config.ePubNotesMode !== "END_OF_BOOK") {
		html += renderEndnotes(endnotes);
	}

	html += "</div>";
	html += "</div>";
	return html;
};

export const parseTitleCard = (book: IBookStore.ExpandedBook): string => {
	const html = `
    <div class="title">
      <div class="title-card">
        <h1>${book.title}</h1>
        ${book.subtitle ? `<h3>${book.subtitle}</h3>` : ""}
        <h2>${book.author.join(", ")}</h2>
      </div>
      <div class="publisher-details">
        ${book.publisherLogoURL ? `<img class="publisher-logo" src="${book.publisherLogoURL}">` : ""}
        ${book.publisherName ? `<div>${book.publisherName}</div>` : ""}
      </div>
    </div>
  `;

	return html;
};

export const parseTOC = (tocSummary: TOCSummaryItem[], themeConfig: IThemeStore.ThemeConfig, custom_toc_title?: string | null, showTocSubtitle?: boolean | undefined, showTocSubheading?: boolean | undefined): string => {
	const html = `
  <div class="toc">
    <div class="chapter-title-card ${themeConfig.titleAlignment}">
      <h2>${custom_toc_title ? custom_toc_title : "Contents"}</h2>
    </div>
    <div class="chapter-body">
      <div class="wrapper">
        <div class="toc-list">
          ${tocSummary.map((item) => {
		return (
			`<div class="table-of-content">
                <span>
                  <span class="chapterNumber">${item.chapterNumber ? item.chapterNumber + ". " : ""}</span> ${item.title}

				  ${showTocSubtitle && item.subtitle ?
				`<br/><span class="toc-subtitle">${item.subtitle}</span>`
				: ""}

				${showTocSubheading && item.subheads && item.subheads.length > 0 ? item.subheads.map((head: any) => ` <br/> <span class="toc-subheading">${head.text}</span>`).join("") : ""}
                </span>
              </div>`
		);
	}).join("")}
        </div>
      </div>
    </div>
  `;
	return html;
};

export const parseFullPageImage = (chapter: IChapterStore.Chapter): string => {
	let html = "<div class=\"fullpage-image\">";
	if (chapter.type === "image" && chapter.fullpageImage && chapter.fullpageImage.imageUrl) {
		if (chapter.fullpageImage.verticalAlignment === "top") html += "<div class=\"image-full top\">";
		else if (chapter.fullpageImage.verticalAlignment === "bottom") html += "<div class=\"image-full bottom\">";
		else html += "<div class=\"image-full center\">";
		html += `<img src=${chapter.fullpageImage.imageUrl} />`;
		html += "</div>";
	}
	html += "</div>";
	return html;
};

export const parseFullBleedImage = (chapter: IChapterStore.Chapter): string => {
	let html = "<div class=\"full-bleed \">";
	if (chapter.type === "image" && chapter.fullpageImage && chapter.fullpageImage.imageUrl) {
		html += "<picture class=\"image-full-bleed\">";
		html += `<img src=${chapter.fullpageImage.imageUrl} />`;
		html += "</picture>";
	}
	html += "</div>";
	return html;
};

export function parseEndnotesChapter(chapter, endnotes, config): string {
	let html = `<div class="${chapter.type}">`;
	html += "<div class=\"chapter-body\">";
	html += "<div class=\"wrapper\">";
	html += renderEndnotes(endnotes, false);
	html += "</div>";
	html += "</div>";
	html += "</div>";
	return html;
}

function renderEndnotes(endnotes, separator = true) {
	let html = "<div class=\"endnotes\" epub:type=\"endnotes\">";
	html += separator ? "<div class=\"endnotes-separator\"></div>" : "";
	for (let i = 0; i < endnotes.length; i += 1) {
		const note = endnotes[i];
		html += `<aside id="endnote-${note.id}-text" class="endnote-text" role="note" epub:type="endnote">`;
		html += `<p class="first"><span class="endnote-text-number"><a class="endnote-backlink" epub:type="noteref" href="#endnote-${note.id}">${i + 1}.</a></span> ${note.note}</p>`;
		html += "</aside>";
	}
	html += "</div>";
	return html;
}