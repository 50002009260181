import { makeAutoObservable } from "mobx";
export class AuthStore {
	
	user = null;
	token: string | null = null;

	constructor(){
		makeAutoObservable(this);
	}

	setToken = (token: string | null): void => {
		this.token = token;
	}

	updateToken = async (token: string | null): Promise<void> => {
		if (token) await localStorage.setItem("atticus-auth-token", token);
		else await localStorage.removeItem("atticus-auth-token");

		this.setToken(token);
	}
}

export default new AuthStore();