import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { find, values } from "lodash";
import { observer } from "mobx-react";
import {  observable } from "mobx";
import { Link } from "react-router-dom";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { AtticusClient } from "../../api/atticus.api";
import { db } from "../../db/bookDb";
import { Switch, Row, Col, Form, Tooltip, DatePicker, Button, Card, Checkbox, Progress, Divider, Typography, InputNumber, Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import useRootStore from "../../store/useRootStore";
import { countWords } from "../../utils/helper";
import {
    useParams,
} from "react-router-dom";
import { message, Modal} from "antd";

// Icons
import { FireIcon, SettingsIcon, HelpIcon, SnowFlakeIcon, ToolbarSettingsIcon, ExitIcon } from "../Editor/partials/Icon";

//Styles
import "./goals.scss";



const { Text } = Typography;
const writingDays = [
    { label: "S", value: 0 },
    { label: "M", value: 1 },
    { label: "T", value: 2 },
    { label: "W", value: 3 },
    { label: "T", value: 4 },
    { label: "F", value: 5 },
    { label: "S", value: 6 },
];


export const WritingHabit: FunctionComponent = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [currentHabit, setCurrentHabit] = useState(false);
    const today = new Date();
    const [selectedDate, setSelectedDate] = useState(today);
    const [habitData, setHabitData] = useState<IHabitStore.Habit>();
    const { bookId } = useParams() as { bookId: string };
    const [editHabit, setEditHabit] = useState(false);

    useEffect(() => {
        getAccrossBook();
        getHabitData();
    }, []);

    useEffect(() => {
        getAccrossBook();
    }, [bookId]);

    useEffect(() => {
      ReactTooltip.rebuild();
    });

    //-------update the server-----------
    useEffect(() => {
        const initializeInter = setInterval(() => {
            if (navigator.onLine) {
                const updateServer = async () => {
                    const userIdIDAPI = await AtticusClient.GetProfile();
                    const habitDataIDB = await db.writingHabits.where("userId").equals(userIdIDAPI._id).first();
                    if (habitDataIDB) {
                        habitDataIDB.lastUpdateAt = new Date().toISOString();
                        await AtticusClient.UpdateHabit(habitDataIDB);
                    }

                };
                updateServer();
            }
        }, 15000);

        return () => clearInterval(initializeInter);
    }, []);

    const getUserData = async () => {
       // const profileResponse = await AtticusClient.GetProfile();
        const profileResponse = localStorage.getItem("userId");
        if (profileResponse) {
            return profileResponse;
        } else {
            return "";
        }
    };

    //--------------current and longest streak data----------------
    const getCurrentStreak = (habitData) => {
        const idbdays = habitData.writeDays;
        const idbDateRecords = habitData.dateRecords;
        let currentSreak = habitData.currentStreak;

        const prevWeek: any = [];
        const writingDays: any = [];
        const shouldWrite: any = [];

        for (let a = 0; a < idbdays.length; a++){
            if (idbdays[a] === 0) {
                writingDays.push("Sun");
            } else if (idbdays[a] === 1) {
                writingDays.push("Mon");
            } else if (idbdays[a] === 2) {
                writingDays.push("Tue");
            } else if (idbdays[a] === 3) {
                writingDays.push("Wed");
            } else if (idbdays[a] === 4) {
                writingDays.push("Thu");
            } else if (idbdays[a] === 5) {
                writingDays.push("Fri");
            } else if (idbdays[a] === 6) {
               writingDays.push("Sat");
            }
        }

        for (let i = 7; i > 0; i--){
            const currentDate = new Date();
            const eachDay = currentDate.setDate(currentDate.getDate() - i);
            prevWeek.push(new Date(eachDay));
        }

        for (let x = 6; x >= 0; x--){
            for (let y = writingDays.length; y >= 0; y--){
                if (prevWeek[x].toString().includes(writingDays[y])) {
                    shouldWrite.push(prevWeek[x]);
                }
            }
        }

        const latestWrite = Math.max(...shouldWrite);
        const shouldWriteLast = new Date(latestWrite).toISOString().slice(0, 10);
        const lastWrote = idbDateRecords[idbDateRecords.length - 1];

        if (lastWrote) {
            if (shouldWriteLast === lastWrote.date) {
                if (lastWrote.writtenCount >= lastWrote.goal) {
                    return currentSreak = currentSreak + 1;
                } else {
                    return currentSreak = 0;
                }
            } else {
                return currentSreak = 0;
            }
        } else {
           return currentSreak = 0;
        }

    };

    //----------daily habit data check---------------------
    const getHabitData = async () => {
        const userIdIDAPI: any = await getUserData();
        const currentDate = new Date().toISOString().slice(0, 10);

        if (userIdIDAPI) {
            const habitDataIDB = await db.writingHabits.where("userId").equals(userIdIDAPI).first();
            if (habitDataIDB && habitDataIDB.currentBookId !== bookId) {
               return getAccrossBook();
            }

            if (!habitDataIDB && navigator.onLine) {
                const serverHabitData = await AtticusClient.GetHabit(userIdIDAPI);
                if (serverHabitData) {
                    serverHabitData.lastUpdateAt = serverHabitData.lastUpdateAt.slice(0, 10);
                    await db.writingHabits.add(serverHabitData);
                    getHabitData();
                }
            }

            if (habitDataIDB) {
                if (habitDataIDB.lastUpdateAt !== currentDate) {
                    // if (habitDataIDB?.countDeleted) {
                    //     habitDataIDB.dateRecords = [...habitDataIDB.dateRecords, {
                    //         date: habitDataIDB.lastUpdateAt,
                    //         goal: habitDataIDB.goalCount,
                    //         writtenCount: habitDataIDB.writtenCount + habitDataIDB.preBookWordCount + habitDataIDB.deletedWordCount
                    //     }];

                    //     habitDataIDB.preWordCount = habitDataIDB.preWordCount + habitDataIDB.writtenCount + habitDataIDB.preBookWordCount + habitDataIDB.deletedWordCount;
                    // }

                    if (!habitDataIDB?.countDeleted) {
                        habitDataIDB.dateRecords = [...habitDataIDB.dateRecords, {
                            date: habitDataIDB.lastUpdateAt,
                            goal: habitDataIDB.goalCount,
                            writtenCount: habitDataIDB.writtenCount + habitDataIDB.preBookWordCount
                        }];

                        habitDataIDB.preWordCount = habitDataIDB.preWordCount + habitDataIDB.writtenCount + habitDataIDB.preBookWordCount;
                    }

                    habitDataIDB.lastUpdateAt = currentDate;
                    habitDataIDB.writtenCount = 0;
                    habitDataIDB.preBookWordCount = 0;
                    habitDataIDB.deletedWordCount = 0;

                    const updateHabit = await db.writingHabits.put(habitDataIDB);

                    if (updateHabit) {
                        const habitDataIDBBeforeStrak = await db.writingHabits.where("userId").equals(userIdIDAPI).first();

                        const currentStreakData = getCurrentStreak(habitDataIDBBeforeStrak);

                        habitDataIDB.currentStreak = currentStreakData;

                        if (currentStreakData > 0) {
                            habitDataIDB.snowFireStreak = "fire";
                        } else {
                            habitDataIDB.snowFireStreak = "snow";
                        }

                        if (currentStreakData > habitDataIDB.longestStreak) {
                            habitDataIDB.longestStreak = currentStreakData;
                        }

                        await db.writingHabits.put(habitDataIDB);

                        const habitDataIDBAfterStrak = await db.writingHabits.where("userId").equals(userIdIDAPI).first();

                        setHabitData(habitDataIDBAfterStrak);
                        setCurrentHabit(true);

                        const updateServerDB = await AtticusClient.UpdateHabit(habitDataIDBAfterStrak);

                    }
                }

                if (habitDataIDB.lastUpdateAt === currentDate) {
                    const habitDataIDB = await db.writingHabits.where("userId").equals(userIdIDAPI).first();

                    if (habitDataIDB) {
                        setHabitData(habitDataIDB);
                        setCurrentHabit(true);
                    }


                }

            }
        }

    };

    //------------convert to %------------------------------
    const percentage = (partialValue, totalValue) => {
        return (100 * partialValue) / totalValue;
    };


    //-----------collapse menu---------------------
    const onChangeCollapse = (checked) => {
        const ele = document.getElementById("collapsible-writing");
        if (ele) {
            if (checked) {
                ele.style.display = "block";
            }
            else {
                ele.style.display = "none";
            }
        }
    };

    //------------get initial word count - before creating the habit-----------
    const { getBookBodies, body, chapter } = useRootStore().bookStore;
    const [count, setCount] = useState(0);
    const [list, setList] = useState<Array<string>>([]);

    useEffect(() => {
        getBookBodies().then((d) => {
            setCount(d.words);
            setList(d.ids);
        });
    }, []);


    const BookWCCompletedF = () => {
        const words = list.includes(chapter._id) ? countWords(body) : 0;
        return words + count;
    };


    //-----------update wc across books-------------
    const getAccrossBook = async () => {
        const otherChapterCount = await getBookBodies();
        const currentChapterCount = countWords(body);
        const tot = (otherChapterCount.words + currentChapterCount);

        const userIdIDAPI: any = await getUserData();
        const habitIDB = await db.writingHabits.where("userId").equals(userIdIDAPI).first();

        if (habitIDB) {
            if (bookId !== habitIDB.currentBookId) {
                habitIDB.preWordCount = tot;
                habitIDB.preBookWordCount = habitIDB.writtenCount > 0 ? habitIDB.preBookWordCount + habitIDB.writtenCount : habitIDB.preBookWordCount;
                habitIDB.writtenCount = 0;
                habitIDB.currentBookId = bookId;

                await db.writingHabits.put(habitIDB);
                const acrossHabit = await db.writingHabits.where("userId").equals(userIdIDAPI).first();

                if (acrossHabit) {
                    if (acrossHabit.currentBookId === bookId) {
                        getHabitData();
                    }
                    setHabitData(acrossHabit);
                }

            }
        }


    };


    //------------update today's written word count-----------
    const updateTodayWordCount = async (count) => {
        const userIdIDAPI: any = await getUserData();
        const habitCount = await db.writingHabits.where("userId").equals(userIdIDAPI).first();
        if (habitCount) {
            // if ((count < habitCount.writtenCount) && habitCount.countDeleted) {
            //     habitCount.deletedWordCount =  habitCount.deletedWordCount + habitCount.writtenCount - count;
            //     await db.writingHabits.put(habitCount);
            //     const deltedCountUpdated = await db.writingHabits.where("userId").equals(userIdIDAPI).first();
            //     setHabitData(deltedCountUpdated);
            // }

            habitCount.writtenCount = count;
            const habitWrittenCountToday = await db.writingHabits.put(habitCount);
            const deltedCountUpdated = await db.writingHabits.where("userId").equals(userIdIDAPI).first();
        }
    };


    const updateStreak = async (streak) => {
        if (habitData && habitData.snowFireStreak === "snow") {
            habitData.snowFireStreak = "fire";
            await db.writingHabits.put(habitData);
            const userIdIDAPI: any = await getUserData();
            const habitCount = await db.writingHabits.where("userId").equals(userIdIDAPI).first();
            setHabitData(habitCount);
        }
    };

    const updatePreWordCount = async (words, negCount) => {
        if (habitData) {
            if (negCount < 0) {
                const ngToPos = Math.abs(negCount);

                if (ngToPos > habitData.preBookWordCount) {
                    habitData.preBookWordCount = 0;
                } else {
                    habitData.preBookWordCount = habitData.preBookWordCount + negCount;
                }
            }


            habitData.preWordCount = words;
            habitData.writtenCount = 0;

            await db.writingHabits.put(habitData);

            const userIdIDAPI: any = await getUserData();

            if (userIdIDAPI) {
                const habitDataIDB = await db.writingHabits.where("userId").equals(userIdIDAPI).first();
                if (habitDataIDB) {
                    setHabitData(habitDataIDB);
                }
            }
        }
    };

    const [userId, setUserId] = useState<any>();

    useEffect(() => {
        const getUserId = async () => {
            AtticusClient.GetProfile().then((d: any) => {
                setUserId(d);
            });
        };

        getUserId();

    }, []);


    //---------------------daily word count tracker-----------------------
    const DailyWordCount = observer(() => {
        const { getBookBodies, body, chapter } = useRootStore().bookStore;
        const [count, setCount] = useState(0);
        const [list, setList] = useState<Array<string>>([]);

        useEffect(() => {
            getBookBodies().then((d) => {
                setCount(d.words);
                setList(d.ids);
            });
        }, []);

        useEffect(() => {
            getBookBodies().then((d) => {
                setCount(d.words);
            });
        }, [chapter._id]);

        const words = list.includes(chapter._id) ? countWords(body) : 0;

        let lastCount = 0;
        const bookwords = (words + count);

        if (habitData) {
            lastCount = habitData.preWordCount;
        }

        const total = (words + count) - lastCount;

        useEffect(() => {
            if (habitData && total < habitData.preWordCount) {
                updateTodayWordCount(total);
            }

            if (total === (words + count)) {
                updateTodayWordCount(total);
            }

            if (habitData && bookwords > habitData.preBookWordCount) {
                updateTodayWordCount(total);
            }

            if (habitData && habitData?.currentBookId === bookId) {
                if (total + habitData.preBookWordCount >= habitData.goalCount) {
                    updateStreak("fire");
                }
            }
        }, [total]);

        useEffect(() => {
            const initializeInter = setInterval(() => {
                const getIDBData = async () => {
                    if (userId) {
                        const habitData = await db.writingHabits.where("userId").equals(userId._id).first();

                        if (habitData && habitData.writtenCount < 0 && bookwords !== habitData.preWordCount) {
                            updatePreWordCount(bookwords, habitData.writtenCount);
                        }

                    }
                };

                getIDBData();
            }, 3000);

            return () => clearInterval(initializeInter);
        });


        return (
            <>
                <Row>
                    <Col>
                        <Row>
                            <h2 style={{ color: "#00be95", fontSize: 21, padding: "0.0em 0em" }}>

                                {/* {
                                    habitData && habitData.currentBookId === bookId &&
                                    (habitData.countDeleted && (total + habitData.deletedWordCount) >= 0) &&
                                    (habitData.countDeleted && (total + habitData.preBookWordCount) + habitData.deletedWordCount)
                                } */}

                                {/* {total < 0 &&
                                    <Tooltip placement="bottom" title={<span>You&apos;ve deleted words and are behind the total word count of the current book that was calculated before you started writing today</span>}>
                                            <h2 style={{ color: "red", fontSize: 21, padding: "0.3em 0em" }}>{total < 0 && total}</h2>
                                    </Tooltip>
                                } */}

                                {
                                    habitData && habitData.currentBookId === bookId &&
                                    !habitData.countDeleted &&
                                    total >= 0 &&
                                    (total + habitData.preBookWordCount)
                                }
                                {
                                    habitData && habitData.currentBookId === bookId &&
                                    !habitData.countDeleted &&
                                    total < 0 &&
                                    habitData.preBookWordCount
                                }
                                <Text type="secondary" style={{ fontSize: 15 }}><sub>/{habitData?.goalCount}</sub></Text>
                                </h2>{habitData?.snowFireStreak === "snow" ? <SnowFlakeIcon /> : <FireIcon /> }</Row>
                        <Row><Text style={{ fontSize: 12 }} type="secondary">Daily writing goal</Text></Row>
                    </Col>
                    <Col>
                        <div style={{ paddingTop: 0, paddingLeft: "9em" }}>
                            {(habitData && !habitData.countDeleted) &&    <Progress width={55} type="dashboard" percent={Math.round(percentage(total + habitData.preBookWordCount, habitData?.goalCount))}  strokeColor={"#00be95"} strokeWidth={13} />}
                            {/* {(habitData && habitData.countDeleted) &&    <Progress width={55} type="dashboard" percent={Math.round(percentage((total + habitData.deletedWordCount), habitData?.goalCount))}  strokeColor={"#00be95"} strokeWidth={13} />} */}
                        </div>
                    </Col>
                </Row>
            </>
        );
    });

    //-------save new habit data-----------------------
    const saveHabitData = async (habit) => {
        if (!editHabit) {
            const saveGoalIDB = await db.writingHabits.add(habit);
            if (saveGoalIDB) {
                setCurrentHabit(true);
                setEditHabit(false);
                setHabitData(habit);
                setLoading(false);
            }
        }

        if (editHabit) {
            const saveGoalIDB = await db.writingHabits.put(habit);
            if (saveGoalIDB) {
                setCurrentHabit(true);
                setEditHabit(false);
                setHabitData(habit);
                setLoading(false);
            }
        }

    };

    //-------------handle habit form submission------------
    const handleWritingHabitSubmit = async (fieldsValue) => {
        if (!navigator.onLine) {
            const modal = Modal.warning({
                title: "You must be connected to the Internet to create a Habit.",
                closable: true,
                okButtonProps: {
                    style: {
                    display: "none",
                    },
                },
                width: 550,

            });

            return modal;

        }

        if (navigator.onLine) {
            setLoading(true);

            const profileResponse = await AtticusClient.GetProfile();
            if (profileResponse) {
                localStorage.setItem("userId", profileResponse._id);
            }


            try {
                const userIdIDAPI: any = await getUserData();
                const date = new Date();

                const habitDateObjSaveNew = {
                    _id: userIdIDAPI + "0",
                    userId: userIdIDAPI,
                    writeDays: fieldsValue.writeDays,
                    countDeleted: fieldsValue.countDeletedWords,
                    dateRecords: [],
                    preWordCount: BookWCCompletedF(),
                    goalCount: fieldsValue.wordsPerDay,
                    writtenCount: 0,
                    currentStreak: 0,
                    longestStreak: 0,
                    snowFireStreak: "snow",
                    deletedWordCount: 0,
                    currentBookId: bookId,
                    preBookWordCount: 0,
                    lastUpdateAt: date.toISOString().slice(0, 10)
                };

                let editWrittenCount = 0;
                if (habitData) {
                    editWrittenCount = habitData.writtenCount + habitData.deletedWordCount;
                }

                const habitDateObjEdit = {
                    _id: userIdIDAPI + "0",
                    userId: habitData?.userId,
                    writeDays: fieldsValue.writeDays,
                    countDeleted: fieldsValue.countDeletedWords,
                    dateRecords: habitData?.dateRecords,
                    preWordCount: habitData?.preWordCount,
                    goalCount: fieldsValue.wordsPerDay,
                    writtenCount: editWrittenCount,
                    currentStreak: 0,
                    longestStreak: habitData?.longestStreak,
                    snowFireStreak: habitData?.snowFireStreak,
                    deletedWordCount: fieldsValue.countDeletedWords ? habitData?.deletedWordCount : 0,
                    currentBookId: habitData?.currentBookId,
                    preBookWordCount: habitData?.preBookWordCount,
                    lastUpdateAt: habitData?.lastUpdateAt
                };

                if (!editHabit) {
                    saveHabitData(habitDateObjSaveNew);
                }

                if (editHabit) {
                    saveHabitData(habitDateObjEdit);
                }

            } catch (e) {
                console.log(e);
            }
        }

    };


    //------------heatmap functions-----------------------
    const getRange = (count) => {
        return Array.from({ length: count }, (_, i) => i);
    };


    const getHeatMapValues = () => {

        if (habitData && habitData.dateRecords.length > 0) {

            const recValues = habitData?.dateRecords.map(rec => {
                return {
                    date: rec.date,
                    goal: rec.goal,
                    words: rec.writtenCount,
                };
            });

            return recValues;

        }

        const randomValues = getRange(100).map(index => {
            return {
                date: null,
                goal: null,
                words: null,
            };
        });

        return randomValues;

    };

    const onPanelChange = (value, mode) => {
        setSelectedDate(value._d);
    };

    const startDate = () => {
        const today = new Date(selectedDate);
        const lastDayOfPrevMonth = new Date(today.getFullYear(), today.getMonth(), 0+1);
        return lastDayOfPrevMonth.toISOString().slice(0, 10);
    };

    const endDate = () => {
        const today = new Date(selectedDate);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0+1);
        return lastDayOfMonth.toISOString().slice(0, 10);
    };


    const getEditGoal = () => {
        setEditHabit(true);
    };

    const deleteGoal = async () => {
          if (!navigator.onLine) {
            const modal = Modal.warning({
                title: "You must be connected to the Internet to delete a Habit.",
                closable: true,
                okButtonProps: {
                    style: {
                    display: "none",
                    },
                },
                width: 550,

            });

            return modal;
        }

        if (habitData) {
            const userIdIDAPI: any = await getUserData();
            await AtticusClient.DeleteHabit(userIdIDAPI);
            const deleteHabitIDB = await db.writingHabits.where("userId").equals(userIdIDAPI).delete();
            if (deleteHabitIDB) {
                localStorage.removeItem("userId");
            }
            form.resetFields();
            setCurrentHabit(false);
        }

    };

        const menu = (
        <Menu>
            <Menu.Item key="1">
                <p onClick={() => getEditGoal()}>Edit Habit</p>
            </Menu.Item>
            <Menu.Item key="2">
                <p onClick={() => deleteGoal()}>Delete Habit</p>
            </Menu.Item>
        </Menu>
    );

    //-------------parent component-------------------
    return (
        <>
            <Row justify="space-between" align="top">
                <Col flex={1}>
                    <h2 className="setting-area-title">{editHabit ? "Edit" : ""} Writing Habit
                        <Tooltip placement="bottom" title={<span>This will help you build a daily writing habit. This will be applied to all writing projects.</span>}>
                            <span style={{ marginLeft: ".5em" }}><HelpIcon /></span>
                        </Tooltip>
                    </h2>
                </Col>
                {currentHabit && !editHabit &&
                    <Col>
                        {!navigator.onLine &&
                            <Tooltip placement="right" title={<span>You must be connected to the Internet to view Habit Settings.</span>}>
                                <button className="edit-goal-icon-btn" style={{ cursor: "not-allowed" }}><ToolbarSettingsIcon /></button>
                            </Tooltip>
                        }
                        {navigator.onLine &&
                            <Dropdown overlay={menu} placement="bottomCenter">
                                <button className="edit-goal-icon-btn" style={{ cursor: "pointer" }}><ToolbarSettingsIcon /></button>
                            </Dropdown>
                        }
                    </Col>}

                {!currentHabit &&
                    <Col>
                        <Switch size="small" defaultChecked onChange={onChangeCollapse} />
                    </Col>
                }

                {editHabit && <Col>
                    <button className="edit-goal-icon-btn" onClick={() => setEditHabit(false)}><ExitIcon /></button>
                </Col>}
            </Row>

            {!currentHabit && !editHabit &&
                <Form
                    className="setting-area-form"
                    form={form}
                    layout="vertical"
                    onFinish={handleWritingHabitSubmit}
                    requiredMark={"optional"}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="wordsPerDay"
                                label="Word count per day"
                                rules={[{ required: true, message: "Please enter the word count!" }]}
                            >
                                <InputNumber
                                    style={{ width: "100%", height: 40, backgroundColor: "#f7f7f7" }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Item
                            name="writeDays"
                            label="Writing Days"
                            className="goal-setting-check-btns"
                            rules={[{ required: true, message: "Please select the writing days!" }]}
                        >
                            <Checkbox.Group options={writingDays} />
                        </Form.Item>
                    </Row>
                    {/* <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="countDeletedWords"
                                    valuePropName="checked"
                                >
                                    <Checkbox defaultChecked={false}>Count deleted words</Checkbox>
                    
                                </Form.Item>
                            </Col>
                        </Row> */}
                    <Row>
                        <Col span={24}>
                            {
                                loading ? (
                                    <Button style={{ width: "100%" }} type="primary" htmlType="button">Saving...</Button>
                                ) : (
                                    <Button style={{ width: "100%" }} type="primary" htmlType="submit">Save</Button>
                                )
                            }
                        </Col>
                    </Row>
                </Form>
            }

            {currentHabit && !editHabit &&
                <>
                    <DailyWordCount />
                    <Divider style={{ backgroundColor: "#F5F5F5", paddingTop: 1.5 }} />
                    <div style={{ paddingTop: 5 }}>
                        <Row>
                            <Col flex="2 0 174px"><Text strong>Monthly tracker</Text></Col>
                            <Col flex="auto">
                                <DatePicker 
                                    picker="month" 
                                    bordered={false} 
                                    onPanelChange={onPanelChange} 
                                    //defaultValue={moment()} 
                                    format={"MMM, YYYY"} 
                                    suffixIcon={<DownOutlined style={{ cursor: "pointer" }} />} 
                                />
                            </Col>
                        </Row>
                        <Row className="inner-s react-calendar-heatmap" justify="space-between" align="middle">
                            <CalendarHeatmap
                                startDate={startDate()}
                                endDate={endDate()}
                                showWeekdayLabels={true}
                                showMonthLabels={false}
                                horizontal={false}
                                weekdayLabels={["S", "M", "T", "W", "T", "F", "S"]}
                                gutterSize={12}
                                values={getHeatMapValues()}
                                classForValue={value => {
                                    if (!value) {
                                        return "color-empty";
                                    }
                                    const quarter = value.goal / 4;
                                    let result;

                                    if (value.words < (quarter * 1)) {
                                        result = 1;
                                    } else if (value.words > quarter && value.words < (quarter * 2)) {
                                        result = 2;
                                    } else if (value.words > (quarter * 2) && value.words < (quarter * 3)) {
                                        result = 3;
                                    } else {
                                        result = 4;
                                    }

                                    return `color-scale-${result}`;
                                }}
                                tooltipDataAttrs={value => {
                                    if (value.date == undefined) {
                                        return;
                                    }
                                    const formattedDate = moment(value.date).format("MM/DD/YY");
                                    return {
                                        "data-tip": `\u00A0\u00A0\u00A0\u00A0${formattedDate} Goal: ${value.goal} Actual: ${value.words}\u00A0\u00A0\u00A0\u00A0`,
                                    };
                                }}
                            />
                            <ReactTooltip />
                        </Row>
                    </div>
                    <div>
                        <Row gutter={16}>
                            <Col span={12}>
                                <div className="writing-habit-streak">
                                    <div style={{ margin: 0 }}>
                                        <h4 style={{ fontWeight: 700, color: "#3568BA", fontSize: 12 }}>{habitData?.currentStreak} days</h4>
                                        <Text type="secondary" style={{ fontSize: 12 }}>Current streak</Text>
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="writing-habit-streak">
                                    <div style={{ margin: 0 }}>
                                        <h4 style={{ fontWeight: 700, color: "#00BE95", fontSize: 12 }}>{habitData?.longestStreak} days</h4>
                                        <Text type="secondary" style={{ fontSize: 12 }}>Longest streak</Text>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </>
            }

            {editHabit &&
                <Form
                    className="setting-area-form"
                    form={form}
                    layout="vertical"
                    onFinish={handleWritingHabitSubmit}
                    requiredMark={"optional"}
                    initialValues={{
                        wordsPerDay: habitData?.goalCount,
                        writeDays: habitData?.writeDays,
                        // countDeletedWords: habitData?.countDeleted
                    }}
                >
                    <div className="content setting-config">
                        <span className="warn-edit">You&apos;ll lose your current streak if you edit the Habit.</span>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="wordsPerDay"
                                    label="Word count per day"
                                    rules={[{ required: true, message: "Please enter the word count!" }]}
                                >
                                    <InputNumber
                                        style={{ width: "100%", height: 40, backgroundColor: "#f7f7f7" }}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Item
                                name="writeDays"
                                label="Writing Days"
                                className="goal-setting-check-btns"
                                rules={[{ required: true, message: "Please select the writing days!" }]}
                            >
                                <Checkbox.Group options={writingDays} />
                            </Form.Item>
                        </Row>
                        {/* <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="countDeletedWords"
                                        valuePropName="checked"
                                    >
                                        <Checkbox defaultChecked={false}>Count deleted words</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row> */}
                        <Row>
                            <Col span={24}>
                                {
                                    loading ? (
                                        <Button style={{ width: "100%" }} type="primary" htmlType="button">Saving...</Button>
                                    ) : (
                                        <Button style={{ width: "100%" }} type="primary" htmlType="submit">Save</Button>
                                    )
                                }
                            </Col>
                        </Row>
                    </div>
                </Form>
            }
        </>
    );
};





