import {
	getFontGroup,
	hasBoldFont,
	hasBoldItalicFont,
	hasItalicFont
} from "../../Previewer/print/configs";

export const availableFontStylesForFont = (fontFamily: string): Partial <Record<IThemeStore.ChapterTitleFontStyle, string>> | null => {
    const fontGroup = getFontGroup(fontFamily);

    if (fontGroup === null || fontGroup === undefined) return null;

    if (fontGroup.fonts === null || fontGroup.fonts === undefined) return null;

    const options: Partial <Record<IThemeStore.ChapterTitleFontStyle, string>> = {
        "normal": "Regular",
        "small-caps": "Small Caps",
        "underlined": "Underlined"
    };

    if (hasBoldFont(fontGroup.fonts)) {
        options.bold = "Bold";
    }

    if (hasItalicFont(fontGroup.fonts)) {
        options.italic = "Italics";
    }

    if (hasBoldItalicFont(fontGroup.fonts)) {
        options["bold-italic"] = "Bold & Italics";
    }

    return options;
};