import React, { FunctionComponent } from "react";
import { observer } from "mobx-react";
// 3rd party Components
import { Formik } from "formik";
import { Form, Button, message } from "antd";
import { TextField } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";

// api
import { AtticusClient } from "../../api/atticus.api";

// stores
import useRootStore from "../../store/useRootStore";

import miniLogo from "../../components/Shared/assets/miniLogo.png";

const styles = {
	miniLogo: {
		height: 100,
		width: 100,
	},
};
// types
interface FormErrors { 
	email?: string,
	password?: string
}
interface Props {
	modal: boolean
}

const SignIn: FunctionComponent<Props> = ({ modal }: Props) => {

	const { updateToken, token } = useRootStore().authStore;

	if (token) return <Redirect to="/" />;

	return (
		<div className="center-body">
			<Formik
				initialValues={{
					email: "",
					password: "",
				}}
				validate={values => {
					const errors: FormErrors = {};
					if (!values.email) errors.email = "Required";
					if (!values.password) errors.password = "Required";
					return errors;
				}}
				onSubmit={async (values, { setSubmitting, setFieldError }) => {
					try {
						const resp = await AtticusClient.SignIn(values.email, values.password);
						await updateToken(resp.token);
					} catch (e) {
						if (e.response.status === 406) {
							const validationErrors = e.response.data.errors;
							const validationKeys = Object.keys(validationErrors);
							for (const k of validationKeys) {
								setFieldError(k, validationErrors[k].message);
							}
							message.error("Please fix the highlighted fields", 4);
						} else {
							message.error("Error signing in");
						}
						setSubmitting(false);
					}            
					setSubmitting(false);
				}}
			>
				{({
					values,
					errors,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
				}) => (
					<>
						<Form
							onSubmitCapture={handleSubmit}
							colon={false}
							layout={"vertical"}
						>
							<div className="auth-logo__container">
								<img style={styles.miniLogo} src={miniLogo} />
							</div>
							<div className="card auth-card">
								<div className="margin-bottom">
									<h2 className="section-heading">{"Log In"}</h2>
								</div>
								<div className="form-field">
									<TextField
										type="email"
										name="email"
										onBlur={handleBlur}
										label="Email"
										value={values.email}
										onChange={handleChange}   
										helperText={errors.email}
										error={errors.email ? true : false}
										fullWidth
										size="medium"
									/>
								</div>
                  
								<div className="form-field">
									<TextField
										type="password"
										name="password"
										helperText={errors.password}
										error={errors.password ? true : false}
										fullWidth
										onBlur={handleBlur}
										label="Password"
										onChange={handleChange}
										value={values.password}
										size="medium"
									/>
								</div>
								<div className="form-field">
									<Button 
										block 
										type="primary" 
										size="large"
										htmlType="submit" 
										loading={isSubmitting} 
										className="auth-btn btn-a"
									>
										{"Sign In"}
									</Button>
								</div>
							</div>
						</Form>
						<div className="after-section">
							<Link to="/auth/forgot-password" className="default-link">{" "}{"Forgot password?"}</Link>
							{/*
							{modal ? (
								<Link to={{ hash: "#forgot-password" }} className="default-link">{" "}{"Forgot password?"}</Link>
							) : (
								<Link to="/auth/forgot-password" className="default-link">{"Forgot password?"}</Link>
							)}
							<Link to="/auth/forgot-password" className="special-link">{"Reset"}</Link>
              */}
						</div>
						{/* <div style={{ padding: "1rem 0rem"}}>
                {"Don't have an account? "}
                {modal ? (
                  <Link to={{ hash: "#signup" }}>{'Create Account'}</Link>
                ) : (
                  <a href="https://atticus.pub">{'Create Account'}</a>
                )}
              </div> */}
					</>
				)}
			</Formik>
		</div>
	);
};

export default observer(SignIn);