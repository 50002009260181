import React, { FunctionComponent, useState } from "react";
import { Menu, Dropdown, Modal, Button, message, Tooltip } from "antd";
import { MoreOutlined, DeleteOutlined, FontSizeOutlined, CopyOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

// helpers
import { IChapterTemplateBase } from "../../types/chapter";
import { useOnlineStatus } from "../../utils/isOffline";
import useRootStore from "../../store/useRootStore";
import { AtticusClient } from "../../api/atticus.api";

// styles
import "./index.scss";

// constants
const TooltipColor = "#3568BA";

interface ChapterTemplateItemProps {
    // book: IBookStore.Book;
    chapterTemplate: IChapterTemplateBase;
    deleteTemplate?: (templateId: string) => Promise<void>;
    duplicateBook?: (bookId: string) => Promise<void>;
}

const ChapterTemplateItem: FunctionComponent<ChapterTemplateItemProps> = ({ chapterTemplate, deleteTemplate }: ChapterTemplateItemProps) => {
    const history = useHistory();
    const isOnline = useOnlineStatus();
    const { setChapterTemplateView } = useRootStore().appStore;
    const { chapter, setChapterLoading, setBookEdit, setChapterTemplate } = useRootStore().bookStore;
    const { syncChapterTemplate } = useRootStore().chapterStore;
    const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);

    const handleTemplateOk = () => {
        setIsTemplateModalVisible(false);
        syncChapterTemplate(true, chapterTemplate._id, chapterTemplate.motherChapterId, true);
        message.success("Changes applied successfully", 4);
    };

    const onDeleteChapterTemplates = async (templateId) => {
        if (deleteTemplate)
            await deleteTemplate(templateId);
    };

    const onEditChapterTemplate = async (_id) => {
        setChapterTemplateView(true);
        setChapterTemplate(chapterTemplate);
        setChapterLoading(false);
        setBookEdit(false);
        history.push(`/chapter-template/${chapterTemplate._id}`);
    };

    const capitalize = (str) =>{
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
        <div  className="book-list-item" style={{marginLeft: "1em"}}>
            <div onClick={() => onEditChapterTemplate(chapterTemplate._id)} className="book-cover">
                <h3 style={{fontSize: 13}}> {chapterTemplate.title}</h3>
            </div>
            <div className="meta">
            <Tooltip title="Chapter Type" color={TooltipColor} arrowPointAtCenter>
                    <h4 className="title">{capitalize(chapterTemplate.type)}</h4>
                </Tooltip>
                {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Tooltip title={book.author.join(", ")} color={TooltipColor} arrowPointAtCenter>
                        <span className="authors">By {book.author.join(", ")}</span>
                    </Tooltip>
                </div> */}
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className="edited-date">Edited {chapterTemplate.lastUpdateAt ? moment(chapterTemplate.modifiedAt).format("MMM D, YYYY") : "No date"}</span>

                    <Dropdown
                        trigger={["click"]}
                        placement="topCenter"
                        overlay={
                            <Menu>
                                <Menu.Item
                                    onClick={() => onEditChapterTemplate(chapterTemplate._id)}
                                    icon={<FontSizeOutlined />}
                                >
                                    Edit
                                </Menu.Item>
                                {isOnline && (
                                    <Menu.Item
                                        onClick={() => {
                                            Modal.confirm({
                                                icon: null,
                                                title: <h2 className="section-heading">Delete</h2>,
                                                content: `Are you sure you want to delete "${chapterTemplate.title}"?`,
                                                centered: true,
                                                onOk: () => {
                                                    onDeleteChapterTemplates(chapterTemplate._id);
                                                },
                                                okText: "Yes",
                                                okButtonProps: {
                                                    type: "primary",
                                                    danger: true,
                                                    style: {
                                                        flex: 1,
                                                    },
                                                },
                                                cancelText: "No",
                                                cancelButtonProps: {
                                                    className: "btn-a",
                                                    style: {
                                                        flex: 1,
                                                    },
                                                },
                                            });
                                        }}
                                        icon={<DeleteOutlined />}
                                    >
                                        Delete
                                    </Menu.Item>
                                )}
                                {isOnline && (
                                    <Menu.Item
                                        onClick={() => {
                                            Modal.confirm({
                                                icon: null,
                                                title: <h2 className="section-heading">Sync Chapter Templates</h2>,
                                                content: `Are you sure you want to sync "${chapterTemplate.title}" chapter template to all books that is using this template ?`,
                                                centered: true,
                                                onOk: handleTemplateOk,
                                                okText: "Yes",
                                                okButtonProps: {
                                                    type: "primary",
                                                    danger: true,
                                                    style: {
                                                        flex: 1,
                                                    },
                                                },
                                                cancelText: "No",
                                                cancelButtonProps: {
                                                    className: "btn-a",
                                                    style: {
                                                        flex: 1,
                                                    },
                                                },
                                            });
                                        }}
                                        icon={<CopyOutlined />}
                                    >
                                        Sync Template to all books
                                    </Menu.Item>
                                )}
                            </Menu>
                        }
                    >
                        <MoreOutlined style={{ cursor: "pointer", marginLeft: "auto", marginRight: -5 }} />
                    </Dropdown>
                </div>
                {/* <Modal
                    title="You are making a change to a saved template"
                    visible={isTemplateModalVisible}
                    onOk={handleTemplateOk}
                    onCancel={handleTemplateCancel}
                    footer={[
                        <Button key="submit" type="primary" onClick={handleTemplateOk}>
                            Apply current changes
                        </Button>]}>
                    <Radio.Group style={{ width: "100%" }} onChange={onChange}>
                        <Space direction="vertical">
                            <Radio value="1">Apply only to this book</Radio>
                            <Radio value="2">Apply to all books that use this saved template</Radio>
                        </Space>
                    </Radio.Group>
                </Modal> */}
            </div>

        </div>
    );
};

export default ChapterTemplateItem;
