import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { groupBy } from "lodash";
import { Form, Select, Button, Row, Col, Input, Divider, Checkbox } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Languages from "../../../utils/Languages.json";

import FileUpload from "./FileUpload";
import ImageGallery from "./ImageGallery";
import BookBrush from "./BookBrush";

import useRootStore from "../../../store/useRootStore";

interface EditBookProps {
  onCancel: (() => void) | null;
  book: IBookStore.Book;
}

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};
interface ChapterMenuItem {
  _id: string;
  title: string;
}
const EditBook = observer((props: EditBookProps) => {
  const { book } = props;
  const { saveBook, getAuthors, getProjects, getVersionTags } = useRootStore().shelfStore;
  const { book: bk, setBook } = useRootStore().bookStore;
  const { refreshCache } = useRootStore().pdfCacheStore;
  const [imageUrl, setImageUrl] = useState(book.coverImageUrl);
  const [customTocTitle, setCustomTocTitle] = useState(
    book.custom_toc_title ? true : false
  );
  const [publisherLogo, setPublisherLogo] = useState(book.publisherLogoURL);
  const [form] = Form.useForm();

  const [ projectFreeText, setProjectFreeText ] = useState("");
  const authors = getAuthors();
  const projects = getProjects();
  const versionTags = getVersionTags();

  const onFinish = (values: any) => {
    delete values["CustomTocTitle"];
    const vals: Partial<IBookStore.Book> = {
      ...values,
      coverImageUrl: imageUrl,
      publisherLogoURL: publisherLogo,
      custom_toc_title: form.getFieldValue("custom_toc_title"),
    };
    setBook({ ...bk, ...props.book, ...vals });
    saveBook(props.book._id, vals);
    refreshCache(book._id, "book-properties-change");
  };

  //Update image hook
  const setImage = (url: string) => {
    setImageUrl(url);
    form.submit();
  };

  //Update publisher logo hook
  const setPLogo = (url: string) => {
    setPublisherLogo(url);
    form.submit();
  };


  useEffect(() => {
    if (!customTocTitle) {
      form.setFieldsValue({ custom_toc_title: null });
    }

    form.submit();
  }, [customTocTitle]);

  const getIGURL = (link) => {
      setImage(link);
  };

  const getIGURLP = (link) => {
      setPLogo(link);
  };

  return (
    <Form
      className="book-form"
      name="validate_other"
      form={form}
      {...formItemLayout}
      onValuesChange={(_, vals) => onFinish(vals)}
      onFinish={(vals) => onFinish(vals)}
      initialValues={props.book}
    >
      <Row gutter={32}>
        <Col span={6}>
          <Form.Item label="Book Cover" tooltip="Cover images for Apple iBooks cannot exceed 1600x2400 pixels or a total of 4 million pixels">
            <Form.Item
              name="coverImg"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
            >
              {imageUrl ? (
                <>
                  <img className="att-upload-image" src={imageUrl} />
                  <Button
                    size="small"
                    danger
                    type="primary"
                    block
                    onClick={() => setImage("")}
                  >
                    Remove
                  </Button>
                </>
              ) : (
                <>
                  <FileUpload
                    folder="cover-images"
                    fileType="image/*"
                    onFileUpload={(url) => setImage(url || "")}
                  >
                    <div className="upload-image-container">
                      <PlusOutlined className="icon" />
                    </div>
                  </FileUpload>
                  <ImageGallery changeURL={getIGURL} />
                  <Col style={{marginTop: "7px"}} >
                    <BookBrush />
                  </Col>

                </>
              )}
            </Form.Item>
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Title cannot be empty" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="subtitle"
            label="Subtitle"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="CustomTocTitle" label="Custom Table Of Contents Title">
            <Checkbox
              checked={customTocTitle}
              onChange={(e) => {
                setCustomTocTitle(e.target.checked);
              }}
            />
          </Form.Item>
          {customTocTitle ? (
            <Form.Item
              rules={[
                {
                  required: customTocTitle ? true : false,
                  message: "Custom Table Of Contents title cannot be empty",
                },
              ]}
              name="custom_toc_title"
              label="Table Of Contents Title"
            >
              <Input />
            </Form.Item>
          ) : null}
          <Form.Item
            name="author"
            label="Author"
            hasFeedback
            //rules={[{ required: true, message: 'Please select an author!' }]}
          >
            <Select mode="tags">
              {authors.map((a, i) => (
                <Option key={i} value={a}>
                  {a}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="project"
            label="Project"
            hasFeedback
            //rules={[{ required: true, message: 'Please select a project!' }]}
          >
            <Select
              showSearch
              onSearch={(val) => {
                setProjectFreeText(val);
              }}
              onSelect={(val) => {
                form.setFieldsValue({ project: val });
                //form.submit();
              }}
            >
              {projectFreeText && projects.indexOf(projectFreeText) === -1 ? (
                <Option key={"new"} value={projectFreeText}>
                  {projectFreeText}
                </Option>
              ) : null}
              <Option key="empty" value="" title="" style={{ color: "grey"}}>Select project</Option>
              {projects.map((a, i) => (
                <Option key={i} value={a}>
                  {a}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="versionTags" label="Version" hasFeedback>
            <Select mode="tags">
              {versionTags.map((tag, i) => (
                <Option key={`${tag}-${i}`} value={tag}>
                  {tag}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Divider />
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="language" label="Language">
                <Select showSearch defaultValue='en' filterOption={(input, option:any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                  {/* <Option value="en">English</Option> */}
                  {Languages.map((item,key) =>(
                    <Option key={item.code} value={item.code}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="printISBN" label="Print ISBN">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="ebookISBN" label="EBook ISBN">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Divider />
      </Row>
      <Row gutter={32}>
        <Col span={6}>
          <Form.Item label="Publisher Logo">
            <Form.Item
              name="publisherLogoURL"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
              shouldUpdate
            >
              {publisherLogo ? (
                <>
                  <img className="att-upload-image" src={publisherLogo} />
                  <Button
                    size="small"
                    danger
                    type="primary"
                    block
                    onClick={() => setPLogo("")}
                  >
                    Remove
                  </Button>
                </>
              ) : (
                <>
                  <FileUpload
                    folder="publisherLogos"
                    fileType="image/*"
                    onFileUpload={(url) => setPLogo(url || "")}
                    shape="square"
                  >
                    <div className="upload-image-container">
                      <PlusOutlined className="icon" />
                    </div>
                  </FileUpload>
                  <ImageGallery changeURL={getIGURLP}/>
                </>
              )}
              <div>
                {/* <FileUpload
                  folder="publisherLogos"
                  fileType="image/*"
                  onFileUpload={(url) => {
                    form.setFieldsValue({ publisherLogoURL: url || "" });
                    form.submit();
                  }}
                  shape="square"
                >
                  <div className="upload-image-container">
                    <PlusOutlined className="icon" />
                    {publisherLogoURL ? (
                      <img className="upload-image" src={publisherLogoURL} />
                    ) : null}
                  </div>
                </FileUpload> */}
              </div>
            </Form.Item>
          </Form.Item>
        </Col>
        <Col span={18}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="publisherName" label="Publisher Name">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="publisherLink" label="Publisher Link">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="spacer"></div>
    </Form>
  );
});

export default EditBook;
