import React, { useState } from "react";
import { Drawer, Form, Button, Col, Row, Input, Select, DatePicker } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import useRootStore from "../../store/useRootStore";

const { TextArea } = Input;

const JSONPreviewer = () => {
  const [show, setshow] = useState(false);
  const [disa, setdisa] = useState(true);
  const { body } = useRootStore().bookStore;

  return (
    <>
      <Button type="primary" onClick={() => setshow(!show)}>
        JSON
      </Button>
      <Drawer
        title={
          <Row align="middle">
            <h3>Body Previewer (JSON)</h3>
            <Button type="link" onClick={() => setdisa(!disa)}>{disa ? "SHOW" : "HIDE"} JSON</Button>
          </Row>
        }
        style={{
          backgroundColor: "white"
        }}
        width={720}
        onClose={() => setshow(false)}
        visible={show}
        footer={null}
      >
        <>
          <Row>
            <TextArea
              style={{
                height: "85vh",
                color: "grey"
              }}
              disabled={disa}
              value={JSON.stringify(body, null, 4)}
            ></TextArea>
          </Row>
        </>
      </Drawer>
    </>
  );
};

export default JSONPreviewer;