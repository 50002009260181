import { makeAutoObservable, observable } from "mobx";
import { makeid } from "../utils/helper";

import { AtticusClient } from "../api/atticus.api";

export class AppStore {
  view: [] = [];
  loading = true;
  sprintInit = false;
  chapterTemplateView = false;
  editor_setting : IAppStore.EditorSettings = {
    view: "goals",
    show: false
  };
  chapTemplate: IChapterStore.IChapterTemplateBase = {
    _id: "",
    type: "uncategorized",
    title: "",
    image: "",
    children: []
  };
  editor: IAppStore.EditorConfigs = {
    indentation: "spaced",
    justified: false,
    font: "",
    fontsize: 16,
    linespacing: 1.5
  };
  // socialProfile:  ISocialProfileStore.IProfiles[] = [];
  selectedSocialProfile = {
    profileName: "",
    smAccounts: [{
      id: "",
    name: "",
    username: "",
    icon: ""
    }],
    userId: ""
  };
  sessionId = makeid(12)
  displayState = "default";
  /**
   * To identify the global local pdf renderer call
   */
  pdfExporterOptions: IAppStore.PDFExporterOptions = {} as IAppStore.PDFExporterOptions

  constructor() {
    makeAutoObservable(this, {
      view: observable.ref,
      editor: observable.deep,
      pdfExporterOptions: observable.deep
    });
  }
  
  setSettingView = (config: IAppStore.EditorSettings): void => {
    this.editor_setting = config;
  }

  setLoading = (loading: boolean): void => {
    this.loading = loading;
  }

  patchEditor = (conf: Partial<IAppStore.EditorConfigs>) => {
    this.editor = { ...this.editor, ...conf };
  }

  setSprintInit = (view: boolean): void => {
    this.sprintInit = view;
  }

  setChapterTemplateView = (view: boolean): void => {
    this.chapterTemplateView = view;
  }

  setMainChapterTemplate = (temp: IChapterStore.IChapterTemplateBase): void => {
    this.chapTemplate = temp;
  }

  setSelectedProfile = async(profile: any): Promise<void> => {
    this.selectedSocialProfile = profile;
  }

  setDisplayState = (dState: string) => {
    this.displayState = dState;
  }

  setPDFExproterOptions = (bookId: string, bookName: string): void => {
    if (this.pdfExporterOptions.renderBook) {
      this.pdfExporterOptions.renderBook(bookId, bookName);
    }
  }

}
export default new AppStore();