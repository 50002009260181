import React, { FunctionComponent, useEffect } from "react";
import { Row, Col, Button, Typography, Tooltip } from "antd";
import { observer } from "mobx-react";

// Icons
import { GoalsIcon, NotesIcon, ToolbarSettingsIcon, FindReplaceSettingsIcon, HistorySettingsIcon, ReverseToggleSettingsIcon } from "./partials/Icon";
import useRootStore from "../../store/useRootStore";

const { Text } = Typography;

interface TabProps {
    id: IAppStore.SettingView,
    name: string,
    icon: any,
    disabled: boolean
}
const tabviews : TabProps[] = [
    {
        id: "goals",
        name: "Goals",
        icon: <GoalsIcon />,
        disabled: false,
    },
    {
        id: "search",
        name: "Search",
        icon: <FindReplaceSettingsIcon />,
        disabled: false,
    },
    {
        id: "setting",
        name: "Settings",
        icon: <ToolbarSettingsIcon />,
        disabled: false,
    },
    {
        id: "notes",
        name: "Notes",
        icon: <NotesIcon />,
        disabled: true,
    },
    {
        id: "history",
        name: "History",
        icon: <HistorySettingsIcon />,
        disabled: true,
    }
];

 const SettingsToolbar: FunctionComponent = () => {
    const { setSettingView, editor_setting } = useRootStore().appStore;

    const toggleSettingView = () => {
        setSettingView({
            ...editor_setting,
            show: !editor_setting.show
        });
    };

    const setTab = (viewId: IAppStore.SettingView) => {
        setSettingView({
            ...editor_setting,
            view: viewId
        });
    };


    return (
        <>
            <div className={` collapse-pivot ${editor_setting.show ? "reverse" : ""}`} onClick={toggleSettingView}>
                <ReverseToggleSettingsIcon />
            </div>
            <div className="collapse-setting-toolbar" style={{ width: editor_setting.show ? "calc(400px - 1em)" : "auto"}}>
                {editor_setting.show ? (
                    <div className="setting-toolbar collapse-settings">
                        {tabviews.map(d => (
                            <div key={`tb_${d.id}`} className={`btn-settings ${editor_setting.view === d.id ? "active" : ""}`}>
                                {d.disabled ? (
                                    <Tooltip title="Coming Soon">
                                        <Button
                                            disabled={true}
                                            size="middle"
                                        >
                                            {d.icon} 
                                            {editor_setting.view === d.id ? <span>{d.name}</span> : null}
                                        </Button>
                                   </Tooltip>
                                ) : (
                                    <Button
                                        size="middle"
                                        onClick={() => setTab(d.id)
                                    }>
                                        {d.icon} 
                                        {editor_setting.view === d.id ? <span>{d.name}</span> : null}
                                    </Button>
                                )}
                            </div>
                        )
                        )}
                    </div>
                ) : (
                    <div style={{ display: "flex", alignItems: "center", padding: 11 }}>
                        <Text strong>More Tools</Text>
                    </div>
                )}
                
            </div>
        </>
    );
};

export default observer(SettingsToolbar);