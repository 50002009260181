import React, { useEffect, useState, useRef, useMemo } from "react";
import { find, values } from "lodash";
import { observer } from "mobx-react";
import { Button, message, Modal } from "antd";
import { VerticalLeftOutlined, VerticalRightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import { db } from "../../db/bookDb";


import Toolbar from "./Toolbar";
import {
  parseChapter,
  parseTitleCard,
  parseTOC,
  parseFullPageImage,
  parseFullBleedImage,
  parseEndnotesChapter,
} from "./html";
import {
  generateStylesheet,
  generatePrintStylesheet,
  generateDigitalStylesheet,
} from "./styles";
import { printFonts } from "../Shared/helpers/printConfigs";
import { generateToc, isValidChapter } from "../Shared/helpers/toc";

import useRootStore from "../../store/useRootStore";

import "./index.scss";
import "./previewerStyles.scss";
import { PrintPreviewer } from "./print";
import { PdfSlateEndnote } from "./print/types";

const isNumberedChapter = (chapter: IChapterStore.ChapterMeta) =>
  (chapter.type === "chapter" || chapter.type === "uncategorized") &&
  (chapter.numbered === undefined || chapter.numbered === true);

const Previewer = observer(() => {
  let view = "";
  let csss = "";
  const para = useRef(null);
  const [device, selectDevice] = useState("ipad");
  const [deviceURL, selectDeviceURL] = useState(
    "https://atticus-content.s3.amazonaws.com/devices/iPad+Pro.png"
  );
  const [endnotes, setEndnotes] = useState<PdfSlateEndnote[]>([]);
  // const [allDevicePreviews, setAllDevicePreviews] = useState<Array<DeviceSpec>>([]);

  const {
    theme,
    images,
    style,
    customCss,
    individualChapterImage,
    userThemes,
  } = useRootStore().themeStore;
  const [previewFontSize, setPreviewFontSize] = useState(12);
  const [previewFont, setPreviewFont] = useState("Palatino");
  const {
    chapter,
    body,
    book,
    exportBook,
    setErrorBook,
    setErrorChapter,
    customThemeBuilderView,
    getChapterBodyById,
    getAllEndNotesOfBook
  } = useRootStore().bookStore;
  const { getDevicePreview, getAllDevices } = useRootStore().themeStore;
  const { setPDFExproterOptions } = useRootStore().appStore;

  const [pdfExporting, setPdfExporting] = useState(false);
  const [epubExporting, setEpubExporting] = useState(false);
  const [chapters, setChapters] = useState<IChapterStore.Chapter[]>([]);

  const { push } = useHistory();

  let chapterNumber: number | undefined = undefined;
  const drpCapCls = [
    "chapter",
    "uncategorized",
    "prologue",
    "epilogue",
    "custom",
  ].includes(chapter.type)
    ? "withDropcap"
    : "";
  useEffect(() => {
    doHeightChange();
  }, [para]);

  const getChaps = async (ids) => {
    const chaps = await getChapterBodyById(book.chapterIds);
    setChapters(chaps);
  };

  useEffect(() => {
    getChaps(book.chapterIds);
  }, [chapter]);

  useEffect(() => {
    let unmounted = false;
    (async () => {
      if (chapter.type !== "endnotes") return;
      if (theme && theme.notesMode !== "END_OF_BOOK" && theme.ePubNotesMode !== "END_OF_BOOK") return;
      const notes = await getAllEndNotesOfBook();
      if (!unmounted) setEndnotes(notes);
    })();
    return () => {
      unmounted = true;
    };
  }, [chapter]);

  const removeErrorBooks = (bookId: string) => {
		const delErrorChapter =  db.failedChapters.where("_bookId").anyOf(bookId).delete();
		const delErrorBook =  db.failedBooks.where("_bookId").anyOf(bookId).delete();
	};

  const exportEpub = async () => {
    setEpubExporting(true);
    try {

      const resp = await exportBook(book._id, "epub");
      window.open(resp.url, "_blank");
      removeErrorBooks(book._id);

    } catch (e: any) {

      if(e.response?.data.message != "Request failed with status code 406") {
				setErrorBook(book._id);
				setErrorChapter(e.response?.data?.errors?.chapterID?.message, book._id);
			} 

      Modal.confirm({
        icon: null,
        title: <h2 className="section-heading">Export Failed</h2>,
        content: (
          <div>
            <p style={{ textAlign: "justify" }}>
              Sorry about that! Your book wasn&apos;t able to be exported due to
              an error in the document. Use the link below to learn more about
              why this might happen and what you can do to resolve the error.
            </p>
            <a
              target="_blank"
              href="https://www.atticus.io/troubleshooting-export-errors/"
              rel="noreferrer"
            >
              https://www.atticus.io/troubleshooting-export-errors/
            </a>
          </div>
        ),
        centered: true,
        // onOk: onDeleteBook,
        okText: "Ok",
        okButtonProps: {
          type: "primary",
          danger: true,
          style: {
            flex: 1,
          },
        },
        cancelText: false,
        cancelButtonProps: {
          className: "btn-a",
          style: {
            display: "none",
          },
        },
      });
    }
    setEpubExporting(false);
    // setErrorBook(book._id);
  };

  const exportLocalPdf = () => {    
    setPDFExproterOptions(book._id, book.title);
  };

  const exportPdf = async () => {
    setPdfExporting(true);
    try {
      await exportBook(book._id, "pdf");
      message.success("We'll email you your PDF when it's ready", 4);
    } catch (e) {
      Modal.confirm({
        icon: null,
        title: <h2 className="section-heading">Export Failed</h2>,
        content: (
          <div>
            <p style={{ textAlign: "justify" }}>
              Sorry about that! Your book wasn&apos;t able to be exported due to
              an error in the document. Use the link below to learn more about
              why this might happen and what you can do to resolve the error.
            </p>
            <a
              target="_blank"
              href="https://www.atticus.io/troubleshooting-export-errors/"
              rel="noreferrer"
            >
              https://www.atticus.io/troubleshooting-export-errors/
            </a>
          </div>
        ),
        centered: true,
        // onOk: onDeleteBook,
        okText: "Ok",
        okButtonProps: {
          type: "primary",
          danger: true,
          style: {
            flex: 1,
          },
        },
        cancelText: false,
        cancelButtonProps: {
          className: "btn-a",
          style: {
            display: "none",
          },
        },
      });
    }
    setPdfExporting(false);
    // setErrorBook(book._id);
  };

  const getAdjacentChapters = () => {
    if (!chapter) return { prev: null, next: null };
    const frontMatterChapterIds = book.frontMatter.filter(({_id}) => isValidChapter(_id, book)).map(({_id}) => _id);
    const bodyChapterIds = book.chapters.filter(({_id}) => isValidChapter(_id, book)).map(({_id}) => _id);
    const bockMatterChapterIds = book.backMatter.filter(({_id}) => isValidChapter(_id, book)).map(({_id}) => _id);

    let prev;
    let next;

    // check if the current chapter is in frontmatter
    const fmIndex = frontMatterChapterIds.indexOf(chapter._id);

    if (fmIndex > -1) {
      // previous chapter
      if (fmIndex > 0) prev = frontMatterChapterIds[fmIndex - 1];

      // next chapter
      if (fmIndex < frontMatterChapterIds.length - 1)
        next = frontMatterChapterIds[fmIndex + 1];
      else if (bodyChapterIds.length > 0) next = bodyChapterIds[0];
      else if (bockMatterChapterIds.length > 0) next = bockMatterChapterIds[0];
    }

    // check if the current chapter is in the body
    const cIndex = bodyChapterIds.indexOf(chapter._id);

    if (cIndex > -1) {
      // previous chapter
      if (cIndex > 0) prev = bodyChapterIds[cIndex - 1];
      else if (frontMatterChapterIds.length > 0)
        prev = frontMatterChapterIds[frontMatterChapterIds.length - 1];

      // next chapter
      if (cIndex < bodyChapterIds.length - 1)
        next = bodyChapterIds[cIndex + 1];
      else if (bockMatterChapterIds.length > 0) next = bockMatterChapterIds[0];
    }

    // check if the current chapter is in backmatter
    const bIndex = bockMatterChapterIds.indexOf(chapter._id);

    if (bIndex > -1) {
      // previous chapter
      if (bIndex > 0) prev = bockMatterChapterIds[bIndex - 1];
      else if (bodyChapterIds.length > 0)
        prev = bodyChapterIds[bodyChapterIds.length - 1];
      else if (frontMatterChapterIds.length > 0)
        prev = frontMatterChapterIds[frontMatterChapterIds.length - 1];

      // next chapter
      if (bIndex < bockMatterChapterIds.length - 1)
        next = bockMatterChapterIds[bIndex + 1];
    }

    return {
      prev,
      next,
    };
  };

  const doHeightChange = () => {
    if (para.current) {
      // const height = 0; //para.current.offsetHeight;
      // const sd = Math.ceil(height / dimensions.iphone);
      // setPages(sd);
    }
  };

  const onChangDevice = (d) => {
    getDevicePreview(d).then((dv) => {
      selectDevice(dv ? dv._deviceName : d);
      selectDeviceURL(dv ? dv.image : deviceURL);
    });
  };

  const handlePrevChapter = () => {
    if (prev) {
      push({
        hash: `chapter=${prev}`,
      });
    }
  };

  const handleNextChapter = () => {
    if (next) {
      push({
        hash: `chapter=${next}`,
      });
    }
  };

  const toolbarBackgrounColorCallBack = (color) => {
    // setPreviewColor(color);
  };

  const toolbarFontSizeCallBack = (size) => setPreviewFontSize(size);
  const toolbarFontCallBack = (font) => setPreviewFont(font);
  let imgs =
    (customThemeBuilderView === "customThemeStep2"
      ? individualChapterImage
      : style?.properties?.individualChapterImage) && images
      ? {
          ...images,
          url: chapter.image,
          chapterId: chapter._id,
        }
      : images;

  if (style?.image) {
    imgs = {
      ...style.image,
      chapterId: chapter._id,
    };
  }

  if (isNumberedChapter(chapter) && book.chapterIds.indexOf(chapter._id) > -1) {
    let curChapterNumber = 1;
    for (const chap of book.chapters) {
      if (chap._id === chapter._id) break;
      if (isNumberedChapter(chap)) curChapterNumber += 1;
    }
    chapterNumber = curChapterNumber;
  }
  if (theme) {

    const frontMatterTypes = ["title", "blurbs", "dedication", "epigraph",
    "foreword", "introduction", "preface", "prologue"].includes(chapter.type);

    const backMatterTypes = ["epilogue", "afterword", "acknowledgments", 
    "about-author", "also-by", ].includes(chapter.type);

    if (chapter.type === "title") view = parseTitleCard(book);
    // else if (chapter.type === "image" && chapter.fullpageImage?.printExtent==="margins") view = parseFullPageImage({ ...chapter, children: body });
    else if (
      chapter.type === "image" &&
      chapter.fullpageImage?.printExtent === "full-bleed" &&
      device === "print"
    )
      view = parseFullBleedImage({ ...chapter, children: body });
    else if (chapter.type === "image")
      view = parseFullPageImage({ ...chapter, children: body });
    else if (chapter.type === "toc") {
      view = parseTOC(
        generateToc(book, chapters),
        theme,
        book.custom_toc_title,
        book.showTocSubtitle,
        book.showTocSubheading
      );
    } else if (frontMatterTypes) {
      // removes chapter header images for frontmatter
      view = parseChapter(
            {
              ...chapter,
              children: body,
            },
            theme,
            chapterNumber,
            device === "print",
            undefined,
            customThemeBuilderView
          );
    } else if(chapter.type ==="endnotes") {
      if (theme.ePubNotesMode === "END_OF_BOOK") {
        view = parseEndnotesChapter(
          chapter,
          endnotes,
          theme,
        );
      }
      else {
        view = "<div style={{ marginTop: \"20rem\" }}>";
        view += "This content is not included in ePub";
        view += "</div>";
      }           
    } else if (backMatterTypes) {
      // removes chapter header images for frontmatter
      view = parseChapter(
            {
              ...chapter,
              children: body,
            },
            theme,
            chapterNumber,
            device === "print",
            undefined,
            customThemeBuilderView
          );
    } else {
      view = parseChapter(
        {
          ...chapter,
          children: body,
        },
        theme,
        chapterNumber,
        device === "print",
        imgs ? imgs : undefined,
        customThemeBuilderView
      );
    }
  }
  if (theme && style) {
    const dss =
      customThemeBuilderView !== "customThemeStep2" ? style.css : customCss;
    if (device === "print") {
      const fonts = find(printFonts, { _id: theme.printBaseFont });
      csss = `${fonts?.css} ${dss} ${generatePrintStylesheet(
        "left",
        theme
      )} ${generateStylesheet(theme)}`;
    } else {
      const fonts = style.fonts
        .map((f) => `@import url('${f.url}');`)
        .join(" ");
      csss = `${fonts} ${dss} ${generateStylesheet(
        theme
      )} ${generateDigitalStylesheet(theme)}`;
    }
  }

  const { prev, next } = getAdjacentChapters();
  const stylecls =
    customThemeBuilderView === "customThemeStep2" ? "customed" : style?._id;

  const chapterForPreviewer = useMemo(
    () => ({ ...chapter, children: body }),
    [chapter, body]
  );

  return (
    <div className={`previewer-wrapper full ${drpCapCls} ${stylecls}`}>
      <style scoped>{csss}</style>
      <Toolbar
        device={device}
        ondeviceselect={onChangDevice}
        //editor={chapter.body[0].children[0].text}
        previewBackground={toolbarBackgrounColorCallBack}
        previewFontSize={toolbarFontSizeCallBack}
        previewFont={toolbarFontCallBack}
      />
      <div className="previewer">
        {device === "paperwhite" && (
          <div className="p-device paperwhite">
            <div
              className="inside"
              style={{
                fontSize: previewFontSize,
                fontFamily: `${previewFont}`,
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: view }}></div>
            </div>
            <img width={440} src={`${deviceURL}`} />
          </div>
        )}
        {device === "fire" && (
          <div className="p-device fire">
            <div
              className="inside"
              style={{
                fontSize: previewFontSize,
                fontFamily: `${previewFont}`,
              }}
            >
              <div
                className="print-wrapper"
                dangerouslySetInnerHTML={{ __html: view }}
              ></div>
            </div>
            <img width={440} src={`${deviceURL}`} />
          </div>
        )}
        {device === "oasis" && (
          <div className="p-device oasis">
            <div
              className="inside"
              style={{
                fontSize: previewFontSize,
                fontFamily: `${previewFont}`,
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: view }}></div>
            </div>
            <img width={440} src={`${deviceURL}`} />
          </div>
        )}
        {device === "forma" && (
          <div className="p-device forma">
            <div
              className="inside"
              style={{
                fontSize: previewFontSize,
                fontFamily: `${previewFont}`,
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: view }}></div>
            </div>
            <img width={440} src={`${deviceURL}`} />
          </div>
        )}
        {device === "clara" && (
          <div className="p-device clara">
            <div
              className="inside"
              style={{
                fontSize: previewFontSize,
                fontFamily: `${previewFont}`,
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: view }}></div>
            </div>
            <img width={440} src={`${deviceURL}`} />
          </div>
        )}
        {device === "libra" && (
          <div className="p-device libra">
            <div
              className="inside"
              style={{
                fontSize: previewFontSize,
                fontFamily: `${previewFont}`,
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: view }}></div>
            </div>
            <img width={440} src={`${deviceURL}`} />
          </div>
        )}
        {device === "nia" && (
          <div className="p-device nia">
            <div
              className="inside"
              style={{
                fontSize: previewFontSize,
                fontFamily: `${previewFont}`,
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: view }}></div>
            </div>
            <img width={440} src={`${deviceURL}`} />
          </div>
        )}
        {device === "glowlight" && (
          <div className="p-device glowlight">
            <div
              className="inside"
              style={{
                fontSize: previewFontSize,
                fontFamily: `${previewFont}`,
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: view }}></div>
            </div>
            <img width={440} src={`${deviceURL}`} />
          </div>
        )}
        {device === "glowlight_plus" && (
          <div className="p-device glowlight_plus">
            <div
              className="inside"
              style={{
                fontSize: previewFontSize,
                fontFamily: `${previewFont}`,
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: view }}></div>
            </div>
            <img width={440} src={`${deviceURL}`} />
          </div>
        )}
        {device === "iphone" && (
          <div className="p-device iphone">
            <div
              className="inside"
              style={{
                fontSize: previewFontSize,
                fontFamily: `${previewFont}`,
              }}
            >
              <div
                className="inter"
                dangerouslySetInnerHTML={{ __html: view }}
              ></div>
            </div>
            <img width={380} src={`${deviceURL}`} />
          </div>
        )}
        {device === "ipad" && (
          <div className="p-device ipad">
            <div
              className="inside"
              dangerouslySetInnerHTML={{ __html: view }}
              style={{
                fontSize: previewFontSize,
                fontFamily: `${previewFont}`,
              }}
            ></div>
            <img width={440} src={`${deviceURL}`} />
          </div>
        )}
        {device === "galaxy_s21" && (
          <div className="p-device galaxy_s21">
            <div
              className="inside"
              dangerouslySetInnerHTML={{ __html: view }}
              style={{
                fontSize: previewFontSize,
                fontFamily: `${previewFont}`,
              }}
            ></div>
            <img width={360} src={`${deviceURL}`} />
          </div>
        )}
        {device === "galaxy_tab_7" && (
          <div className="p-device galaxy_tab_7">
            <div
              className="inside"
              style={{
                fontSize: previewFontSize,
                fontFamily: `${previewFont}`,
              }}
            >
              <div
                className="print-wrapper"
                dangerouslySetInnerHTML={{ __html: view }}
              ></div>
            </div>
            <img width={440} src={`${deviceURL}`} />
          </div>
        )}
        {device === "print" && theme && (
          <PrintPreviewer
            theme={theme}
            chapter={chapterForPreviewer}
            prev={prev}
            handlePrevChapter={handlePrevChapter}
            next={next}
            handleNextChapter={handleNextChapter}
            styles={style}
            chapterNumber={chapterNumber}
            images={imgs}
            customThemeBuilderView={customThemeBuilderView}
          />
          // <figure className={chapter.type === "copyrights" ? "copyright-print previewer-print" : "previewer-print"} style={{ fontFamily: `${previewFont}` }}>
          // 	<div className={chapter.type==="image" && chapter.fullpageImage?.printExtent==="full-bleed" ?"full-bleed" :"_wrapper"}>
          // 		<div className="textbox" ref={para}>
          // 			<div className='print-wrapper' dangerouslySetInnerHTML={{ __html: view }}></div>
          // 		</div>
          // 	</div>
          // </figure>
        )}
      </div>
      {device !== "print" && (
        <div className="previewer-btns">
          <div className="previewer__btns--top">
            <Button onClick={handlePrevChapter} disabled={!prev}>
              <VerticalRightOutlined style={{ position: "relative", top: 1 }} />
              Chapter
            </Button>
            <Button onClick={handleNextChapter} disabled={!next}>
              Chapter
              <VerticalLeftOutlined style={{ position: "relative", top: 2 }} />
            </Button>
          </div>
        </div>
      )}
      <div className="previewer__btns--bottom">
        <Button loading={pdfExporting} type="primary" onClick={exportLocalPdf}>
          Export PDF
        </Button>
        <Button loading={epubExporting} type="primary" onClick={exportEpub}>
          Export Epub
        </Button>
      </div>
    </div>
  );
});

export default Previewer;
