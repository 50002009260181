import React, { useState } from "react";
import { Button } from "antd";

import useRootStore from "../../store/useRootStore";

export default function SnapshotContainer() {
	const { exportSpanshot, book } = useRootStore().bookStore;
  const [ exporting, setExporting ] = useState<boolean>(false);

  const bookId = book._id;

  const downloadFile = async (fileName, snapshotStr) => {
    const blob = new Blob([snapshotStr],{type: "application/json" });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
    
	const createSnapshot = async () => {
    setExporting(true);
		const book = await exportSpanshot(bookId);

    if (book) {
      const fileName = `${book.title}-${new Date().toISOString()}`.replace(/ /ig, "-");
      downloadFile(fileName, JSON.stringify(book));
    }
    setExporting(false);
	};

	return (
		<div className="inner" style={{ backgroundColor: "#f7f7f7", borderRadius: 5 }}>
			<h2>Snapshots</h2>
			<div style={{ marginTop: "1rem" }}>
        <p>Downloading a snapshot allows you to quickly save a copy of your book to your device and can be used as a backup. This file can also be used to help our support team debug any issues you might be having. You cannot currently import this file directly into Atticus but we can use it to reinstate that version of your book for you.</p>
        <div style={{ marginTop: "1rem" }}>
          <Button onClick={createSnapshot} type="default" size="large" className="btn-b" loading={exporting}>Download Snapshot</Button>
        </div>
			</div>
		</div>
	);
}
