import React, {useState, useEffect} from "react";
import { Row, Col, Modal, Button, Tabs, Pagination, Menu, Dropdown, notification } from "antd";
import { MoreOutlined, DeleteOutlined, FontSizeOutlined, WarningOutlined, CopyOutlined, DeleteTwoTone } from "@ant-design/icons";
import { db } from "../../../db/bookDb";
import { rest } from "lodash";
import { AtticusClient } from "../../../api/atticus.api";
import moment from "moment";

const { TabPane } = Tabs;

const ImageGallery = ({ changeURL }) => {
	const [currentKey, setCurrentKey] = useState("1");
	const [gallery, setGallery] = useState<any>([]);
	const [filterView, setFilterView] = useState<any>([]);
	const [imgSelected, setImgSelected] = useState("");
	const [modal, toggleModal] = useState(false);
	const [imgData, setImgData] = useState({ link: "", id: "" });
	const [dbImg, setDBImg] = useState<any>({});
	const [pagination, setPagination] = useState({
		current: 1,
		minIndex: 0,
		maxIndex: 18
	});

	const pageSize = 18;

	const handleChangePaginationAll = (page) => {
    setPagination({
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize
    });
  };

	const getIDBImgGallery = async () => {
		//const IDBImgGallery = await db.imageGallery.where("_imgId").startsWith("ig").toArray();
		const profileResponse = await AtticusClient.GetProfile();
		const galleryRes = await AtticusClient.GetGallery(profileResponse._id);

		if (galleryRes) {
			setGallery(galleryRes);
		}

	};
 
	useEffect(() => {
		callback(currentKey);
	}, [gallery]);
	
	const getURL = (link, id) => {
		changeURL(link);
	};

	const getImgData = (img, link, id) => {
		setDBImg(img);
		setImgSelected(id);
		setImgData({ link: link, id: id });
	};

	const callback = (key) => {
		setCurrentKey(key);
		setPagination({
			current: 1,
			minIndex: 0,
			maxIndex: 18
		});

		setImgSelected("");
		if (key === "2") {
			const contentArr: any = [];
			gallery.forEach((img) => {
				if (img.folderName === "files") {
					contentArr.push(img);
				}
			});
			setFilterView(contentArr);
		}

		if (key === "3") {
			const themeArr: any = [];
			gallery.forEach((img) => {
				if (img.folderName.includes("theme")) {
					themeArr.push(img);
				}
			});
			setFilterView(themeArr);
		}

		if (key === "4") {
			const coverArr: any = [];
			gallery.forEach((img) => {
				if (img.folderName.includes("cover")) {
					coverArr.push(img);
				}
			});
			setFilterView(coverArr);
		}

		if (key === "5") {
			const ornamentalArr: any = [];
			gallery.forEach((img) => {
				if (img.folderName.includes("ornamental")) {
					ornamentalArr.push(img);
				}
			});
			setFilterView(ornamentalArr);
		}
	};

	const getFilterView = () => {
		return <>
			<Pagination
				size="small"
				pageSize={pageSize}
				current={pagination.current}
				total={filterView.length}
				onChange={handleChangePaginationAll}
				style={{ bottom: "0px" }}
			/>
			<div className="ig-main-div">
			{filterView.length === 0 && <p>Your image gallery is empty!</p>}
			<Row gutter={[10, 10]}>
				{filterView.map((image, i) => {
					return i >= pagination.minIndex && i < pagination.maxIndex && (
						<div key={i} onClick={() => getImgData(image, image.link, i)} >
							<Col span={6}>
								<img width="100" height="100" src={image.link} className={imgSelected === i ? "ig-img" : ""} />{" "}
							</Col>
							<Row>
								<span className="ig-edited-date">{ moment(image.date).format("MMM D, YYYY")}</span>
								<Dropdown
									trigger={["click"]}
									placement="topCenter"
									overlay={menu}
								>
									<MoreOutlined style={{ cursor: "pointer" }} className="ig-more" />
								</Dropdown>
							</Row>
						</div>
					);
				})}
			</Row>
			</div>
		</>;
	};

	const viewIG = () => {
		if (!navigator.onLine) {
			const notify = notification["error"]({
				message: "You must be connected to the Internet to open the Gallery.",
			});
			return notify;
		}
		getIDBImgGallery();
		toggleModal(!modal);
	};

	const deleteImg = async (type) => {
		if (!dbImg) {
			return alert("No image found!");
		}

		const imgDataObj = {
			_imgId: dbImg._imgId,
			folder: dbImg.folderName,
			userId: dbImg.userId
		};

		try {
			await AtticusClient.DeleteImgInGallery(imgDataObj, type);
			//await db.imageGallery.where("_imgId").equals(dbImg._imgId).delete();

			getIDBImgGallery();
			setImgSelected("");
			setImgData({ link: "", id: "" });
			setDBImg({});
			notification["success"]({
				message: "Image deleted successfully",
			});
		} catch (err) {
			notification["error"]({
				message: "Server error!",
			});
		}


	};

	const menu = (
		<Menu>
			<Menu.Item
				onClick={() => {
					Modal.confirm({
						icon: null,
						title: <h2 className="section-heading">Delete Image</h2>,
						content: "Are you sure you want to delete the image from Gallery?",
						centered: true,
						onOk: () => deleteImg("gallery"),
						okText: "Yes",
						okButtonProps: {
								type: "primary",
								danger: true,
								style: {
										flex: 1,
								},
						},
						cancelText: "No",
						cancelButtonProps: {
								className: "btn-a",
								style: {
										flex: 1,
								},
						},
					});
				}}
				icon={<DeleteOutlined />}
			>
				Delete from Gallery
			</Menu.Item>                   
			<Menu.Item
				onClick={() => {
					Modal.confirm({
							icon: null,
							title: <h2 className="section-heading">Delete Image</h2>,
							content: "Are you sure you want to delete the image from Gallery and all the books?",
							centered: true,
							onOk: () => deleteImg("s3"),
							okText: "Yes",
							okButtonProps: {
									type: "primary",
									danger: true,
									style: {
											flex: 1,
									},
							},
							cancelText: "No",
							cancelButtonProps: {
									className: "btn-a",
									style: {
											flex: 1,
									},
							},
					});
				}}
				icon={<DeleteTwoTone twoToneColor="red" />}
			>
				Delete from Gallery and all the books
			</Menu.Item>           
		</Menu>
  );

	return (
		<div>
			<Button type="primary" className="igBtn" onClick={() => viewIG()}>
        My Image Gallery
			</Button>

			<Modal
				width={850}
        visible={modal}
        okButtonProps={{
          className: "b-btn"
        }}
        cancelButtonProps={{
          className: "a-btn"
        }}
        title="My Image Gallery"
				onOk={() => {
					getURL(imgData.link, imgData.id);
          toggleModal(false);
        }}
				onCancel={() => {
					setImgSelected("");
					toggleModal(false);
				}}
        okText="OK"
        cancelText="Cancel"
      >
				<Tabs defaultActiveKey="1" onChange={callback}>

					<TabPane tab="All" key="1">
						<Pagination
							size="small"
							pageSize={pageSize}
							current={pagination.current}
							total={gallery.length}
							onChange={handleChangePaginationAll}
							style={{ bottom: "0px" }}
						/>
						<div className="ig-main-div">
							{gallery.length === 0 && <p>Your gallery is empty!</p>}
							<Row gutter={[ 10, 10 ]}>
								{gallery.map((image, i) => {
									return i >= pagination.minIndex && i < pagination.maxIndex && (
										<div key={i} onClick={() => getImgData(image, image.link, i)} className="imgCard">
											<Col span={6}>
													<img width="100" height="100" src={image.link} className={imgSelected === i ? "ig-img" : ""}/>{" "}
											</Col>
											<Row>
												<span className="ig-edited-date">{ moment(image.date).format("MMM D, YYYY")}</span>
												<Dropdown
													trigger={["click"]}
													placement="topCenter"
													overlay={menu}
												>
													<MoreOutlined style={{ cursor: "pointer" }} className="ig-more" />
												</Dropdown>
											</Row>
										</div>
									);
								})}
							</Row>
						</div>
					</TabPane>

					<TabPane tab="Content" key="2">
						{getFilterView()}
					</TabPane>

					<TabPane tab="Theme" key="3">
						{getFilterView()}
					</TabPane>

					<TabPane tab="Cover" key="4">
						{getFilterView()}
					</TabPane>

					<TabPane tab="Ornamental" key="5">
						{getFilterView()}
					</TabPane>

				</Tabs>

   
      </Modal>
		</div>
	);
};

export default ImageGallery;
