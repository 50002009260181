import React, { ReactNode } from "react";
import { DeserializeHtml, SlatePlugin } from "@udecode/slate-plugins-core";
import { getRenderLeaf, getLeafDeserializer, Deserialize, getOnHotkeyToggleMark, setDefaults } from "@udecode/slate-plugins";
import { SansSerifPluginOptions, SansSerifLeafOptions, SansSerifDeserializeOptions, SansSerifRenderLeafOptions } from "./types";
import { DEFAULTS_SANSSERIF } from "./defaults";

export const renderLeafSansSerif = (options?: SansSerifRenderLeafOptions) => {
  const keyOptions = setDefaults(options, DEFAULTS_SANSSERIF)["sansserif"];
  return getRenderLeaf(keyOptions);
};

export const deserializeSmallCap = (
  options: SansSerifDeserializeOptions
): DeserializeHtml => {
  const { sansserif } = setDefaults(options, DEFAULTS_SANSSERIF);
  return {
    leaf: getLeafDeserializer({
      type: sansserif.type,
      rules: [
        { nodeNames: ["sansserif"] },
        {
          style: {
            fontFamily: "sansserif"
          },
        },
      ],
      ...options?.sansserif?.deserialize,
    }),
  };
};

export const SansSerifPlugin = (options: SansSerifPluginOptions): SlatePlugin => {
  return {
    renderLeaf: renderLeafSansSerif(options),
    deserialize: deserializeSmallCap(options),
    onKeyDown: getOnHotkeyToggleMark({ type: "sansserif", hotkey: "cmd+shift+s" })
    //inlineTypes: ["SansSerif"],
    //voidTypes: ["SansSerif"]
  };
};



export const SansSerifComponent = (props: any) => {
  return (
    <span {...props.attributes}>{props.children}</span>
  );
};