import React from "react";
import { observer } from "mobx-react";
import { Formik } from "formik";
import { Col, Row, Form, Input, Button, message, FormItemProps } from "antd";

import { AtticusClient } from "../../api/atticus.api";

interface FormErrors {
	password?: string;
	newPassword?: string;
	confirmPassword?: string;
}

const commonFormItemProps = {
	labelAlign: "left",
	colon: false,
} as FormItemProps;

const ChangePassword = observer(() => {

	return (
		<Formik
			initialValues={{
				password: "",
				newPassword: "",
				confirmPassword: ""
			}}
			validate={values => {
				const errors: FormErrors = {};
				if (!values.password) {
					errors.password = "Required";
				}
				if (!values.confirmPassword) {
					errors.confirmPassword = "Required";
				}
				if (values.confirmPassword && values.confirmPassword.length < 6) {
					errors.password = "Your password should have at least 6 characters";
				}
				if (values.newPassword !== values.confirmPassword) {
					errors.confirmPassword = "Oops... Your passwords don't match";
				}
				return errors;			
			}}
			onSubmit={async (values, { setSubmitting }) => {
				try {
					setSubmitting(true);
					const passwords = {
						oldPassword: values.password,
						newPassword: values.newPassword
					};
					await AtticusClient.UpdatePassword(passwords);  
					message.success("Your profile has been updated successfully!", 3);
				} catch (e) {
					setSubmitting(false);
					message.error("Your old password is incorrect", 3);			
				}        
				setSubmitting(false);
			}}
		>
			{({
				values,
				errors,
				handleChange,
				handleSubmit,
				isSubmitting,
			}) => (
				<>
					<Form
						onSubmitCapture={handleSubmit}
						colon={false}
						layout={"vertical"}
					>
						<div className="card auth-card book-form book__form--extended">
							<Row>
								<Col span={24}>
									<div className="form-field padding__bottom--zero">
										<Form.Item label="Old Password" {...commonFormItemProps} className="ant-input-extended padding__bottom--zero" help={errors.password} validateStatus={errors.password ? "error" : ""}>
											<Input placeholder="Password" name="password" type="password" onChange={handleChange} value={values.password} size="large" />
										</Form.Item>
									</div>
								</Col>
							</Row>								
							<Row>
								<Col span={24}>
									<div className="form-field" style={{ paddingBottom: 0 }}>
										<Form.Item label="New Password" {...commonFormItemProps} className="ant-input-extended padding__bottom--zero" help={errors.newPassword} validateStatus={errors.newPassword ? "error" : ""}>
											<Input placeholder="New Password" name="newPassword" type="password" onChange={handleChange} value={values.newPassword} size="large" />
										</Form.Item>
									</div>
								</Col>
							</Row>
							<Row>
								<Col span={24}>
									<div className="form-field">
										<Form.Item label="Re-Enter New Password" {...commonFormItemProps} className="ant-input-extended padding__bottom--zero" help={errors.confirmPassword} validateStatus={errors.confirmPassword ? "error" : ""}>
											<Input placeholder="Confirm Password" name="confirmPassword" type="password" onChange={handleChange} value={values.confirmPassword} size="large" />
										</Form.Item>
									</div>
								</Col>
							</Row>
							<div className="form-field">
								<Button 
									type="primary" 
									size="large"
									htmlType="submit" 
									loading={isSubmitting} 
								>
									{"Update"}
								</Button>
							</div>
						</div>
					</Form>
				</>
			)}
		</Formik>
	);
});

export default ChangePassword;