import React, { FunctionComponent } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import * as querystring from "query-string";
import { observer } from "mobx-react";

// 3rd party Components
import { Formik } from "formik";
import { Form, Input, Button, message } from "antd";
import { TextField } from "@material-ui/core";

// api
import { AtticusClient } from "../../api/atticus.api";

// stores
import useRootStore from "../../store/useRootStore";

import miniLogo from "../../components/Shared/assets/miniLogo.png";
// import "./auth.scss";

const styles = {
	miniLogo: {
		height: 100,
		width: 100,
	},
};

// types
interface FormErrors {
  newPassword?: string;
  code?: string;
}

const ResetPassword: FunctionComponent = () => {
	const { token } = useRootStore().authStore;
	const location = useLocation<Location>();
	const { push } = useHistory();

	const queryParams = querystring.parse(location.search);

	if (token) return <Redirect to="/" />;

	return (
		<div className="center-body">
			<Formik
				initialValues={{
					newPassword: "",
				}}
				validate={(values) => {
					const errors: FormErrors = {};
					if (!values.newPassword) errors.newPassword = "Required";
					return errors;
				}}
				onSubmit={async (values, { setSubmitting, setFieldError }) => {
					try {
						const resp = await AtticusClient.ResetPassword(queryParams.userId, queryParams.code, values.newPassword);  
						push("/auth/sign-in");          
					} catch (e) {
						if (e.response.status === 406) {
							const validationErrors = e.response.data.errors;
							const validationKeys = Object.keys(validationErrors);

							for (const k of validationKeys) {
								setFieldError(k, validationErrors[k].message);
							}

							message.error("Please fix the highlighted fields", 4);
						} else {
							message.error("Error Resetting your password");
						}
						setSubmitting(false);
					}
				}}
			>
				{({
					values,
					errors,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
				}) => (
					<Form
						onSubmitCapture={handleSubmit}
						colon={false}
						layout={"vertical"}
					>
						<div className="auth-logo__container">
							<img style={styles.miniLogo} src={miniLogo} />
						</div>
						<div className="card auth-card">
							<div className="justify-center">
								<div className="full-screen-card">
									<div className="margin-bottom">
										<h2 className="section-heading">Reset Password</h2>
									</div>
									<div className="form-field">
										<TextField
											type="password"
											name="newPassword"
											onBlur={handleBlur}
											label="Password"
											fullWidth
											onChange={handleChange}
											value={values.newPassword}
											helperText={errors.newPassword}
											error={errors.newPassword ? true : false}
										/>
									</div>
								</div>
							</div>
							<div className="form-field">
								<Button
									block
									type="primary"
									size="large"
									htmlType="submit"
									loading={isSubmitting}                
									className="auth-btn btn-a"
								>
									{"Reset Password"}
								</Button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default observer(ResetPassword);