import { Book, ErrorBook, ErrorChapter } from "../types/book";
import { Node } from "slate";
import Bugsnag from "@bugsnag/js";
import { Chapter, ChapterBody, ChapterMeta, IChapterTemplateBase } from "../types/chapter";
import { ThemeConfig, ThemeBase, DeviceSpec } from "../types/theme";

//IndexedDB
import { db } from "../db/bookDb";

// Helpers
import { removeKey } from "./helper";
import { AtticusClient } from "../api/atticus.api";

interface BookQueryInclude {
    chapterMeta?: boolean;
    chapterBodies?: boolean;
}

interface ThemeQueryInclude {
    themeMeta?: boolean;
    themeBase?: boolean;
    themeFields?: boolean;
}

const nullPromise = (): Promise<null> => new Promise(res => res(null));

export const GetBookFromDB = async (bookId: string, options?: BookQueryInclude): Promise<Book | undefined> => {
    const book = await db.books.get(bookId);

    if (book) {
        if (!book.frontMatterIds) book.frontMatterIds = [];
        if (!book.backMatterIds) book.backMatterIds = [];

        const allPromises: Promise<ChapterMeta[] | ChapterBody[] | null>[] = [];

        if (options && options.chapterMeta) {
            allPromises.push(db.chapterMetas.where("bookId").equals(book._id).toArray());
        } else {
            allPromises.push(nullPromise());
        }

        if (options && options.chapterBodies) {
            allPromises.push(db.chapterBodies.where("bookId").equals(book._id).toArray());
        } else {
            allPromises.push(nullPromise());
        }

        const [meta, bodies] = await Promise.all(allPromises);

        // Book Bodies
        if (options?.chapterBodies || options?.chapterMeta) {
            const frontMatter: Chapter[] = [];
            const chapters: Chapter[] = [];
            const backMatter: Chapter[] = [];

            // Front-matter Body
            for (const chapterId of book.frontMatterIds) {
                let tmpChapter: Chapter | null = null;

                if (meta) {
                    const curMetaIndex = (meta as ChapterMeta[]).findIndex((m) => m._id === chapterId);
                    if (curMetaIndex > -1) {
                        tmpChapter = { ...(meta as ChapterMeta[])[curMetaIndex], children: [] };
                    }
                }

                if (options.chapterBodies && bodies && tmpChapter) {
                    const curBodyIndex = (bodies as ChapterBody[]).findIndex((b) => b._id === chapterId);
                    if (curBodyIndex > -1) {
                        const tmpChapterBody = (bodies as ChapterBody[])[curBodyIndex];

                        tmpChapter = {
                            ...tmpChapter,
                            ...tmpChapterBody,
                        };
                    }
                }
                if (tmpChapter) frontMatter.push(tmpChapter);
            }

            // book body
            for (const chapterId of book.chapterIds) {
                let tmpChapter: Chapter | null = null;

                if (meta) {
                    const curMetaIndex = (meta as ChapterMeta[]).findIndex((m) => m._id === chapterId);
                    if (curMetaIndex > -1) {
                        tmpChapter = { ...(meta as ChapterMeta[])[curMetaIndex], children: [] };
                    }
                }

                if (options.chapterBodies && bodies && tmpChapter) {
                    const curBodyIndex = (bodies as ChapterBody[]).findIndex((b) => b._id === chapterId);
                    if (curBodyIndex > -1) {
                        const tmpChapterBody = (bodies as ChapterBody[])[curBodyIndex];

                        tmpChapter = {
                            ...tmpChapter,
                            ...tmpChapterBody,
                        };
                    }
                }
                if (tmpChapter) chapters.push(tmpChapter);
            }

            // Back-matter Body
            for (const chapterId of book.backMatterIds) {
                let tmpChapter: Chapter | null = null;

                if (meta) {
                    const curMetaIndex = (meta as ChapterMeta[]).findIndex((m) => m._id === chapterId);
                    if (curMetaIndex > -1) {
                        tmpChapter = { ...(meta as ChapterMeta[])[curMetaIndex], children: [] };
                    }
                }

                if (options.chapterBodies && bodies && tmpChapter) {
                    const curBodyIndex = (bodies as ChapterBody[]).findIndex((b) => b._id === chapterId);
                    if (curBodyIndex > -1) {
                        const tmpChapterBody = (bodies as ChapterBody[])[curBodyIndex];

                        tmpChapter = {
                            ...tmpChapter,
                            ...tmpChapterBody,
                        };
                    }
                }
                if (tmpChapter) backMatter.push(tmpChapter);
            }

            book.frontMatter = frontMatter;
            book.chapters = chapters;
            book.backMatter = backMatter;
        }
    }

    return book;
};

export const SaveBookToDB = async (book: Book): Promise<void> => {

    const chapterMetas: ChapterMeta[] = [];
    const chapterBodies: ChapterBody[] = [];

    if (book.chapters) {
        for (const chapter of book.chapters) {
            const tmpMeta: ChapterMeta = {
                _id: chapter._id,
                bookId: chapter.bookId,
                title: chapter.title,
                type: chapter.type,
                subtitle: chapter.subtitle,
                image: chapter.image,
                index: chapter.index,
                lastSuccessfulSync: chapter.lastUpdateAt,
                numbered: chapter.numbered,
                includeIn: chapter.includeIn,
                startOn: chapter.startOn,
                templateId: chapter.templateId,
                fullpageImage: chapter.fullpageImage,
                allChangesSynced: true,
            };

            const tmpBody: ChapterBody = {
                _id: chapter._id,
                bookId: chapter.bookId,
                children: chapter.children,
                lastSuccessfulSync: chapter.lastUpdateAt,
                allChangesSynced: true,
            };

            chapterMetas.push(tmpMeta);
            chapterBodies.push(tmpBody);
        }
    }

    const allPromises: Promise<unknown>[] = [];

    const parsedBook = removeKey(book, "chapters") as Book;

    allPromises.push(db.books.put({
        ...parsedBook,
        modifiedAt: book.lastUpdateAt,
        lastSuccessfulSync: book.lastUpdateAt,
    }));
  
    allPromises.push(db.chapterMetas.bulkPut(chapterMetas));
    allPromises.push(db.chapterBodies.bulkPut(chapterBodies));

    await Promise.all(allPromises);

    return;
};

// Chapter Template Library
export const SaveChapterTemplateToDB = async (template: IChapterTemplateBase): Promise<void> => {

    const allPromises: Promise<unknown>[] = [];
    allPromises.push(db.chapterTemplates.put({
        ...template,
        modifiedAt: template.lastUpdateAt,
        lastSuccessfulSync: template.lastUpdateAt
    }));
  
    allPromises.push(db.chapterTemplates.put(template));

    await Promise.all(allPromises);

    return;
};

// Chapter Template Library
export const GetChapterTemplates = async (templateId: string): Promise<IChapterTemplateBase | undefined> => {
    const dev = await db.chapterTemplates.get(templateId);
    return dev;
};

export const GetAllChapterTemplates = async (): Promise<IChapterTemplateBase[] | undefined> => {
    const dev = await db.chapterTemplates.toArray();
    return dev;
};

// Chapter Template Library
export const UpdateChapterTemplateMeta = async (chapterId: string, updates: Partial<ChapterMeta>): Promise<void> => {
    const allPromises: Promise<unknown>[] = [];
    allPromises.push(db.chapterTemplates.update(chapterId, updates));

    // allPromises.push(db.chapterMetas.update(chapterId, updates));
    // allPromises.push(db.chapterBodies.update(chapterId, updates));
    await Promise.all(allPromises);
};

// Chapter Template Library
export const UpdateChapterTemplateInDB = async (templateId: string, updates: Partial<IChapterTemplateBase>): Promise<void> => {
    await db.chapterTemplates.update(templateId, updates).catch (function (err) {
        Bugsnag.notify(err);
        console.log(err);
    });
};

export const GetChapterFromDB = async (chapterId: string): Promise<Chapter | null> => {
    const allPromises: Promise<ChapterMeta | ChapterBody | undefined>[] = [];

    allPromises.push(db.chapterMetas.get(chapterId));
    allPromises.push(db.chapterBodies.get(chapterId));

    const [chapterMeta, chapterBody] = await Promise.all(allPromises);

    if (chapterMeta && chapterBody) {
        const chapter: Chapter = { ...(chapterMeta as ChapterMeta), ...(chapterBody as ChapterBody) };
        return chapter;
    }
    return null;
};

export const UpdateChapterInDB = async (chapterId: string, updates: Partial<Chapter>): Promise<void> => {
    await db.chapterBodies.update(chapterId, updates).catch (function (err) {
        Bugsnag.notify(err);
        console.log(err);
    });
};

export const UpdateBookInDB = async (bookId: string, updates: Partial<Book>): Promise<void> => {
    await db.books.update(bookId, updates);
};

export const UpdateChapterMeta = async (chapterId: string, updates: Partial<ChapterMeta>): Promise<void> => {
    const allPromises: Promise<unknown>[] = [];
    allPromises.push(db.chapterMetas.update(chapterId, updates));
    allPromises.push(db.chapterBodies.update(chapterId, updates));
    await Promise.all(allPromises);
};

export const DeleteChaptersFromDB = async (chapterIds: string[]): Promise<void> => {
    const allPromises: Promise<unknown>[] = [];

    allPromises.push(db.chapterMetas.where("_id").anyOf(chapterIds).delete());
    allPromises.push(db.chapterBodies.where("_id").anyOf(chapterIds).delete());

    await Promise.all(allPromises);
};

//Themes
export const SaveThemeToDB = async (theme: ThemeConfig): Promise<void> => {

    const themeConfigArray: ThemeConfig[] = [];

    if (theme.themeId) {
        const tempThemeMeta: ThemeConfig = {
            themeId: theme.themeId,
            baseFontSize: theme.baseFontSize,
            printBaseFont: theme.printBaseFont,
            layout: theme.layout,
            titleCard: {
                chapterNumber: theme.titleCard.chapterNumber,
                title: theme.titleCard.title,
                subtitle: theme.titleCard.subtitle,
                image: theme.titleCard.image,
            },
            paragraph: {
                paragraphSpacing: theme.paragraph.paragraphSpacing,
                indent: theme.paragraph.indent,
                hyphens: theme.paragraph.hyphens,
                justify: theme.paragraph.justify
            },
            firstParagraph: {
                uppercaseFourWords: theme.firstParagraph.uppercaseFourWords,
                indent: theme.firstParagraph.indent,
                dropcap: theme.firstParagraph.dropcap
            },
            coloredImg: theme.coloredImg,
            ornamentalBreakImage: theme.ornamentalBreakImage,
            ornamentalBreakWidth: theme.ornamentalBreakWidth,
            titleAlignment: theme.titleAlignment,
            beginFirstSentence: theme.beginFirstSentence,
            chapterNumbering: theme.chapterNumbering,
            trim: {
                height: theme.trim.height,
                width: theme.trim.width
            },
            margin: {
                bottom: theme.margin.bottom,
                top: theme.margin.top,
                outer: theme.margin.outer,
                inner: theme.margin.inner
            },
            _id: theme._id,
            bookId: theme.bookId,
            createdAt: theme.createdAt,
            startPage: theme.startPage,
            lastUpdateAt: theme.lastUpdateAt,
            modifiedAt: theme.lastUpdateAt,
            lastSuccessfulSync: theme.lastUpdateAt,
            notesMode: theme.notesMode,
            ePubNotesMode: theme.ePubNotesMode,
            dynamicPageBreaks: theme.dynamicPageBreaks,
            allChangesSynced: true
        };
        const allPromises: Promise<unknown>[] = [];

        allPromises.push(db.themeConfig.put({
            ...tempThemeMeta,
            modifiedAt: theme.lastUpdateAt,
            lastSuccessfulSync: theme.lastSuccessfulSync,
        }));

        await Promise.all(allPromises);

        return;

    }

};

export const GetThemeFromDB = async (bookId: string, options?: ThemeQueryInclude): Promise<ThemeConfig | undefined> => {
    const theme = await db.themeConfig.get({ bookId });

    //Commented due to errors of book not loading on the stagings
    // if (theme) {
    //  const allPromises: Promise<unknown>[] = [];
    //  allPromises.push(db.themeConfig.where("bookId").equals(theme.bookId).toArray());

    //  await Promise.all(allPromises);
    // }
    return theme;
};

export const UpdateThemeInDB = async (bookId: string, updates: Partial<ThemeConfig>): Promise<void> => {
    await db.themeConfig.update(bookId, updates);
};

export const SaveAllThemes = async (themes: ThemeBase[]): Promise<void> => {
    const allThemes: ThemeBase[] = [];

    if (themes) {
        for (const theme of themes) {
            const allT: ThemeBase = {
                _id: theme._id,
                name: theme.name,
                fonts: theme.fonts,
                css: theme.css,
                createdAt: theme.createdAt,
                lastUpdateAt: theme.lastUpdateAt,
                modifiedAt: theme.modifiedAt,
                lastSuccessfulSync: theme.lastSuccessfulSync,
                allChangesSynced: true
            };
            allThemes.push(allT);
        }

        const allPromises: Promise<unknown>[] = [];
        allPromises.push(db.themeBase.bulkPut(allThemes));
    }
    return;
};

export const SavePreviewDevice = async (device: DeviceSpec[]): Promise<void> => {
    const allDevices: DeviceSpec[] = [];
    if (device) {
        for (const dv of device) {
            const allT: DeviceSpec = {
                _deviceName: dv._deviceName,
                image: dv.image,
                fonts: dv.fonts
            };
            allDevices.push(allT);
        }
    }
    const allPromises: Promise<unknown>[] = [];
    allPromises.push(db.deviceConfig.bulkPut(allDevices));
    return;
};

export const GetPreviewDeviceDB = async (_deviceName: string): Promise<DeviceSpec | undefined> => {
    const dev = await db.deviceConfig.get(_deviceName);
    return dev;
};

export const GetAllPreviewDevices = async () => {
    const bookTheme = await db.deviceConfig.toArray();

    const unique = bookTheme.map((a) => {
        return a.image;
    });
    return unique;
};

export const GetAllTheme = async (_id: string): Promise<ThemeBase | undefined> => {
    const bookTheme = await db.themeBase.get(_id);
    return bookTheme;
};

// Error Visualization
export const SaveErrorBook = async (error: string[]): Promise<void> => {
    const errorBooks: ErrorBook[] = [];
    if(error) {
        for(const er of error) {
            const allE = {
                _bookId: er
            };
            errorBooks.push(allE);
        }
    }
    const allPromises: Promise<unknown>[] = [];
    allPromises.push(db.failedBooks.bulkPut(errorBooks) );
    return;
};

// Error Visualization
export const GetErrorBook = async (): Promise<ErrorBook[] | undefined> => {
    try {
        const errorBook = await db.failedBooks.toArray();
        return errorBook;
    } catch (error) {
        console.log(error);
    }
};

// Error Visualization
export const SaveErrorBookChapter = async (error: ErrorChapter): Promise<void> => {
    const errorChapters: ErrorChapter[] = [];

    if(error) {
        const allPromises: Promise<unknown>[] = [];
        allPromises.push(db.failedChapters.put({
            ...error
            // lastSuccessfulSync: error.lastSuccessfulSync
         }) );
        return;
    }

};

// Error Visualization
export const GetErrorChapters = async (): Promise<ErrorChapter[] | undefined>=> {
    try {
        const errorChapter = await db.failedChapters.toArray();
        return errorChapter;
    } catch (error) {
        console.log(error);
    }
};
