import React, { FunctionComponent, useState, ReactNode, useEffect } from "react";
import { useHistory } from "react-router-dom";

// components
import { ArrowUpOutlined, PlusOutlined } from "@ant-design/icons";

// custom components
import NewBookModal from "./NewBookModal";
import UploadBookModal from "./UploadBookModal";

import { useOnlineStatus } from "../../utils/isOffline";

import useRootStore from "../../store/useRootStore";

interface CardButtonProps {
  title: string;
  Icon: ReactNode;
  disabled: boolean;
  onClick: () => void;
}

const CardButton: FunctionComponent<CardButtonProps> = (props: CardButtonProps) => {
	const { Icon, disabled } = props;
	const [ hovering, setHovering ] = useState(false);

	return (
		<div 
			style={{
				cursor: "pointer",
				opacity: disabled ? 0.6 : 1,
			}} 
			onMouseDown={(e) => {
				e.preventDefault();
				props.onClick();
			}}
		>
			<div 
				onMouseEnter={() => setHovering(true)}
				onMouseLeave={() => setHovering(false)}
				className={`book-list-item card-button${hovering ? " card-button-hover" : ""}`}
			>
				<div className="book-cover no-background">
					{Icon}
				</div>
				<div className="meta">
					<span className="button-text">{props.title}</span>
				</div>
			</div>
		</div>
	);
};

interface NewBookToolbarProps {
  onUpload: (params: IShelfStore.BookForm) => Promise<unknown>;
  onNewBook: (params: IShelfStore.BookFormFile) => Promise<unknown>;
}

const NewBookToolbar: FunctionComponent = () => {
	const { onUpload, newBook } = useRootStore().shelfStore;
	const { setView, setMode } = useRootStore().bookStore;
	const { setTheme } = useRootStore().themeStore;

	const isOnline = useOnlineStatus();

	const { push } = useHistory();

	const [showUploadModal, setShowUploadModal] = useState(false);
	const [showNewBookModal, setShowNewBookModal] = useState(false);

	const inits: IThemeStore.ThemeConfig = {
		themeId: "finch",
		baseFontSize: 10,
		printBaseFont: "LibreCaslon", layout: "layout6",
		titleCard: {
			chapterNumber: true,
			title: true,
			subtitle: false,
			image: false,
		},
		allChangesSynced: false,
		beginFirstSentence: "chapter",
		bookId: "26f7352f-6626-4c28-b1c3-85e1dfd0d64a",
		chapterNumbering: "chapter+word",
		createdAt: "2021-06-10T14:12:28.325Z",
		firstParagraph: {
			uppercaseFourWords: false,
			indent: 0, 
			dropcap:false,
		},
        coloredImg: "all",
		lastSuccessfulSync: undefined,
		lastUpdateAt: "2021-08-30T08:48:44.699Z",
		margin: { bottom: 0.8, top: 0.8, outer: 1.27, inner: 2.2 },
		ornamentalBreakImage: "", //https://atticus-content.s3.amazonaws.com/ornamental-breaks/Black-11.png
        ornamentalBreakWidth: 50,
		paragraph: { paragraphSpacing: 1.5, indent: 2, hyphens: true, justify: true },
		titleAlignment: "left",
		trim: { height: 20.32, width: 12.7},
		notesMode:"FOOTNOTE",
		ePubNotesMode:"END_OF_CHAPTER",
		dynamicPageBreaks: {
			breakSubheadings: false,
			breakOrnamentalBreaks: false,
		},
		_id: ""
	}; 

	useEffect(() => {
		setTheme(inits);
		setMode(true);
		setView(false);
	}, []);

	return (
		<>
			<div 
				style={{ display: "flex" }}
				className="upload-book-icon-container black-card"
			>
				<CardButton
					title="Upload"
					disabled={!isOnline}
					Icon={<ArrowUpOutlined
						style={{ fontSize: "2rem" }}
					/>}
					onClick={() => {
						if (isOnline) setShowUploadModal(true);}
					}
				/>
			</div>
			<div 
				style={{ display: "flex" }}
				className="new-book-icon-container blue-card"
			>
				<CardButton
					disabled={false}
					title="New Book"
					Icon={<PlusOutlined
						style={{ fontSize: "2rem" }}
					/>}
					onClick={() => setShowNewBookModal(true)}
				/>
			</div>
			{
				showUploadModal ?
					<UploadBookModal
						onUpload={async (params) => {
							const bookId = await onUpload(params);
              setMode(false);
							setShowUploadModal(false);
							push(`/books/${bookId}`);
						}}
						onClose={() => { setShowUploadModal(false); }}
					/>
					: null}
			{
				showNewBookModal ? (
					<NewBookModal
						onNewBook={async (params) => {
							const bookId = await newBook(params);
              setMode(true);
							setShowNewBookModal(false);
							push(`/books/${bookId}`);
						}}
						onClose={() => { setShowNewBookModal(false); }}
					/>
				)
					: null}
		</>
	);
};

export default NewBookToolbar;