import React from "react";
import { Form, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Draggable } from "react-beautiful-dnd";

const SocialMedia = ({ platform, removeItem, index }) => {

	const AddOnBefore = () => (
		<div>
			<p className="sm-item-name">{platform.name}</p>
		</div>
	);

	return (
		<Draggable key={platform.id} draggableId={platform.id} index={index}>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
				>
					<Form.Item
						name={platform.id}
						rules={[
							{
								required: true,
								message: "Input something!"
							}
						]}
						initialValue={platform.username}
						className="sm-item"
						>
						<Input
							addonBefore={<AddOnBefore/>}
							addonAfter={<DeleteOutlined onClick={() => removeItem(platform.id)} className="sm-icon-deleteIcon"/>}
							className={snapshot.isDragging? "sm-dragged" : "sm-static"}
						/>
					</Form.Item>
				</div>
			)}
		</Draggable>
	);
};

export default SocialMedia;
