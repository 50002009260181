export interface GroupedShortCut {
  character: string;
  count: number;
  replaceWith: string;
}

export default [
  {
    character: "'",
    count: 2,
    replaceWith: "\"",
  },
  {
    character: ".",
    count: 3,
    replaceWith: "\u2026",
  },
  {
    character: "-",
    count: 2,
    replaceWith: "—",
  },
] as GroupedShortCut[];
