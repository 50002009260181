import React, { FunctionComponent } from "react";
import { Formik, FormikErrors } from "formik";
import { Button, Modal, Form, Input, FormItemProps } from "antd";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { generate as generateRandomString } from "randomstring";
import { ArrowRightOutlined } from "@ant-design/icons";

import SelectAuthor from "../Shared/Forms/SelectAuthor";
import SelectProject from "../Shared/Forms/SelectProject";

const commonFormItemProps = {
	labelAlign: "left",
	colon: false,
} as FormItemProps;

interface NewBookModalProps {
    onNewBook: (params: IShelfStore.BookForm) => Promise<unknown>;
    onClose: () => void;
}

const NewBookModal: FunctionComponent<NewBookModalProps> = ({ onNewBook, onClose }: NewBookModalProps) => {

	const layout = {
		labelCol: { span: 5 },
		wrapperCol: { span: 24 },
	};	

	const history = useHistory();

	return (
		<>
			<Formik
				initialValues={{ _id: uuidv4(), chapterId: generateRandomString(16), title: "", author: [], project: "" } as IShelfStore.BookForm}
				validate={() => {
					const errors: FormikErrors<IShelfStore.BookForm> = {};
					return errors;
				}}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					await onNewBook(values);
					setSubmitting(false);
					history.push(`/books/${values._id}/#chapter=${values.chapterId}/new-book`);
				}}
			>
				{({
					values,
					handleChange,
					submitForm,
					setFieldValue,
					isSubmitting,
				}) => (
					<Modal
						maskClosable={false}
						title={<h2 className="section-heading">{"Let's Start a New Journey"}</h2>}
						visible={true}
						style={{
							borderRadius: 10,
						}}
						onCancel={() => onClose()}
						footer={(
							<Button
								type="primary"
								block size="large"
								loading={isSubmitting}
								onMouseDown={submitForm}
							>
								{"Go Get Em Atticus!"} <ArrowRightOutlined />
							</Button>
						)}
					>
						<Form {...layout} layout="vertical">
							<div className="book-form">
								<Form.Item label="Title" {...commonFormItemProps} className="ant-input-extended">
									<Input placeholder="Title" name="title" onChange={handleChange} value={values.title} size="large" />
								</Form.Item>
								<Form.Item name="author" label="Author" {...commonFormItemProps} className="ant-input-extended ant-select-extended">
									<SelectAuthor placeholderText={"Author"} onSelect={v => setFieldValue("author", v)} value={values.author} />
								</Form.Item>
								<Form.Item name="project" label="Project" {...commonFormItemProps} className="ant-input-extended ant-select-extended">
									<SelectProject placeholderText={"Project Name"} onSelect={v => setFieldValue("project", v)} value={values.project} />
								</Form.Item>
							</div>
						</Form>
					</Modal>
				)}
			</Formik>
		</>
	);
};

export default NewBookModal;