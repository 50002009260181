import React, { FunctionComponent } from "react";

const A: FunctionComponent = () => (
	<div className="pregenerated__theme--box--wrapper">
		<div className="pregenerated__theme--box theme-layout">
			<div className="top__part--section theme__style--common" style={{
				justifyContent: "flex-start",
				alignItems: "flex-start",
				paddingLeft: "5px",
				paddingTop: "5px"
			}}>
				<div style={{  
							height: "60%",
							width: "100%",
							backgroundColor: "#FFFFFF",
							paddingTop: "15px"

						}}>
							<h6>Chapter #1</h6>
							<h4>Chapter Title</h4>
							<span>Subtitle</span>
						</div>
			</div>
			<div className="bottom__part--section">
				<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,</p> 
				<p>harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum.</p> 
				<p>architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit,</p>
			</div>
		</div>
		<div className="pregenerated__theme--box--hwrapper">
			<h4>No Image</h4>
		</div>
	</div>
);

const B: FunctionComponent = () => (
	<div className="pregenerated__theme--box--wrapper">
		<div className="pregenerated__theme--box theme-layout">
			<div className="top__part--section theme__style--common" style={{
				justifyContent: "flex-end",
				alignItems: "flex-start",
				backgroundColor: "#EBEBEB",
				paddingLeft: "5px",
				paddingTop: "15px",
			}}>
				<h6>Chapter #1</h6>
				<h4>Chapter Title</h4>
				<span>Subtitle</span>
			</div>
			<div className="bottom__part--section">
				<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,</p> 
				<p>harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum.</p> 
				<p>architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit,</p>
			</div>
		</div>
		<div className="pregenerated__theme--box--hwrapper">
			<h4>Background Image</h4>
		</div>
	</div>	
);

const C: FunctionComponent = () => (
	<div className="pregenerated__theme--box--wrapper">
		<div className="pregenerated__theme--box theme-layout">
			<div className="top__part--section theme__style--common" style={{
				justifyContent: "center",
				alignItems: "center",
				backgroundColor: "#EBEBEB",
				paddingLeft: 0,
				paddingTop: 0,
				paddingBottom: 0
			}}>
				<div style={{  
					height: "60%",
					width: "100%",
					backgroundColor: "#FFFFFF",

				}}>
					<h6>Chapter #1</h6>
					<h4>Chapter Title</h4>
					<span>Subtitle</span>
				</div>
				<div style={{
					height: "40%",
					width: "100%",
					backgroundColor: "#EBEBEB",
				}}></div>
			</div>
			<div className="bottom__part--section">
				<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,</p> 
				<p>harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum.</p> 
				<p>architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit,</p>
			</div>
		</div>
		<div className="pregenerated__theme--box--hwrapper">
			<h4>Image Element</h4>
		</div>
	</div>
);

export default {
	A,
	B,
	C
};