import React, { useState, FunctionComponent, ReactNode } from "react";
import { Transforms, Range } from "slate";
import { ReactEditor, useEditor } from "slate-react";
import { SlatePlugin } from "@udecode/slate-plugins-core";
import { Button, Popover } from "antd";

import EndnotesUpsertModal from "../../modals/EndnotesUpsert";

import "./styles.scss";

import {
	getRenderElement,
	ToolbarElement,
} from "@udecode/slate-plugins";

// Icons
import { EndnoteIcon } from "./partials/Icon";

// Types
import { EndnoteNode } from "./types";

interface EndnoteProps {
  className: string;
  element: EndnoteNode;
	children: ReactNode;
}

export const withEndnote = () => <T extends ReactEditor>(editor: T): ReactEditor => {
	const { isVoid } = editor;
	editor.isVoid = (element) => (element.type === "endnote" ? true : isVoid(element));

	return editor;
};

export const EndnoteComponent:FunctionComponent<EndnoteProps> = ({ className, element, children } : EndnoteProps) => {
	const editor = useEditor();
	const [open, setOpen] = useState<boolean>(false);

	const updateNote = (note: string) => {
		const path = ReactEditor.findPath(editor, element);

		Transforms.setNodes(editor, {
			...element,
			note,
		}, { at: path });

		resetForm();
	};

	const resetForm = () => {
		setOpen(false);
	};

	const PopOverContent = () => {
		const editBtn = () => {
			setOpen(true);
		};

		return (
			<div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
				<p style={{paddingRight: "10px"}}>{element.note}</p>
				<Button type="primary" onClick={editBtn}>Edit</Button>
			</div>
		);
	};

  return (
		<div contentEditable={false} style={{display: "inline"}}>
			{children}
			<Popover placement="bottom" content={PopOverContent}>
				<span className={className} onMouseDown={() => setOpen(true)}>
					<EndnoteIcon />
				</span>
			</Popover>

			{open && 
				<EndnotesUpsertModal visible={open} currentNote={element.note} updateNote={updateNote} onCancel={resetForm}  />
			}
		</div>
  );
};

const renderEndnote = () => {
	const Endnote = {
    component: EndnoteComponent,
    type: "endnote",
    hotkey: "",
    defaultType: "endnote",
    rootProps: {
      className: "slate-endnote",
    },
  };

	return getRenderElement(Endnote);
};

/**
 * Enables support for links.
 */
export const EndnotePlugin = (): SlatePlugin => {
	return {
		renderElement: renderEndnote(),
		inlineTypes: ["endnote"],
		voidTypes: ["endnote"]
	};
};

export const EndnoteToolbarButton: FunctionComponent = () => {
	const editor = useEditor();

	const [ open, setOpen ] = useState(false);
	const [ selection, setSelection ] = useState<Range | null>(null);

	const resetForm = () => {
		setOpen(false);
	};

	const addEndnote = (note: string) => {
		if (Range.isRange(selection)) {
			Transforms.insertNodes(editor, {
				type: "endnote",
				note,
				children: [{ text: "" }],
			}, {
				at: Range.isExpanded(selection) ? selection.focus : selection,
			});

			resetForm();
		}
	};

	return (
		<>
			<ToolbarElement
				type={"endnote"}
				icon={<EndnoteIcon />}
				onMouseDown={() => {
					const at = editor.selection;

					setSelection(at);
					setOpen(true);
				}}
			/>
			{open &&
				<EndnotesUpsertModal isInsert={true} visible={open} addNote={addEndnote} onCancel={resetForm} />
			}
		</>
	);
};
