import { makeAutoObservable } from "mobx";
import { Path, Range, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { AtticusClient } from "../api/atticus.api";

export class SocialProfileStore {

	showProfileModal = false;
	selectedSocialProfileId: string | null = null;
	userSMProfiles: ISocialProfileStore.ISMProfile[] = [];
	modalType = "";
	modalState: ISocialProfileStore.ModalState = "default";
	smAccountsToInsert: ISocialProfileStore.ISMAccount[] = [];
	smProfileToInsert: ISocialProfileStore.ISMProfile | null = null;
	smProfilePathToReplace: Path | null = null; 
	editorSelection: Range | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	setShowProfileModal = (visible: boolean) => {
		this.showProfileModal = visible;
	}

	setEditorSelection = (selection: Range | null) => {
		this.editorSelection = selection;
	}

	resetAndHideProfileModal = () => {
		this.setShowProfileModal(false);
		this.resetModal();
	}

	setModalState = (state: "default" | "editProfile" | "addNewProfile") => {
		this.modalState = state;
	}

	resetSelectedProfile = () => {
		this.selectedSocialProfileId = null;
	}

	resetModal = (leaveElementPath?: boolean) => {
		this.modalState = "default";
		this.resetSelectedProfile();
		this.resetSMProfileToInsert();
		if(!leaveElementPath){
			this.resetSelectSMProfilePath();
		}
	}

	showEditProfileModalFromEditor = (profileId: string) => {
		this.setSelectedSocialProfileId(profileId);
		this.setModalState("editProfile");
		this.setShowProfileModal(true);
	}

	setSelectedSocialProfileId = (id: string) => {
		this.selectedSocialProfileId = id;
	}

	setSMProfilePathToReplace = (path: Path | null) => {
		this.smProfilePathToReplace = path;
	}

	resetSelectSMProfilePath = () => {
		this.setSMProfilePathToReplace(null);
	}

	setUserSMProfiles = (profiles: ISocialProfileStore.ISMProfile[]) => {
		this.userSMProfiles = profiles;
	}

	setSMAccountsToInsert = (accounts: ISocialProfileStore.ISMAccount[]) => {
		this.smAccountsToInsert = accounts;
	}

	setSMProfileToInsert = (profile: ISocialProfileStore.ISMProfile) => {
		this.smAccountsToInsert = profile.smAccounts;
		this.smProfileToInsert = profile;
	}

	resetSMProfileToInsert = () => {
		this.smAccountsToInsert = [];
		this.smProfileToInsert = null;
	}

	addNewSMProfileToDB = async (profileParams: ISocialProfileStore.ICreateSMProfile) => {
		const createProfileResponse = await AtticusClient.CreateProfile(profileParams);
		await this.getSMProfilesFromDB();
		this.resetSelectedProfile();
		if(createProfileResponse.data){
			this.setSMProfileToInsert(createProfileResponse.data);
		}
		this.modalState = "default";
	}

	getSMProfilesFromDB = async () => {
		const profiles = await AtticusClient.GetProfiles();
		this.setUserSMProfiles(profiles.data);
	}

	editSMProfileInDB = async(profile: ISocialProfileStore.ISMProfile) => {
		await AtticusClient.UpdateProfile(profile);
		await this.getSMProfilesFromDB();
		this.resetSelectedProfile();
		this.setSMProfileToInsert(profile);
		this.modalState = "default";
	}

	deleteSMProfileFromDB = async(profileId: string) => {
		await AtticusClient.DeleteProfile(profileId);
		await this.getSMProfilesFromDB();
	}

	removeElementAtPath = (path: Path, editor: ReactEditor) => {
		Transforms.removeNodes(editor, { at: path });
	}

	insertAccountsIntoEditor = (editor: ReactEditor) => {
		if (this.smAccountsToInsert.length) {
			let location : Range | Path | null = null;
			if(this.smProfilePathToReplace){
				this.removeElementAtPath(this.smProfilePathToReplace, editor);
				location = this.smProfilePathToReplace;
			}else{
				location = this.editorSelection;
			}
			Transforms.insertNodes(
				editor,
				{
					type: "profile",
					url: this.smAccountsToInsert,
					_id: this.smProfileToInsert?._id,
					profileName: this.smProfileToInsert?.profileName,
					printAlign: this.smProfileToInsert?.printAlign,
					children: [{ text: "" }]
				},
				{
					at: location ? location : undefined,
				}
			);
			if (this.editorSelection) Transforms.setSelection(editor, this.editorSelection);
			this.setEditorSelection(null);
			this.resetAndHideProfileModal();
			this.resetSelectSMProfilePath();
		}
	}

}

export default new SocialProfileStore();