import React, { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Collapse, Popconfirm, Row, Col, Form, Input, Segmented, Button, Card, Radio, Checkbox, Popover } from "antd";
import useRootStore from "../../store/useRootStore";

//Styles
import "../Goals/goals.scss";
import { WarningOutlined } from "@ant-design/icons";

interface FindReplaceProps {
    doReplace: (r: IBookStore.ReplaceParams) => void
}

const FindReplace : React.FC<FindReplaceProps> = ({ doReplace }) => {
    const {
        extras,
        search,
        searchLevel,
        searchMatchedRanges,
        searchStep,
        setSearch,
        setExtras,
        doSearchSetCount,
        handleReplaceForBooks,
        debounceSearch,
        setSearcLevel,
        handleSearchNext,
        handleSearchPrevious
    } = useRootStore().bookStore;

    const totalSearchResults = searchMatchedRanges.length;
    const [draftReplace, setDraftReplace] = React.useState("");
    const [replacing, setReplacing] = useState(false);
    const [replaceCount, setReplaceCount] = useState(0);
    const [moreThanOneSearchWord, setMoreThanOneSearchWord] = useState(false);

    const content = (
        <div className="fnr-warning-popover-content">
            <p>Phrase searches are sensitive to text formatting.
            <a href="http://atticus.io/phrase-searches" target="_blank" rel="noopener noreferrer"> Learn More</a></p>
        </div>
      );

    const findInputSuffix = moreThanOneSearchWord ? (
    <Popover
        placement="topRight"
        color="#000000d9"
        content={content}
    >
        <WarningOutlined
            className="warning-icon-yellow"
        />
    </Popover>) : <span />;

    const handleSearchChange = (evt: React.SyntheticEvent<HTMLInputElement>) => {
        if (evt.currentTarget.value.split(" ").length > 1) {
            setMoreThanOneSearchWord(true);
        } else {
            setMoreThanOneSearchWord(false);
        }
        debounceSearch({ ...search, q: evt.currentTarget.value });
    };

    const handleReplaceChange = (evt: React.SyntheticEvent<HTMLInputElement>) => {
        setDraftReplace(evt.currentTarget.value);
    };

    const onReplaceOne = () => {
        doReplace({ text: draftReplace, all: false });
        const r = search.caseSensitive ? draftReplace : draftReplace.toLocaleLowerCase();
        if (r.includes(search.q)) {
            setReplaceCount(v => v + 1);
        }
    };

    const onReplaceAll = async () => {
        setReplacing(true);

        if(search.q.length < 0){
            setReplacing(false);
            return;
        }

        if (searchLevel === "book") {
            doReplace({ text: draftReplace, all: true });
            await handleReplaceForBooks(draftReplace);
        } else {
            doReplace({ text: draftReplace, all: true });
        }

        setReplacing(false);
    };

    const toggleCaseSensitive = () => {
        setSearch({
            ...search,
            caseSensitive: !search.caseSensitive,
        });
    };

    const toggleWholeWord = () => {
        setSearch({
            ...search,
            wholeWord: !search.wholeWord,
        });
    };

    useEffect(() => doSearchSetCount(), [search, searchLevel]);


    useEffect(() => {
        return () => {
            setExtras([]);
            setSearch({
                ...search,
                q: ""
            });
        };
    }, []);

    useEffect(() => {
        handleSearchNext();
    }, [replaceCount]);
    
    const booktotal = extras.reduce((acc, v) => acc + v.extra, 0);

    return (
        <div className="setting-area-col setting-area-forn">
            <Row justify="space-between" align="middle">
                <h2 className="setting-area-title">Find and Replace</h2>
            </Row>
            <Row>
                <Segmented
                    className="att-segment-a"
                    value={searchLevel.charAt(0).toUpperCase() + searchLevel.slice(1)}
                    onChange={v => setSearcLevel(v.toLocaleString().toLowerCase())}
                    block
                    options={["Chapter", "Book"]}
                />
            </Row>
            <Row className="fnr-input-section">
                <Col span={24}>
                    <Form.Item
                        name="find"
                        label="Find"
                    >
                        <Input className="fnr-input" onChange={handleSearchChange} status={moreThanOneSearchWord ? "warning" : ""} suffix={findInputSuffix} />
                    </Form.Item>
                </Col>
                {(totalSearchResults + booktotal) ? (
                    <div>
                        {searchLevel === "chapter" ? (
                            <div><b>{totalSearchResults === 0 ? 0 : searchStep + 1}</b> of <b>{totalSearchResults}</b></div>
                        ) : (
                            <div><b>{totalSearchResults === 0 ? 0 : searchStep + 1}</b> of <b>{booktotal}</b></div>
                        )}
                    </div>
                ) : null}
            </Row>
            <div className="spacer"></div>
            <Row>
                <Checkbox name="wholeWord" onChange={toggleWholeWord} value={search.wholeWord}>Match the whole word</Checkbox>
                <Checkbox name="caseSensitive" onChange={toggleCaseSensitive} value={search.caseSensitive}>Match case</Checkbox>
            </Row>
            <div className="spacer"></div>
            <Row gutter={16} justify="space-between">
                <Col span={12}>
                    <Button onClick={handleSearchPrevious} style={{ width: "100%" }} type="primary" htmlType="submit">Previous</Button>
                </Col>
                <Col span={12}>
                    <Button onClick={handleSearchNext} style={{ width: "100%" }} type="primary" htmlType="submit">Next</Button>
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={24}>
                    <Form.Item
                        name="replace"
                        label="Replace with"
                    >
                        <Input onChange={handleReplaceChange} />
                    </Form.Item>
                </Col>
            </Row>
            <div className="spacer"></div>
            {!replacing ? (
                <Row gutter={16} justify="space-between">
                    <Col span={12}>
                        <Button style={{ width: "100%" }} className="btn-a" type="primary" htmlType="submit" onClick={onReplaceOne}>Replace</Button>
                    </Col>
                    <Col span={12}>
                        <Popconfirm
                            title={`Are you sure to replace all ${searchLevel} occurences ?`}
                            onConfirm={onReplaceAll}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button style={{ width: "100%" }} className="btn-a" type="primary" htmlType="submit">Replace All</Button>
                        </Popconfirm>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Button block className="btn-g" disabled >Replacing...</Button>
                </Row>
            )}
        </div>
    );
};

export default observer(FindReplace);