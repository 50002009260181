import React, { FunctionComponent, useState, useEffect } from "react";
import "react-calendar-heatmap/dist/styles.css";
import { Select, Row, Col, Form, Slider, Button, Card, Checkbox, Radio, Switch } from "antd";

import useRootStore from "../../store/useRootStore";
import { trimSizes, editorFontsList } from "../Shared/helpers/printConfigs";
import useLocalStorage from "../../utils/hooks/useLocalStorage";

const { Option } = Select;

const inits: IAppStore.EditorConfigs = {
    indentation: "spaced",
    justified: false,
    font: "",
    fontsize: 16,
    linespacing: 1.5
};


const WritingSettings: FunctionComponent = () => {
    const [form] = Form.useForm();
    const prjProg = 49;
    const today = new Date();
    const { patchEditor, editor } = useRootStore().appStore;

    const [conf, setConf] = useLocalStorage("atticusEdtrConf", "");

    const handleChange = (v: Partial<IAppStore.EditorConfigs>) => {
        console.log({ v });
        const a = {
            ...editor,
            ...v
        };
        setConf(JSON.stringify(a));
        patchEditor(a);
    };

    const reset = () => {
        patchEditor(inits);
        setConf(JSON.stringify(inits));
        form.setFieldsValue(inits);
    };

    return (
        <div className="setting-area-col">
            <Form
                className="setting-area-form"
                form={form}
                layout="vertical"
                onValuesChange={handleChange}
                initialValues={conf ? JSON.parse(conf) : editor as IAppStore.EditorConfigs}
            >
                <Row justify="space-between" align="middle">
                    <h2 className="setting-area-title">Settings</h2>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="font" label="Font" shouldUpdate>
                            <Select placeholder="Select Font">
                                {/* <Option value=""></Option> */}
                                {editorFontsList.map((font, i) => <Option className={font._id} value={font._id} key={i}>{font.name}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="fontsize" label="Font Size" shouldUpdate>
                            <Slider handleStyle={{ border: "solid 2px #abc5e0", marginTop: "-5px" }} min={12} max={20} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="linespacing" label="Line Height" shouldUpdate>
                            <Slider handleStyle={{ border: "solid 2px #abc5e0", marginTop: "-5px" }} step={0.1} min={1} max={2} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="indentation"
                            label="Paragraph Settings"
                            className="goal-setting-radio-btns"
                            shouldUpdate
                        >
                            <Radio.Group>
                                <Radio value="indent">Indented</Radio >
                                <Radio value="spaced">Spaced</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Justified" valuePropName="checked" name="justified" shouldUpdate>
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Row>
                        <Form.Item
                            name="match"
                            label="Height of writing area"
                            className="goal-setting-check-btns"
                        >
                            <Checkbox.Group>
                            <div style={{ display: "block" }}>
                                <Checkbox value="one">8.5&quot; x 11&quot; </Checkbox>
                                <Checkbox value="two">8.3&quot; x 11.7&quot; (A4) </Checkbox>
                                <Checkbox value="three">5&quot; x 8&quot; </Checkbox>
                                <Checkbox value="four">6&quot; x 9&quot; </Checkbox>
                                <Checkbox value="five">Custom</Checkbox>
                            </div>
                            </Checkbox.Group>
                        </Form.Item>
                    </Row> 
                    <Row>
                        <Form.Item
                            name="custom-area"
                            label=""
                        // rules={[{ required: true, message: "Please enter the total word count!" }]}
                        >
                            <Row gutter={6} justify="space-between">
                            <Col span={12}><Input style={{ width: "100%" }} placeholder="Width" /></Col>
                            <Col span={12}><Input style={{ width: "100%" }} placeholder="Height" /></Col>
                            </Row>

                        </Form.Item>
                    </Row> 
                    <Row>
                        <Col span={24}>
                            <Form.Item
                            name="deleted-words"
                            >
                            <Checkbox.Group>
                                <Checkbox value="count-deleted">Apply to all books in this account</Checkbox>
                            </Checkbox.Group>
                            </Form.Item>
                        </Col>
                    </Row> 
                */}
                <Row>
                    <Col>
                        <Button size="small" type="link" onClick={reset}>Reset to Default</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};



export default WritingSettings;