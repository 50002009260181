import React, { useState, FunctionComponent, ReactNode, useEffect, useCallback } from "react";
import { SlatePlugin } from "@udecode/slate-plugins-core";
import { ReactEditor, useEditor } from "slate-react";
import { Transforms, Range, Text } from "slate";
import {
	getRenderElement,
	getNodeDeserializer,
	ToolbarElement,
} from "@udecode/slate-plugins";

import { Modal, Button, Form, Popover, Badge, Radio, RadioChangeEvent } from "antd";
import { EditOutlined } from "@ant-design/icons";

// types
import { InternalLink, LinkNode } from "./types";

import { LinkIcon } from "./partials/Icon";
import { checkHttps } from "../../../../utils/helper";
import { InternalLinkComponent } from "./partials/InternalLink";
// styles
import "./styles.scss";
import useRootStore from "../../../../store/useRootStore";
import { observer } from "mobx-react";
import { isNullOrUndefined } from "../../../../utils/strings";
import { AtticusClient } from "../../../../api/atticus.api";

// Form layout
const layout = {
	labelCol: { span: 24 },
	wrapperCol: { span: 24 },
};

interface LinkProps {
	className: string;
	children: ReactNode;
	element: LinkNode;
}

export const LinkComponent: FunctionComponent<LinkProps> = observer(({ className, children, element }: LinkProps) => {
	const editor = useEditor();

	const { showModal, setUpdateLinkUrl, dismissAllModals, newLinkUrl, nodeID } = useRootStore().editorModalStore;

	useEffect(() => {
		if (isNullOrUndefined(newLinkUrl.url) || isNullOrUndefined(newLinkUrl.action)) return;

		switch (newLinkUrl.action) {
			case "update":
				updateLink(newLinkUrl.url ?? "");
				break;
			case "delete":
				deleteLink();
				break;
			default:
				break;
		}

		return;
	}, [newLinkUrl]);


	const updateLink = (url: string) => {
		const path = ReactEditor.findPath(editor, element);
		if (nodeID === element.id) {
			Transforms.setNodes(editor, {
				...element,
				url,
			}, { at: path });
		}
		dismissAllModals();
	};


	const deleteLink = () => {
		const path = ReactEditor.findPath(editor, element);
		if (nodeID === element.id) {
			Transforms.unwrapNodes(editor, { at: path });
		}
		dismissAllModals();
	};

	const launchUpdateModal = (id: any) => {
		showModal("update_link");
		setUpdateLinkUrl(element.url, id, element?.linkType);
	};

	const PopOverContent = () => {
		const editBtn = () => {
			launchUpdateModal(element.id);
		};

		const visitBtn = () => {
			window.open(element.url, "_blank");
		};

		return (
			<div>
				<Button type="primary" onClick={editBtn}>Edit Link</Button>
				<Button onClick={visitBtn}>Visit Link</Button>
			</div>
		);
	};

	return (
		<>
			<a
				href=""
				className={className}
				title={element.url}
				style={{cursor: "text"}}
				>
				{children}
			</a>
			<Popover placement="bottom" content={PopOverContent}>
				<span style={{paddingLeft: "5px", cursor: "pointer", paddingRight: "10px"}}>
					<Badge count={<EditOutlined onClick={() => launchUpdateModal(element.id)}/>}/>
				</span>
			</Popover>
		</>
	);
});

const renderLink = () => {
	const Link = {
		component: LinkComponent,
		type: "a",
		hotkey: "",
		defaultType: "a",
		rootProps: {
			className: "slate-link",
		},
	};

	return getRenderElement(Link);
};

/**
 * Enables support for links.
 */
export const LinkPlugin = (): SlatePlugin => {
	return {
		renderElement: renderLink(),
		deserialize: {
			element: getNodeDeserializer({
				type: "a",
				node: (el) => ({
					type: "a",
					url: el.getAttribute("href"),
				}),
				rules: [{ nodeNames: "A" }],
			}),
		},
		inlineTypes: ["a"],
	};
};


export const LinksToolbarButton: FunctionComponent = () => {
	const editor = useEditor();
	const [open, setOpen] = useState(false);
	const [url, setUrl] = useState("");
	const [error, setError] = useState<string | null>(null);
	const [selection, setSelection] = useState<Range | null>(null);
	const internalLinkTemp: InternalLink = {
		type: "web-link",
		webLink: ""
	};
	const [internalLink, setInternalLink] = useState<InternalLink | undefined>(internalLinkTemp);
	const [btnLoading, setBtnLoading] = useState(false);

	const resetForm = () => {
		setOpen(false);
		setUrl("");
		setSelection(null);
		setInternalLink(internalLinkTemp);
	};

	const addLink = async () => {
		setBtnLoading(true);
		let booklinkerLink = "";
		const isBooklinker = internalLink?.type === "booklinker";
		
		if (!url.startsWith("#chapter") && !checkHttps(url)) {
			setError("Should be a valid link starting with https:// or mailto:");
			return;
		}

		if(isBooklinker){
			const res = await AtticusClient.CreateBooklinkerLink(url);
			if (res.success) {
				booklinkerLink = res.linkResult;
			} else {
				setError(res.responseStatus.message);
				setBtnLoading(false);
				return;
			}
		}

		if (Range.isRange(selection)) {
			if (Range.isExpanded(selection)) {
				Transforms.wrapNodes(editor, {
					type: "a",
					url: isBooklinker ? booklinkerLink : url,
					linkType: internalLink?.type || "",
					children: [],
				}, {
					at: selection,
					match: node => Text.isText(node),
					split: true,
				});
			} else {
				Transforms.insertNodes(editor, {
					type: "a",
					url: isBooklinker ? booklinkerLink : url,
					linkType: internalLink?.type || "",
					children: [{
						text: isBooklinker ? booklinkerLink : url,
					}],
				}, {
					at: selection,
				});
			}
		}
		resetForm();
		setBtnLoading(false);
	};

	const handleChangeChapterType = useCallback((e: RadioChangeEvent) => {
    if (e.target.value === "none") {
      setInternalLink(undefined);
      return;
    }

    setInternalLink({ ...internalLink, type: e.target.value });
  }, []);

	return (
		<>
			<ToolbarElement
				type={"a"}
				icon={<LinkIcon />}
				onMouseDown={() => {
					const at = editor.selection;

					setOpen(true);
					setSelection(at);
				}}
			/>
			<Modal
				visible={open}
				title="Add Link"
				onCancel={() => {
					resetForm();
					setBtnLoading(false);
				}}
				footer={
					internalLink?.type !== "booklinker" ?
					(
					
					<Button
						disabled={!url || error !== null}
						type="primary"
						block size="large"
						onMouseDown={() => addLink()}
					>Insert Link</Button>
				): (
					<>
						<Button
						disabled={!url || error !== null}
						type="primary"
						block size="large"
						loading={btnLoading}
						onMouseDown={() => addLink()}
						>
						Insert Universal Book Link
					</Button>

				<p style={{fontSize: "smaller", textAlign: "center", color: "gray", marginTop: "2px"}}>By using this feature, you agree to the Booklinker <a href="https://booklinker.com/terms" target="_blank" rel="noopener noreferrer" style={{textDecoration: "underline", color: "gray"}}>Terms and Privacy Policy</a></p>

				<p style={{textAlign: "center", marginTop: "10px",}}>Powered by <a href="https://booklinker.com/" target="_blank" rel="noopener noreferrer">Booklinker&#8482;</a></p>
				</>
				)}
			>
				<Form {...layout}>
					<Radio.Group
						optionType="button"
						buttonStyle="solid"
						value={internalLink ? internalLink.type : "web-link"}
						onChange={handleChangeChapterType}
					>
						<Radio.Button value="web-link">Web Link</Radio.Button>
						<Radio.Button value="internal-link">Internal Link</Radio.Button>
						<Radio.Button value="booklinker">Link to Book</Radio.Button>
					</Radio.Group>
					<InternalLinkComponent link={internalLink} setLink={setInternalLink} url={url} setUrl={setUrl} error={error} setError={setError} />
				</Form>
			</Modal>
		</>
	);
};
