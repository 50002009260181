import Dexie, { Table } from "dexie";

export class AppDatabase extends Dexie {
    books: Table<IBookStore.Book, string>;
    chapterBodies: Table<IChapterStore.ChapterBody, string>;
    chapterMetas: Table<IChapterStore.ChapterMeta, string>;
    authors: Table<IBookStore.BookAuthor, string>;
    projects: Table<IBookStore.BookProject, string>;
    themeConfig: Table<IThemeStore.ThemeConfig, string>;
    themeBase: Table<IThemeStore.ThemeBase, string>;
    deviceConfig: Table<IThemeStore.DeviceSpec, string>;
    bookGoals: Table<IGoalStore.Goal, string>;
    writingHabits: Table<IHabitStore.Habit, string>;
    sprintTimer: Table<ISprintTimerStore.SprintTimer, string>;
    chapterTemplates: Table<IChapterStore.IChapterTemplateBase, string>
    imageGallery: Table<any, string>
    failedBooks: Table<IBookStore.ErrorBook, string>;
    failedChapters: Table<IBookStore.ErrorChapter, string>;


    constructor() {
        super("AtticusDatabase");
        //
        // Define tables and indexes
        //
        this.version(10).stores({
            books: "&_id, title, author",
            chapterBodies: "&_id, bookId",
            chapterMetas: "&_id, bookId, index",
            authors: "&_id,name",
            projects: "&_id,name,books",
            themeConfig: "&_id, bookId",
            themeBase: "&_id, name",
            deviceConfig: "&_deviceName",
            bookGoals: "&_id, bookId",
            writingHabits: "&_id, userId",
            sprintTimer: "&_bookId",
            chapterTemplates: "&_id",
            imageGallery: "&_imgId",
            failedBooks: "&_bookId",
            failedChapters: "&_chapterId, _bookId",
        });

        this.books = this.table("books");
        this.chapterBodies = this.table("chapterBodies");
        this.chapterMetas = this.table("chapterMetas");
        this.authors = this.table("authors");
        this.projects = this.table("projects");
        this.themeConfig = this.table("themeConfig");
        this.themeBase = this.table("themeBase");
        this.deviceConfig = this.table("deviceConfig");
        this.bookGoals = this.table("bookGoals");
        this.writingHabits = this.table("writingHabits");
        this.sprintTimer = this.table("sprintTimer");
        this.chapterTemplates = this.table("chapterTemplates");
        this.imageGallery = this.table("imageGallery");
        this.failedBooks = this.table("failedBooks");
        this.failedChapters = this.table("failedChapters");
    }
}

export const db = new AppDatabase();
