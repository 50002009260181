interface FontSource {
  src: string;
  fontStyle?: string;
  fontWeight?: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: unknown;
}

export interface BulkLoad {
  family: string;
  fonts: FontSource[];
}

type FontDataFont = Pick<FontSource, "fontStyle" | "fontWeight"> & {
  fileName: string;
  [key: string]: unknown;
};

export interface FontsData {
  family: string;
  fonts: FontDataFont[];
  baseFontSizeMultiplier?: number
}

export const fallBackFontFamilies = [
  "NotoSans",
  "NotoSerif",
  "NotoSansMath",
  "NotoSansSymbols2",
  "canada1500",
];

const fontsData: FontsData[] = [
  {
    family: "RockSalt",
    fonts: [{ fileName: "RockSalt-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "Benne",
    fonts: [
      { fileName: "Benne-Regular.ttf", fontWeight: 400 },
      { fileName: "Benne-Bold.ttf", fontWeight: 900 },
      { fileName: "Benne-Italic.ttf", fontStyle: "italic" },
      {
        fileName: "Benne-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
    ],
  },
  {
    family: "Delius",
    fonts: [{ fileName: "Delius-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "Crushed",
    fonts: [{ fileName: "Crushed-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "HelveticaNeue",
    fonts: [{ fileName: "HelveticaNeue-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "Selima",
    fonts: [{ fileName: "Selima-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "GreatVibes",
    fonts: [{ fileName: "GreatVibes-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "Marcellus",
    fonts: [{ fileName: "Marcellus-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "EuphoriaScript",
    fonts: [{ fileName: "EuphoriaScript-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "Megrim",
    fonts: [{ fileName: "Megrim-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "EmilysCandy",
    fonts: [{ fileName: "EmilysCandy-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "PressStart2P",
    fonts: [{ fileName: "PressStart2P-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "Anton",
    fonts: [{ fileName: "Anton-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "Audiowide",
    fonts: [{ fileName: "Audiowide-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "Aldrich",
    fonts: [{ fileName: "Aldrich-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "Forum",
    fonts: [{ fileName: "Forum-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "SpecialElite",
    fonts: [{ fileName: "SpecialElite-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "Voltaire",
    fonts: [{ fileName: "Voltaire-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "SixCaps",
    fonts: [{ fileName: "SixCaps-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "TheanoDidot",
    fonts: [
      { fileName: "TheanoDidot-Regular.ttf", fontWeight: 400 },
      { fileName: "TheanoDidot-Bold.ttf", fontWeight: 900 },
      { fileName: "TheanoDidot-Italic.ttf", fontStyle: "italic" },
      {
        fileName: "TheanoDidot-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
    ],
  },
  {
    family: "YoungSerif",
    fonts: [
      { fileName: "YoungSerif-Regular.ttf", fontWeight: 400 },
      { fileName: "YoungSerif-Bold.ttf", fontWeight: 900 },
      { fileName: "YoungSerif-Italic.ttf", fontStyle: "italic" },
      {
        fileName: "YoungSerif-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
    ],
  },
  {
    family: "CinzelDecorative",
    fonts: [
      { fileName: "CinzelDecorative-Regular.ttf", fontWeight: 400 },
      { fileName: "CinzelDecorative-Bold.ttf", fontWeight: 900 },
    ],
  },
  {
    family: "Cinzel",
    fonts: [
      { fileName: "Cinzel-Regular.ttf", fontWeight: 400 },
      { fileName: "Cinzel-Bold.ttf", fontWeight: 900 },
    ],
  },
  {
    family: "BigShouldersDisplay",
    fonts: [
      { fileName: "BigShouldersDisplay-Regular.ttf", fontWeight: 400 },
      { fileName: "BigShouldersDisplay-Bold.ttf", fontWeight: 900 },
    ],
  },
  {
    family: "Syncopate",
    fonts: [
      { fileName: "Syncopate-Regular.ttf", fontWeight: 400 },
      { fileName: "Syncopate-Bold.ttf", fontWeight: 900 },
    ],
  },
  {
    family: "LifeSaversBold",
    fonts: [
      { fileName: "LifeSavers-Regular.ttf", fontWeight: 400 },
      { fileName: "LifeSavers-Bold.ttf", fontWeight: 900 },
    ],
  },
  {
    family: "VollkornSC",
    fonts: [
      { fileName: "VollkornSC-Regular.ttf", fontWeight: 400 },
      { fileName: "VollkornSC-Bold.ttf", fontWeight: 900 },
    ],
  },
  {
    family: "VollkornSC-Bold",
    fonts: [{ fileName: "VollkornSC-Bold.ttf", fontWeight: 900 }],
  },
  {
    family: "Spartan",
    fonts: [
      { fileName: "Spartan-Regular.ttf", fontWeight: 400 },
      { fileName: "Spartan-Bold.ttf", fontWeight: 900 },
      { fileName: "Spartan-Light.ttf", fontStyle: "italic" },
    ],
  },
  {
    family: "OldStandardTT",
    fonts: [
      { fileName: "OldStandardTT-Regular.ttf", fontWeight: 400 },
      { fileName: "OldStandardTT-Bold.ttf", fontWeight: 900 },
      { fileName: "OldStandardTT-Italic.ttf", fontStyle: "italic" },
    ],
  },
  {
    family: "Cardo",
    fonts: [
      { fileName: "Cardo104s.ttf", fontWeight: 400 },
      { fileName: "Cardob101.ttf", fontWeight: 900 },
      { fileName: "Cardoi99.ttf", fontStyle: "italic" },
      {
        fileName: "Cardo-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
    ],
  },
  {
    family: "CrimsonPro",
    fonts: [
      { fileName: "CrimsonPro-Regular.ttf", fontWeight: 400 },
      { fileName: "CrimsonPro-Bold.ttf", fontWeight: 900 },
      { fileName: "CrimsonPro-Italic.ttf", fontStyle: "italic" },
      {
        fileName: "CrimsonPro-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
    ],
    baseFontSizeMultiplier: 1.000390625,
  },
  {
    family: "LibreBaskerville",
    fonts: [
      { fileName: "LibreBaskerville.ttf", fontWeight: 400 },
      { fileName: "LibreBaskerville-Regular.otf", fontWeight: 400 },
      { fileName: "LibreBaskerville-Bold.otf", fontWeight: 900 },
      { fileName: "LibreBaskerville-Italic.otf", fontStyle: "italic" },
      {
        fileName: "LibreBaskerville-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
    ],
  },
  {
    family: "LibreCaslon",
    fonts: [
      { fileName: "LibreCaslon.ttf", fontWeight: 400 },
      { fileName: "LibreCaslon-Regular.ttf", fontWeight: 400 },
      { fileName: "LibreCaslon-Bold.ttf", fontWeight: 900 },
      { fileName: "LibreCaslon-Italic.ttf", fontStyle: "italic" },
      {
        fileName: "LibreCaslon-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
    ],
    baseFontSizeMultiplier: 0.9125,
  },
  {
    family: "Lora",
    fonts: [
      { fileName: "Lora-Regular.ttf", fontWeight: 400 },
      { fileName: "Lora-Bold.ttf", fontWeight: 900 },
      { fileName: "Lora-Italic.ttf", fontStyle: "italic" },
      { fileName: "Lora-BoldItalic.ttf", fontStyle: "italic", fontWeight: 900 },
    ],
  },
  {
    family: "Rosario",
    fonts: [
      { fileName: "Rosario-Regular.ttf", fontWeight: 400 },
      { fileName: "Rosario-Bold.ttf", fontWeight: 900 },
      { fileName: "Rosario-Italic.ttf", fontStyle: "italic" },
      {
        fileName: "Rosario-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
    ],
  },
  {
    family: "Spectral",
    fonts: [
      { fileName: "Spectral-Regular.ttf", fontWeight: 400 },
      { fileName: "Spectral-Bold.ttf", fontWeight: 900 },
      { fileName: "Spectral-Italic.ttf", fontStyle: "italic" },
      {
        fileName: "Spectral-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
    ],
  },
  {
    family: "SourceSansPro",
    fonts: [
      { fileName: "SourceSansPro-Regular.ttf", fontWeight: 400 },
      { fileName: "SourceSansPro-Bold.ttf", fontWeight: 900 },
      { fileName: "SourceSansPro-Italic.ttf", fontStyle: "italic" },
      {
        fileName: "SourceSansPro-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
    ],
  },
  {
    family: "Cantarell",
    fonts: [
      { fileName: "Cantarell-Regular.ttf", fontWeight: 400 },
      {
        fileName: "Cantarell-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
      { fileName: "Cantarell-Bold.ttf", fontWeight: 900 },
      { fileName: "Cantarell-Italic.ttf", fontStyle: "italic" },
    ],
  },
  {
    family: "RobotoCondensed",
    fonts: [
      { fileName: "RobotoCondensed-Regular.ttf", fontWeight: 400 },
      {
        fileName: "RobotoCondensed-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
      { fileName: "RobotoCondensed-Bold.ttf", fontWeight: 900 },
      { fileName: "RobotoCondensed-Italic.ttf", fontStyle: "italic" },
    ],
  },
  {
    family: "RobotoLight",
    fonts: [
      { fileName: "RobotoLight.ttf", fontWeight: 400 },
      { fileName: "RobotoLight-Italic.ttf", fontStyle: "italic" },
    ],
  },
  {
    family: "Roboto",
    fonts: [
      { fileName: "Roboto-Regular.ttf", fontWeight: 400 },
      {
        fileName: "Roboto-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
      { fileName: "Roboto-Bold.ttf", fontWeight: 900 },
      { fileName: "Roboto-Italic.ttf", fontStyle: "italic" },
    ],
  },
  {
    family: "Roboto",
    fonts: [
      {
        fileName: "Roboto-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
      { fileName: "Roboto-Bold.ttf", fontWeight: 900 },
      { fileName: "Roboto-Italic.ttf", fontStyle: "italic" },
      { fileName: "Roboto-Regular.ttf" },
      { fileName: "Roboto-Regular.ttf", fontWeight: 400 },
    ],
  },
  {
    family: "Poppins",
    fonts: [
      { fileName: "Poppins-Light.ttf", fontWeight: 300 },
      { fileName: "Poppins-Regular.ttf", fontWeight: 400 },
      {
        fileName: "Poppins-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
      { fileName: "Poppins-Bold.ttf", fontWeight: 900 },
      { fileName: "Poppins-Italic.ttf", fontStyle: "italic" },
    ],
  },
  {
    family: "AlegreyaSansSC",
    fonts: [
      { fileName: "AlegreyaSansSC-Light.ttf", fontWeight: 300 },
      { fileName: "AlegreyaSansSC-Regular.ttf", fontWeight: 400 },
      { fileName: "AlegreyaSansSC-Medium.ttf", fontWeight: 600 },
      {
        fileName: "AlegreyaSansSC-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
      { fileName: "AlegreyaSansSC-Bold.ttf", fontWeight: 900 },
      { fileName: "AlegreyaSansSC-Italic.ttf", fontStyle: "italic" },
    ],
  },
  {
    family: "Alegreya",
    fonts: [
      { fileName: "Alegreya-Regular.ttf", fontWeight: 400 },
      {
        fileName: "Alegreya-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
      { fileName: "Alegreya-Bold.ttf", fontWeight: 900 },
      { fileName: "Alegreya-Italic.ttf", fontStyle: "italic" },
    ],
  },
  {
    family: "Quantico",
    fonts: [
      { fileName: "Quantico-Regular.ttf", fontWeight: 400 },
      {
        fileName: "Quantico-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
      { fileName: "Quantico-Bold.ttf", fontWeight: 900 },
      { fileName: "Quantico-Italic.ttf", fontStyle: "italic" },
    ],
  },
  {
    family: "Raleway",
    fonts: [
      { fileName: "Raleway-Light.ttf", fontWeight: 300 },
      { fileName: "Raleway-Regular.ttf", fontWeight: 400 },
      {
        fileName: "Raleway-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
      { fileName: "Raleway-Bold.ttf", fontWeight: 900 },
      { fileName: "Raleway-Italic.ttf", fontStyle: "italic" },
    ],
  },
  {
    family: "Oswald",
    fonts: [
      { fileName: "Oswald-Regular.ttf", fontWeight: 400 },
      { fileName: "Oswald-Bold.ttf", fontWeight: 900 },
      { fileName: "Oswald-Light.ttf", fontStyle: "italic" },
    ],
  },
  {
    family: "Montserrat",
    fonts: [
      { fileName: "Montserrat-Regular.ttf", fontWeight: 400 },
      {
        fileName: "Montserrat-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
      { fileName: "Montserrat-Bold.ttf", fontWeight: 900 },
      { fileName: "Montserrat-Italic.ttf", fontStyle: "italic" },
    ],
  },
  {
    family: "EBGaramond",
    fonts: [
      { fileName: "EBGaramond.ttf", fontWeight: 400 },
      {
        fileName: "EBGaramond-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
      { fileName: "EBGaramond-Bold.ttf", fontWeight: 900 },
      { fileName: "EBGaramond-Italic.ttf", fontStyle: "italic" },
    ],
    // baseFontSizeMultiplier: 0.949
  },
  {
    family: "PTSans",
    fonts: [
      { fileName: "PTSans-Regular.ttf", fontWeight: 400 },
      {
        fileName: "PTSans-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
      { fileName: "PTSans-Bold.ttf", fontWeight: 900 },
      { fileName: "PTSans-Italic.ttf", fontStyle: "italic" },
    ],
  },
  {
    family: "PT Sans",
    fonts: [
      { fileName: "PTSans-Regular.ttf", fontWeight: 400 },
      {
        fileName: "PTSans-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
      { fileName: "PTSans-Bold.ttf", fontWeight: 900 },
      { fileName: "PTSans-Italic.ttf", fontStyle: "italic" },
    ],
  },
  {
    family: "Allura",
    fonts: [{ fileName: "Allura-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "BlackOpsOne",
    fonts: [{ fileName: "BlackOpsOne-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "DelaGothicOne",
    fonts: [{ fileName: "DelaGothicOne-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "EBGaramond12",
    fonts: [
      { fileName: "EBGaramond12-Regular.ttf", fontWeight: 400 },
      { fileName: "EBGaramond12-Italic.ttf", fontStyle: "italic" },
    ],
  },
  {
    family: "OpenDyslexic",
    fonts: [
      { fileName: "OpenDyslexic-Regular.ttf", fontWeight: 400 },
      { fileName: "OpenDyslexic-Bold.ttf", fontWeight: 900 },
      { fileName: "OpenDyslexic-Italic.ttf", fontStyle: "italic" },
      {
        fileName: "OpenDyslexic-BoldItalic.otf",
        fontStyle: "italic",
        fontWeight: 900,
      },
    ],
  },
  {
    family: "OpenSans",
    fonts: [
      { fileName: "OpenSans-Regular.ttf", fontWeight: 400 },
      { fileName: "OpenSans-Bold.ttf", fontWeight: 900 },
      { fileName: "OpenSans-Italic.ttf", fontStyle: "italic" },
      {
        fileName: "OpenSans-BoldItalic.ttf",
        fontStyle: "italic",
        fontWeight: 900,
      },
    ],
  },
  {
    family: "OpenSansLT",
    fonts: [
      { fileName: "OpenSansLT-Regular.ttf", fontWeight: 400 },
      { fileName: "OpenSansLT-Bold.ttf", fontWeight: 900 },
    ],
  },
  {
    family: "LifeSavers",
    fonts: [
      { fileName: "LifeSavers-Regular.ttf", fontWeight: 400 },
      { fileName: "LifeSavers-Bold.ttf", fontWeight: 900 },
    ],
  },
  {
    family: "CourierPrime",
    fonts: [
      { fileName: "CourierPrime-Regular.ttf", fontWeight: 400 },
      { fileName: "CourierPrime-Bold.ttf", fontWeight: 900 },
      {
        fileName: "CourierPrime-BoldItalic.ttf",
        fontWeight: 900,
        fontStyle: "italic",
      },
      { fileName: "CourierPrime-Italic.ttf", fontStyle: "italic" },
    ],
  },
  {
    family: "canada1500",
    fonts: [
      { fileName: "canada1500-Regular.ttf", fontWeight: 400 },
      { fileName: "canada1500-Bold.ttf", fontWeight: 900 },
      {
        fileName: "canada1500-BoldItalic.ttf",
        fontWeight: 900,
        fontStyle: "italic",
      },
      { fileName: "canada1500-Italic.ttf", fontStyle: "italic" },
    ],
  },
  {
    family: "NotoSans",
    fonts: [
      { fileName: "NotoSans-Regular.ttf", fontWeight: 400 },
      { fileName: "NotoSans-Bold.ttf", fontWeight: 900 },
      {
        fileName: "NotoSans-BoldItalic.ttf",
        fontWeight: 900,
        fontStyle: "italic",
      },
      { fileName: "NotoSans-Italic.ttf", fontStyle: "italic" },
    ],
  },
  {
    family: "NotoSerif",
    fonts: [
      { fileName: "NotoSerif-Regular.ttf", fontWeight: 400 },
      { fileName: "NotoSerif-Bold.ttf", fontWeight: 900 },
      {
        fileName: "NotoSerif-BoldItalic.ttf",
        fontWeight: 900,
        fontStyle: "italic",
      },
      { fileName: "NotoSerif-Italic.ttf", fontStyle: "italic" },
    ],
  },
  {
    family: "NotoSansMath",
    fonts: [{ fileName: "NotoSansMath-Regular.ttf", fontWeight: 400 }],
  },
  {
    family: "NotoSansSymbols2",
    fonts: [{ fileName: "NotoSansSymbols2-Regular.ttf", fontWeight: 400 }],
  },
];

const withRegularFontAsItalicFont = (fonts: FontDataFont[]) => {
  // Get font with fontWeight 400
  const font = fonts.find(({ fontWeight }) => fontWeight === 400);
  if (!font) return fonts;
  const { fileName } = font;
  // Add this font as Italic font
  const _fonts = [...fonts, { fileName, fontStyle: "italic" }];
  return _fonts;
};

export const hasItalicFont = (fonts: FontDataFont[]) => fonts.findIndex(({ fontStyle }) => fontStyle === "italic") !== -1;
export const hasBoldFont = (fonts: FontDataFont[]) => fonts.findIndex(({ fontWeight }) => fontWeight === 900) !== -1;
export const hasBoldItalicFont = (fonts: FontDataFont[]) => {
    return (
        fonts.findIndex(
            ({ fontWeight, fontStyle }) => fontWeight === 900 && fontStyle === "italic"
        ) !== -1
    );
};

const addFallbacksForMissingStyles = (fonts: FontDataFont[]) => {
  if (!hasItalicFont(fonts)) {
    /**
     * Use same font with fontWeight 400 as Italic font
     */
    return withRegularFontAsItalicFont(fonts);
  }
  return fonts;
};

/**
 * @param fontsBasePath Base path of the fonts directory without trailing slashes
 * @returns Fonts
 */
const getFonts = (fontsBasePath: string): BulkLoad[] => {
  const mappedFonts = fontsData.map(({ family, fonts }) => {
    const _fonts = addFallbacksForMissingStyles(fonts).map((font) => ({
      ...font,
      src: `${fontsBasePath}/${font.fileName}?renderer=pdf`,
    }));
    return {
      family,
      fonts: _fonts,
    } as BulkLoad;
  });

  return mappedFonts;
};

export const getFontGroup = (fontFamilyName: string): FontsData | null  => fontsData.find((font) => font.family === fontFamilyName) ?? null;

export { getFonts, fontsData };
