import React, { FunctionComponent, ReactNode } from "react";

import { Tooltip } from "antd";
//import { Tooltip } from '@material-ui/core';

interface TooltipIconProps{
  title: string;
  children: ReactNode;
}

export const TooltipIcon: FunctionComponent<TooltipIconProps> = ({ title, children}: TooltipIconProps) => {
	return(
		<Tooltip 
			placement="bottom" 
			title={title}
		>
			<div>
				{children}
			</div>
		</Tooltip>
	);
};