import React, { FunctionComponent } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { MoreHoriz } from "@material-ui/icons";
import { Collapse, Divider } from "antd";
import { observer } from "mobx-react";
import { find, merge } from "lodash";
//store
import useRootStore from "../../store/useRootStore";

// Defaults
import { isMergable } from "./defaults";

// types
import { ChapterMeta } from "../../types/chapter";

// styles
import "./index.scss";

//partials
import ChapterTemplateBlockItem from "./ChapterTemplateListItem";
import BodyDropdown from "./BodyDropdown";

const { Panel } = Collapse;


const reorder = (list: IChapterStore.ChapterMeta[], sourceIndex: number, destinationIndex: number) => {
	const result = Array.from(list);
	const [removed] = result.splice(sourceIndex, 1);
	result.splice(destinationIndex, 0, removed);
	return result;
};

const removeItem = (list: IChapterStore.ChapterMeta[], index: number) => {
	return [
		...list.slice(0, index),
		...list.slice(index + 1, list.length),
	];
};

const addItem = (list: IChapterStore.ChapterMeta[], index: number, newItem: IChapterStore.ChapterMeta) => {
	return [
		...list.slice(0, index),
		newItem,
		...list.slice(index, list.length),
	];
};

const Chapters = observer(() => {
	const { chapter, book, sortChapters, getAndSetChapterTemplate, mergeChapter, deleteChapter, body, chapterTemplate: chapTemplate } = useRootStore().bookStore;
  if (!book) return null;

	let frontMatter = book.frontMatter;
	let chapters = book.chapters;
	let backMatter = book.backMatter;

	const curChapterId = chapTemplate._id;

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		if (result.source.droppableId === result.destination.droppableId && result.source.index === result.destination.index) {
			return;
		}

		// Inside the same section
		if (result.source.droppableId === "frontmatter" && result.source.droppableId === result.destination.droppableId) {
			frontMatter = reorder(frontMatter, result.source.index, result.destination.index);
		}

		if (result.source.droppableId === "body" && result.source.droppableId === result.destination.droppableId) {
			chapters = reorder(chapters, result.source.index, result.destination.index);
			//set first chapter as begin on right as always
			chapters[0] = { ...chapters[0], startOn: "right" };
		}

		if (result.source.droppableId === "backmatter" && result.source.droppableId === result.destination.droppableId) {
			backMatter = reorder(backMatter, result.source.index, result.destination.index);
		}

		// between sections body <=> frontmatter

		if (result.source.droppableId === "body" && result.destination.droppableId === "frontmatter") {
			const movedItem = chapters[result.source.index];
			chapters = removeItem(chapters, result.source.index);
			frontMatter = addItem(frontMatter, result.destination.index, movedItem);
		}

		if (result.source.droppableId === "frontmatter" && result.destination.droppableId === "body") {
			const movedItem = frontMatter[result.source.index];
			frontMatter = removeItem(frontMatter, result.source.index);
			chapters = addItem(chapters, result.destination.index, movedItem);
		}

		// between sections body <=> backmatter

		if (result.source.droppableId === "body" && result.destination.droppableId === "backmatter") {
			const movedItem = chapters[result.source.index];
			chapters = removeItem(chapters, result.source.index);
			backMatter = addItem(backMatter, result.destination.index, movedItem);
		}

		if (result.source.droppableId === "backmatter" && result.destination.droppableId === "body") {
			const movedItem = backMatter[result.source.index];
			backMatter = removeItem(backMatter, result.source.index);
			chapters = addItem(chapters, result.destination.index, movedItem);
		}

		// between sections frontmatter <=> backmatter

		if (result.source.droppableId === "frontmatter" && result.destination.droppableId === "backmatter") {
			const movedItem = frontMatter[result.source.index];
			frontMatter = removeItem(frontMatter, result.source.index);
			backMatter = addItem(backMatter, result.destination.index, movedItem);
		}

		if (result.source.droppableId === "backmatter" && result.destination.droppableId === "frontmatter") {
			const movedItem = backMatter[result.source.index];
			backMatter = removeItem(backMatter, result.source.index);
			frontMatter = addItem(frontMatter, result.destination.index, movedItem);
		}

		sortChapters(frontMatter, chapters, backMatter);
	};

	const getChapterNumber = (arr: ChapterMeta[], index: number) => {
    if (arr.length == 0) return undefined;

		let chapterNumber = 0;
		for (let i = 0; i <= index; i += 1) {
			if (arr[i] && (arr[i].type === "chapter" || arr[i].type === undefined || arr[i].type === "uncategorized" || arr[i].type === "custom") && arr[i].includeIn !== "none") {
				if (arr[i].numbered === undefined || arr[i].numbered === true) chapterNumber += 1;
			}
		}

		if (arr[index] && (arr[index].type === "chapter" || arr[index].type === undefined || arr[index].type === "uncategorized" || arr[index].type === "custom") && arr[index].includeIn !== "none") {
			if (arr[index].numbered === undefined || arr[index].numbered === true) return chapterNumber;
		}
		return undefined;
	};

	return (
		<div>
			<DragDropContext onDragEnd={onDragEnd}>
				{/* Book Front-matter */}
				<div className="section-divider">
					<Divider />
				</div>

				{/* Book body */}
				<Collapse
					defaultActiveKey={["body"]}
					ghost
					className="sidebar-collapse"
				>
					<Panel header="" key="body" collapsible="header" extra={<BodyDropdown />} className="sidebar-collapse-custom-panel">
						<Droppable droppableId="body">
							{(provided) => (
								<div ref={provided.innerRef} {...provided.droppableProps}>
									<div style={{ minHeight: "2rem" }}>
										{[chapTemplate].map((c, i) => {
											return (
												<ChapterTemplateBlockItem
													chapter={chapTemplate}
													index={i}
													key={i}
													beginOn={i == 0 ? false : true}
													number={undefined}
													isBody={true}
													section="body"
													active={curChapterId === chapTemplate._id}
													deleteChapter={deleteChapter}
													setChapter={getAndSetChapterTemplate}
													// mergeChapter={mergeSupported ? mergeChapter : undefined}
													updateChapterMeta={(meta) => {
														// debouncedSaveChapterTemplateBodyUpdates(meta);
													}}
												/>
											);
										})}
										{provided.placeholder}
									</div>
								</div>
							)}
						</Droppable>
					</Panel>
				</Collapse>
				<div className="section-divider">
					<Divider />
				</div>

				{/* Book Back matter */}


			</DragDropContext>
		</div>
	);
});

export default Chapters;
