
import React, { FunctionComponent, useCallback } from "react";
import { Draggable } from "react-beautiful-dnd";
import { CloseOutlined, MoreHoriz, MenuBook } from "@material-ui/icons";
import { Menu, Dropdown, Collapse, Button, Modal, Divider, message, Tooltip, Badge } from "antd";
import {
	useParams,
	useHistory,
} from "react-router-dom";
import { AtticusClient } from "../../api/atticus.api";

//store
import useRootStore from "../../store/useRootStore";

import { useOnlineStatus } from "../../utils/isOffline";

import { CheckOutlined, WarningOutlined, MobileOutlined } from "@ant-design/icons";

// Defaults
import { sectionTypes, getLabel, isMergable } from "./defaults";

// types
import { ChapterType, ChapterMeta, SectionType } from "../../types/chapter";


const { confirm } = Modal;
const { SubMenu } = Menu;

interface ChapterBlockItemProps {
	chapter: IChapterStore.ChapterMeta;
	index: number;
	beginOn: boolean;
	isBody?: boolean;
	number?: number;
	section: SectionType;
	active?: boolean;
    extra?: any,
	setChapter: (chapterId: string) => void;
	deleteChapter: (chapterId: string) => void;
	mergeChapter?: (section: SectionType, chapterId: string) => Promise<void>;
	updateChapterMeta: (chapter: IChapterStore.ChapterMeta) => void;
}

const specialChapterTypes = ["toc", "endnotes"];
const nonConvertableChapterTypes = ["toc", "title", "image", "endnotes"];


const ChapterBlockItem: FunctionComponent<ChapterBlockItemProps> = (props: ChapterBlockItemProps) => {
	const {
		chapter,
		index,
		beginOn,
		isBody,
		number,
		section,
		active,
        extra,
		setChapter,
		deleteChapter,
		mergeChapter,
		updateChapterMeta,
	} = props;

	const isOnline = useOnlineStatus();
	const { storeChapterTemplate, syncChapterTemplate } =
    useRootStore().chapterStore;
	const { saveChapterMetaUpdates, getCurrentStoredBook, getChapterBodyById, getErrorChapter } = useRootStore().bookStore;
	const { refreshCache } = useRootStore().pdfCacheStore;
	const [error, setError] = React.useState(false);

	const numberSpan = isBody && number ? (<span className="number">{number}.</span>) : null;

	const draggable = specialChapterTypes.indexOf(chapter.type) === -1 || chapter.type === "endnotes";
	const deletable = specialChapterTypes.indexOf(chapter.type) === -1;
	const moreOptions = nonConvertableChapterTypes.indexOf(chapter.type) === -1;

	const { bookId } = useParams() as { bookId: string };
	const { theme, putBookTheme, style } = useRootStore().themeStore;

	const parseThemeConfig = (t: any) => {

		if (!theme) return null;

		const thm: IThemeStore.ThemeConfig = {
			...theme,
			baseFontSize: t.baseFontSize,
			chapterNumbering: t.chapterNumbering,
			titleAlignment: t.titleAlignment,
			coloredImg: "all",
			ornamentalBreakImage: t.ornamentalBreakImage || "",
			ornamentalBreakWidth: t.ornamentalBreakWidth,
			printBaseFont: t.printBaseFont,
			layout: t.layout,
			themeId: t.themeId,
			beginFirstSentence: t.beginFirstSentence,
			startPage: "toc",
			margin: {
				...theme.margin
			},
			titleCard: {
				...theme.titleCard
			},
			paragraph: {
				...theme.paragraph
			},
			firstParagraph: {
				...theme.firstParagraph
			},
			trim: {
				...theme.trim
			}
		};
		return thm;
	};

	const setStartPage = async () => {
		if (bookId) {
			const bkFromDB = await AtticusClient.GetBookTheme(bookId);

			const parsedTheme = parseThemeConfig(bkFromDB);
			const themeStyle = style as IThemeStore.ThemeBase;

			if (parsedTheme) return await putBookTheme(bookId, parsedTheme, themeStyle);
		}
		return false;
	};

	const onDelete = async (deleteChapterId: string, deleteChapterTitle: string) => {

		if (deleteChapterId) {
			const bkFromDB = await AtticusClient.GetBookTheme(bookId);
			if (bkFromDB.startPage === deleteChapterId || bkFromDB.startPage === deleteChapterTitle.trim().replace(/[^\w\s]/gi, "").replace(/ /g, "-")) {
				setStartPage();
				deleteChapter(chapter._id);
			} else {
				deleteChapter(chapter._id);
			}
		}

	};

	const frontMatterCovertOptions = sectionTypes.frontMatter.filter((type) => isMergable(type));
	const bodyCovertOptions = sectionTypes.body.filter((type) => isMergable(type));
	const backMatterCovertOptions = sectionTypes.backMatter.filter((type) => isMergable(type));

	const onConvert = async (type: string) => {
		updateChapterMeta({
			...chapter,
			type: type as ChapterType
		});
	};

	const toggleNumbered = () => {
		updateChapterMeta({
			...chapter,
			numbered: chapter.numbered || chapter.numbered === undefined ? false : true,
		});
	};

	const updateIncludeIn = (includeIn: "all" | "ebook" | "print" | "none") => {
		updateChapterMeta({
			...chapter,
			includeIn,
		});
    const {_id, type, startOn } = chapter;
    refreshCache(chapter.bookId, "chapter-properties-change", { "chapter-properties-change": { chapter: { chapterId: _id, chapterType: type, startOn: startOn, includeIn } } });
	};

	const updateStartOn = (startOn: "right" | "left" | "any") => {
		updateChapterMeta({
			...chapter,
			startOn,
		});
	};

	const getChapterCacheData = async () => {
		const { frontMatterIds, chapterIds, backMatterIds } = getCurrentStoredBook();
		const allChapterIds = [...frontMatterIds, ...chapterIds, ...backMatterIds];
		const chapterData = await getChapterBodyById(allChapterIds);
		const chapterCacheData = chapterData.map(({ _id, type, startOn }) => ({ chapterId: _id, chapterType: type, startOn } as IPDFCacheStore.ChapterCacheMetaData));
		return chapterCacheData;
	};

	const handleMergeChaptersClick = async (section: SectionType, chapterId: string) => {
		if (mergeChapter) {
			await mergeChapter(section, chapterId);
			const chapterCacheData = await getChapterCacheData();
			refreshCache(chapter.bookId, "chapter-merge", { "chapter-merge": { chapters: chapterCacheData } });
		}
	};

	const loadFailChapters = async () => {
		const failChaps = await getErrorChapter();
		if (failChaps) {
			const checkBook = failChaps.map((a) => {
				if (a._chapterId === chapter._id) {
					setError(true);
				}
			});
		}
	};

	const ChapterVisibilityIndicator = () => {
		if (chapter.includeIn == "print") {
			return <MenuBook />;
		}
		if (chapter.includeIn === "ebook") {
			return <MobileOutlined />;
		}
		return null;
	};

	React.useEffect(() => {
		loadFailChapters();
	}, [chapter]);

	return (
		<a
			onClick={() => {
				setChapter(chapter._id);
			}}
		>
			<Draggable
				draggableId={chapter._id}
				index={index}
				key={chapter._id}
				isDragDisabled={!draggable}
			>
				{(provided, snapshot) => (
					<div
						className={`chapter-block-item${snapshot.isDragging ? " hover" : ""}${active ? " active" : ""} ${chapter.includeIn === "none" ? " chapter-hidden" : ""}`}
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						style={{
							...provided.draggableProps.style,
						}}
					>
						<div className="text-area">
                            {extra  ? (
                                <>
                                    <Badge count={extra} style={{ marginRight: ".5em" }} />
                                </>
                            ): null}
							{chapter.title ? (
								<>
									<div>{numberSpan}</div>
									<div>{chapter.title}</div>
									{error ? (
										<div style={{ paddingLeft: 10 }}>
											<Tooltip title="Copy/Paste error" color={"#3568BA"} arrowPointAtCenter>
											<WarningOutlined style={{ color: "red" }} />
											</Tooltip>
										</div>
									) : null}
								</>
							) : (
								<>
									<div>{numberSpan}</div>
									<div>{numberSpan ? null : chapter.type === "chapter" ? "Untitled" : getLabel(chapter.type)}</div>
									{error ? (
										<div style={{ paddingLeft: 10 }}>
											<WarningOutlined style={{ color: "red" }} />
										</div>
									) : null}
								</>
							)}
						</div>
						<ChapterVisibilityIndicator/>
						<div className="action-group">
							{/*moreOptions ? (*/}
							<Dropdown
								trigger={["click"]}
								overlay={
									<Menu>
										{mergeChapter ? (
											<Menu.Item onClick={async () => {
												handleMergeChaptersClick(section, chapter._id);
											}}>Merge with next chapter</Menu.Item>
										) : null}
										{moreOptions ? (
											<SubMenu title="Convert To">
												{frontMatterCovertOptions.map((elem, i) => (
													<Menu.Item key={`frontMatter-${i}`} onClick={() => { onConvert(elem); }}>{getLabel(elem)}</Menu.Item>
												))}
												<Divider style={{ margin: 0, padding: 0 }} />
												{bodyCovertOptions.map((elem, i) => (
													<Menu.Item key={`body-${i}`} onClick={() => { onConvert(elem); }}>{getLabel(elem)}</Menu.Item>
												))}
												<Divider style={{ margin: 0, padding: 0 }} />
												{backMatterCovertOptions.map((elem, i) => (
													<Menu.Item key={`backMatter-${i}`} onClick={() => { onConvert(elem); }}>{getLabel(elem)}</Menu.Item>
												))}
											</SubMenu>
										) : null}
										{chapter.type !== "chapter" && chapter.type !== "uncategorized" && chapter.type !== "endnotes" ? (
											<SubMenu title="Include In">
												<Menu.Item
													icon={chapter.includeIn === undefined || chapter.includeIn === "all" ? <CheckOutlined /> : null}
													onClick={() => { updateIncludeIn("all"); }}
												>All</Menu.Item>
												<Divider style={{ margin: 0, padding: 0 }} />
												<Menu.Item
													icon={chapter.includeIn === "ebook" ? <CheckOutlined /> : null}
													onClick={() => { updateIncludeIn("ebook"); }}
												>E-Book Only</Menu.Item>
												<Menu.Item
													icon={chapter.includeIn === "print" ? <CheckOutlined /> : null}
													onClick={() => { updateIncludeIn("print"); }}
												>Print Only</Menu.Item>
												<Divider style={{ margin: 0, padding: 0 }} />
												<Menu.Item
													icon={chapter.includeIn === "none" ? <CheckOutlined /> : null}
													onClick={() => { updateIncludeIn("none"); }}
												>None</Menu.Item>
												{/*
                          <Divider style={{ margin: 0, padding: 0 }} />
                          <Menu.Item
                            icon={chapter.includeIn === "none" ? <CheckOutlined /> : null}
                            onClick={() => { updateIncludeIn("none"); }}
                          >Neither</Menu.Item>
                          */}
											</SubMenu>
										) : null}
										{beginOn ? (
											<SubMenu title="Begin On">
												<Menu.Item
													icon={chapter.startOn === undefined || chapter.startOn === "any" ? <CheckOutlined /> : null}
													onClick={() => { updateStartOn("any"); }}
												>Either Side</Menu.Item>
												<Menu.Item
													icon={chapter.startOn === "right" ? <CheckOutlined /> : null}
													onClick={() => { updateStartOn("right"); }}
												>Right-side</Menu.Item>
												<Menu.Item
													icon={chapter.startOn === "left" ? <CheckOutlined /> : null}
													onClick={() => { updateStartOn("left"); }}
												>Left-side</Menu.Item>
											</SubMenu>
										) : null}
										{chapter.type === "chapter" || chapter.type === "uncategorized" || chapter.type === "custom" || chapter.type === undefined ? (
											<Menu.Item
												icon={chapter.numbered === undefined || chapter.numbered === true ? <CheckOutlined /> : null}
												onClick={() => {
													toggleNumbered();
												}}
											>Numbered</Menu.Item>
										) : null}
										{chapter.type !== "endnotes" ? (
										<Menu.Item onClick={async () => {
											const templateId: string = await storeChapterTemplate(chapter._id, chapter.title, section);
											await saveChapterMetaUpdates({
												...chapter,
												templateId: templateId
											});
                      await syncChapterTemplate(true, templateId, chapter._id, false);

											message.success("Template created successfully", 4);
											//window.location.reload();
										}}>Save as a template</Menu.Item>
										): null}
									</Menu>
								}
							>
								<Button type="text" shape="round" size="middle" className="action-btn"><MoreHoriz className="action-icon" /></Button>
							</Dropdown>
							{/*) : null}*/}
							{deletable && isOnline ? (
								<Button type="text" shape="round" size="large" className="action-btn" onMouseDown={() => {
									confirm({
										icon: null,
										title: <h2 className="section-heading">Delete Chapter</h2>,
										content: `Are you sure you want to delete "${chapter.title}"`,
										centered: true,
										okType: "danger",
										okText: "Delete",
										okButtonProps: {
											type: "primary",
											danger: true,
											style: {
												flex: 1,
											},
										},
										onOk: () => {
											onDelete(chapter._id, chapter.title);
										},
										cancelButtonProps: {
											className: "btn-a",
											style: {
												flex: 1,
											},
										},
										cancelText: "Cancel",
									});
								}}>
									<CloseOutlined className="action-icon" />
								</Button>
							) : null}
							{/* {draggable ? (
                  <DragIndicator
                      className="action-icon"
                      style={{
                      cursor: "grab",
                      }}
                  />
              ) : null} */}
						</div>
					</div>
				)}
			</Draggable>
		</a>
	);
};

export default ChapterBlockItem;
