import React, { FunctionComponent } from "react";
import { Formik } from "formik";
import { Col, Row, Avatar, Form, Input, Button, FormItemProps } from "antd";
import { UserOutlined, EditFilled } from "@ant-design/icons";

import FileUpload from "../Shared/Forms/FileUpload";

import { MyProfileProps } from "../../types/profile";

const commonFormItemProps = {
	labelAlign: "left",
	colon: false,
} as FormItemProps;

const MyProfile: FunctionComponent<MyProfileProps> = ({ profile, OnSubmitHandler, OnProfilePictureUploadHanlder }: MyProfileProps) => {

	if (!profile) return null;

	return (
		<Formik
			initialValues={{
				firstName: "",
				lastName: "",
				website: "",
				profilePictureURL: ""
			}}
			onSubmit={OnSubmitHandler}
		>
			{({
				values,
				handleChange,
				handleSubmit,
				isSubmitting,
			}) => (
				<>
					<Form
						colon={false}
						layout={"vertical"}
						onSubmitCapture={handleSubmit}
					>
						<div className="card auth-card book-form book__form--extended">
							<Row>
								<Col span={24}>
									<div className="form-field">
										<Form.Item label="Photo" {...commonFormItemProps}>
											<FileUpload
												fileType="image/*"
												componentWidth={80}
												background={"transparent"}
												onFileUpload={OnProfilePictureUploadHanlder}
											>
												<div className="avatar__mask--container"><EditFilled style={{ fontSize: 20, color: "#FFFFFF" }} /></div>
												<Avatar 
													size="large" 
													src={profile.profilePictureURL} 
													icon={<UserOutlined style={{ fontSize: 50 }} />} 
													style={{ width: 80, height: 80, display: "flex", alignItems: "center", justifyContent: "center" }} 
												/>
											</FileUpload>
										</Form.Item>
									</div>                  
								</Col>
							</Row>
							<Row>
								<Col span={11}>
									<div className="form-field">
										<Form.Item label="First Name" {...commonFormItemProps}>
											<Input placeholder="First Name" name="firstName" onChange={handleChange} value={values.firstName || profile.firstName} size="large" />
										</Form.Item>
									</div>                  
								</Col>
								<Col span={11} push={2}>
									<div className="form-field">
										<Form.Item label="Last Name" {...commonFormItemProps} className="ant-input-extended">
											<Input placeholder="Last Name" name="lastName" onChange={handleChange} value={values.lastName || profile.lastName} size="large" />
										</Form.Item>
									</div>
								</Col>
							</Row>
							<Row>
								<Col span={24}>
									<div className="form-field">
										<Form.Item label="Email" {...commonFormItemProps} className="ant-input-extended">
											<Input disabled placeholder="Email" name="email" onChange={handleChange} value={profile.email} size="large" />
										</Form.Item>
									</div>
								</Col>
							</Row>								
							<Row>
								<Col span={24}>
									<div className="form-field">
										<Form.Item label="Website" {...commonFormItemProps} className="ant-input-extended">
											<Input placeholder="Website" name="website" onChange={handleChange} value={values.website || profile.website} size="large" />
										</Form.Item>
									</div>
								</Col>
							</Row>
							<div className="form-field">
								<Button 
									type="primary" 
									size="large"
									htmlType="submit" 
									loading={isSubmitting} 
								>
									{"Update"}
								</Button>
							</div>
						</div>
					</Form>
				</>
			)}
		</Formik>
	);
};

export default MyProfile;