import { find, filter } from "lodash";
import { toJS } from "mobx";
import { ChapterType } from "../../../types/chapter";
import { SlateBaseParentBlockNode } from "../../../types/slate";

/**
 *  This is an ugly fix to address an issue where deleted chapterIds still show in
 *  frontMatterIds, backMatterIds and chapterIds fields in certain books
 * 
 *  TODO: Re-evaluate chapter delete / sync logic to find the root cause for the above issue
 *  Run a script to clean up the book collection in the DB and remove deleted chapter Ids from
 *  frontMatterIds, backMatterIds and chapterIds fields
 */
export const isValidChapter = (chapterId, book) => {
  return !book.deletedChapterIds?.includes(chapterId);
};

export interface TOCSummaryItem {
  itemId: string;
  title: string;
  beforeNumbering?: boolean;
  chapterNumber: string;
  subtitle?: string;
  subheads?: Array<any>;
  chapterType?: ChapterType;
  includeIn: "all" | "ebook" | "print" | "none";
}

export const generateToc = (book, children?): TOCSummaryItem[] => {
  const toc: TOCSummaryItem[] = [];

  // generate frontmatter
  if (book.frontMatterIds) {
    let tocIndex = -1;

    book.frontMatterIds.forEach((fm, i) => {
      const chap = find(book.frontMatter, ["_id", fm]);
      if (chap && chap.type === "toc") tocIndex = i;
    });

    const beforeTOC = book.frontMatterIds.slice(0, tocIndex !== -1 ? tocIndex : book.frontMatterIds.length);
    const afterTOC = book.frontMatterIds.slice(tocIndex !== -1 ? tocIndex + 1 : book.frontMatterIds.length, book.frontMatterIds.length);

    // Before TOC

    for (let i = 0; i < beforeTOC.length; i += 1) {
      const chap = find(book.frontMatter, ["_id", beforeTOC[i]]);
      if (!chap) continue;
    }

    // After TOC
    for (let i = 0; i < afterTOC.length; i += 1) {
      const chap = find(book.frontMatter, ["_id", afterTOC[i]]);
      if (!chap || !isValidChapter(chap._id, book)) continue;

      const subheads: any = [];
      let chapId: any = [];
      if (children && children.length > 0) chapId = children.filter(chapter => chapter._id === chap._id);

      if (chapId.length > 0) {
        chapId[0].children.forEach(child => {
          if (child && child.type === "h2") subheads.push(child.children[0]);
          else if (child.type === "align_center" || child.type === "align_right") {
            if (child.children.length > 0 && child.children[0].type === "h2") {
              subheads.push(child.children[0].children[0]);
            }
          }
        });
      }

      if (chap.type !== "custom") {
        toc.push({
          itemId: chap._id,
          title: chap.title,
          beforeNumbering: true,
          chapterNumber: "",
          subheads: book.showTocSubheading ? subheads : [],
          chapterType: chap.type,
          includeIn: chap.includeIn || "all",
        });
      }
    }
  }

  let curChapterNumber = 1;

  // generate body
  if (book.chapterIds) {

    for (let i = 0; i < book.chapterIds.length; i += 1) {
      const chap = find(book.chapters, ["_id", book.chapterIds[i]]);
      if (!chap || !isValidChapter(chap._id, book)) continue;

      let numbered = false;

      if ((chap.type === undefined || chap.type === "chapter" || chap.type === "uncategorized") && (chap.numbered === undefined || chap.numbered)) {
        numbered = true;
      }

      // const subheads = chap.children;

      const subheads: any = [];
      let chapContent: any = [];
      if (children && children.length > 0) chapContent = children.filter(chapter => chapter._id === chap._id);

      // if(chapId.length>0){  
      //   chapId[0].children.forEach(child => {
      //     if( child &&child.type==="h2") subheads.push(child.children[0]);
      //     else if(child.type ==="align_center" || child.type==="align_right" ){
      //       if(child.children.length>0 && child.children[0].type==="h2") {
      //         subheads.push(child.children[0].children[0]);
      //       }
      //     }
      //   });
      //   }


      if (chapContent.length > 0) {
        chapContent[0].children.forEach(child => {
          if (child && child.type === "h2") subheads.push(child.children[0]);
          else if (child.children.length > 0) {
            for (const item of child.children as SlateBaseParentBlockNode[]) {
              if (item.type === "h2") subheads.push(item.children[0]);
            }
          }
        });
      }

      if (chap.type !== "custom") {
        toc.push({
          itemId: chap._id,
          title: chap.title
            ? chap.title
            : "Chapter " + `${numbered ? curChapterNumber : ""}`,
          chapterNumber: `${numbered ? curChapterNumber : ""}`,
          subtitle: book.showTocSubtitle ? chap.subtitle : undefined,
          subheads: book.showTocSubheading ? subheads : [],
          chapterType: chap.type,
          includeIn: chap.includeIn || "all",
        });
      }



      if (numbered) curChapterNumber += 1;
    }
  }

  // generate backmatter
  if (book.backMatterIds && book.showBackMatterTOC !== false) {
    for (let i = 0; i < book.backMatterIds.length; i += 1) {
      const chap = find(book.backMatter, ["_id", book.backMatterIds[i]]);
      if (!chap || !isValidChapter(chap._id, book)) continue;

      if (chap.type !== "custom") {
        toc.push({
          itemId: chap._id,
          title: chap.title,
          chapterNumber: "",
          includeIn: chap.includeIn || "all",
        });
      }
    }
  }

  return toc;
};