import { makeAutoObservable, observable } from "mobx";

export class EditorModalStore {
  blockquoteQuotee = ""
  blockquoteQuoteeId: number | null = null;
  activeModal: IEditorModalStore.ModalType = null;
  nodeID: IEditorModalStore.LinkID = null;
  // -- Start: Update Endnote Modal -- //
  updateEndNote: IEditorModalStore.EndNote = {
    note: null,
  };

  newEndNote: IEditorModalStore.EndNote = {
    note: null,
  };
  // -- End: Update Endnote Modal -- //

  // -- Start: Update Link Modal -- //
  updateLinkUrl: IEditorModalStore.LinkUrl = {
    url: null,
    linkType: undefined,
  };

  newLinkUrl: IEditorModalStore.LinkUrlAction = {
    action: null,
    url: null,
  };
  // -- End: Update Link Modal -- //

  constructor() {
    makeAutoObservable(this, {
      activeModal: observable.ref,
      blockquoteQuotee: observable.deep
    });
  }
  
  toggleBlockquoteModal = (open: boolean): void => {
    this.activeModal = open ? "blockquote" : null;
  };

  setBlockqouteQuotee = (quotee: string): void => {
    this.blockquoteQuotee = quotee;
  };

  setBlockqouteQuoteeId = (id: number | null): void => {
    this.blockquoteQuoteeId = id;
  };

  showModal = (activeModal: IEditorModalStore.ModalType): void => {
    this.activeModal = activeModal;
  };

  dismissAllModals = (): void => {
    // Reset all state
    this.activeModal = null;

    // Update Link Url Modal
    this.updateLinkUrl = {
      url: null,
    };

    this.newLinkUrl = {
      url: null,
      action: null
    };
  };

  // -- Start: Update Link Modal -- //
  setUpdateLinkUrl = (url: IEditorModalStore.LinkUrlType, id: IEditorModalStore.LinkID, linkType?: any): void => {
    this.updateLinkUrl = {
      url: url,
      linkType: linkType,
    };
    this.nodeID = id;
  };

  setNewLinkUrl = (url: IEditorModalStore.LinkUrlType, action: IEditorModalStore.LinkUrlActionType): void => {
    this.newLinkUrl = {
      url: url,
      action: action,
    };
  };
  // -- End: Update Link Modal -- //
}

export default new EditorModalStore();
