import React, { FunctionComponent } from "react";
import { SvgIcon } from "@material-ui/core";

interface IconProps{
  size?: number;
  active?: boolean;
}

export const LinkIcon: FunctionComponent<IconProps> = ({ size = 15 }: IconProps) => (
	<SvgIcon viewBox="0 0 150 150" style={{ width: size }} className="slate-ToolbarButton">
		<g id="Group_37" data-name="Group 37">
			<g id="Group_36" data-name="Group 36">
				<path id="Path_77" data-name="Path 77" className="cls-1" d="M63.46,110.46l-15.9,15.9A16.9,16.9,0,1,1,23.3,102.82l.36-.36L55.53,70.58a16.92,16.92,0,0,1,23.9,0A5.64,5.64,0,0,0,88,63.2a7.66,7.66,0,0,0-.57-.57,28.17,28.17,0,0,0-39.84,0L15.64,94.52A28.18,28.18,0,0,0,55.5,134.36h0l15.89-15.9A5.63,5.63,0,1,0,64,110a4.94,4.94,0,0,0-.56.56Z" />
				<path className="cls-1" d="M35.57,147a32.59,32.59,0,0,1-23-55.63l31.9-31.89a32.63,32.63,0,0,1,46-.1,10.55,10.55,0,0,1,.88.89A10.05,10.05,0,0,1,76.22,73.62a12.5,12.5,0,0,0-17.57.09L26.46,105.89a12.5,12.5,0,0,0,.28,17.66,12.46,12.46,0,0,0,17.65-.27l16-15.94h0c.25-.26.5-.51.77-.74a10.06,10.06,0,0,1,14.18,1,10.07,10.07,0,0,1-.88,14.06l-15.8,15.8a32.37,32.37,0,0,1-23,9.55ZM67.46,58.81a23.7,23.7,0,0,0-16.8,7L18.77,97.64a23.76,23.76,0,0,0,16.8,40.56h0a23.57,23.57,0,0,0,16.8-7L68.5,115.13a1.22,1.22,0,0,0,.12-1.72,1.18,1.18,0,0,0-.83-.42,1.2,1.2,0,0,0-.89.29l-.11.12-.9-.51.7.69-15.9,15.9A21.32,21.32,0,0,1,20.13,99.74l.45-.45L52.41,67.46a21.34,21.34,0,0,1,30.14,0l.22.23a1.22,1.22,0,1,0,1.84-1.6A.57.57,0,0,0,84.5,66l-.24-.22A23.7,23.7,0,0,0,67.46,58.81Z" />
				<path id="Path_78" data-name="Path 78" className="cls-1" d="M134.36,15.62a28.19,28.19,0,0,0-39.85,0L75.42,34.75a5.64,5.64,0,0,0,8,8l19.14-19.11a16.9,16.9,0,0,1,23.9,23.9L91.35,82.56a16.9,16.9,0,0,1-23.9,0,5.64,5.64,0,1,0-8.52,7.38,7.66,7.66,0,0,0,.57.57,28.17,28.17,0,0,0,39.84,0L134.4,55.44A28.17,28.17,0,0,0,134.36,15.62Z" />
				<path className="cls-1" d="M79.42,103.16a32.53,32.53,0,0,1-23-9.43,10.66,10.66,0,0,1-.88-.9A10.05,10.05,0,0,1,70.66,79.52a12.51,12.51,0,0,0,17.57-.08l35.06-35.07a12.49,12.49,0,0,0-8.82-21.14h-.19a12.37,12.37,0,0,0-8.69,3.53L86.26,46a10.1,10.1,0,0,1-13.17,0,10,10,0,0,1-1-14.17l.21-.23L91.38,12.5a32.64,32.64,0,0,1,46.1,0h0a32.63,32.63,0,0,1,.05,46.07L102.46,93.63A32.5,32.5,0,0,1,79.42,103.16ZM63.19,85a1.2,1.2,0,0,0-.8.3,1.22,1.22,0,0,0-.12,1.72l.13.13.22.2a23.77,23.77,0,0,0,33.6,0l35.06-35.06a23.79,23.79,0,0,0,0-33.58h0a23.8,23.8,0,0,0-33.61,0l-18.93,19a1.22,1.22,0,0,0,1.7,1.7l19-19a21.32,21.32,0,0,1,30.19,30.11L94.47,85.68a21.35,21.35,0,0,1-30.15,0l-.21-.23a1.16,1.16,0,0,0-.83-.41Z" />
			</g>
		</g>
	</SvgIcon>
);