import { toWords as numbersToWord } from "number-to-words";
import {
    themeProperties,
} from "../configs/prebuilt-theme-properties";
import { getThemeConfigsForChapterTypes } from "../configs/chapter-theme-configs";
import { PdfThemeConfig, PdfChapterType, PdfChapter, PdfExpandedBook, PdfCustomThemeHeader, PdfCustomImages, PdfThemeBase } from "../types";
import { flatten } from "lodash";
import { CustomImages, ThemeBase } from "../../../../types/theme";
import { ADDITIONAL_CHAPTER_MARGINS, CM_FACTOR, fontsData } from "../configs";
import { Node } from "slate";

const getChapterNumber = (type: string | null, chapterNumber?: number) => {
    if (!chapterNumber) return null;
    switch (type) {
        case "number":
            return `${chapterNumber}`;
        case "chapter+number":
            return `Chapter ${chapterNumber}`;
        case "word":
            return `${numbersToWord(chapterNumber)}`;
        case "chapter+word":
            return `Chapter ${numbersToWord(chapterNumber)}`;
        default:
            return null;
    }
};

const chapterNumberToTitleCase = (sentence: string) => {
    if (!sentence) return sentence;
    const words = sentence.split(/\s+/);
    return words
        .map(
            (word) => {
                if (word.indexOf("-") == -1)
                    return `${word.charAt(0).toUpperCase()}${word.substr(1).toLowerCase()}`;
                
                return word.split("-").map((_word) => `${_word.charAt(0).toUpperCase()}${_word.substr(1).toLowerCase()}`).join("-");
            }
        )
        .join(" ");
};


const isPrebuiltTheme = (themeId: string) => {
    return Object.keys(themeProperties).includes(themeId);
};

const centimetersToPt = (cm: number): number => {
    return cm * CM_FACTOR;
};

const mapHeaderFooterFontSize = (size?: number, baseFontSize?: number) => {
    if (!size) {
        return baseFontSize || "8pt";
    }
    return `${4 + 2 * size}pt`;
};

const withoutUnit = (value: string, unit: string): number => {
    return parseFloat(value.replace(unit, ""));
};

const prepareThemeConfigForChapterType = (themeConfig: PdfThemeConfig, chapterType: PdfChapterType) => {
    const {
        titleCard: cTitleCard,
        paragraph: cParagraph,
        firstParagraph: cFirstParagraph,
        trim: cTrim,
        margin: cMargin,
        baseFontSize: cBaseFontSize
    } = getThemeConfigsForChapterTypes(chapterType);
    const { titleCard, paragraph, firstParagraph, trim, margin, baseFontSize } = themeConfig;
    return {
        ...themeConfig,
        titleCard: { ...titleCard, ...cTitleCard },
        paragraph: { ...paragraph, ...cParagraph },
        firstParagraph: { ...firstParagraph, ...cFirstParagraph },
        trim: { ...trim, ...cTrim },
        margin: { ...margin, ...cMargin },
        baseFontSize: cBaseFontSize ? cBaseFontSize : baseFontSize
    };
};

const isChapterNumbered = (chapterType: string, chapterNumbered?: boolean): boolean => {
    if (
        (chapterType === undefined ||
            chapterType === "chapter" ||
            chapterType === "uncategorized" ||
            chapterType === "custom") &&
        (chapterNumbered === undefined || chapterNumbered)
    ) {
        return true;
    }

    return false;
};

const getFormattedChapter = (chapter: PdfChapter): PdfChapter => {
    let formattedChapter = chapter;
    if (chapter.type === "dedication") {
        formattedChapter = {
            ...chapter,
            children: chapter.children.map(c => {
                if (c.type === "p") {
                    return {
                        type: "align_center",
                        children: [c]
                    };
                }

                if (c.type === "align_right") {
                    return {
                        ...c,
                        type: "align_center",
                    };
                }

                return c;
            }),
        };
    }

    if (chapter.type === "also-by") {
        formattedChapter = {
            ...chapter,
            children: chapter.children.map(c => {
                if (c.type === "p") {
                    return {
                        type: "align_center",
                        children: [c]
                    };
                }

                if (c.type === "align_right") {
                    return {
                        ...c,
                        type: "align_center",
                    };
                }

                return c;
            }),
        };
    }

    if (chapter.type === "epigraph") {
        formattedChapter = {
            ...chapter,
            children: flatten(chapter.children.map(c => {
                if (c.type === "p") {
                    return {
                        ...c,
                        type: "blockquote",
                    };
                }

                if (c.type === "align_center" || c.type === "align_right") {
                    return (c.children as any).map(d => {
                        return {
                            ...d,
                            type: "blockquote"
                        };
                    });
                }

                return c;
            })),
        };
    }

    if (chapter.type === "chapter") {
      formattedChapter = {
        ...chapter,
        children: chapter.children
          .map((c: Node) => {
            if (c.type === "h2") {
              const childrenObject = c.children as Node[];
              if (
                childrenObject.length === 1 &&
                childrenObject[0].text === ""
              ) {
                c.removeOnFilter = true;
              }
            }

            if (c.type === "p") {
              // Intentional line breaks are structured this way.
              const childrenObject = c.children as Node[];
              if (
                childrenObject.length === 1 &&
                childrenObject[0].text === ""
              ) {
                return c;
              }

              c.children = childrenObject.filter((textNode) => {
                return textNode.text !== "";
              });
            }

            return c;
          })
          .filter((c: Node) => c?.removeOnFilter !== true),
      };
    }

    return formattedChapter;
};

const getAdjustedBookDimensions = (
    themeConfig: PdfThemeConfig,
    book: PdfExpandedBook,
    images?: PdfCustomImages,
    isThemeEditing?: boolean,
    customThemeHeaders?: PdfCustomThemeHeader | null
): { height: number, width: number, marginOutside: number, paddingBottom: number, paddingTop: number, marginInside: number } => {
    //inputs -> book, themeConfig , customThemeHeaders
    const isFullBleed = () => {

        const fullPageImageChapters = book.chapters?.filter(chapter => chapter.fullpageImage?.printExtent === "full-bleed");

        // check for full-page-images
        if (fullPageImageChapters && fullPageImageChapters?.length > 0) return true;

        // custom or pre-build config
        if (!isThemeEditing && images && images?.printExtent !== "margins") return true;

        // while editing
        if (isThemeEditing && customThemeHeaders?.image.printExtent === "full-bleed") return true;

        return false;
    };

    const computeDimensions = (trimAdjuster: number) => {
        const { bottom, top } = ADDITIONAL_CHAPTER_MARGINS;
        const height = (themeConfig.trim.height) + (trimAdjuster * 2);
        const width = (themeConfig.trim.width) + (trimAdjuster);
        const paddingTop = (themeConfig.margin.top + top) + (trimAdjuster);
        const paddingBottom = (themeConfig.margin.bottom + bottom) + (trimAdjuster);
        const marginOutside = (themeConfig.margin.outer) + (trimAdjuster);

        return { height, width, paddingTop, paddingBottom, marginOutside, marginInside: themeConfig.margin.inner };
    };

    return computeDimensions(isFullBleed() ? 0.3 : 0);
};

const isNumberedChapter = (chapter: IChapterStore.ChapterMeta) =>
    (chapter.type === "chapter" || chapter.type === "uncategorized") &&
    (chapter.numbered === undefined || chapter.numbered === true);

const getChapterIndex = (book: PdfExpandedBook, chapter: IChapterStore.Chapter): number | undefined => {
    if (isNumberedChapter(chapter) && book.chapterIds.indexOf(chapter._id) > -1) {
        let curChapterNumber = 1;
        for (const chap of book.chapters) {
            if (chap._id === chapter._id) break;
            if (isNumberedChapter(chap) && !book.frontMatterIds.find(id => chap._id === id)) curChapterNumber += 1;
        }
        return curChapterNumber;
    }
    return undefined;
};

const getImages = (chapter: IChapterStore.Chapter, customThemeBuilderView: string, individualChapterImage: boolean, style: ThemeBase | null, images: CustomImages) => {

    if ((customThemeBuilderView === "customThemeStep2" ? individualChapterImage : style?.properties?.individualChapterImage) && images) {
        return {
            ...images,
            url: chapter.image,
            chapterId: chapter._id,
        };
    }

    if (style?.image) {
        return {
            ...style.image,
            chapterId: chapter._id,
        };
    }

    return images;
};

const hasLightText = (styles: PdfThemeBase | null, customThemeBuilderView?: string, customThemeHeaders?: PdfCustomThemeHeader) => {
    const isThemeEditing = customThemeBuilderView === "customThemeStep2";
    if (isThemeEditing) {
        return customThemeHeaders?.textLight === "light";
    }
    return styles?.properties?.textLight === "light";
};

const hasFulBleedImage = (chapter: PdfChapter, themeConfig: PdfThemeConfig, styles: PdfThemeBase | null, images?: PdfCustomImages, customThemeBuilderView?: string, customThemeHeaders?: PdfCustomThemeHeader) => {
    const isThemeEditing = customThemeBuilderView === "customThemeStep2";
    const isTitleImageVisible = themeConfig.titleCard.image;
    let _image: PdfCustomImages | null = null;

    if (isThemeEditing && customThemeHeaders && customThemeHeaders.image) {
        _image = customThemeHeaders.image;
        if (customThemeHeaders.individualChapterImage) {
            _image.url = chapter.image;
        }
    }

    if (!isThemeEditing && isTitleImageVisible && images) {
        _image = images;
        if (styles && styles.properties?.individualChapterImage) {
            _image.url = chapter.image;
        }
    }

    if (!_image) return false;

    const { printExtent, background, url } = _image;

    if (!background) return false;
    if (!url) return false;
    if (printExtent !== "full-bleed") return false;
    return true;
};

const prepareThemeConfigForFontFamily = (themeConfig: PdfThemeConfig): PdfThemeConfig => {
    const { baseFontSize, printBaseFont } = themeConfig;
    const config = fontsData.find(({ family }) => family === printBaseFont);
    if (!config) return themeConfig;
    const { baseFontSizeMultiplier } = config;
    if (!baseFontSizeMultiplier) return themeConfig;
    return { ...themeConfig, baseFontSize: baseFontSize * baseFontSizeMultiplier };
};

export {
    getAdjustedBookDimensions,
    getChapterNumber,
    chapterNumberToTitleCase,
    isPrebuiltTheme,
    centimetersToPt,
    mapHeaderFooterFontSize,
    prepareThemeConfigForChapterType,
    isChapterNumbered,
    getFormattedChapter,
    getChapterIndex,
    getImages,
    hasLightText,
    hasFulBleedImage,
    prepareThemeConfigForFontFamily,
    withoutUnit
};
