import { SvgIcon } from "@material-ui/core";
import React, { FunctionComponent } from "react";

interface PageBreakIconProps {
  size?: number;
  active?: boolean;
}

export const PageBreakIcon: FunctionComponent<PageBreakIconProps> = ({
  size = 15,
}) => {
  return (
      <svg
        className="svg-icon"
        style={{
          width:size,
          height: size,
          verticalAlign: "middle",
          overflow: "hidden",
        }}
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M896 460.8h-768C85.6576 460.8 51.2 426.3424 51.2 384v-307.2a25.6 25.6 0 0 1 51.2 0v307.2a25.6 25.6 0 0 0 25.6 25.6h768a25.6 25.6 0 0 0 25.6-25.6v-307.2a25.6 25.6 0 0 1 51.2 0v307.2c0 42.3424-34.4576 76.8-76.8 76.8zM76.8 563.2h-51.2a25.6 25.6 0 0 1 0-51.2h51.2a25.6 25.6 0 0 1 0 51.2zM230.4 563.2h-51.2a25.6 25.6 0 0 1 0-51.2h51.2a25.6 25.6 0 0 1 0 51.2zM384 563.2h-51.2a25.6 25.6 0 0 1 0-51.2h51.2a25.6 25.6 0 0 1 0 51.2zM537.6 563.2h-51.2a25.6 25.6 0 0 1 0-51.2h51.2a25.6 25.6 0 0 1 0 51.2zM691.2 563.2h-51.2a25.6 25.6 0 0 1 0-51.2h51.2a25.6 25.6 0 0 1 0 51.2zM844.8 563.2h-51.2a25.6 25.6 0 0 1 0-51.2h51.2a25.6 25.6 0 0 1 0 51.2zM998.4 563.2h-51.2a25.6 25.6 0 0 1 0-51.2h51.2a25.6 25.6 0 0 1 0 51.2zM947.2 1024a25.6 25.6 0 0 1-25.6-25.6v-307.2a25.6 25.6 0 0 0-25.6-25.6h-768a25.6 25.6 0 0 0-25.6 25.6v307.2a25.6 25.6 0 0 1-51.2 0v-307.2c0-42.3424 34.4576-76.8 76.8-76.8h768c42.3424 0 76.8 34.4576 76.8 76.8v307.2a25.6 25.6 0 0 1-25.6 25.6z"
          fill=""
        />
      </svg>
 
  );
};
