import {
	StyledElement
} from "@udecode/slate-plugins";

import {
	ImageKeyOption,
	ImagePluginOptionsValues
} from "./types";

const ELEMENT_ORNAMENTAL_BREAK = "ornamental-break";

export const DEFAULTS_ORNAMENTAL_BREAK: Record<
  ImageKeyOption,
  ImagePluginOptionsValues
> = {
	"ornamental-break": {
		component: StyledElement,
		type: ELEMENT_ORNAMENTAL_BREAK,
		rootProps: {
			className: `slate-${ELEMENT_ORNAMENTAL_BREAK}`,
			as: "div",
			styles: {
				root: [
				],
			},
		},
	}
};