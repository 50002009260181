import React, { useState, FunctionComponent } from "react";
import { Modal, Button, Radio, Row, Col, Tabs, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ImageGallery from "../Forms/ImageGallery";
// helpers
import { getOrnamentalBreaks } from "../helpers/ornamentalBreaks";
import FileUpload from "../Forms/FileUpload";
import BookBrush from "../Forms/BookBrush";

const ob = getOrnamentalBreaks(36);
const { TabPane } = Tabs;

interface CustomThemeImageLibraryProps {
  onUpdate: (url: string) => void;
  isImageDeleted?:boolean
}

const CustomThemeBackgroundLibrary: FunctionComponent<CustomThemeImageLibraryProps> = (props: CustomThemeImageLibraryProps) => {
  const { onUpdate } = props;
  const [uploading, setUploading] = useState<boolean>(false);

    const getIGURL = (link) => {
        onUpdate(link);
    };

  return (
    <>
      <FileUpload
        fileType="image/*"
        shape='none'
        folder='custom-theme-backgrounds'
        onFileUpload={(fileUrl) => {
          onUpdate(fileUrl || "");
        }}
        onFileUploadStart={() => setUploading(true)}
        onFileUploadEnd={() => setUploading(false)}
        isImageDeleted={props.isImageDeleted}
      >
        {!uploading ? (
          <Button
            type="primary"
            icon={<PlusOutlined />}

          >
            Upload Image (png or jpeg)
          </Button>
        ) : <Spin tip="Uploading" style={{width:"100px"}} />}
      </FileUpload>
 
      <div className="igCustomBg"><ImageGallery changeURL={getIGURL} /></div>
      
      <BookBrush/>
      
    </>
  );
};

export default CustomThemeBackgroundLibrary;
