import { NodeEntry, Transforms, Element, Node, Text } from "slate";
import { ReactEditor } from "slate-react";

// Element type definitions
export const blockElements = ["p", "h2", "blockquote", "code_block", "align_left", "align_center", "align_right", "ornamental-break", "image", "ul", "ol", "li", "break-chapter"];
export const normalizeElements = ["align_left", "align_center", "align_right", "h2", "blockquote", "code_block"];
export const voidElements = ["ornamental-break", "image", "break-chapter", "endnote"];
export const aligmentElements = ["align_left", "align_center", "align_right"];
export const inlineElements = ["endnote", "link"];
export const supportedLiChildrenElements = ["ul", "ol", "p"];
export const listElements = ["ul", "ol"];

export const withAlignPlugin = () => <T extends ReactEditor>(editor: T): ReactEditor => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]: NodeEntry) => {
    if (!Element.isElement(node)) return;

    if (aligmentElements.indexOf(node.type as string) > -1) {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (Element.isElement(child) &&
          (inlineElements.indexOf(child.type as string) === -1 && voidElements.indexOf(child.type as string) > -1) || listElements.indexOf((child as Element).type as string) > -1) {
            Transforms.liftNodes(editor, { at: childPath });
            return;
        }
      }
    }

    if (blockElements.indexOf(node.type as string) > -1) {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (Element.isElement(child) && child.type === "a") {
          let allEmpty = true;
          let invalidNodes = false;

          for (const [ linkChild ] of Node.children(editor, childPath)) {
            if (!Text.isText(linkChild)) { 
              invalidNodes = true;
              allEmpty = false;
            }

            if (linkChild.text && (linkChild.text as string).length > 0) {
              allEmpty = false;
            }
          }

          if (allEmpty) {
            Transforms.removeNodes(editor, {
              at: childPath,
            });
            return;
          } 
          
          if (invalidNodes) {
            console.log("INVALID NODE");
            console.log(child);
            Transforms.liftNodes(editor, {
              at: childPath,
              match: (n) => !Text.isText(n),
            });
            return;
          }
          
        }
      }
    }

    normalizeNode([node, path]);
  };

  return editor;
};