import { Form, Input, Radio, RadioChangeEvent, Select, FormItemProps } from "antd";
import { groupBy } from "lodash";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import useRootStore from "../../../../../store/useRootStore";
import { ImageLink } from "../types";
import { checkHttps } from "../../../../../utils/helper";

const { Option } = Select;

const commonFormItemProps = {
	labelAlign: "left",
	colon: false,
} as FormItemProps;

interface ImageLinkComponentProps {
  link: ImageLink | undefined;
  setLink: (link: ImageLink | undefined) => void;
}

interface ChapterMenuItem {
  _id: string;
  title: string;
}

export const ImageLinkComponent: FunctionComponent<ImageLinkComponentProps> = ({
  link,
  setLink,
}) => {
  const { book } = useRootStore().bookStore;
  const [chaptersList, setChaptersList] = useState<ChapterMenuItem[]>([]);
  const [error, setError] = useState("");
  useEffect(() => {
    const groupedChapters = groupBy(book.chapters, "parentChapterId");
    const chaptersList: ChapterMenuItem[] = [];

    for (const chapter of book.frontMatter) {
      chaptersList.push({ _id: chapter._id, title: chapter.title });
    }

    for (const group in groupedChapters) {
      let parentChapter = "";
      const chapters = groupedChapters[group];
      for (const chapter of chapters) {
        if (chapter.type === "volume") {
          chaptersList.push({ _id: chapter._id, title: chapter.title });
          parentChapter = `${chapter.title} → `;
        } else {
          chaptersList.push({
            title: `${parentChapter}${chapter.title}`,
            _id: chapter._id,
          });
        }
      }
    }

    for (const chapter of book.backMatter) {
      chaptersList.push({ _id: chapter._id, title: chapter.title });
    }

    setChaptersList(chaptersList);
  }, [book]);

  const handleChangeChapterType = useCallback((e: RadioChangeEvent) => {
    if (e.target.value === "none") {
      setLink(undefined); 
      return;
    }

    setLink({ ...link, type: e.target.value });
  }, []);

  const handleChapterSelectionChange = (value: string) => {
    setLink({
      type: "internal-link",
      internalLink: { chapterId: value },
    });
  };

  const handleChapterSelectionFilterOption = (input: string, option: any) => {
    const { children } = option as { children: string };
    return children.toLowerCase().search(input.trim()) !== -1;
  };

  const validateText = (url) => {
		if (!checkHttps(url)) {
			setError("Should be a valid link starting with https:// or mailto:");
		} else {
			setError("");
		}
		return url;
	};

  return (
    <Form.Item label="Link" {...commonFormItemProps}>
      <Radio.Group
        optionType="button"
        buttonStyle="solid"
        value={link ? link.type : "none"}
        onChange={handleChangeChapterType}
      >
        <Radio.Button value="none">None</Radio.Button>
        <Radio.Button value="web-link">Web Link</Radio.Button>
        <Radio.Button value="internal-link">Internal Link</Radio.Button>
      </Radio.Group>
      {link && (
        <div className="image-link-value-container">
          {link.type === "web-link" && (
            <Form.Item {...commonFormItemProps}>
              <Input
                placeholder="Web Link"
                type="text"
                value={link.webLink}
                onChange={(e) =>
                  setLink({ type: link.type, webLink: validateText(e.target.value) })
                }
              />
              <span id="errorText" className='error'>{error}</span>
            </Form.Item>
          )}
          {link.type === "internal-link" && (
            <Form.Item {...commonFormItemProps}>
              <Select
                showSearch
                onChange={handleChapterSelectionChange}
                filterOption={handleChapterSelectionFilterOption}
                placeholder="Select a target"
                value={link.internalLink?.chapterId}
              >
                {chaptersList.map((chapter) => {
                  return (
                    <Option key={chapter._id} value={chapter._id}>
                      {chapter.title}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
        </div>
      )}
    </Form.Item>
  );
};
