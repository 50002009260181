import React, { FunctionComponent, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { observer } from "mobx-react";

import { Form, Input, Button, Row, Col, Avatar, Card } from "antd";

import EditorLayout from "../Shared/Layouts/Editor";

import { EditChapter } from ".";
import ChapterSider from "../Chapters/Sider";
import { EditBook } from "../Books";
import Previewer from "../Previewer/Previewer";
import ThemeBody from "../Theme/ThemeBody";
import MultipleThemeSelection from "../Theme/MultipleThemesSelection";
import Loading from "../Shared/Loading";

import { getHashMap, getEditorType } from "../../utils/helper";

import useRootStore from "../../store/useRootStore";

import AtticusAnimation_v1 from "../../components/Shared/assets/alpha-section-full.gif";
import { GetAllChapterTemplates } from "../../utils/offline.book.helpers";

const AboutAuthorEditor: FunctionComponent = () => {
	return (
		<div className="atticus-editor-container">
			<div className="editor-area">
				<div
					style={{
						margin: "auto",
						maxWidth: 1080,
						width: "100%",
					}}
				>
					<div style={{ height: "1em" }}></div>
					<Form layout="vertical">
						<h2>About Author</h2>
						<div style={{ marginTop: "1rem" }}>
							<Form.Item>
								<Avatar
									size={160}
									style={{ marginRight: "1rem" }}
									shape="square"
								>
									N
								</Avatar>
								<Button type="default">Upload Image</Button>
							</Form.Item>
							<Form.Item label="Biography">
								<Input.TextArea rows={6} />
							</Form.Item>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item label="Facebook">
										<Input />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label="Twitter">
										<Input />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label="Instagram">
										<Input />
									</Form.Item>
								</Col>
							</Row>
							<Form.Item>
								<Button type="primary">Save</Button>
							</Form.Item>
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
};

const EditorView: FunctionComponent = () => {
  const { writing, chapterLoading, themeview, bookEdit, setBookEdit,  getAndSetCurBook, getAndSetCurChapter, getAndSetChapterTemplate, chapterTemplate: chapTemplate } = useRootStore().bookStore;
	const { customThemeBuilderView } = useRootStore().bookStore;
	const { getBookTheme } = useRootStore().themeStore;
	const { setMainChapterTemplate, setChapterTemplateView } = useRootStore().appStore;

	const [mounted, setMounted] = useState(false);
	const { location } = useHistory();

  const { urlFragments, type } = getEditorType(location.pathname);

  const bookId =
    type === "chapter-template"
      ? null
      : (useParams() as { bookId: string }).bookId;

  const chapterTemplateView = type === "chapter-template";

  const hashObject = getHashMap(location.hash);

  const chapterId = type === "books" ? (hashObject["chapter"] as string) : null;
  const chapterTemplateId =
    type === "chapter-template" ? (urlFragments[1] as string) : null;
  const frontMatterType =
    type === "frontmatter" ? (hashObject["frontmatter"] as string) : null;

	const mountBook = async () => {
    if (bookId === null) return;

		const bb = await getAndSetCurBook(bookId);
		// saveThemeUpdates(bookId);
			await getBookTheme(bookId);
		if (chapterId) {
			await getAndSetCurChapter(chapterId);

		} else if (bb && bb.chapters && bb.chapters.length > 0) {
			await getAndSetCurChapter(bb.chapters[0]._id);
		}
	};

	const mountChapterTemplates = () => {
		if(chapterTemplateId) {
			setBookEdit(false);
      setChapterTemplateView(true);
			// setChpTemp(chapTemplate);
			getAndSetChapterTemplate(chapterTemplateId);
		} else {
			setBookEdit(false);
      setChapterTemplateView(true);
			// setChpTemp(chapTemplate);
			getAndSetChapterTemplate(chapTemplate._id);
		}
	};

	const renderAllTemplates = async () => {
		const getTempsFromDb = await GetAllChapterTemplates();

		const filtered = getTempsFromDb?.filter((a) => a._id === chapterTemplateId);

		if (filtered) {
			for (const temp of filtered) {
				setMainChapterTemplate(temp);
			}
		}
	};

	const renderThemeBodyConditionally = () => {
		switch (customThemeBuilderView) {
			case "customThemeStep1":
				return <MultipleThemeSelection />;
				break;
			case "customThemeStep2":
				return <ThemeBody />;
				break;
		}
	};

	useEffect(() => {
		mountBook().then(() => setMounted(true));
	}, [bookId]);

	useEffect(() => {
		if (chapterId && !chapterTemplateId) {
			getAndSetCurChapter(chapterId);
		}
	}, [chapterId]);

	useEffect(() => {
    if (chapterTemplateView) {
		mountChapterTemplates();
		renderAllTemplates();
    }
	}, [chapterTemplateView]);

	// // chapter template library
	// useEffect(() => {
	// 	console.log("EditorView template useEffect",);
	// 	if(chapterTemplateId){
	// 		console.log("going to callgetAndSetChapterTemplate");
	// 		getAndSetChapterTemplate(chapterTemplateId);
	// 	}
	// }, [chapterTemplateView]);

	if (!mounted) {
		return (
			<div className="loading-animation-container">
				<img className="loading-animation" src={AtticusAnimation_v1} />
			</div>
		);
	}

	if (frontMatterType) {
		return (
			<EditorLayout
				editor={<AboutAuthorEditor />}
				list={<ChapterSider chapterId={chapterId} />}
			/>
		);
	}

	const LoadingView = <div style={{ height: "100%", display: "flex", alignItems: "center" }}><Loading /></div>;

	return (
		<>
			<EditorLayout
                editor={
                    !themeview ? (
                        bookEdit ? (
                            <EditBook />
                        ) : chapterLoading ? LoadingView : (
                            <EditChapter
                                isChapterTemplate={type === "chapter-template"}
                                chapterTemplateId={chapterTemplateId}
                            />
                        )
                    ) : (
                        renderThemeBodyConditionally()
                    )
                }
                list={<ChapterSider chapterId={chapterId} />}
                previewer={!writing ? <Previewer /> : null}
            />
		</>
	);
};

export default observer(EditorView);
