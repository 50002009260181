import React, { useState, FunctionComponent, ReactNode } from "react";
import { UploadOutlined, LoadingOutlined } from "@ant-design/icons";
// components
import { Button, Modal, Form, Input, Radio, Popconfirm } from "antd";

// custom components
import FileUpload from "../Forms/FileUpload";
import ImageGallery from "../Forms/ImageGallery";

// icons
// import { ImageUploadIcon, ImageSettingsIcon, RemoveIcon } from "./partials/Icon";

import useRootStore from "../../../store/useRootStore";

const { TextArea } = Input;

// Form layout
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

interface ImageUploadComponentProps {
  handler: (url: string) => void,
  show: boolean
}

const ImageUploadComponent = ({handler, show} : ImageUploadComponentProps) => {
  const [uploading, setUploading] = useState(false);
  const [caption, setCaption] = useState("");
  const [imageURL, setImageURL] = useState<string>("");

  const clearForm = () => {
    setCaption("");
    setImageURL("");
    handler("");
  };

  const onSubmit = async () => {
    if (imageURL) {
      handler(imageURL);
    }
  };

  const getIGURL = (link) => {
    setImageURL(link);
  };

  return (
    <>
      <Modal
        onCancel={() => {
          clearForm();
        }}
        visible={show}
        title="Upload your image"
        footer={
          imageURL ? (
            <Button
              type="primary"
              block
              size="large"
              onMouseDown={() => onSubmit()}
            >
              Set Image
            </Button>
          ) : null
        }
      >
        <ImageGallery changeURL={getIGURL} />
         <h3 className="imgUploadTitle">Upload a New Image</h3>
          <FileUpload
            fileType="image/*"
            onFileUploadStart={() => setUploading(true)}
            onFileUpload={(fileUrl) => {
              setUploading(false);
              setImageURL(fileUrl || "");
            }}
          >
            {imageURL ?  (
              <img style={{ width: "100%"}} src={imageURL} />
            ): (
              <>
                <p>
                  <p className="ant-upload-drag-icon">
                  {uploading ? <LoadingOutlined /> : <UploadOutlined />}
                </p>
                <p className="ant-upload-text">
                    {uploading ? "Uploading" : "Click or drag file to this area to upload"}
                  </p>
                </p>
              </>
            )}
            
          </FileUpload>
      </Modal>
    </>
  );
};

export default ImageUploadComponent;
