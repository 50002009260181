

interface ThemeIdentifiers {
  name: string,
  _id: string
}
interface ThemeProps {
  properties: Partial<IThemeStore.ThemeFields>
}

interface PregenThemes extends ThemeProps, ThemeIdentifiers {}

export const pre_themes : Partial<PregenThemes>[] = [
  {
    _id: "finch",
    name: "Finch",
    properties: {
      titleCard: [
        "chapterNumber",
        "chapterTitle"
      ],
      numberview: "chapter+word",
      alignment: "left"
    },
  }, 
  {
    _id: "clairmont",
    name: "Clairmont",
    properties: {
      titleCard: [
        "chapterTitle"
      ],
      alignment: "center"
    }
  },
  {
    _id: "minerva",
    name: "Minerva",
    properties: {
      titleCard: [
        "chapterTitle",
        "chapterNumber",
        "chapterSubtitle",
      ],
      alignment: "left",
      numberview: "chapter+word",
    }
  },
  {
    _id: "delphini",
    name: "Delphini",
    properties: {
      titleCard: [
        "chapterTitle",
        "chapterNumber",
        "chapterSubtitle",
        "chapterImage"
      ],
      alignment: "center",
      numberview: "chapter+word",
    }
  },
  {
    _id: "titus",
    name: "Titus",
    properties: {
      titleCard: [
        "chapterTitle",
        "chapterNumber",
      ],
      alignment: "center",
      numberview: "number",
    }
  },
  {
    _id: "seraphina",
    name: "Seraphina",
    properties: {
      titleCard: [
        "chapterTitle",
        "chapterImage",
      ],
      alignment: "center",
      numberview: "word",
    }
  },
  {
    _id: "delta",
    name: "Delta",
    properties: {
      titleCard: [
        "chapterNumber",
        "chapterTitle"
      ],
      numberview: "number",
      alignment: "right"
    },
  },
  {
    _id: "minax",
    name: "Minax",
    properties: {
      titleCard: [
        "chapterNumber",
        "chapterTitle",
        "chapterImage"

      ],
      numberview: "number",
      alignment: "left"
    },
  },
  {
    _id: "penelope",
    name: "Penelope",
    properties: {
      titleCard: [
        "chapterTitle",
      ],
      alignment: "right",
      numberview: "word",
    }
  },
  {
    _id: "watts",
    name: "Watts",
    properties: {
      titleCard: [
        "chapterTitle",
        "chapterSubtitle",
      ],
      alignment: "left",
    }
  },
  {
    _id: "aether",
    name: "Aether",
    properties: {
      titleCard: [
        "chapterTitle",
        "chapterSubtitle",
      ],
      alignment: "right",
    }
  },
  {
    _id: "hughes",
    name: "Hughes",
    properties: {
      titleCard: [
        "chapterTitle"
      ],
      numberview: "word",
      alignment: "center"
    }
  },
  {
    _id: "intratech",
    name: "Intratech",
    properties: {
      titleCard: [
        "chapterTitle",
        "chapterNumber",
      ],
      alignment: "left",
      numberview: "chapter+word",
    }
  },
  {
    _id: "atreides",
    name: "Atreides",
    properties: {
      titleCard: [
        "chapterTitle",
      ],
      alignment: "center",
    }
  },
  {
    _id: "scarlett",
    name: "Scarlett",
    properties: {
      titleCard: [
        "chapterTitle",
      ],
      alignment: "center",
    }
  },
  {
    _id: "bonkers-books",
    name: "Bonkers Books",
    properties: {
      titleCard: [
        "chapterNumber",
        "chapterTitle",
      ],
      alignment: "center",
    }
  },
  {
    _id: "elinor",
    name: "Elinor",
    properties: {
      titleCard: [
        "chapterTitle",
      ],
      alignment: "center",
    }
  },
];