import React, { useState, useEffect } from "react";
import { Dropdown, Button, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { ReactEditor, useEditor, useSelected } from "slate-react";
import { Editor, Range, Point, Node } from "slate";
import { observer } from "mobx-react";

import useRootStore from "../../../store/useRootStore";

import { countWords } from "../../../utils/helper";

const WordCount = observer(() => {
  const [type, settype] = useState("chapter");
  
  return (
    <Dropdown
      overlay={
        <Menu
          selectable
          defaultSelectedKeys={[type]}
          onClick={(e) => settype(e.key as string)}
        >
          <Menu.Item key="book">Book</Menu.Item>
          <Menu.Item key="chapter">Chapter</Menu.Item>
          <Menu.Item key="selection">Selection</Menu.Item>
        </Menu>
      }
    >
      <Button className="btn-a" size="middle">
        {type === "book" ? (
          <BookWC />
        ) : null}

        {type === "chapter" ? (
          <ChapterWC />
        ) : null}

        {type === "selection" ? (
          <SelectionWC />
        ) : null}
      </Button>
    </Dropdown>
  );
});


const BookWC = observer(() => {
  const { getBookBodies, body, chapter } = useRootStore().bookStore;
  const [count, setCount] = useState(0);
  const [list, setList] = useState<Array<string>>([]);

  useEffect(() => {
    getBookBodies().then((d) => {
      setCount(d.words);
      setList(d.ids);
    });
  }, []);

  useEffect(() => {
    getBookBodies().then((d) => {
      setCount(d.words);
    });
  }, [chapter._id]);
  
  const words = list.includes(chapter._id) ? countWords(body) : 0;
  
  return (
    <p>{(count + words).toLocaleString()} words <DownOutlined /></p>
  );
});

const ChapterWC = observer(() => {
  const { body } = useRootStore().bookStore;

  return (
    <p>{countWords(body).toLocaleString()} words <DownOutlined /></p>
  );
});

const SelectionWC = observer(() => {
  const { words } = useRootStore().bookStore;

  return (
    <p>{words.toLocaleString()} words <DownOutlined /></p>
  );
});

export default WordCount;