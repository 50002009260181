import React, { FunctionComponent } from "react";
import { SvgIcon } from "@material-ui/core";

interface IconProps{
  size?: number;
  active?: boolean;
}

export const OrnamentalBreakIcon: FunctionComponent<IconProps> = ({ size = 15}: IconProps) => (
	<SvgIcon viewBox="0 0 150 150" style={{ width: size }} className="slate-ToolbarButton">
		<g id="Group_38" data-name="Group 38">
			<path className="cls-1" d="M77.18,79.84a9.68,9.68,0,0,1-9.68-9.68V12.09a9.68,9.68,0,0,1,19.36,0V70.16A9.68,9.68,0,0,1,77.18,79.84Z" />
			<path className="cls-1" d="M75,81.82a9.62,9.62,0,0,1-4.83-1.29L13.21,47.64a9.68,9.68,0,1,1,9.68-16.76l57,32.88A9.68,9.68,0,0,1,75,81.82Z" />
			<path className="cls-1" d="M18.06,114.71a9.68,9.68,0,0,1-4.85-18.06l57-32.89a9.68,9.68,0,0,1,9.68,16.77L22.89,113.41A9.66,9.66,0,0,1,18.06,114.71Z" />
			<path className="cls-1" d="M77.18,147.58a9.67,9.67,0,0,1-9.68-9.67V70.16a9.68,9.68,0,0,1,19.36,0v67.75A9.68,9.68,0,0,1,77.18,147.58Z" />
			<path className="cls-1" d="M131.94,114.71a9.66,9.66,0,0,1-4.83-1.3L70.16,80.53a9.68,9.68,0,0,1,9.68-16.77l56.95,32.89a9.68,9.68,0,0,1-4.85,18.06Z" />
			<path className="cls-1" d="M75,81.82a9.68,9.68,0,0,1-4.85-18.06l57-32.88a9.68,9.68,0,0,1,9.68,16.76L79.84,80.53A9.62,9.62,0,0,1,75,81.82Z" />
		</g>
	</SvgIcon>
);
