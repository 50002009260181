import React, { FunctionComponent, useEffect, useState } from "react";
import { Slider, Form } from "antd";
import { ImageSize } from "../types";

interface ImageSizeComponentProps {
    size?: ImageSize
    onSizeChange?: (size: ImageSize) => void
}

const sizeMarks = {
    1: {
        label: "1%"
    },
    20: {
        label: "20%"
    },
    40: {
        label: "40%"
    },
    60: {
        label: "60%"
    },
    80: {
        label: "80%"
    },
    100: {
        label: "100%"
    }
};

const parseSizes = (size?: ImageSize) => {
    if (typeof size === "string") {
        switch (size) {
            case "small":
                return 30;
            case "medium":
                return 45;
            case "large":
                return 100;
            default:
                return 100;
        }
    }
    if (typeof size === "number") return size;
    return 100;
};

export const ImageSizeComponent: FunctionComponent<ImageSizeComponentProps> = ({ size: inputSize, onSizeChange }) => {
    const [size, setSize] = useState<number>();

    const handleSizeChange = (value: number) => {
        setSize(value);
        if (onSizeChange) onSizeChange(value);
    };

    useEffect(() => {
        setSize(parseSizes(inputSize));
    }, [inputSize]);

    return (
        <>
            <Form.Item label="Size" labelAlign="left" colon={false}>
                <Slider defaultValue={100} min={1} max={100} tooltipVisible={true} value={size} onChange={handleSizeChange} marks={sizeMarks} />
            </Form.Item>
        </>
    );
};
