import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { find } from "lodash";

import PreGeneratedTheme from "../Shared/Forms/PreGeneratedTheme";

import useRootStore from "../../store/useRootStore";

const MultipleThemeSelection = observer(() => {
  const { theme, theme_styles, style, setImages, putBookTheme, setStyle, userThemes } = useRootStore().themeStore;
	const { book, addNewChapter, deleteChapter, debouncedSaveChapterMetaUpdates, getChapterBodyById } = useRootStore().bookStore;
	const { refreshCache } = useRootStore().pdfCacheStore;
	const themeRef = useRef(theme?.themeId);

	const parseThemeConfig = (t: IThemeStore.ThemeFields) => {
		let trimsize;
		if(t.trimsize.includes("*")){
			trimsize= t.trimsize.split("*");
		} else{
			trimsize= t.trimsize.split("x");
		}


		// const trimsize =  t.trimsize.split("x");
		if (!theme) return null;

		const thm: IThemeStore.ThemeConfig = {
			...theme,
			baseFontSize: t.fontsize,
			chapterNumbering: t.numberview,
			titleAlignment: t.alignment,
            coloredImg: t.coloredImg,
			ornamentalBreakImage: t.ob || "",
            ornamentalBreakWidth: t.ob_width,
			printBaseFont: t.printBaseFont,
			layout: t.layout,
			themeId: t.style_id,
			beginFirstSentence: t.beginFirstSentence,
			startPage:t.startPage,
			margin: {
				...theme.margin,
				outer: t.margin_out * 2.54,
				inner: t.margin_in * 2.54,
			},
			titleCard: {
				chapterNumber: t.titleCard.indexOf("chapterNumber") > -1,
				title: t.titleCard.indexOf("chapterTitle") > -1,
				subtitle: t.titleCard.indexOf("chapterSubtitle") > -1,
				image: t.titleCard.indexOf("chapterImage") > -1,
			},
			paragraph: {
				indent: t.firstline === "indented" ? 1 : 0,
				paragraphSpacing: t.linespacing,
				hyphens: t.paraAlignment.includes("hyphens"),
				justify: t.paraAlignment.includes("justified")
			},
			firstParagraph: {
				indent: t.firstline === "indented" ? 1 : 0,
				uppercaseFourWords: t.decorations.includes("smallcaps"),
				dropcap: t.decorations.includes("dropcap"),
			},
			trim: {
				width: parseFloat(trimsize[0]) || 0,
				height: parseFloat(trimsize[1]) || 0,
				isLargeTrim: t.isLargeTrim
			},
			notesMode: t.notesMode,
			ePubNotesMode: t.ePubNotesMode,
			dynamicPageBreaks: {
				breakSubheadings: t.dynamicPageBreaks.indexOf("subheadings") > -1,
				breakOrnamentalBreaks: t.dynamicPageBreaks.indexOf("ornamental-breaks") > -1,
            },
			layoutPriority: t.layoutPriority,
		};
		return thm;
	};

	const updateEndnotesChapter = async (updatedTheme: IThemeStore.ThemeConfig) => {
		const isEndnotesChapterNeeded = updatedTheme.ePubNotesMode === "END_OF_BOOK" || updatedTheme.notesMode === "END_OF_BOOK";
		const endnotesChapters = [...book.frontMatter, ...book.chapters, ...book.backMatter]
			.filter((chapter) => chapter.type === "endnotes");
		const isEndnotesChapterPresent = endnotesChapters.length > 0;
		
		let includeIn : "all" | "ebook" | "print" | "none" = "none";
		if(updatedTheme.ePubNotesMode === "END_OF_BOOK" && updatedTheme.notesMode === "END_OF_BOOK"){
			includeIn = "all";
		} else if(updatedTheme.ePubNotesMode === "END_OF_BOOK"){
			includeIn = "ebook";
		} else if(updatedTheme.notesMode === "END_OF_BOOK"){
			includeIn = "print";
		}

		if(isEndnotesChapterNeeded && !isEndnotesChapterPresent){
			// we need an endnotes chapter. but it's not available in the book right now
			// so we add new one
			const chapter = await addNewChapter("body", "endnotes", { includeIn }, undefined, true);
			if(chapter) {
				// Update the PDF cache
				const allChapterIds = [ ...book.frontMatterIds, ...book.chapterIds, ...book.backMatterIds, chapter._id];
				const chapterData = await getChapterBodyById(allChapterIds);
				const chapterCacheData = chapterData.map(({ _id, type, startOn, includeIn }) => ({ chapterId: _id, chapterType: type, startOn, includeIn } as IPDFCacheStore.ChapterCacheMetaData));
				refreshCache(book._id, "chapter-add", { "chapter-add": { chapters: chapterCacheData }});
			}
		}

		if(!isEndnotesChapterNeeded && isEndnotesChapterPresent){
			// we don't need an endnotes chapter, but it is present in the book right now
			// so we delete it. A for loop is used instead of a simple single chapter deletion to 
			// ensure that no multiple endnote chapters survive for long in a book, in the case
			// of break of the invariance where only a single endnotes chapter must be there
			for(const chapter of endnotesChapters){
				await deleteChapter(chapter._id);

				// Update PDF cache
				const allChapterIds = [ ...book.frontMatterIds, ...book.chapterIds, ...book.backMatterIds];
				const chapterData = await getChapterBodyById(allChapterIds);
				const chapterCacheData = chapterData.map(({ _id, type, startOn, includeIn }) => ({ chapterId: _id, chapterType: type, startOn, includeIn } as IPDFCacheStore.ChapterCacheMetaData));
				refreshCache(book._id, "chapter-delete", { "chapter-delete": { chapterId: chapter._id, chapters: chapterCacheData }});
			}
		}

		if(isEndnotesChapterNeeded && isEndnotesChapterPresent){
			// if the endnotes chapter is already there, but the note modes have changed, update chapter metadata
			if(theme?.ePubNotesMode !== updatedTheme?.ePubNotesMode || theme?.notesMode !== updatedTheme?.notesMode) {
				for(const chapter of endnotesChapters) {
					await debouncedSaveChapterMetaUpdates({ ...chapter, includeIn });
					refreshCache(book._id, "chapter-properties-change", {"chapter-properties-change": {chapter: {
						chapterId: chapter._id,
						chapterType: chapter.type,
						startOn: chapter.startOn,
						includeIn: includeIn, // updated includeIn
					}}});
				}
			}
		}
	};

	const handleSubmit = async (t: IThemeStore.ThemeFields, sty: IThemeStore.ThemeBase) => {
		const parsedTheme = parseThemeConfig(t);
		themeRef.current = sty._id;
		if (parsedTheme) {
			await updateEndnotesChapter(parsedTheme); // we need to call this method before putBookTheme because it uses current theme data
			await putBookTheme(book._id, parsedTheme, sty);
		}


		return false;
	};

	const handleUpdate = async (t: IThemeStore.ThemeFields, sty: IThemeStore.ThemeBase) => {
		const parsedTheme = parseThemeConfig(t);
		// setTheme(parsedTheme);
		if (parsedTheme) {
			await updateEndnotesChapter(parsedTheme); // we need to call this method before putBookTheme because it uses current theme data
			await putBookTheme(book._id, parsedTheme, sty);
		}
		// if (parsedTheme) saveThemeUpdates(book._id, parsedTheme); 
		return false;
	};

	useEffect(() => {
		// if (!savedNewCustomTheme) {
		// 	getBookTheme(book._id);
		// }
    const defImg = {
      chapterId: "",
      url: "",
      background: false,
      colored: "all",
      placement: "below-subtitle",
      alignment: "left",
      width: 100,
      printExtent: "margins",
    } as IThemeStore.CustomImages;

    const rs = find([...theme_styles, ...userThemes], ["_id", themeRef.current]);
		if (rs) {
			setStyle(rs);
      setImages(rs.properties ? rs.properties.image : defImg);
		}
	}, []);


	return (
		theme && style ?
		<PreGeneratedTheme
			style={style}
			theme={theme}
			setThm={setStyle}
			styleChange={setStyle}
			onSubmit={handleSubmit}
			onUpdate={handleUpdate}
			userThemes={userThemes}
			styles={theme_styles || []}
		/>
		: null);
});

export default MultipleThemeSelection;
