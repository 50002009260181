import React from "react";
import { Modal, Button, Radio, Row, Col, Tabs, Spin } from "antd";
import BookBrushLogo from "../assets/logo-main.png";

const BookBrush = () => {
	const bookBrushURL = process.env.REACT_APP_BOOK_BRUSH_URL
		? process.env.REACT_APP_BOOK_BRUSH_URL
		: "https://bookbrush.com/atticus/?ap_id=atticus";

	return (
		<div className="bookbrush-row">
			<Button
				onClick={() => {
					Modal.confirm({
						icon: null,
						title: <div className="bb-logo-div"><img className="bb-logo" src={BookBrushLogo}/></div>,
						content: <div>
							<p>Book Brush and Atticus have teamed up to make creating custom images for formatting that much easier.</p>
							<br />
							<p>Click below to create a free Book Brush account (15 image downloads included) and create custom ornamental breaks or chapter theme images using Atticus specific settings.</p>
						</div>,
						centered: true,
						onOk: () => window.open(bookBrushURL, "_blank"),
						okText: "Take me to Book Brush",
						okButtonProps: {
							style: {
								flex: 1,
								background: "linear-gradient(to right, #5F00BD , #F60040)",
								paddingTop: "5px",
								paddingBottom: "5px"
							},
						},
						cancelText: "",
						cancelButtonProps: {
							className: "btn-a",
							style: { display: "none" }
						},
						closable: true
					});
				}}
			>
				Create image with Book Brush
			</Button>
		</div>
	);
};

export default BookBrush;
