import {
  StyledLeaf
} from "@udecode/slate-plugins";

export const DEFAULTS_SANSSERIF = {
  sansserif: {
    component: StyledLeaf,
    type: "sansserif",
    rootProps: {
      className: "slate-sansserif",
      as: "span",
      styles: {
        root: {
          fontFamily: "PT Sans"
        }
      },
    },
  }
};