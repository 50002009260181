import { DeleteOutlined } from "@ant-design/icons";
import { getRenderElement, SlatePlugin, ToolbarElement } from "@udecode/slate-plugins";
import { Popconfirm, Button } from "antd";
import React, { FunctionComponent, ReactNode } from "react";
import { Transforms } from "slate";
import { ReactEditor, useEditor, useSelected } from "slate-react";
import { PageBreakIcon } from "./partials/Icon";

// styles
import "./styles.scss";

export const withPageBreak = () => <T extends ReactEditor>(editor: T): ReactEditor => {
  const { isVoid } = editor;
  editor.isVoid = (element) => (element.type === "page-break" ? true : isVoid(element));

  return editor;
};



interface PageBreakComponentProp {
    children: ReactNode;
    element: Node
  }
  
export const PageBreakComponent: FunctionComponent<PageBreakComponentProp> = ({ children, element }: PageBreakComponentProp) => {
    const selected = useSelected();
      const editor = useEditor();
  
    let className = "slate-page-break";
    className = `${className}${selected ? " selected" : ""}`;
  
    return (
      <div className={className} >
        <div></div>
        <div contentEditable={false}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span><PageBreakIcon size={20} /></span>
        </div>
        {children}
        <div>
          <Popconfirm
            title="Are you sure want to delete this page break?"
            onConfirm={() => {
              const path = ReactEditor.findPath(editor, element as any);
              Transforms.removeNodes(editor, { at: path });
            }}
            okButtonProps={{
              danger: true
            }}
            okText="Delete"
            cancelText="Cancel"
          >
            <Button>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      </div>
    );
  };

const renderPageBreak = () => {
    const pagebr = {
      component: PageBreakComponent,
      type: "page-break",
      hotkey: "",
      defaultType: "page-break",
      rootProps: {
      },
    };
  
    return getRenderElement(pagebr);
};



export const PageBreakPlugin = (): SlatePlugin => {
    return {
      renderElement: renderPageBreak(),
      voidTypes: ["page-break"],
   };
};

export const PageBreakButton: FunctionComponent = () => {
  const editor = useEditor();
    return (
        <ToolbarElement
          type={"page-break"}
          icon={<PageBreakIcon />}
          onMouseDown={() => {
            console.log("page break click");
            Transforms.insertNodes(editor,{
              type:"page-break",
              children:[
                {
                  text:""
                }
              ]
            });
          }}
        />
      );
};

