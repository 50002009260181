import React, { useState } from "react";
import { observer } from "mobx-react";
import { Row, Col, Typography, Tooltip, Button } from "antd";
import Countdown from "react-countdown";

// Icons
import { SprintPauseTimerIcon, SprintPlayTimerIcon } from "../Editor/partials/Icon";

import { db } from "../../db/bookDb";

import miniLogo from "../Shared/assets/miniLogo.png";
import "./sprint.scss";

const { Text } = Typography;
import useRootStore from "../../store/useRootStore";

interface SprintOnlyProps {
    sp?: ISprintTimerStore.SprintTimer | null,
    // show: boolean;
    toggle?: (visible: boolean) => void,
    cancel?: (visible: boolean) => void
}

const styles = {
    logo: {
        display: "flex",
        alignItems: "center",
    },
    miniLogo: {
        height: 35,
        width: "auto",
        marginRight: "0.6rem",
    },
    wordLogo: {
        height: 20,
        width: "auto",
    }
};

const SprintOnly = observer((props: SprintOnlyProps) => {
    const { getCurrentBookId } = useRootStore().bookStore;
    const bkkId = getCurrentBookId();

    const [timerPopConfirm, setTimePopConfirm] = useState(false);
    const [completedSP, setCompleted] = useState(false);

    const writingMinutes = props.sp?.writingTime ? props.sp?.writingTime : 0;
    const sprintWritingStatus = props.sp ? props.sp.status : null;
    const staticWM = props.sp?.statTime;

    const setToggleViews = () => {
        props.toggle ? props.toggle(true) : null;
        // setCompleted(false);
    };

    const handleCancel = () => {
        setTimePopConfirm(false);
        props.cancel ? props.cancel(true) : null;
    };

    React.useEffect(() => {
        setCompleted(false);
    }, [writingMinutes]);


    // Renderer callback with condition
    const renderer = ({ formatted: { hours, minutes, seconds }, completed, api, total }) => {

        if (api.isPaused()) {
            const tData = {
                _bookId: bkkId,
                writingTime: total,
                breakTime: 0,
                statTime: staticWM,
                status: "paused"
            };

            const timeData = db.sprintTimer.put(tData);

            return (
                <div className="sprint-break">
                    <Tooltip color="#3568BA" placement="bottom" title={<span>Play Timer</span>}>
                        <div className="before-sprint" onClick={api.start}>
                            <SprintPlayTimerIcon />
                            {writingMinutes > 3600000 ? (<span style={{ color: "#3568BA", paddingLeft: 2 }} >{hours}{minutes}:{seconds}</span>) : (<span style={{ color: "#3568BA", paddingLeft: 2 }}>{minutes}:{seconds}</span>)}
                            {/* onClick={() => setToggleViews()} */}
                        </div>
                    </Tooltip>
                </div>
            );
        }
        if (completed) {

            // Render a completed state
            setTimePopConfirm(true);
            setCompleted(true);

            const tData = {
                _bookId: bkkId,
                writingTime: total, //hours,minutes,seconds,
                breakTime: 0,
                statTime: staticWM,
                status: "completed"
            };

            const timeData = db.sprintTimer.put(tData);

            return null;

        } else {

            // Render a countdown
            if (completedSP == false) {
                setTimePopConfirm(false);

                const tData = {
                    _bookId: bkkId,
                    writingTime: total,
                    statTime: staticWM,
                    breakTime: 0,
                    status: "active"
                };

                const timeData = db.sprintTimer.update(bkkId, tData);

            }

            return (
                <>
                    {/* <div className="after-sprint">
                        <Tooltip color="#3568BA" placement="bottom" title={<span>Pause Timer</span>}>
                            <div className="before-sprint" onClick={api.pause}>
                                <SprintPauseTimerIcon />
                                {completedSP == true ? (
                                    writingMinutes > 3600000 ? (
                                        <span style={{ color: "#00BE95", paddingLeft: 2 }} onClick={() => setToggleViews()}>{hours}:{minutes}:{seconds}</span>) :
                                        (<span style={{ color: "#00BE95", paddingLeft: 2 }} onClick={() => setToggleViews()}>{minutes}:{seconds}</span>)
                                ) : (
                                    writingMinutes > 3600000 ? (
                                        <span style={{ color: "#00BE95", paddingLeft: 2 }}>{hours}:{minutes}:{seconds}</span>) :
                                        (<span style={{ color: "#00BE95", paddingLeft: 2 }}>{minutes}:{seconds}</span>)
                                )}
                            </div>
                        </Tooltip>
                    </div> */}

                    <div className="after-sprint">
                        {completedSP == true ? (
                            <div className="before-sprint">
                                <SprintPauseTimerIcon />
                                {writingMinutes > 3600000 ? (
                                    <span style={{ color: "#00BE95", paddingLeft: 2 }} onClick={() => setToggleViews()}>{hours}:{minutes}:{seconds}</span>) :
                                    (<span style={{ color: "#00BE95", paddingLeft: 2 }} onClick={() => setToggleViews()}>{minutes}:{seconds}</span>)}
                            </div>
                        ) : (
                            <Tooltip color="#3568BA" placement="bottom" title={<span>Pause Timer</span>}>
                                <div className="before-sprint" onClick={api.pause}>

                                    <SprintPauseTimerIcon />
                                    {writingMinutes > 3600000 ? (
                                        <span style={{ color: "#00BE95", paddingLeft: 2 }}>{hours}:{minutes}:{seconds}</span>) :
                                        (<span style={{ color: "#00BE95", paddingLeft: 2 }}>{minutes}:{seconds}</span>)
                                    }
                                </div>
                            </Tooltip>
                        )}
                    </div>

                </>
            );
        }
    };


    return (
        <Tooltip color="#3568BA" placement="bottom" title={
            <div>
                <Row>
                    <Col>
                        <div style={styles.logo}>
                            <img style={styles.miniLogo} src={miniLogo} />
                        </div>
                    </Col>
                    <Col>
                        <Row>
                            <Text style={{ color: "white", fontSize: 12 }} strong>Your sprint is complete! Want to start another sprint?</Text>
                        </Row>
                        <Row>
                            <div className="spacer"></div>
                            <div style={{ display: "flex", alignItems: "center" }} >
                                <Button type="primary" style={{ width: 160, height: 30, fontSize: 12 }} onClick={() => setToggleViews()}>Yes, start another sprint</Button>
                                <Button type="primary" style={{ height: 30, fontSize: 12, marginLeft: 5 }} onClick={handleCancel} > No </Button>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </div>
        }
            visible={timerPopConfirm}
            overlayInnerStyle={{ width: 373 }}
        >
            <div className="after-sprint">
                <div style={{ paddingLeft: 5 }}>
                    {sprintWritingStatus === "active" ? (<Countdown key={staticWM} date={Date.now() + (writingMinutes)} renderer={renderer} />) : null}    {/* //ref={setRef} */}
                    {sprintWritingStatus === "paused" ? (<Countdown key={staticWM} date={Date.now() + (writingMinutes)} renderer={renderer} />) : null}
                </div>
            </div>
        </Tooltip>
    );
});

export default React.memo(SprintOnly);