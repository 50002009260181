import React, { useEffect, useState } from "react";
import {
  createStyles,
  fade,
  Theme,
  ThemeProvider,
  withStyles,
  makeStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import { TextField, TextFieldProps, InputBase, Grow, Typography } from "@material-ui/core";
import { observer } from "mobx-react";

import useRootStore from "../../../store/useRootStore";

import { getChapterById } from "../../../utils/initials";
import { Row, Col, Button, Popconfirm, Avatar, Slider, Modal, Radio, Space, message } from "antd";
import { DeleteOutlined, } from "@ant-design/icons";

import WordCount from "./WordCount";
import JSONPreviewer from "../../Shared/JSONPreviewer";
import IndividualChapterImageUpload from "../../Shared/Uploads/IndividualChapterImage";
import { useOnlineStatus } from "../../../utils/isOffline";
import { ChapterMeta } from "../../../types/chapter";

interface TitlebarProps {
  onEnter: any,
  customTitle: boolean,
  placeholder: string,
  autofocus: boolean
}

const environmentType = process.env.NODE_ENV || "production";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnGroup: {
      marginRight: `-${theme.spacing(0.5)}px`,
      marginLeft: `-${theme.spacing(0.5)}px`,
      padding: 0,
      marginTop: theme.spacing(0.5),

      "& > *": {
        textTransform: "capitalize"
      },
    },
    title: {
      width: "100%",
      fontSize: 21,
      fontWeight: 600,

      "& input": {
        paddingBottom: 0,
      }
    },
    subtitle: {
      width: "100%",
      fontSize: 15,
      fontWeight: 500,

      "& input": {
        height: 32,
        padding: 0,
      }
    },
  }),
);

const Titlebar = observer((props: TitlebarProps) => {
  const { onEnter, autofocus, placeholder, customTitle } = props;
  const { chapter, debouncedSaveChapterMetaUpdates, saveChapterMetaUpdates } = useRootStore().bookStore;
  const { syncChapterTemplate } = useRootStore().chapterStore;
  const { refreshCache } = useRootStore().pdfCacheStore;
  const [show, toggleShow] = useState(false);
  const classes = useStyles();
  const [title, setTitle] = useState(chapter.title);
  const [subtitle, setSubtitle] = useState(chapter.subtitle);
  const [indChaptImg, setIndChaptImg] = useState(chapter.image);
  const [imgDlg, setImgDlg] = useState(false);
  const isOnline = useOnlineStatus();

  const [templateCheckedValues, setTemplateCheckedValues] = useState("");

  const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);

  const showTemplateModal = () => {
    setIsTemplateModalVisible(true);
  };

  const handleTemplateOk = () => {
    setIsTemplateModalVisible(false);
    syncChapterTemplate(templateCheckedValues.includes("2"), chapter.templateId, chapter._id, false);
    message.success("Changes applied successfully", 4);
  };

  const handleTemplateCancel = () => {
    setIsTemplateModalVisible(false);
  };

  function onChange(checkedValues) {
    setTemplateCheckedValues(checkedValues.target.value as string);
  }

  function onTitleChange(v) {
    setTitle(v);
    debouncedSaveChapterMetaUpdates({
      ...chapter,
      title: v
    });
    refreshCache(chapter.bookId, "chapter-title-change", { "chapter-title-change": { chapterId: chapter._id } });
  }

  function onSubtitleChange(v) {
    setSubtitle(v);
    debouncedSaveChapterMetaUpdates({
      ...chapter,
      subtitle: v
    });
    refreshCache(chapter.bookId, "chapter-subtitle-change", { "chapter-subtitle-change": { chapterId: chapter._id } });
  }

  function onIndividualChapterImageUpload(v) {
    setImgDlg(false);
    setIndChaptImg(v);
    saveChapterMetaUpdates({
      ...chapter,
      image: v
    });
    refreshCache(chapter.bookId, "chapter-contents-change", { "chapter-contents-change": { chapterId: chapter._id } });
  }

  useEffect(() => {
    setSubtitle(chapter.subtitle);
  }, [chapter.subtitle]);

  useEffect(() => {
    setTitle(chapter.title);
  }, [chapter.title]);

  useEffect(() => {
    setIndChaptImg(chapter.image);
  }, [chapter.image]);


  return (
    <Row className="att-editor-bar" gutter={15} align="top">
      {indChaptImg ? (
        <Col>
          {/* <Popover
            content={
              <Row gutter={12} style={{minWidth: 100}}>
                <Col flex={1}>
                  <label>Width</label>
                  <Slider min={20} max={100} defaultValue={100} />
                </Col>
                <Col>
                  <Button size="middle" className="btn-a" onClick={() => setImgDlg(!imgDlg)}>Change</Button>
                </Col>
                <Col>
                  <Button size="middle" danger type="primary" shape="circle" onClick={onIndividualChapterImageUpload} icon={<DeleteOutlined />} />
                </Col>
              </Row>
            }
            title={null}
            placement="bottom"
            trigger="click"
          >
            <Avatar className="att-title-card-avatar" shape="square" src={indChaptImg} size={72} />
          </Popover> */}
          <Avatar className="att-title-card-avatar" shape="square" size={72}>
            <img
              className="att-title-card-img"
              src={indChaptImg}
              alt={`${chapter.title}`}
            />
            <span className="att-hover">
              <Popconfirm
                title="Are you sure to delete this image?"
                onConfirm={() => onIndividualChapterImageUpload("")}
                okButtonProps={{
                  danger: true,
                }}
                okText="delete"
                cancelText="No"
              >
                <Button
                  size="middle"
                  danger
                  type="primary"
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </span>
          </Avatar>
        </Col>
      ) : null}
      <Col flex={1}>
        <Typography
          variant="body2"
          color="textSecondary"
          className="chapter-type-toolbar"
        >
          {customTitle
            ? chapter.type === "copyrights"
              ? "Copyright"
              : chapter.type.charAt(0).toUpperCase() + chapter.type.slice(1)
            : getChapterById(chapter.type).name}{" "}
          {chapter.templateId ? " - " : ""}
          {chapter.templateId ? (
            <a
              href="#"
              onClick={() => {
                showTemplateModal();
              }}
            >
              <span color="red">
                Click to apply changes to all books using this template
              </span>
            </a>
          ) : null}
        </Typography>
        <Modal
          title="You are making a change to a saved template"
          visible={isTemplateModalVisible}
          onOk={handleTemplateOk}
          onCancel={handleTemplateCancel}
          footer={[
            <Button key="submit" type="primary" onClick={handleTemplateOk}>
              Apply current changes
            </Button>,
          ]}
        >
          <Radio.Group style={{ width: "100%" }} onChange={onChange}>
            <Space direction="vertical">
              <Radio value="1">Apply only to this book</Radio>
              <Radio value="2">
                Apply to all books that use this saved template
              </Radio>
            </Space>
          </Radio.Group>
        </Modal>
        <div>
          <InputBase
            type="text"
            value={title}
            placeholder={placeholder}
            autoFocus={autofocus}
            className={classes.title}
            inputProps={{ "aria-label": "naked" }}
            onChange={(e) => onTitleChange(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                onEnter();
              }
            }}
          />
        </div>
        <div>
          {show || subtitle ? (
            <Grow in={Boolean(show || subtitle)}>
              <InputBase
                type="text"
                value={subtitle}
                placeholder={"Sub Title"}
                onBlur={() => {
                  toggleShow(false);
                }}
                autoFocus={show}
                className={classes.subtitle}
                inputProps={{ "aria-label": "naked" }}
                onChange={(e) => onSubtitleChange(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onEnter();
                  }
                }}
              />
            </Grow>
          ) : null}
        </div>
        <div className={classes.btnGroup}>
          <Row>
            <Col>
              {!show && !subtitle && chapter.type !== "image" ? (
                <Button
                  size="small"
                  type="link"
                  color="primary"
                  onClick={() => toggleShow(!show)}
                >
                  Add Subtitle
                </Button>
              ) : null}
              {!indChaptImg ? (
                <>
                  <Button
                    size="small"
                    type="link"
                    onClick={() => setImgDlg(!imgDlg)}
                  >
                    Add Image
                  </Button>
                  <IndividualChapterImageUpload
                    show={imgDlg}
                    handler={onIndividualChapterImageUpload}
                  />
                </>
              ) : null}
            </Col>
          </Row>
        </div>
      </Col>
      <Col>
        <Row gutter={10}>
          <Col>{chapter.type !== "image" ? <WordCount /> : null}</Col>
          {environmentType === "development" && <Col>
            <JSONPreviewer />
          </Col>}
        </Row>
      </Col>
    </Row>
  );
});

export default Titlebar;

