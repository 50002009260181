import { findIndex, replace } from "lodash";
import { default as smartquotes } from "@paladin-analytics/smartquotes";
import { isEmpty } from "./strings";
import { Editor, Path, Text, Node, Transforms, Range } from "slate";
import { toJS } from "mobx";
interface KeyValMap {
  [key: string]: string | boolean
}

interface GetEditorTypeReturn {
  type: string | null;
  urlFragments: string[];
}

export const getHashMap: (hashString: string) => KeyValMap = (hashString) => {
  const keyValMap: KeyValMap = {};

  const arr = hashString.replace("#", "").split(",");
  for (const keyValStr of arr) {
    const tmp = keyValStr.split("=");
    if (tmp.length === 2) {
      keyValMap[tmp[0]] = tmp[1] as string;
      continue;
    }
    if (tmp.length === 1) {
      keyValMap[tmp[0]] = true;
    }
  }
  return keyValMap;
};

export const getEditorType = (pathname: string): GetEditorTypeReturn => {
  const urlFragments = pathname
    .split("/")
    .filter((fragment) => !isEmpty(fragment));

  let type: string | null = null;

  if (urlFragments.length > 0 && urlFragments[0]) {
    type = urlFragments[0];
  }

  return {
    type,
    urlFragments,
  };
};

export const upsert = function (ar: Array<any>, newval,  key) {
  //const match = find(ar, key);
  const index = findIndex(ar, {[key] : newval});
  const arr = ar;
  if(index != -1){
      arr.splice(index, 1, newval);
  } else {
      arr.push(newval);
  }
  return arr;
};

export const removeKey = (arr: any, k: string) => Object.keys(arr).reduce((newObj, key) => {
  if (key !== k) {
    newObj[key] = arr[key];
  }
  return newObj;
}, {});

const a = ["", "one ", "two ", "three ", "four ", "five ", "six ", "seven ", "eight ", "nine ", "ten ", "eleven ", "twelve ", "thirteen ", "fourteen ", "fifteen ", "sixteen ", "seventeen ", "eighteen ", "nineteen "];
const b = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];

function inWords(nu: number) {
  let num = "";
  if ((num = nu.toString()).length > 9)
    return "";

  const n = ("000000000" + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n)
    return;

  let str = "";

  //delete this later
  str = "";
  //

  // str += (n[1] !== 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
  // str += (n[2] !== 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
  // str += (n[3] !== 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
  // str += (n[4] !== 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
  // str += (n[5] !== 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
  return str;
}

const countWordsInTexts = (arr: any[]) => {
  let count = 0;
  arr && arr.forEach(ds => {
    let s = ds["text"];
    if (s && s.length != 0 && s.match(/\b[-?(\w+)?]+\b/gi)) {
      s = s.replace(/(^\s*)|(\s*$)/gi, "");
      s = s.replace(/[ ]{2,}/gi, " ");
      s = s.replace(/\n /, "\n");
      count += s.split(" ").length;
    }
  });
  return count;
};

export const countWords = content => {
  let count = 0;
  if (content && content.length > 0) {
    content.forEach(value => {
      const t = value["type"];
      const d = value["children"];
      const isAligned = t === "align_center" || t === "align_left" || t === "align_right";
      const isList = t === "ol" || t === "ul";

      if (isAligned) {
        count += countWords(d);
      }

      if(isList){
        d.forEach(li => {
          const ls = li["children"];
          count += countWords(ls);
        });
      }

      if(!isAligned && !isList){
        count += countWordsInTexts(d);
      }
    });
  }
  return count;
};

export const removeSmartQuotes = (text) => {
  return text
    .replace(/[“”″]/g, "\"")
    .replace(/[‘’′]/g, "'")
    .replace(/'{2}/g, "'");
};

export const objectKeysEqual = (
  sourceObject: Record<string, unknown>,
  destinationObject: Record<string, unknown>
): boolean => {
  return (
    JSON.stringify(Object.keys(sourceObject)) ===
    JSON.stringify(Object.keys(destinationObject))
  );
};

export const isOnlyQuote = (text: string): boolean => {
  if (isEmpty(text)) {
    return false;
  }

  return text?.match(/^(“|”|″|"|‘|’|′|')$/i) !== null;
};

export const replaceQuotesWithCurlyOpening = (text: string): string => {
  if (isEmpty(text)) {
    return text;
  }

  return text.replace(/^(“|”|″|")$/g, "“").replace(/^(|‘|’|′|')$/g, "‘");
};

export const applySmartQuotes = (content) => {
  if (content && content.length > 0) {
    const firstElement = content[0];

    const trimmedText = firstElement?.text?.trim() || "";

    // De-fragment records.
    if (isOnlyQuote(trimmedText)) {
      let editingIndex = 0;

      for (const [i, child] of content.entries()) {
        if (i === 0) continue;
        if (child === null || child === undefined) continue;

        const latestChild = content[editingIndex];

        if (objectKeysEqual(latestChild, child)) {
          content[editingIndex].text = removeSmartQuotes(
            `${content[editingIndex]?.text}${child?.text}`
          );
          content[i].text = "";
        } else {
          editingIndex = i;
        }
      }
    }

    // Apply smart quotes.
    for (let i = 0; i < content.length; i++) {
      const { text = null, children = [] } = content[i];

      if (children.length > 0) {
        content[i].children = applySmartQuotes(children);
      } else {
        if (!isEmpty(text)) {
          const manualStartingQuote = i == 0 && content.length > 1 && content[i + 1] !== null && content[i + 1] !== undefined && isOnlyQuote(text?.trim()) && !objectKeysEqual(content[i], content[i+1]);

          if (manualStartingQuote) {
            content[i].text = replaceQuotesWithCurlyOpening(content[i].text);
          } else {
            content[i].text = smartquotes(removeSmartQuotes(text));
          }
        }
      }
    }
  }

  return content;
};

export const countChars = content => {
  let count = content.length > 1 ? content.length - 1 : 0;
  content.forEach(value => {
    count += value["children"][0]["text"].length;
  });
  return count;
};

export const checkHttps = content => {
  if (content.toLowerCase().startsWith("https://") || content.toLowerCase().startsWith("http://")) {
    const regexp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?!&//=]*)/gi;
    const urls = content.match(regexp);
    if(urls) {
      return true;
    } else {
      return false;
    }
  } else if (content.toLowerCase().startsWith("mailto:")) {
    return true;
  } else {
    return false;
  }
};

export const zeroPad = (num, places) => {
  const zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
};

export function makeid(length) {
  let result           = "";
  const characters       = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
 return result;
}

export const shouldIncludeChapterInPrint = (chapter: { includeIn?: "all" | "ebook" | "print" | "none" }): boolean => {
  if (!chapter.includeIn) return true;
  return !["ebook", "none"].includes(chapter.includeIn);
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));


export const doSearchReplace = (content: Node[], s: IBookStore.SearchParams, r: string) => content.map(value => {
    const t = value["type"];
    let c = value.children as Node[];

    const isAligned = t === "align_center" || t === "align_left" || t === "align_right";
    const isList = t === "ol" || t === "ul";

    if (isAligned) {
        c = doSearchReplace(c, s, r);
    }

    if (isList) {
        c.forEach(li => {
            const ls = li.children as Node[];
            c = doSearchReplace(ls, s, r);
        });
    }

    if (!isAligned && !isList) {
        c = doReplaceInText(c, s ,r);
    }

    return {
        ...value,
        children: c
    };
});

export const sanitizeRegexCharacters = (word: string): string => {
  const illegalChars = [".", "(", ")", "[", "]", "|", "{", "}", "*", "+", "?", "^", "$", "/", "-", "\\"];

  return word.split("").map((char => {
    return illegalChars.includes(char) ? `\\${char}` : char;
  })).join("");
};

const doReplaceInText= (arr: any[], s: IBookStore.SearchParams, r: string) => arr.map(ds => {
    if(ds["text"]){
        const {q, caseSensitive, wholeWord} = s;
        const rt = ds["text"];
       
        const regExpPattern = wholeWord ? `(?<!\\w)(${sanitizeRegexCharacters(q)})(?!\\S)` : q;
        const regExpFlags = caseSensitive ? "g" : "gi";
        const st = new RegExp(regExpPattern, regExpFlags);

        // Escaping $ symbol to make the replace term as literal
        const sanitizedReplaceTerm = r.replaceAll("$", "$$$");

        return {
            ...ds,
            text: replace(rt, st, sanitizedReplaceTerm)
        };
    } else {
        return ds;
    }
});


export const doKeywordCount = (content: Node[], s: IBookStore.SearchParams) =>  {
    let count = 0;
    if (content && content.length > 0) {
        content.forEach(value => {
            const t = value["type"];
            const d = value["children"] as any[];
            const isAligned = t === "align_center" || t === "align_left" || t === "align_right";
            const isList = t === "ol" || t === "ul";

            if (isAligned) {
                count += doKeywordCount(d, s);
            }

            if (isList) {
                d.forEach(li => {
                    const ls = li["children"] as Node[];
                    count = count + doKeywordCount(ls, s);
                });
            }

            if (!isAligned && !isList) {
                count += doKeywordCountInText(d, s);
            }
        });
    }
    return count;
};



export const doKeywordCountInText = (arr: any[], term: IBookStore.SearchParams) => {
    let count = 0;
    const {q, wholeWord, caseSensitive} = term;
    arr && arr.forEach(ds => {
        if(ds.text && ds.text.length > 2){
            let t = ds.text;
            let s = q;
            let parts = [];
    
            if(!caseSensitive){
                t = t.toLowerCase();   
                s = s.toLowerCase();
            }
    
            if(wholeWord){
                parts = t.split(new RegExp(`(?<!\\w)(${sanitizeRegexCharacters(s)})(?!\\S)`)).filter((e:string) => e !== s);
            } else {
                parts = t.split(s);
            }
    
            count += parts.length - 1;
            // if (s && s.length != 0 && s.match(/\b[-?(\w+)?]+\b/gi)) {
            //     s = s.replace(/(^\s*)|(\s*$)/gi, "");
            //     s = s.replace(/[ ]{2,}/gi, " ");
            //     s = s.replace(/\n /, "\n");
            //     count += s.split(r).length;
            // }
        }
        
    });

    return count;
};


export const MIN_SEARCH_WORD_LENGTH = 1;
/**
 * Return a list of ranges for which a search parameter exists in a node
 * @param node
 * @param path
 * @param searchParams
 * @param focusedRange If given, will tack on an extra property to the DecoratedRange to
 * show that this range in particular is in focus
 */
export const getSearchRanges = (
    node: Node,
    path: Path,
    searchParams: IBookStore.SearchParams,
    focusedRange?: IBookStore.DecoratedRange
) => {
    const ranges: IBookStore.DecoratedRange[] = [];
    const { q, caseSensitive, wholeWord } = searchParams;
     
    // only do a search if it is above the minimum number of characters
    if (!Text.isText(node) || q.length < MIN_SEARCH_WORD_LENGTH) {
        return ranges;
    }

    let { text } = node;
    
    // handle casing
    let search = q;
    if (!caseSensitive) {
        text = text.toLowerCase();
        search = q.toLowerCase();
    }
    
    let parts: string[];

    //Do a regex match for split to check if it's a whole word
    if(wholeWord){
        parts = text.split(new RegExp(`(?<!\\w)(${sanitizeRegexCharacters(search)})(?!\\S)`)).filter((e: string) => e !== search);
    } else {
        parts = text.split(search);
    }

    let offset = 0;

    parts.forEach((part, index) => {
        if (index !== 0) {
            // check if this range is the focused one
            const isFocusedSearchHighlight = focusedRange && Path.equals(focusedRange.anchor.path, path) && focusedRange.focus.offset === offset;

            ranges.push({
                anchor: { path, offset: offset - search.length },
                focus: { path, offset },
                highlight: true,
                select_highlight: !!isFocusedSearchHighlight,
            });
        }

        offset = offset + part.length + search.length;
    });

    return ranges;
};

export const getAllSearchRanges = (
    editor: Editor,
    searchParams: IBookStore.SearchParams
) => {
    if (
        !editor.children.length ||
        searchParams.q.length < MIN_SEARCH_WORD_LENGTH
    ) {
        return [];
    }

    const matchingNodes = Editor.nodes(editor, {
        at: [],
        match: (node) =>
            Text.isText(node) &&
            node.text.toLowerCase().includes(searchParams.q.toLowerCase()),
    });
    let nodeMatch = matchingNodes.next();
    const ranges: IBookStore.DecoratedRange[] = [];
    while (!nodeMatch.done) {
        const [node, path] = nodeMatch.value;
        ranges.push(...getSearchRanges(node, path, searchParams));
        nodeMatch = matchingNodes.next();
    }
    return ranges;
};

export const replaceOne = (
    editor: Editor,
    text: string,
    focusedSearch: IBookStore.DecoratedRange
) => {
    const r = toJS(focusedSearch);
    Transforms.insertText(editor, text, {
        at: {
            anchor: r.anchor,
            focus: r.focus,
        },
    });
};

export const replaceAll = (
    editor: Editor,
    text: string,
    matchedRanges: IBookStore.DecoratedRange[]
) => {
    if (!matchedRanges.length) {
        return;
    }
    // we run into a problem when the text we are replacing is not the same length
    // as the text we are replacing it with. we can't just use the ranges we calculated
    // before because of this. This affects when there's multiple matches within a node,
    // so we can keep track of the node matches and calculate the offset diff we need to do
    const originalWordLength = Math.abs(
        matchedRanges[0].anchor.offset - matchedRanges[0].focus.offset
    );
    let sameNodeAdjustment = 0;
    let prevNodePath: Path | undefined;

    toJS(matchedRanges).forEach((range) => {
        const currentPath = range.anchor.path;

        if (prevNodePath && Path.equals(currentPath, prevNodePath)) {
            // this is not the first instance where we replaced text. we need to calculate
            // how much our offsets are off by
            sameNodeAdjustment = sameNodeAdjustment + (text.length - originalWordLength);
        } else {
            sameNodeAdjustment = 0;
        }
        Transforms.insertText(editor, text, {
            at: {
                anchor: {
                    ...range.anchor,
                    offset: range.anchor.offset + sameNodeAdjustment,
                },
                focus: {
                    ...range.focus,
                    offset: range.focus.offset + sameNodeAdjustment,
                },
            },
        });

        prevNodePath = range.anchor.path;
    });
};

/**
 * Get the next search match based on where the user's selection currently is.
 * We want to get the *next* match after the cursor, so that if the user is looking
 * at the middle of the doc, they aren't brought up to the first match, but rather the
 * next match. Returns a step number
 * @param editor
 * @param ranges
 */
export const getNextSearchMatchStep = (
    editor: Editor,
    ranges: IBookStore.DecoratedRange[]
) => {
    // with the ranges, we should set our step accordingly, based on the editor selection
    let step = 0;
    if (editor.selection) {
        const { anchor: selectionAnchor } = editor.selection;
        // we want to find the first range that is after the current selection
        // use .some + return True to mimic 'break' behavior
        const found = ranges.some((r) => {
            // returns -1, 0, or 1 for before, at, or after
            const pathCompare = Path.compare(r.anchor.path, selectionAnchor.path);
            // this match is above the selection
            if (pathCompare === -1) {
                ++step;
                // this match is in the same node as the selection
            } else if (pathCompare === 0) {
                // this match is before the selection
                if (r.anchor.offset <= selectionAnchor.offset) {
                    ++step;
                    // this match is after the selection, we found the next one
                } else {
                    return true;
                }
            }
            // this match is in a node after the selection, we found the next one
            else {
                return true;
            }
            return false;
        });
        // we never found a match, could be selection is after ALL matches, so instead set to the first one
        // could also consider setting to the last one?
        if (!found) {
            step = 0;
        }
    }
    return step;
};