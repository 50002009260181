import React, { FunctionComponent } from "react";
import { Formik, FormikErrors } from "formik";
import { Button, Modal, Form, Input, FormItemProps } from "antd";
import { v4 as uuidv4 } from "uuid";
import { generate as generateRandomString } from "randomstring";
import { ArrowRightOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";

import FileUpload from "../Shared/Forms/FileUpload";
import SelectAuthor from "../Shared/Forms/SelectAuthor";
import SelectProject from "../Shared/Forms/SelectProject";

const commonFormItemProps = {
	labelAlign: "left",
	colon: false,
} as FormItemProps;

interface UploadBookModalProps {
    onUpload: (params: IShelfStore.BookFormFile) => Promise<unknown>;
    onClose: () => void;
}

const UploadBookModal: FunctionComponent<UploadBookModalProps> = ({ onUpload, onClose }: UploadBookModalProps) => {
	const layout = {
		labelCol: { span: 5 },
		wrapperCol: { span: 24 },
	};

	return (
		<>
			<Formik
				initialValues={{ _id: uuidv4(), chapterId: generateRandomString(16), title: "", fileURL: "", fileType: "", author: [], project: "" } as IShelfStore.BookFormFile}
				validate={() => {
					const errors: FormikErrors<IShelfStore.BookFormFile> = {};
					return errors;
				}}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					await onUpload(values);
					setSubmitting(false);
				}}
			>
				{({
					values,
					setFieldValue,
					handleChange,
					submitForm,
					isSubmitting,
					setSubmitting,
				}) => (
					<Modal
						maskClosable={false}
						title={<h2 className="section-heading">Upload Book</h2>}
						visible={true}
						style={{
							borderRadius: 10,
						}}
						onCancel={() => onClose()}
						footer={(
							<Button
								type="primary"
								block 
								size="large"
								onMouseDown={submitForm}
								loading={isSubmitting}
							>
								{"Go Get Em Atticus!"} <ArrowRightOutlined />
							</Button>
						)}
					>
						<Form {...layout} layout="vertical">
							<div className="book-form">
								<Form.Item name="upload" {...commonFormItemProps}>
									<FileUpload
										fileType=".docx,.epub,.rtf,.mobi"
										onFileUpload={(fileUrl, fileType) => {
											setFieldValue("fileURL", fileUrl);
                      if (fileType) setFieldValue("fileType", fileType);
										}}
										onFileUploadStart={() => setSubmitting(true)}
										onFileUploadEnd={() => setSubmitting(false)}
									>
										<p className="ant-upload-drag-icon">
											<UploadOutlined />
										</p>
										<p className="ant-upload-text" style={{ fontSize: "0.8rem" }}>
											<b>Choose a file</b> or drag and drop it here
										</p>
									</FileUpload>
								</Form.Item>
								<Form.Item label="Title" {...commonFormItemProps}>
									<Input placeholder="Title" name="title" onChange={handleChange} value={values.title} />
								</Form.Item>
								<Form.Item name="author" label="Author" {...commonFormItemProps}>
									<SelectAuthor placeholderText={"Author"} onSelect={v => setFieldValue("author", v)} value={values.author} />
								</Form.Item>
								<Form.Item name="project" label="Project" {...commonFormItemProps}>
									<SelectProject placeholderText={"Project Name"}  onSelect={v => setFieldValue("project", v)} value={values.project} />
								</Form.Item>
							</div>
						</Form>
					</Modal>
				)}
			</Formik>
		</>
	);
};

export default UploadBookModal;