import { Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as InstallIcon } from "./install_desktop.svg";

/**
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a web site to a home screen on mobile.
 *
 * @deprecated Only supported on Chrome and Android Webview.
 */
interface BeforeInstallPromptEvent extends Event {
  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;
}

const InstallPWA: React.FC = () => {
  const deferredPrompt = useRef<BeforeInstallPromptEvent | null>();
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    // catch browser's pwa install prompt window, prevent it
    // this event fires when user does not have the PWA installed.
    window.addEventListener("beforeinstallprompt", (evt) => {
      evt.preventDefault();
      deferredPrompt.current = evt;

      setVisible(true);
    });

    // close the prompt
    window.addEventListener("appinstalled", () => {
      setVisible(false);
      deferredPrompt.current = null;
    });
  }, []);

  const promptInstall = async () => {
    if (!deferredPrompt.current) return;

    deferredPrompt.current.prompt();

    const { outcome } = await deferredPrompt.current.userChoice;
    if (outcome === "accepted") {
      deferredPrompt.current = null;
    }
  };

  return (
    <>
      {visible ? (
        <span
          onClick={promptInstall}
          style={{
            display: "flex",
            border: "2px",
            borderStyle: "solid",
            borderColor: "#fff",
            borderRadius: "100px",
            padding: "5.5px",
            cursor: "pointer",
          }}
        >
          <Tooltip title="Install App">
            <InstallIcon width="20px" height="20px" />
          </Tooltip>
        </span>
      ) : (
        <> </>
      )}
    </>
  );
};

export default InstallPWA;
