import React, { FunctionComponent } from "react";

import { NewBookToolbar } from "../Books";

export const Welcome: FunctionComponent = () => {
	return (
		<div className="welcome-screen">
			<div className="container" style={{ display: "flex", justifyContent: "center" }}>
				<div className="welcome-card">
					<h1>Welcome to Atticus!</h1>
					<p>To get started, upload an existing document or create a new project.</p>
					<div className="horizontal-list">
						<NewBookToolbar />
					</div>
				</div>
			</div>
		</div>
	);
};