import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import {
    useParams,
} from "react-router-dom";
import {
    Switch, Row, Col, Form, Input, DatePicker, Button, Card, Checkbox, Progress, Divider, Typography, Menu, Dropdown, 
    Tooltip, InputNumber, Tag} from "antd";
import { findIndex } from "lodash";
import moment from "moment";

import { HelpIcon, ToolbarSettingsIcon, SnowFlakeIcon, FireIcon, ExitIcon, CreateNewIcon } from "../Editor/partials/Icon";
import { AtticusClient } from "../../api/atticus.api";
import { observer } from "mobx-react";
import useRootStore from "../../store/useRootStore";
import { countWords } from "../../utils/helper";
import { db } from "../../db/bookDb";
import { message, Modal} from "antd";
import "./goals.scss";
import { useOnlineStatus } from "../../utils/isOffline";


//Styles
import "./goals.scss";

const { Text } = Typography;
const writingDays = [
    { label: "S", value: 0, dayName: "sunday" },
    { label: "M", value: 1, dayName: "monday"  },
    { label: "T", value: 2, dayName: "tuesday"  },
    { label: "W", value: 3, dayName: "wednesday"  },
    { label: "T", value: 4, dayName: "thursday"  },
    { label: "F", value: 5, dayName: "friday"  },
    { label: "S", value: 6, dayName: "saturday"  },
];

const isoWithTimeZone = (date) => {
    const tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? "+" : "-",
        pad = function (num) {
            const norm = Math.floor(Math.abs(num));
            return (norm < 10 ? "0" : "") + norm;
        };

    return date.getFullYear() +
        "-" + pad(date.getMonth() + 1) +
        "-" + pad(date.getDate()) +
        "T" + pad(date.getHours()) +
        ":" + pad(date.getMinutes()) +
        ":" + pad(date.getSeconds()) +
        dif + pad(tzo / 60) +
        ":" + pad(tzo % 60);
};


const calculateGoal = (targetWordCount: number, startDate: string, dueDate: string, writeDays: number[], writtenWordCount: number) => {

    const countCertainDays = (days: number[], d1: string, d2: string) => {
        const dateObj1: any = new Date(d1);
        const dateObj2: any = new Date(d2);

        const nDays = 1 + Math.round((dateObj2 - dateObj1) / (24 * 3600 * 1000));
        const sum = (a: any, b: any) => {
            return a + Math.floor((nDays + (dateObj1.getDay() + 6 - b) % 7) / 7);
        };
        return days.reduce(sum, 0);
    };

    const goalStartDate = startDate;
    const goalDueDate = dueDate;
    const daysToSearch = writeDays;

    const totalDays = countCertainDays(daysToSearch, goalStartDate, goalDueDate);
    const wordsPerDay = (targetWordCount - writtenWordCount) / totalDays;

    const calculation = {
        totalDays: totalDays,
        daysLeft: totalDays,
        wordsPerDay: wordsPerDay
    };

    return calculation;

};


export const BookGoal: FunctionComponent = () => {
    const [goalData, setGoalData] = useState<IGoalStore.Goal | null>();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [currentGoal, setCurrentGoal] = useState(false);
    const [editGoal, setEditGoal] = useState(false);
    const { bookId } = useParams() as { bookId: string };
    const containerRef = useRef<any>();
    const [showAnim, setShowAnim] = useState(false);
    const isOnline = useOnlineStatus();

    const getGoalData = async () => {
        const goalData = await db.bookGoals.where("bookId").equals(bookId).first();
        if (goalData) {
            setLoading(false);
            setCurrentGoal(true);
            setGoalData(goalData);
        }
    };

    const updateTodayWordCount = async (count) => {
        const goalData = await db.bookGoals.where("bookId").equals(bookId).first();
        if (goalData && goalData.status === "active") {
            goalData.currentDateWrittenWordCount = count;
            const updatedTWC = await db.bookGoals.put(goalData);
        }
    };

    const updateStreak = async (streak) => {
        if (goalData && goalData.status === "active") {
            goalData.streak = streak;
            const updatedTWC = await db.bookGoals.put(goalData);
        }
    };

    const completeGoalDeleteIDB = async (total) => {        
        if (goalData && goalData.status === "active") {
            goalData.currentDateWrittenWordCount = total;
            const completedGoalCWCID = await db.bookGoals.put(goalData);
            const totalWrittenWordCount = goalData.preGoalWordCount + goalData.currentDateWrittenWordCount;
            const completedGoal = await AtticusClient.CompleteGoal(bookId, goalData._id, totalWrittenWordCount);
            goalData.status = "completed";
            const completedGoalID = await db.bookGoals.put(goalData);

            setShowAnim(true);
            setTimeout(() => {
                setShowAnim(false);
            }, 5000);
        }
    };

    const createNewGoal = async () => {
        if (goalData) {
            setCurrentGoal(false);
            const deleteGoalIDB = await db.bookGoals.where("bookId").equals(bookId).delete();
            resetForm();
        }
    };

    const dailyWCCheckup = async () => {
    
        const goalData = await db.bookGoals.where("bookId").equals(bookId).first();
        const todayDate = new Date();
        const today = isoWithTimeZone(todayDate).slice(0, 10);

        if (goalData && goalData.status === "completed") {
            return getGoalData();
        }

        if (goalData && goalData.status === "active") {        
            const currentDateIDB = goalData.currentDate === today;
            const lastUpdatedWordsPerDay = goalData.lastUpdatedWordsPerDay === today;

            if (!currentDateIDB || !lastUpdatedWordsPerDay) {
                if (!isOnline) {
                    const modal = Modal.warning({
                        title: "You must be connected to the Internet at least once a day to update the Goal.",
                        closable: true,
                        okButtonProps: {
                            style: {
                            display: "none",
                            },
                        },
                        width: 550,
                
                    });
                    
                    return modal;
                }

                if (goalData.currentDateWrittenWordCount >= goalData.wordsPerDay) {
                    updateStreak("fire");
                } else {
                    updateStreak("snow");
                }

                const writtenWC = goalData.preGoalWordCount + goalData.currentDateWrittenWordCount;
                const sDate = new Date().toDateString().slice(0, 10);
                const updatedGoal = await AtticusClient.FetchLatestGoal(bookId, writtenWC, sDate);

                goalData.currentDate = today;
                goalData.lastUpdatedWordsPerDay = today;
                goalData.preGoalWordCount = goalData.preGoalWordCount + goalData.currentDateWrittenWordCount;
                goalData.currentDateWrittenWordCount = 0;
                const calculatedGoalResult = calculateGoal(goalData.targetWordCount, goalData.currentDate, goalData.dueDate, goalData.writeDays, updatedGoal.writtenWordCount);

                if (updatedGoal) {
                    goalData.wordsPerDay = calculatedGoalResult.wordsPerDay;
                    const updatedTWC = await db.bookGoals.put(goalData);
                    getGoalData();
                }
            } else {
                getGoalData();
            }
        } else {
            //if the goal is not saved in IDB
            const getProjectGoal = await AtticusClient.GetProjectGoal(bookId);
            if (getProjectGoal) {
                const startDate = new Date();

                const IDBGoal = {
                        _id: getProjectGoal._id,
                        bookId: getProjectGoal.bookId,
                        preGoalWordCount: getProjectGoal.writtenWordCount,
                        wordsPerDay: getProjectGoal.wordsPerDay,
                        lastUpdatedWordsPerDay: isoWithTimeZone(startDate).slice(0, 10),
                        currentDateWrittenWordCount: 0,
                        currentDate: isoWithTimeZone(startDate).slice(0, 10),
                        targetWordCount: getProjectGoal.targetWordCount,
                        streak: "snow",
                        status: getProjectGoal.status,
                        dueDate: getProjectGoal.dueDate,
                        writeDays: getProjectGoal.writeDays
                        
                };

                const saveGoalIDB = await db.bookGoals.add(IDBGoal);
            }
            
            getGoalData();
        }

    };

    useEffect(() => {
         dailyWCCheckup();
    }, []);

    const BookWCCompleted = observer(() => {
        const { getBookBodies, body, chapter } = useRootStore().bookStore;
        const [count, setCount] = useState(0);
        const [list, setList] = useState<Array<string>>([]);

        useEffect(() => {
            getBookBodies().then((d) => {
                setCount(d.words);
                setList(d.ids);
            });
        }, []);

        useEffect(() => {
            getBookBodies().then((d) => {
                setCount(d.words);
            });
        }, [chapter._id]);

        const words = list.includes(chapter._id) ? countWords(body) : 0;

        return (
            <div>
                <p style={{ display: "none" }} ref={containerRef}>{(count + words)}</p>

                {goalData && goalData.status === "active" &&
                    <>
                        <Row>
                            <Col span={12}><h4 style={{ fontWeight: 600 }}>{Math.round(percentage((count + words), goalData?.targetWordCount))}% <Text style={{ fontSize: 12 }} type="secondary">completed</Text></h4></Col>
                            <Col span={12}><Text type="secondary" style={{ fontSize: 12 }}>{(count + words).toLocaleString()} of {goalData?.targetWordCount.toLocaleString()}</Text></Col>
                        </Row>
                        <Row>
                            <Progress showInfo={false} strokeLinecap="square" percent={Math.round(percentage((count + words), goalData?.targetWordCount))} strokeWidth={18} strokeColor="#3568BA"/>
                        </Row>
                    </>
                }
                {goalData && goalData.status === "completed" && <>
                        <Row>
                            <Col span={12}><h4 style={{ fontWeight: 700 }}>100%  completed</h4></Col>
                            <Col span={12}><Text type="secondary">{(goalData.preGoalWordCount + goalData.currentDateWrittenWordCount).toLocaleString()} of {goalData?.targetWordCount.toLocaleString()}</Text></Col>
                            </Row>
                        <Row>
                            <Progress showInfo={false} strokeLinecap="square" percent={100} strokeWidth={18} />
                        </Row>  
                    </>
                }
               
            </div>
        );
    });


    useEffect(() => {
        const initializeInter = setInterval(() => {
            const getIDBData = async () => {
                const data = await db.bookGoals.where("bookId").equals(bookId).first();
                
                if (data && data.preGoalWordCount + data.currentDateWrittenWordCount >= data.targetWordCount) {
                    await completeGoalDeleteIDB(data.currentDateWrittenWordCount);
                }
            };

            getIDBData();
        }, 1000);
        
        return () => clearInterval(initializeInter);
    });

    const DailyWordCount = observer(() => {
        const { getBookBodies, body, chapter } = useRootStore().bookStore;
        const [count, setCount] = useState(0);
        const [list, setList] = useState<Array<string>>([]);
    
        useEffect(() => {
            getBookBodies().then((d) => {
                setCount(d.words);
                setList(d.ids);
            });
        }, []);

        const words = list.includes(chapter._id) ? countWords(body) : 0;

        let lastCount = 0;

        if (goalData) {
            lastCount = goalData.preGoalWordCount;
        }

        const total = (words + count) - lastCount;

        useEffect(() => {
            updateTodayWordCount(total);

            if (goalData) {
                if (total >= goalData.wordsPerDay) {
                    updateStreak("fire");
                }
            }
        }, [total]);

          
        if (goalData && goalData?.status === "completed") {
            return (
                <>
                    <Row justify="space-between" align="middle">
                        <Col flex={1}>
                            <h2>Book Goal</h2>
                        </Col>
                        <Col>
                            <button className="edit-goal-icon-btn" onClick={() => createNewGoal()}><CreateNewIcon /></button>
                        </Col>
                    </Row>
                    <div className="spacer"></div>
                    <Row>
                        {showAnim && <div className="pyro"> <div className="before"></div> <div className="after"></div> </div>}
                        <Col>
                            <Row><Text style={{ color: "#FF8B16" }} strong>Way to go. You have reached your goal!</Text></Row>
                        </Col>
                    </Row>
                </>
            );
        }


        return (
            <>
                <Row justify="space-between" align="middle">
                    <Col flex={1}>
                        <h2>Book Goal</h2>
                    </Col>
                    <Col>
                        <Dropdown overlay={menu} placement="bottomCenter">
                            <button className="edit-goal-icon-btn" ><ToolbarSettingsIcon /></button>
                        </Dropdown>
                    </Col>
                </Row>
                <div className="spacer"></div>
                <Row justify="space-between" align="bottom">
                    <Col flex={1}>
                        <Row>
                            <Tooltip placement="bottom" title={<span>You&apos;ve deleted words and are behind the total word count goal that was calculated before you started writing today</span>}>
                                {/* <Tooltip placement="bottom" title={<span>You&apos;re {Math.abs(total)} {Math.abs(total) > 1 ? "words" : "word"} behind than the total written word count you had before creating the goal</span>}> */}
                                <h2 style={{ color: "red", fontSize: 21, padding: "0.3em 0em" }}>{total < 0 && total}</h2>
                            </Tooltip>
                            <h2 style={{ color: "#3568BA", fontSize: 21, margin: "-15px 0px" }}>{total >= 0 && total}<Text type="secondary" style={{ fontSize: 15 }}><sub>/{goalData && Math.round(goalData.wordsPerDay)}{goalData?.streak === "snow" ? <SnowFlakeIcon /> : <FireIcon />}</sub></Text></h2></Row>
                        <Row><Text style={{ fontSize: 12 }} type="secondary">Words needed to stay on track</Text></Row>
                    </Col>
                    <Col>
                        <div>
                            <Progress width={55} type="dashboard" percent={Math.round(percentage(total, goalData?.wordsPerDay))} strokeWidth={13} />
                        </div>
                    </Col>
                </Row>
            </>
        );
    });


    const handleGoalsSubmit = async (fieldsValue) => {
        if (!navigator.onLine) {
            const modal = Modal.warning({
                title: "You must be connected to the Internet to create a Goal.",
                closable: true,
                okButtonProps: {
                    style: {
                    display: "none",
                    },
                },
                width: 550,
           
            });
            
            return modal;
        }

        if (editGoal) {
            deleteGoal();
        }
        const values = {
            ...fieldsValue,
            "dueDateISO": isoWithTimeZone(fieldsValue.dueDate._d).slice(0, 10)
        };

        try {
            const startDate = new Date();

            const goalDataObj = {
                "bookId": bookId,
                "targetWordCount": values.targetWordCount,
                "startDate": isoWithTimeZone(startDate).slice(0, 10),
                "dueDate": values.dueDateISO,
                "writeDays": values.writingDays, //values.writingDays
                "writtenWordCount": parseInt(containerRef.current?.innerText)
            };

            setLoading(true);

            if (!editGoal) {
                const savedGoal = await AtticusClient.SetBookGoal(goalDataObj);
                const calculatedGoalResult = calculateGoal(goalDataObj.targetWordCount, goalDataObj.startDate, goalDataObj.dueDate, goalDataObj.writeDays, savedGoal.writtenWordCount);                

                if (savedGoal) {
                    const IDBGoal = {
                        _id: savedGoal._id,
                        bookId: savedGoal.bookId,
                        preGoalWordCount: savedGoal.writtenWordCount,
                        wordsPerDay: calculatedGoalResult.wordsPerDay,
                        lastUpdatedWordsPerDay: isoWithTimeZone(startDate).slice(0, 10),
                        currentDateWrittenWordCount: 0,
                        currentDate: isoWithTimeZone(startDate).slice(0, 10),
                        targetWordCount: savedGoal.targetWordCount,
                        streak: "snow",
                        status: "active",
                        dueDate: savedGoal.dueDate,
                        writeDays: savedGoal.writeDays
                    };

                    const saveGoalIDB = await db.bookGoals.add(IDBGoal);
                    getGoalData();
                }
            }

            if (editGoal) {
                const editGoalDataObj = {
                    "bookId": bookId,
                    "targetWordCount": values.targetWordCount,
                    "startDate": isoWithTimeZone(startDate).slice(0, 10),
                    "dueDate": values.dueDateISO,
                    "writeDays": values.writingDays,
                    "writtenWordCount": goalData?.preGoalWordCount
                };
                
                const editedGoal = await AtticusClient.EditGoal(editGoalDataObj);
                const calculatedGoalResult = calculateGoal(editGoalDataObj.targetWordCount, editGoalDataObj.startDate, editGoalDataObj.dueDate, editGoalDataObj.writeDays, editedGoal.writtenWordCount);                

                if (editedGoal && goalData) {
                    const IDBGoal = {
                        _id: editedGoal._id,
                        bookId: editedGoal.bookId,
                        preGoalWordCount: editedGoal.writtenWordCount,
                        wordsPerDay: calculatedGoalResult.wordsPerDay,
                        lastUpdatedWordsPerDay: isoWithTimeZone(startDate).slice(0, 10),
                        currentDateWrittenWordCount: goalData.currentDateWrittenWordCount,
                        currentDate: isoWithTimeZone(startDate).slice(0, 10),
                        targetWordCount: editedGoal.targetWordCount,
                        streak: "snow",
                        status: "active",
                        dueDate: editGoalDataObj.dueDate,
                        writeDays: editedGoal.writeDays
                    };

                    const saveGoalIDB = await db.bookGoals.add(IDBGoal);
                    setEditGoal(false);
                    getGoalData();
                }
            }


        } catch (e) {
            console.log(e);
        }

        setLoading(true);
    };

    const percentage = (partialValue, totalValue) => {
        return (100 * partialValue) / totalValue;
    };

    const onSwitchChange = (checked) => {
            const ele = document.getElementById("collapsible");
            if (ele) {
                if (ele.style.display == "none") {
                    ele.style.display = "block";
                }
                else {
                    ele.style.display = "none";
                }
            }
        };

    const getEditGoal = () => {
        setEditGoal(true);
    };

    const deleteGoal = async () => {
        if (!navigator.onLine) {
            const modal = Modal.warning({
                title: "You must be connected to the Internet to delete a Goal.",
                closable: true,
                okButtonProps: {
                    style: {
                    display: "none",
                    },
                },
                width: 550,
           
            });
            
            return modal;
        }

        if (goalData) {
            const deletedGoal = await AtticusClient.DeleteGoal(bookId, goalData._id);

            if (deletedGoal) {
                const deleteGoalIDB = await db.bookGoals.where("bookId").equals(bookId).delete();
                setCurrentGoal(false);
                resetForm();
            }
        }
            
    };

    const resetForm = () => {
        form.resetFields();
    };

    const menu = (
        <Menu>
            <Menu.Item key="1">
                <p onClick={() => getEditGoal()}>Edit Goal</p>
            </Menu.Item>
            <Menu.Item key="2">
                <p onClick={() => deleteGoal()}>Delete Goal</p>
            </Menu.Item>
        </Menu>
    );


    return (
        <>
            <div style={{ display: "none" }}><BookWCCompleted /></div>
            <div style={{ display: "none" }}><DailyWordCount /></div>
            {!currentGoal && !editGoal &&
                <Row justify="space-between" align="top">
                    <Col>
                        <h2 className="setting-area-title">Book Goal
                            <Tooltip placement="bottom" title={<span>This will help you set goals for the current book or writing project and help you meet your target due date.</span>}>
                                <span style={{ marginLeft: ".5em" }}><HelpIcon /></span>
                            </Tooltip>
                        </h2>
                    </Col>
                    <Col>
                        <Switch defaultChecked size="small" onChange={onSwitchChange} />
                    </Col>
                </Row>
            }
            {!currentGoal && !editGoal &&
                <Form
                    className="setting-area-form"
                    form={form}
                    layout="vertical"
                    onFinish={handleGoalsSubmit}
                    requiredMark={"optional"}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="targetWordCount"
                                label="Total word count for this project"
                                validateTrigger="onChange"
                                rules={[{
                                    required: true,
                                    message: "Please enter the total word count!"
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject();
                                        }
                                        if (value <= parseInt(containerRef.current?.innerText)) {
                                            return Promise.reject("Total word count should be higher than current total!");
                                        }
                                        return Promise.resolve();
                                    },
                                })]}
                            >
                                <InputNumber
                                    style={{ width: "100%", height: 40, backgroundColor: "#f7f7f7" }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="dueDate"
                                label="Due Date"
                                // rules={[{ required: true, message: "Please select the due date!" }]}
                                validateTrigger="onChange"
                                rules={[{
                                    required: true,
                                    message: "Please select the due date!"
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject();
                                        }
                                        if (value < new Date()) {
                                            return Promise.reject("Due date cannot be in the past!");
                                        }
                                        return Promise.resolve();
                                    },
                                })]}
                            >
                                <DatePicker style={{ width: "100%", height: 40, padding: 5, backgroundColor: "#f7f7f7" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Item
                            name="writingDays"
                            label="Writing Days"
                            className="goal-setting-check-btns"
                            rules={[{ required: true, message: "Please select the writing days!" }]}
                        >
                            <Checkbox.Group options={writingDays} />
                        </Form.Item>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {
                                loading ? (
                                    <Button style={{ width: "100%" }} type="primary" htmlType="button">Saving...</Button>
                                ) : (
                                    <Button style={{ width: "100%" }} type="primary" htmlType="submit">Save</Button>
                                )
                            }
                        </Col>
                    </Row>
                </Form>
            }

            {currentGoal && !editGoal && goalData && !loading &&
                <>
                    <DailyWordCount />
                    <Divider className="s-divider"/>
                    <Text strong>Book target words</Text>
                    <BookWCCompleted />
                </>
            }

            {editGoal &&
                <Form
                    className="setting-area-form"
                    form={form}
                    layout="vertical"
                    onFinish={handleGoalsSubmit}
                    requiredMark={"optional"}
                    initialValues={{ targetWordCount: goalData?.targetWordCount, dueDate: moment(goalData?.dueDate), writingDays: goalData?.writeDays }}
                >
                    <Row justify="space-between" align="middle">
                        <Col>
                            <h2 className="setting-area-title">Edit Book Goal</h2>
                        </Col>
                        <Col>
                            <button className="edit-goal-icon-btn" onClick={() => setEditGoal(false)}><ExitIcon /></button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="targetWordCount"
                                label="Total word count for this project"
                                validateTrigger="onChange"
                                rules={[{
                                    required: true,
                                    message: "Please enter the total word count!"
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject();
                                        }
                                        if (value <= parseInt(containerRef.current?.innerText)) {
                                            return Promise.reject("Total word count should be higher than current total!");
                                        }
                                        return Promise.resolve();
                                    },
                                })]}
                            >
                                <InputNumber
                                    style={{ width: "100%", height: 40, backgroundColor: "#f7f7f7" }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="dueDate"
                                label="Due Date"
                                rules={[{
                                    required: true,
                                    message: "Please select the due date!"
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject();
                                        }
                                        if (value < new Date()) {
                                            return Promise.reject("Due date cannot be in the past!");
                                        }
                                        return Promise.resolve();
                                    },
                                })]}
                            >
                                <DatePicker style={{ width: "100%", height: 40, padding: 5, backgroundColor: "#f7f7f7" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Item
                            name="writingDays"
                            label="Writing Days"
                            className="goal-setting-check-btns"
                            rules={[{ required: true, message: "Please select the writing days!" }]}
                        >
                            <Checkbox.Group options={writingDays} />
                        </Form.Item>


                    </Row>
                    <Row>
                        <Col span={24}>
                            {
                                loading ? (
                                    <Button style={{ width: "100%" }} type="primary" htmlType="button">Saving...</Button>
                                ) : (
                                    <Button style={{ width: "100%" }} type="primary" htmlType="submit">Save</Button>
                                )
                            }
                        </Col>
                    </Row>
                </Form>
            }
        </>
    );
};