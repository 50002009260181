

import React, { useState } from "react";
import { observer } from "mobx-react";
import { Input, Button, Modal, Row, Col } from "antd";
import useRootStore from "../../../store/useRootStore";


export const QuoteeInputModal = observer(() => {
    const { blockquoteQuotee, activeModal, setBlockqouteQuotee, toggleBlockquoteModal } = useRootStore().editorModalStore;
    const [value, setValue] = useState(blockquoteQuotee);

    const onSubmit = () => {
        setBlockqouteQuotee(value);
        toggleBlockquoteModal(false);
    };

    return (
        <Modal
            visible={activeModal === "blockquote"}
            onCancel={() => toggleBlockquoteModal(false)}
            title="Add Attribution"
            footer={
                <Button type="primary" block size="large" onMouseDown={onSubmit}>
                    Update Blockquote
                </Button>
            }
        >
            <Row align="middle" gutter={[16, 16]}>
                <Col>
                    <label htmlFor="quotee">Author</label>
                </Col>
                <Col flex={1}>
                    <Input
                        width={100}
                        name="quotee"
                        value={value}
                        onChange={e => {
                            const q = e.target.value;
                            setValue(q);
                        }}
                    />
                </Col>
            </Row>
        </Modal>
    );
});