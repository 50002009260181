import React, { useState } from "react";
import { Select } from "antd";
import { observer } from "mobx-react";

import useRootStore from "../../../store/useRootStore";

const { Option } = Select;

interface SelectProjectProps {
    onSelect: (value: string) => void
    value: string;
    placeholderText: string;
}

const SelectProject = observer(({ onSelect, value, placeholderText }: SelectProjectProps) => {
	const [ projectFreeText, setProjectFreeText ] = useState("");
	const { getProjects } = useRootStore().shelfStore;

	const projects = getProjects();

	return (
		<Select 
			value={value} 
			showSearch
			onSearch={val => {
				setProjectFreeText(val);
			}}
			onChange={onSelect}
			placeholder={placeholderText} 
		>
			{projectFreeText && projects.indexOf(projectFreeText) === -1 ? (<Option key={"new"} value={projectFreeText}>{projectFreeText}</Option>) : null}
      <Option key="empty" value="" title="" style={{ color: "grey"}}>Select project</Option>
			{projects.map((a, i) => (
				<Option key={i} value={a}>{a}</Option>
			))}
		</Select>
	);
});

export default SelectProject;