import { Form, Input, Radio, RadioChangeEvent, Select, FormItemProps, Button } from "antd";
import { groupBy } from "lodash";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import useRootStore from "../../../../../store/useRootStore";
import { InternalLink } from "../types";
import { checkHttps } from "../../../../../utils/helper";
import internal from "stream";

const { Option } = Select;

const commonFormItemProps = {
	labelAlign: "left",
	colon: false,
} as FormItemProps;

interface InternalLinkComponentProps {
  link: InternalLink | undefined;
  setLink: (link: InternalLink | undefined) => void;
  url: string | "#";
  setUrl: (url: string | "#") => void;
  error: string | null;
  setError: (error: string | null) => void;
  update?: boolean,
}

interface ChapterMenuItem {
  _id: string;
  title: string;
}

export const InternalLinkComponent: FunctionComponent<InternalLinkComponentProps> = ({
  link,
  setLink,
  url,
  setUrl,
  error,
  setError,
  update,
}) => {
  const { book } = useRootStore().bookStore;
  const [chaptersList, setChaptersList] = useState<ChapterMenuItem[]>([]);

  const isBooklinkerUpdate = update && link?.type === "booklinker";

  useEffect(() => {
    const groupedChapters = groupBy(book.chapters, "parentChapterId");
    const chaptersList: ChapterMenuItem[] = [];

    for (const chapter of book.frontMatter) {
      chaptersList.push({ _id: chapter._id, title: chapter.title });
    }

    for (const group in groupedChapters) {
      let parentChapter = "";
      const chapters = groupedChapters[group];
      for (const chapter of chapters) {
        if (chapter.type === "volume") {
          chaptersList.push({ _id: chapter._id, title: chapter.title });
          parentChapter = `${chapter.title} → `;
        } else {
          chaptersList.push({
            title: `${parentChapter}${chapter.title}`,
            _id: chapter._id,
          });
        }
      }
    }

    for (const chapter of book.backMatter) {
      chaptersList.push({ _id: chapter._id, title: chapter.title });
    }

    setChaptersList(chaptersList);
  }, [book]);

  const handleChapterSelectionChange = (value: string) => {
    setLink({
      type: "internal-link",
      internalLink: { chapterId: value }
    });
    setUrl("#chapter="+value);
  };

  const handleChapterSelectionFilterOption = (input: string, option: any) => {
    const { children } = option as { children: string };
    return children.toLowerCase().search(input.trim()) !== -1;
  };

  const validateText = (url) => {
		if (!checkHttps(url)) {
			setError("Should be a valid link starting with http:// or https:// or mailto:");
		} else {
			setError(null);
		}
		return url;
	};

  const validateBooklinkerText = (url) => {
		if (!checkHttps(url)) {
			setError("Should be a valid Amazon or Apple Book link starting with http:// or https://");
		} else {
			setError(null);
		}
		return url;
	};


  return (
    <Form.Item {...commonFormItemProps}>
      {link && (
        <div className="image-link-value-container">
          {link.type === "web-link" && (
            <Form.Item {...commonFormItemProps}>
              <Input
                placeholder="Web Link"
                type="text"
                value={link.webLink}
                onChange={(e) => {
                    setLink({ type: link.type, webLink: validateText(e.target.value)});
                    setUrl(e.target.value);
                  }
                }
              />
              <span id="errorText" className='error'>{error}</span>
            </Form.Item>
          )}
          {link.type === "internal-link" && (
            <Form.Item {...commonFormItemProps}>
              <Select
                showSearch
                onChange={handleChapterSelectionChange}
                filterOption={handleChapterSelectionFilterOption}
                placeholder="Select a target"
                value={link.internalLink?.chapterId}
              >
                {chaptersList.map((chapter) => {
                  return (
                    <Option key={chapter._id} value={chapter._id}>
                      {chapter.title}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
           {link.type === "booklinker" && (
            <Form.Item {...commonFormItemProps}>
              <p style={{marginTop: "2px", marginBottom: "2px" }}>Create a {isBooklinkerUpdate && "new"} single, universal book link that works for readers around the world to help boost sales <a href="https://booklinker.com/faq" target="_blank" rel="noopener noreferrer">Learn more...</a></p>
              <p style={{ fontWeight: "bold", marginTop: "3px", marginBottom: "4px" }}>Amazon or Apple Books URL:</p>
              <Input
                placeholder={"Amazon or Apple Books URL"}
                type="text"
                value={link.webLink}
                onChange={(e) => {
                    setLink({ type: link.type, webLink: validateBooklinkerText(e.target.value), booklinker: link?.booklinker});
                    setUrl(e.target.value);
                  }
                }
              />
              <span id="errorText" className='error'>{error}</span>
            </Form.Item>
          )}
        </div>
      )}
    </Form.Item>
  );
};
