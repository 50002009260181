import { AtticusClient, ExportResponse } from "../api/atticus.api";
import { makeAutoObservable, observable, toJS } from "mobx";
import { IChapterTemplateBase } from "../types/chapter";

import { SaveChapterTemplateToDB, UpdateChapterTemplateInDB } from "../utils/offline.book.helpers";
import { SaveTemplateToDB } from "../utils/sync";
import { syncBook } from "../utils/sync";

import { db } from "../db/bookDb";

export class ChapterStore {
	id = null;
	saving = false;
	loading = true;
	books: IBookStore.Book[] = [];
	chapterTemplates: IChapterTemplateBase[] = [];
	constructor() {
		makeAutoObservable(this);
	}

	getChapter = async () => {
		return {
			...this
		};
	}

	setLoading = (loading: boolean): void => {
		this.loading = loading;
	}

	setSaving = (saving: boolean): void => {
		this.saving = saving;
	}

	storeChapterTemplate = async (chapterId: string, templateName: string, section: string): Promise<string> => {
		const templateOutput = await AtticusClient.CreateChapterTemplate(chapterId, templateName, section);
		this.chapterTemplates = await AtticusClient.GetChapterTemplates();
		return templateOutput.templateId;
	}

	storeChapterFromTemplate = async (templateId: string, bookId: string): Promise<boolean> => {
		await AtticusClient.CreateChapterFromTemplate(templateId, bookId);
		return true;
	}

	setChapterTemps = (templates: IChapterTemplateBase[]): void => {
		this.chapterTemplates = templates;
	}

	// fetch functions
	loadTemplates = async (): Promise<void> => {
		this.setLoading(true);

		const templatesFromDb = await db.books.toArray();

		if (templatesFromDb) {
			const a = templatesFromDb.map((a) => {
				if (a._id) {
					syncBook(a._id);
				}

			});
		}

		// this.setChapterTemps(templatesFromDb);
		this.setLoading(false);
	}

	syncChapterTemplate = async (allBooks: boolean, templateId?: string, chapterId?: string, chapLib?: boolean): Promise<void> => {
		try {
			this.setLoading(true);
			const syncTemp = await AtticusClient.SyncChapterTemplate(allBooks, templateId, chapterId, chapLib);
      await SaveTemplateToDB();
		} catch (e) {
			console.log(e);
			throw e;
		}
		this.setLoading(false);
		await this.loadTemplates();

	}



	// getChapterTemplates = async (): Promise<boolean> => {
	// 	const resp = await AtticusClient.GetChapterTemplates();
	// 	if(resp){
	// 		this.chapterTemplates = resp;
	// 	}
	// 	return true;
	// }

	deleteChapterTemplate = async (templateId: string): Promise<void> => {
		try {
			this.setLoading(true);
			const deleteChapTemp = await AtticusClient.DeleteChapterTemplate(templateId);

			if (deleteChapTemp) {
				const deleteChapTempFromDb = await db.chapterTemplates.where("_id").equals(templateId).delete();
			}

		} catch (e) {
			console.log(e);
			throw e;
		}
		this.setLoading(false);
	}

}
export default new ChapterStore();
