import React, { FunctionComponent, useState, useEffect } from "react";
import {
  Slider,
  Radio,
  Checkbox,
  Badge,
  Form,
  Row,
  Col,
  Select,
  Divider,
  Button,
  InputNumber,
  Tabs,
  List,
  Collapse,
  Menu,
  Dropdown,
  Modal,
  message,
  Tooltip,
  Switch
} from "antd";
import { EditOutlined, DeleteOutlined, MoreOutlined, CopyOutlined } from "@ant-design/icons";
import { find, findIndex, groupBy } from "lodash";
import useRootStore from "../../../store/useRootStore";

import OrnamentalBreakLibrary from "../OrnamentalBreakLibrary";
import BookViewer from "../../Theme/BookViewer";

import { getOrnamentalBreaks } from "../helpers/ornamentalBreaks";
import { trimSizes, printFonts } from "../helpers/printConfigs";
import { useOnlineStatus } from "../../../utils/isOffline";

import ingramSpark from "../assets/ingram-spark-logo@3x.png";
import kdp from "../assets/kdp-logo@3x.png";
import { pre_themes } from "../helpers/themes";

import "../styles/theme.scss";
import { toJS } from "mobx";

const { TabPane } = Tabs;

interface ThemeProps {
  theme: IThemeStore.ThemeConfig;
  style: IThemeStore.ThemeBase;
  styleChange: (t: IThemeStore.ThemeBase) => void;
  onSubmit: (t: IThemeStore.ThemeFields, sty: IThemeStore.ThemeBase) => Promise<any>;
  onUpdate: (t: IThemeStore.ThemeFields, sty: IThemeStore.ThemeBase) => Promise<any>;
  styles: IThemeStore.ThemeBase[];
  setThm: (d: IThemeStore.ThemeBase) => void;
  userThemes: IThemeStore.ThemeBase[];
  deleteUserTheme?: (themeId: string) => Promise<void>;
  duplicateUserTheme?: (themeId: string) => Promise<void>;
}

const marginValidate = (value: number) => {
  if (value < 0.125)
    return 0.125;
  if (value > 2.0)
    return 2.0;

  return (Math.round(value * 8) / 8);
};

function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return null;
  }
  if (type === "next") {
    return null;
  }
  return originalElement;
}

const formatconfigs = (t: IThemeStore.ThemeConfig) => {
  const vals: IThemeStore.ThemeFields = {
    titleCard: [],
    alignment: t.titleAlignment,
    beginFirstSentence: t.beginFirstSentence,
    numberview: t.chapterNumbering.toString(),
    decorations: [],
    ob: t.ornamentalBreakImage,
    ob_width: t.ornamentalBreakWidth || 50,
    firstline: t.paragraph.indent ? "indented" : "spaced",
    paraAlignment: [],
    layout: t.layout,
    coloredImg: "all",
    fontsize: t.baseFontSize,
    printBaseFont: t.printBaseFont,
    startPage: t.startPage,
    linespacing: t.paragraph.paragraphSpacing,
    trimsize: `${t.trim.width}x${t.trim.height}`,
    isLargeTrim: t.trim.isLargeTrim === true,
    style_id: t.themeId,
    margin_in: marginValidate(t.margin.inner / 2.54),
    margin_out: marginValidate(t.margin.outer / 2.54),
    paragraph: [],
    notesMode:t.notesMode || "FOOTNOTE",
    ePubNotesMode: t.ePubNotesMode || "END_OF_CHAPTER",
    dynamicPageBreaks: [],
    layoutPriority: t.layoutPriority || "WIDOWS_AND_ORPHANS",
  };
  if (t.titleCard.chapterNumber) vals.titleCard.push("chapterNumber");
  if (t.titleCard.title) vals.titleCard.push("chapterTitle");
  if (t.titleCard.subtitle) vals.titleCard.push("chapterSubtitle");
  if (t.titleCard.image) vals.titleCard.push("chapterImage");
  if (t.firstParagraph.dropcap)
    vals.decorations.push("dropcap");

  if (t.firstParagraph.uppercaseFourWords)
    vals.decorations.push("smallcaps");

  if (t.paragraph.justify)
    vals.paraAlignment.push("justified");

  if (t.paragraph.hyphens)
    vals.paraAlignment.push("hyphens");

  if (t.dynamicPageBreaks?.breakSubheadings)
    vals.dynamicPageBreaks.push("subheadings");

  if (t.dynamicPageBreaks?.breakOrnamentalBreaks)
    vals.dynamicPageBreaks.push("ornamental-breaks");

  return vals;
};

const { Option } = Select;
const paperStyles = {
  ingramSpark: {
    height: 25,
    width: 100,
  },
  kdp: {
    height: 35,
    width: 100,
  },
};

const linespacingmarks = {
  1: {
    label: "Single"
  },
  1.25: {
    label: "1.25"
  },
  1.5: {
    label: "1.5"
  },
  1.75: {
    label: "1.75"
  },
  2: {
    label: "Double"
  },
};

const fontsizemarks = {
  9: {
    label: "9pt"
  },
  10: {
    label: "10pt"
  },
  11: {
    label: "11pt"
  },
  12: {
    label: "12pt"
  },
  13: {
    label: "13pt"
  },
  14: {
    label: "14pt"
  },
  15: {
    label: "15pt"
  },
  16: {
    label: "16pt"
  },
  17: {
    label: "17pt"
  },
  18: {
    label: "18pt"
  },
};

interface ChapterMenuItem {
  _id: string;
  title: string;
}

const PreGeneratedTheme: FunctionComponent<ThemeProps> = ({ theme, styleChange, onUpdate, onSubmit, style, styles, setThm, userThemes }: ThemeProps) => {
  const [form] = Form.useForm();
  const isOnline = useOnlineStatus();
  const { setCustomThemeBuilderView, book } = useRootStore().bookStore;
  const { setImages, deleteUserTheme, duplicateUserTheme } = useRootStore().themeStore;
  const [showAdv, toggleAdv] = useState(true);
  const [showInMM, setShowInMM] = useState<boolean>(false);
  const [featuredOrnamentalBreaks, setFeaturedOrnamentalBreaks] = useState<string[]>([]);
  const [restMarginValue, setResetMarginValue] = useState(false);
  const [activeTab, setTab] = useState("1");
  const [activePage, setActivePage] = useState(1);
  const [cusThemeActivePage, setCusThemeActivePage] = useState(1);
  const [chaptersList, setChaptersList] = useState<ChapterMenuItem[]>([]);
  const { getEditTheme } = useRootStore().themeStore;
  const { refreshCache } = useRootStore().pdfCacheStore;
  const [font, setFont] = useState(theme.printBaseFont);

  const handleChange = (_, values) => {
    onUpdate({
      ...values,
      style_id: style._id
    },
      style
    );
    refreshCache(book._id, "theme-change");
  };

  const handleSubmit = (values) => {
    onSubmit({
      ...values,
      style_id: style._id
    },
      style
    );
    refreshCache(book._id, "theme-change");
  };

  const handleStyle = (sty: IThemeStore.ThemeBase) => {
    let img: IThemeStore.CustomImages = {
      chapterId: "",
      url: "",
      background: false,
      colored: "all",
      placement: "below-subtitle",
      alignment: "left",
      width: 100,
      printExtent: "margins",
    };
    setThm(sty);
    styleChange(sty);
    const th = find(pre_themes, { _id: sty._id });
    if (th && th.properties) {
      form.setFieldsValue(th.properties);
    }
    if (sty.properties && sty.properties.image) {
      img = sty.properties.image;
    }
    setImages(img);
    form.submit();
  };

  const handleObUpdate = (url: string) => {
    form.setFieldsValue({ ob: url });
    form.submit();
    updateFeaturedOrnamentalBreaks();
  };

  const updateFeaturedOrnamentalBreaks = () => {
    let obs = getOrnamentalBreaks(8);
    const ob = form.getFieldValue("ob");

    if (ob) {
      if (!obs.includes(ob)) {
        obs = obs.filter((_, i) => i > 0);
        obs.push(form.getFieldValue("ob"));
      }
    }
    setFeaturedOrnamentalBreaks(obs);
  };

  useEffect(() => {
    updateFeaturedOrnamentalBreaks();
    toggleAdv(false);
    setTab(find(userThemes, { _id: style._id }) ? "1" : "2");
  }, []);

  const setCustomThemeBuilder = () => setCustomThemeBuilderView("customThemeStep2");

  const setEditCustomThemeBuilder = async (id) => {
    setCustomThemeBuilderView("customThemeStep2");
    await getEditTheme(id);
  };

  const onDuplicateTheme = async (themeId: string) => {
    try {
      await duplicateUserTheme(themeId);
    } catch (e) {
      message.error(e.message, 4);
    }
  };

  const onDeleteBook = async (themeId) => {
    try {
      await deleteUserTheme(themeId);
    } catch (e) {
      message.error(e.message, 4);
    }
  };

  const resetMargins = async () => {
    setResetMarginValue(true);
    form.setFieldsValue({ margin_in: "0.875", margin_out: "0.5" });
    form.submit();
  };

  useEffect(() => {
    const spreadStyles = toJS(styles);
    spreadStyles.some((s, i) => {
      if (s._id === style._id) {
        const themeNumber = i + 1;
        const pageNumber = Math.ceil(themeNumber / 8);
        setTab("2");
        setActivePage(pageNumber);
      }
    });
    if (userThemes) {
      const spreadUserThemes = toJS(userThemes);
      if (spreadUserThemes) {
        spreadUserThemes.some((s, i) => {
          if (s._id === style._id) {
            const themeNumber = i + 1;
            const pageNumber = Math.ceil(themeNumber / 8);
            if (pageNumber) {
              form.submit();
              setTab("1");
              setCusThemeActivePage(pageNumber);
            }
          }
        });
      }
    }
  }, [style]);

  useEffect(() => {
    const groupedChapters = groupBy(book.chapters, "parentChapterId");
    const chaptersList: ChapterMenuItem[] = [];

    for (const chapter of book.frontMatter) {
      let chapId = (chapter.title.trim() === "") ? chapter._id : chapter.title.trim().replace(/[^\w\s]/gi, "").replace(/ /g, "-");

      if (chapter.type === "toc") {
        chapId = "toc";
      }
      chaptersList.push({ _id: chapId, title: chapter.title });
    }

    for (const group in groupedChapters) {
      let parentChapter = "";
      const chapters = groupedChapters[group];
      for (const chapter of chapters) {
        if (chapter.type === "volume") {
          chaptersList.push({ _id: chapter._id, title: chapter.title });
          parentChapter = `${chapter.title} → `;
        } else {
          chaptersList.push({
            title: `${parentChapter}${chapter.title}`,
            _id: chapter._id
          });
        }
      }
    }

    for (const chapter of book.backMatter) {
      chaptersList.push({ _id: chapter._id, title: chapter.title });
    }

    setChaptersList(chaptersList);
  }, [book]);

  const handleChapterSelectionFilterOption = (input: string, option: any) => {
    const { children } = option as { children: string };
    return children.toLowerCase().search(input.trim()) !== -1;
  };

  const handleLargeTrim = (checked: boolean) => {
      if(checked){
        form.setFieldsValue({ fontsize: 18 });
        form.setFieldsValue({ linespacing: 1.5 });
        form.setFieldsValue({ paraAlignment: [] });
        form.setFieldsValue({ firstline: "spaced" });
        form.setFieldsValue({ printBaseFont: "OpenSansLT" });
      }
      form.submit();
  };

  return (
    <div className="area-container">
      <Form
        form={form}
        layout="vertical"
        className="fullWidth"
        onFinish={handleSubmit}
        onValuesChange={handleChange}
        initialValues={formatconfigs(theme)}
      >
        <Row className="inner-s">
          <Col flex={2}>
            <h2>Current Theme - <span style={{ textTransform: "capitalize" }}>{style.name}</span></h2>
          </Col>
        </Row>
        <div className="content theme-config">
          <div>
            <Tabs
              activeKey={activeTab}
              onChange={(d) => setTab(d)}
              tabBarExtraContent={{
                right: isOnline ? (
                  <Button type="primary" value="build-custom-theme" onClick={() => setCustomThemeBuilder()}>Create Custom Theme</Button>
                ) : (
                  <Button disabled value="build-custom-theme">Custom Theme (Offline)</Button>
                )
              }}
            >
              <TabPane tab="My Themes" key="1">
                <List
                  itemLayout="vertical"
                  className="theme-cards"
                  size="large"
                  locale={{
                    emptyText: (
                      <Row justify="center" className="custom-themes-empty inner">
                        <div>
                          <img width="200" src="/images/dog.gif" />
                        </div>
                        <h2>What will you create ?</h2>
                        <br />
                        {/* <p>Now you can create themes for books to your hearts content</p> */}
                        {/* <div className="inner-s">
                          {isOnline ? <Button size="small" className="btn-a" onClick={() => setCustomThemeBuilder()}>Try it</Button> : null}
                        </div> */}
                      </Row>
                    )
                  }}
                  pagination={userThemes.length > 0 ? {
                    onChange: page => {
                      setCusThemeActivePage(page);
                    },
                    pageSize: 8,
                    responsive: true,
                    position: "top",
                    itemRender: itemRender,
                    current: cusThemeActivePage
                  } : false}
                  dataSource={userThemes}
                  renderItem={(e, n) => (
                    <div className="theme-list-item">
                      <div key={n} className={`theme-card ${e._id} ${e._id === style._id ? "active" : ""}`}>
                        <div
                          className="theme-element"
                          onClick={() => handleStyle(e)}
                          dangerouslySetInnerHTML={{
                            __html: `
                              <style>${e.css.replace(/\r?\n|\r/, "")}</style>
                              <div class="chapter">
                                <div class="chapter-title-card">
                                  <div class="chapter-number">
                                    <span>Chapter 1</span>
                                  </div>
                                  <div class="chapter-title">
                                    <h2>The Title</h2>
                                  </div>
                                  <div class="chapter-subtitle">
                                    <h3>Here's a Subtitle</h3>
                                  </div>
                                </div>
                                <div class="bottom__part--section" style="text-align:${theme.titleAlignment}">
                                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,</p>
                                  <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                  <p>Rrow itself, let it be sorrow; let him love it; let him pursue it, ishing for its acquisitiendum. Because he will ab hold, uniess but through concer, and also of those who resist. Now a pure snore disturbeded sum dust. He ejjnoyes, in order that somewon, also with a severe one, unless of life. May a cusstums offficer somewon nothing of a poison-filled. Until, from a twho, twho chaffinch may also pursue it, not even a lump. But as twho, as a tank; a proverb, yeast; or else they tinscribe nor. Yet yet dewlap bed. Twho may be, let him love fellows of a polecat. Now amour, the, twhose being, drunk, yet twhitch and, an enclosed valley’s always a laugh. In acquisitiendum the Furies are Earth; in (he takes up) a lump vehicles bien</p>
                                </div>
                              </div>
                            `
                          }}
                        />
                        <div className="theme-card-name">
                          <Row justify="center">
                            <Col>
                              <h4>{e.name}</h4>
                            </Col>
                            <Col>
                              {/* <EditOutlined /> <a onClick={() => setEditCustomThemeBuilder(e._id)}>Edit</a> */}
                              <Dropdown
                                trigger={["click"]}
                                placement="topCenter"
                                overlay={
                                  <Menu>
                                    <Menu.Item
                                      onClick={() => setEditCustomThemeBuilder(e._id)}
                                      icon={<EditOutlined />}
                                    >
                                      Edit
                                    </Menu.Item>
                                    {isOnline && (
                                      <Menu.Item
                                        onClick={() => {
                                          Modal.confirm({
                                            icon: null,
                                            title: <h2 className="section-heading">Duplicate Theme</h2>,
                                            content: `Are you sure you want to duplicate ${e.name}?`,
                                            centered: true,
                                            onOk: () => {
                                              onDuplicateTheme(e._id);
                                            },
                                            okText: "Yes",
                                            okButtonProps: {
                                              type: "primary",
                                              danger: true,
                                              style: {
                                                flex: 1,
                                              },
                                            },
                                            cancelText: "No",
                                            cancelButtonProps: {
                                              className: "btn-a",
                                              style: {
                                                flex: 1,
                                              },
                                            },
                                          });
                                        }}
                                        icon={<CopyOutlined />}
                                      >
                                        Duplicate
                                      </Menu.Item>
                                    )}
                                    {isOnline && (
                                      <Menu.Item
                                        onClick={() => {
                                          Modal.confirm({
                                            icon: null,
                                            title: <h2 className="section-heading">Delete Theme</h2>,
                                            content: `Are you sure you want to delete ${e.name}?`,
                                            centered: true,
                                            onOk: () => {
                                              onDeleteBook(e._id);
                                            },
                                            okText: "Yes",
                                            okButtonProps: {
                                              type: "primary",
                                              danger: true,
                                              style: {
                                                flex: 1,
                                              },
                                            },
                                            cancelText: "No",
                                            cancelButtonProps: {
                                              className: "btn-a",
                                              style: {
                                                flex: 1,
                                              },
                                            },
                                          });
                                        }}
                                        icon={<DeleteOutlined />}
                                      >
                                        Delete
                                      </Menu.Item>
                                    )}
                                  </Menu>
                                }
                              >
                                <MoreOutlined style={{ cursor: "pointer" }} />
                              </Dropdown>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  )}
                />
              </TabPane>
              <TabPane tab="Atticus Themes" key="2">
                <List
                  itemLayout="vertical"
                  className="theme-cards"
                  size="large"
                  pagination={{
                    pageSize: 8,
                    responsive: true,
                    position: "top",
                    itemRender: itemRender,
                    defaultCurrent: activePage
                  }}
                  dataSource={styles}
                  renderItem={(e, n) => (
                    <div className="theme-list-item">
                      <div key={n} className={`theme-card ${e._id} ${e._id === style._id ? "active" : ""}`}>
                        <div
                          className="theme-element"
                          onClick={() => handleStyle(e)}
                        >
                          <img className="_img" src={`/thumbnails/${e._id}.png`} />
                        </div>
                        <div className="theme-card-name">
                          <h4>{e.name}</h4>
                        </div>
                      </div>
                    </div>
                  )}
                />
              </TabPane>
            </Tabs>
          </div>            
          <div className="theme-body">
            <Divider />
            <div className="thm-section fullWidth">
              <h2 className="boldedText">Chapter Heading Settings</h2>
              <Row gutter={16}>
                <Col>
                  <Form.Item
                    name="titleCard"
                    label="Visible Chapter Details"
                  >
                    <Checkbox.Group>
                      <Checkbox value="chapterNumber">Number</Checkbox>
                      <Checkbox value="chapterTitle">Title</Checkbox>
                      <Checkbox value="chapterSubtitle">Subtitle</Checkbox>
                      <Checkbox checked value="chapterImage" style={{ display: activeTab == "2" ? "none" : "" }}>Image</Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Form.Item
                  name="numberview"
                  label="Chapter Number View"
                  className="a-radio-btns"
                >
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="number">1</Radio.Button >
                    <Radio.Button value="chapter+number">Chapter 1</Radio.Button>
                    <Radio.Button value="word">One</Radio.Button>
                    <Radio.Button value="chapter+word">Chapter One</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Row>
              <Row gutter={16} style={{ display: activeTab == "2" ? "block" : "none" }}>
                <Col xs={24} xl={12}>
                  <Form.Item
                    name="alignment"
                    label="Chapter Detail Alignment"
                  >
                    <Radio.Group>
                      <Radio value="left">Left</Radio>
                      <Radio value="center">Center</Radio>
                      <Radio value="right">Right</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <Divider />

            <div className="thm-section fullWidth">
              <h2 className="boldedText">Paragraph Settings</h2>
              <Row gutter={16}>
                <Col>
                  <Form.Item
                    name="decorations"
                    label="First Sentence"
                  >
                    <Checkbox.Group>
                      <Checkbox value="dropcap">Drop Caps</Checkbox>
                      <Checkbox value="smallcaps">Lead in Small Caps</Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              </Row>
              {/* {theme?.firstParagraph?.dropcap &&
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="numeroflines"
                        className="a-radio-btns"
                        label="Number of Lines"
                      >
                        <Radio.Group style={{ paddingLeft: 5 }} buttonStyle="solid">
                          <Radio value={2}>2</Radio>
                          <Radio value={3}>3</Radio>
                          <Radio value={4}>4</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>*/}
              <Row gutter={16}>
                <Col>
                  <Form.Item
                    name="beginFirstSentence"
                    label="When to Use the First Sentence Formatting"
                  >
                    <Radio.Group buttonStyle="solid">
                      <Radio value="chapter">Beginning of chapter</Radio>
                      <Radio value="break">Every paragraph following a break</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col>
                  <Form.Item
                    name="firstline"
                    label="Subsequent Paragraphs"
                  >
                    <Radio.Group>
                      <Radio value="indented">Indented</Radio>
                      <Radio value="spaced">Spaced</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <Divider />

            <div className="thm-section fullWidth">
              <Row justify="space-between">
                <h2>Ornamental Break</h2>
              </Row>
              <Row>
                <Form.Item name="ob" className="ob-btns">
                  <Radio.Group>
                    {featuredOrnamentalBreaks.map((o, i) => (
                      <Radio.Button key={`${i}`} value={o}>
                        <img src={o} />
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Row>
              <Row justify="space-between">
                <Col>
                  <OrnamentalBreakLibrary
                    selected={form.getFieldValue("ob")}
                    onUpdate={(newVal) => handleObUpdate(newVal)}
                  />
                </Col>
                <Col>
                  <Row align="middle">
                    <Col>
                      Width:
                    </Col>
                    <Col>
                      <Form.Item name="ob_width" className="ob-btns">
                        <Slider style={{ width: "250px", marginLeft: "1em", marginRight: "1em" }} min={1} max={100} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <Divider />
            <div className="thm-section fullWidth">
              <h2>Notes</h2>
              <Row>
                <Col span={14}>
                  <Form.Item
                    name="notesMode"
                    label="PDF"
                    className="boldedText"
                  >
                    <Radio.Group>
                      <Radio value="FOOTNOTE">Footnotes</Radio>
                      <Radio value="END_OF_CHAPTER">End of Chapter</Radio>
                      <Radio value="END_OF_BOOK">End of Book</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item
                    name="ePubNotesMode"
                    label="ePub"
                    className="boldedText"
                  >
                    <Radio.Group>
                      <Radio value="END_OF_CHAPTER">End of Chapter</Radio>
                      <Radio value="END_OF_BOOK">End of Book</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <Divider />
            <div className="thm-section fullWidth">
              <h2>Ebook Settings</h2>
              <Row gutter={32}>
                <Col span={14}>
                  <Form.Item name="startPage" className="boldedText" label="Start Page">
                    <Select
                      showSearch
                      filterOption={handleChapterSelectionFilterOption}
                      placeholder="Select a target"
                    >
                      {chaptersList.map((chapter) => {
                        return (
                          <Option key={chapter._id} value={chapter._id}>{chapter.title}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <Divider />

            <div className="thm-section fullWidth">
              <h2>Print Settings</h2>
              <Row gutter={32}>
                <Col span={14}>
                  <Form.Item name="printBaseFont" className="boldedText" label="Font">
                    <Select placeholder="Select Font" className={"EBGaramond"} value={font} onChange={d => setFont(d)}>
                    {printFonts.map((font, i) => (
                      <Option className={font._id} value={font._id} key={i}>
                        {font.name}{" "}
                        {font.additionalInfo && (
                          <span
                            style={{
                            fontFamily: "initial",
                            fontWeight: "normal",
                          }}
                          >
                            ({font.additionalInfo})
                          </span>
                        )}
                      </Option>
                    ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={10} >
                  <div className="flex center-vertical">
                    <Form.Item name="isLargeTrim" className="boldedText large-print-checkbox" valuePropName="checked">
                        <Checkbox onClick={(e) => handleLargeTrim((e.target as HTMLInputElement).checked)}>
                          <Tooltip title={"Selecting \"Large Print\" will automatically adjust your font, font size, line spacing and text alignment to meet international standards for large print books."}><span>Large Print</span></Tooltip>
                        </Checkbox>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <h4 className="boldedText">Header and Footer</h4>
              <Row gutter={32} className="hf-layout-btns">
                <Form.Item name="layout">
                  <Radio.Group>
                    <Radio value="layout5">
                      <BookViewer.E />
                    </Radio>
                    <Radio value="layout6">
                      <BookViewer.F />
                    </Radio>
                    <Radio value="layout1">
                      <BookViewer.A />
                    </Radio>
                    <Radio value="layout2">
                      <BookViewer.B />
                    </Radio>
                    <Radio value="layout3">
                      <BookViewer.C />
                    </Radio>
                    <Radio value="layout4">
                      <BookViewer.D />
                    </Radio>
                    <Radio value="layout7">
                      <BookViewer.G />
                    </Radio>
                    <Radio value="layout8">
                      <BookViewer.H />
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Row>
              <br></br>
              <Row gutter={24}>
                <Col span={8}>
                  <h4 className="boldedText">Paper Trim Size</h4>
                  <Radio.Group onChange={(e) => {setShowInMM(e.target.value);}} value={showInMM}>
                    <Radio value={false}>inches</Radio>
                    <Radio value={true}>mm</Radio>
                  </Radio.Group> 
                </Col>
                <Col span={8}>
                  <Row>
                    <Badge color="#FF9900" size="default" />
                    <h5 className="book-subtitle">Supported by KDP</h5>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <Badge color="#6098B2" />
                    <h5 className="book-subtitle">Supported by INGRAM SPARK</h5>
                  </Row>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}></Col>
                <Col span={8}>
                  <div className="auth-logo__container">
                    <img style={paperStyles.kdp} src={kdp} />
                  </div>
                </Col>
                <Col span={8}>
                  <div className="auth-logo__container">
                    <img style={paperStyles.ingramSpark} src={ingramSpark} />
                  </div>
                </Col>
              </Row>

              {/* <h4>Paper Trim Size (in)</h4> */}
              {trimSizes.map((cat, i) => (
                <Row key={i}>
                  <Form.Item
                    name="trimsize"
                    label={cat.category}
                    className="a-radio-btns"
                  >
                    <Radio.Group buttonStyle="solid">
                      {cat.sizes.map((size, j) => (
                        <Radio.Button key={j}
                          value={cat.category === "Large Print Options" ? `${size.width * 2.54}*${size.height * 2.54}` : `${size.width * 2.54}x${size.height * 2.54}`}
                        >
                          {size.kdp === true ? <Badge color="#FF9900" size="default" /> : null}
                          {size.ingramSpark === true ? <Badge color="#6098B2" size="default" /> : null}
                          {showInMM ? `${Math.round(size.width * 25.4)} x ${Math.round(size.height * 25.4)}` : `${size.width} x ${size.height}`}
                        </Radio.Button >
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Row>
              ))}
            </div>

            <Collapse bordered={false} ghost activeKey={showAdv ? "advanced-settings" : ""} onChange={() => toggleAdv(!showAdv)}>
              <Collapse.Panel
                header={
                  <span className="advance-settings">Advanced Settings</span>
                }
                key="advanced-settings"
              >
                <div id="advance-settings" className="fullWidth">
                  <div className="thm-section fullWidth">
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item name="paraAlignment" label="Alignment">
                          <Checkbox.Group>
                            <Checkbox value="justified">Justified</Checkbox>
                            <Checkbox value="hyphens">Hyphens</Checkbox>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <h4>Margins</h4>
                        <Row gutter={16}>
                          <Col>
                            <Form.Item name="margin_in" label="Inside">
                              {restMarginValue === true ? (
                                <InputNumber
                                  step={0.125}
                                  max={2.0}
                                  min={0.125}
                                  value={0.125}
                                //parser={value => value ? value.replace("in", "").trim() : ""}
                                />
                              ) : (
                                <InputNumber
                                  step={0.125}
                                  max={2.0}
                                  min={0.125}
                                  formatter={value => `${value} in`}
                                //parser={value => value ? value.replace("in", "").trim() : ""}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item name="margin_out" label="Outside" style={{ marginLeft: 10 }}>
                              {restMarginValue === true ? (
                                <InputNumber
                                  step={0.125}
                                  max={2.0}
                                  min={0.125}
                                  value={0.125}
                                //parser={value => value ? value.replace("in", "").trim() : ""}
                                />
                              ) : (
                                <InputNumber
                                  step={0.125}
                                  max={2.0}
                                  min={0.125}
                                  formatter={value => `${value} in`}
                                //parser={value => value ? value.replace("in", "").trim() : ""}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col>
                            <Button size="small" onClick={resetMargins} type="link">Reset Margins</Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="thm-section fullWidth">
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item name="fontsize" label="Font Size">
                        <Slider min={9} max={18} marks={fontsizemarks} />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>

                <div className="thm-section fullWidth">
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item name="linespacing" label="Line Spacing">
                        <Slider min={1} max={2} step={0.05} marks={linespacingmarks} />
                      </Form.Item>
                    </Col>
                  </Row>

                </div>

           

                <div className="thm-section fullWidth">
                  <Row>
                    <Form.Item
                      name="dynamicPageBreaks"
                      label="Keep Options"
                    >
                      <Checkbox.Group>
                        <Checkbox value="ornamental-breaks">Ornamental Breaks</Checkbox>
                        <Checkbox value="subheadings">Subheadings</Checkbox>
                      </Checkbox.Group>
                    </Form.Item>
                  </Row>
                </div>

                <div className="thm-section fullWidth">
                  <Row gutter={32}>
                    <Col span={32}>
                      <Form.Item
                          name="layoutPriority"
                          label="Layout Priority"
                        >
                        <Radio.Group>
                          <Radio value="WIDOWS_AND_ORPHANS">Widows and Orphans</Radio>
                          <Radio value="BALANCED_PAGE_SPREAD">Balanced Page Spread</Radio>
                          <Radio value="BEST_OF_BOTH">Best of Both</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Collapse.Panel>
            </Collapse>
            <div className="inner-s"></div>
          </div>
          {/* <div className="thm-section">
                <Row style={{ padding: "1rem 0rem 0.8rem 0rem" }}>
                  <Col flex={2}>
                    <Button className="btn-a" onClick={hideAdvancedSettings}>{!show ? "Show Advanced Settings" : "Hide Advanced Settings"}</Button>
                  </Col>
                </Row>
              </div> */}
        </div>
      </Form>
    </div>
  );
};

export default PreGeneratedTheme;
