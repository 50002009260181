import React, { FunctionComponent } from "react";
import { SvgIcon } from "@material-ui/core";

interface IconProps{
  size?: number;
  active?: boolean;
}

export const ImageUploadIcon: FunctionComponent<IconProps> = ({ size = 15 }: IconProps) => (
	<SvgIcon viewBox="0 0 150 150" style={{ width: size }} className="slate-ToolbarButton">
		<g id="Group_40" data-name="Group 40">
			<path className="cls-1" d="M130.55,132.9H19.05a15,15,0,0,1-15-15V32.11a15,15,0,0,1,15-15h111.5a15,15,0,0,1,15,15v85.78A15,15,0,0,1,130.55,132.9ZM19.05,30a2.14,2.14,0,0,0-2.15,2.14v85.78A2.14,2.14,0,0,0,19.05,120h111.5a2.14,2.14,0,0,0,2.14-2.14V32.11A2.14,2.14,0,0,0,130.55,30Z" />
			<path className="cls-1" d="M15,113.69l-9.1-9.1L39.54,71a6.42,6.42,0,0,1,9.1,0L73.75,96.08,107.8,62a6.44,6.44,0,0,1,9.1,0l27.18,27.18-9.1,9.1L112.35,75.67l-34,34.05a6.44,6.44,0,0,1-9.1,0L44.09,84.61Z" />
			<circle id="Ellipse_8" data-name="Ellipse 8" className="cls-1" cx="66.22" cy="53.56" r="12.87" />
		</g>
	</SvgIcon>
);

export const ImageSettingsIcon: FunctionComponent<IconProps> = ({ size = 15 }: IconProps) => (
	<SvgIcon viewBox="0 0 32 32" style={{ width: size }} className="slate-ToolbarButton">
		<g id="Fill"><path d="M16,12a4,4,0,1,0,4,4A4,4,0,0,0,16,12Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,16,18Z"/><path d="M27.57,18.3l-1.38-.79a9.56,9.56,0,0,0,0-3l1.38-.79a2.86,2.86,0,0,0,1.05-3.91l-.93-1.61a2.88,2.88,0,0,0-3.91-1l-1.38.8a10.07,10.07,0,0,0-2.61-1.52V4.83A2.86,2.86,0,0,0,16.93,2H15.07a2.86,2.86,0,0,0-2.86,2.86V6.41A10.07,10.07,0,0,0,9.6,7.93l-1.38-.8a2.86,2.86,0,0,0-3.91,1L3.38,9.79a2.88,2.88,0,0,0,1,3.91l1.38.79a9.56,9.56,0,0,0,0,3l-1.38.79a2.86,2.86,0,0,0-1,3.91l.93,1.61a2.88,2.88,0,0,0,3.91,1.05l1.38-.8a10.07,10.07,0,0,0,2.61,1.52v1.58A2.86,2.86,0,0,0,15.07,30h1.86a2.86,2.86,0,0,0,2.86-2.86V25.59a10.07,10.07,0,0,0,2.61-1.52l1.38.8a2.86,2.86,0,0,0,3.91-1.05l.93-1.61A2.88,2.88,0,0,0,27.57,18.3Zm-.69,2.91L26,22.82a.86.86,0,0,1-1.17.32l-2.63-1.52-.54.49a8.3,8.3,0,0,1-3.12,1.82l-.7.22v3a.86.86,0,0,1-.86.86H15.07a.86.86,0,0,1-.86-.86v-3l-.7-.22a8.3,8.3,0,0,1-3.12-1.82l-.54-.49L7.22,23.14a.87.87,0,0,1-.65.08.84.84,0,0,1-.52-.4l-.93-1.61A.86.86,0,0,1,5.43,20l2.62-1.51-.16-.72a8.56,8.56,0,0,1-.2-1.8,8,8,0,0,1,.21-1.8l.15-.72L5.43,12a.86.86,0,0,1-.31-1.18l.93-1.61a.86.86,0,0,1,1.17-.32l2.63,1.52.54-.49a8.3,8.3,0,0,1,3.12-1.82l.7-.22v-3A.86.86,0,0,1,15.07,4h1.86a.86.86,0,0,1,.86.86v3l.7.22a8.3,8.3,0,0,1,3.12,1.82l.54.49,2.63-1.52a.87.87,0,0,1,.65-.08.84.84,0,0,1,.52.4l.93,1.61A.86.86,0,0,1,26.57,12L24,13.48l.16.72a8.56,8.56,0,0,1,.2,1.8,8,8,0,0,1-.21,1.8l-.15.72L26.57,20A.86.86,0,0,1,26.88,21.21Z"/></g>
	</SvgIcon>
);

export const RemoveIcon: FunctionComponent<IconProps> = ({ size = 15 }: IconProps) => (
	<SvgIcon viewBox="0 0 32 32" style={{ width: size }} className="slate-ToolbarButton">
		<g><rect height="12" width="2" x="15" y="12"/><rect height="12" width="2" x="19" y="12"/><rect height="12" width="2" x="11" y="12"/><path d="M20,6V5a3,3,0,0,0-3-3H15a3,3,0,0,0-3,3V6H4V8H6V27a3,3,0,0,0,3,3H23a3,3,0,0,0,3-3V8h2V6ZM14,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H14ZM24,27a1,1,0,0,1-1,1H9a1,1,0,0,1-1-1V8H24Z"/></g>
	</SvgIcon>
);