import React from "react";
import { ToolbarElement } from "@udecode/slate-plugins";
import { FunctionComponent } from "react";
import { useEditor } from "slate-react";
import { SmartQuotesIcon } from "../../partials/Icon";
import { ButtonProps, Modal, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useRootStore from "../../../../store/useRootStore";
import { applySmartQuotes, delay } from "../../../../utils/helper";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react";

export const SmartQuotesButton: FunctionComponent = observer(() => {
  const editor = useEditor();

  const { chapter, saveChapterBodyUpdates, setChangeCount } =
    useRootStore().bookStore;
  const { refreshCache } = useRootStore().pdfCacheStore;

  const getUpdatedModalLoadingState = (
    loading: boolean
  ): {
    okButtonProps: ButtonProps,
    cancelButtonProps: ButtonProps,
  } => {
    return {
      okButtonProps: {
        loading: loading,
      },
      cancelButtonProps: {
        disabled: loading,
      },
    };
  };

  const showConfirmModal = () => {
    const modal = Modal.confirm({
      title: "Are you sure you wish to apply smart quotes to your chapter?",
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <ul style={{ textAlign: "left" }}>
            <li>
              This action will replace all existing quotation marks and
              apostrophes with curly quotes and apostrophes.
            </li>
            <li>
              Curly quotes are applied using an advanced algorithm. No curly
              quote algorithm is perfect and some quotes may still need to be
              corrected on an individual basis. After the curly quotes have been
              applied you can manually adjust quotation marks by using keyboard
              shortcuts.
            </li>
            <li>
              <strong>This action is irreversible.</strong>
            </li>
          </ul>
        </>
      ),
      okText: "Apply",
      cancelText: "Cancel",
      autoFocusButton: "cancel",
      className: "modal-confirm",
      cancelButtonProps: {
        type: "primary",
        danger: true,
      },
      okButtonProps: {
        onClick: async (e) => {
          e.preventDefault();

          modal.update(
            getUpdatedModalLoadingState(true)
          );

          await saveChapterBodyUpdates(
            chapter._id,
            applySmartQuotes(cloneDeep(editor.children[0].children))
          );

          setChangeCount();
          refreshCache(chapter.bookId, "chapter-contents-change", {
            "chapter-contents-change": { chapterId: chapter._id },
          });
          await delay(3000); // Needed for user if they immediately go hit export.
          modal.update(getUpdatedModalLoadingState(false));

          modal.destroy();

          notification["success"]({
            message: "Smart Quotes have been applied!",
            description: "Smart Quotes have been applied to this chapter.",
            placement: "topRight",
          });
        },
      },
    });
  };

  return (
    <ToolbarElement
      type={"smart-quotes"}
      icon={<SmartQuotesIcon active={false} />}
      onMouseDown={() => {
        showConfirmModal();
      }}
    />
  );
});
