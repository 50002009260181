import { ScrollNumberProps } from "antd/lib/badge";
import { find } from "lodash";
import {printFonts} from "../Shared/helpers/printConfigs";

export const generateStylesheet = (config: IThemeStore.ThemeConfig): string => {
  const styles = `
    .previewer .wrapper{
      ${config.paragraph.hyphens ? "hyphens: auto;" : ""}

      ${config.paragraph.justify ? `
        text-align: justify;
      ` : ""}
    }
    .quotee {
      text-align: right;
    }
    span.monospace {
      font-family: 'Courier', sans-serif;
    }
    span.smallcaps {
      font-variant: small-caps;
    }
    span.sansserif {
      font-family: 'PT Sans';
    }
    .previewer p:not(p.lead_word, p.dropcap){
      text-indent: ${config.paragraph.indent ? config.paragraph.indent : 0}em;
      margin-block-end: ${!config.paragraph.indent ? config.paragraph.paragraphSpacing : 0}em;
    }
    .withDropcap .dropcap, .ornamental-break + p{
      text-indent: 0!important;
    }
    .chapter-title-card .chp_bg{
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1;
    }

    .chapter-title-card{
      justify-content:center;
    }
    .ornamental-break img{
      width: ${config.ornamentalBreakWidth}%;
    }
    ${config.firstParagraph.dropcap ? `
      .withDropcap .dropcap {
        float: left;
      }
    `: ""}
    
    ${config.firstParagraph.uppercaseFourWords ? (
      `
        .withDropcap .dropcap,
        .withDropcap .lead_word{
          text-transform: uppercase;
        }
      `
    ) : ""}
  `;
  return styles;
};


export const generatePrintStylesheet = (startPage: "left" | "right", config: IThemeStore.ThemeConfig): string => {
  const styles = `
      .previewer-print ._wrapper{
        height: ${config.trim.height * 2.125}rem;
        width: ${config.trim.width * 2.125}rem;
        overflow: auto;
        padding-top: ${config.margin.top * 2.125}rem;
        padding-bottom: ${config.margin.top * 2.125}rem;
        padding-right: ${config.margin.outer * 2.125}rem;
        padding-left: ${config.margin.inner * 2.125}rem;
      }
      .copyright-print ._wrapper{
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
      }
      .previewer-print p {
        font-size: ${config.baseFontSize ? config.baseFontSize: 10}pt;
        line-height: ${config.paragraph.paragraphSpacing};
        font-family: '${config.printBaseFont}';
      }
      .endnote-link{
        color: inherit;
        text-decoration: none;
        padding-left: 0.2rem;
      }
      .endnotes{
        border-top: 1px solid rgba(10,10,10,0.6);
        padding-top: 1rem;
        margin-top: 2rem;
        page-break-inside: avoid;
        break-inside: avoid;
      }
      .endnote-text-number{
        margin-right: 0.2rem;
      }
      .endnote-backlink{
        color: inherit;
        text-decoration: none;
      }
      a{
        text-decoration: none;
        color: inherit;
      }
      .toc-list{
        width: 100%;
      }
      .table-of-content{
        display: flex;
        width: 100%;
        justify-content: space-between;
        border-bottom: 0px;
        margin-bottom: 0.4rem;

        font-size: ${config.baseFontSize ? config.baseFontSize: 10}pt !important;
        line-height: ${config.paragraph.paragraphSpacing} !important;
        font-family: '${config.printBaseFont}' !important;
      }
      .previewer-print .full-bleed{
        height: ${config.trim.height * 2.125}rem;
        width: ${config.trim.width * 2.125}rem;
        overflow: auto;
      }

      ${config.firstParagraph.dropcap ? getStylesForFont(config.printBaseFont, config.baseFontSize * config.paragraph.paragraphSpacing): ""}
    `;

  return styles;
};

export const generateDigitalStylesheet = (config: IThemeStore.ThemeConfig) => {
  const styles = `
    ${config.firstParagraph.dropcap ? (
      `
        .withDropcap .dropcap {
          font-size: 3rem;
          line-height: 1em;
          max-height: ${15 * 2}px;
          margin-right: 0.125em;
          padding: 0 0.25rem;
          webkit-initial-letter: 2;
          initial-letter: 2;
          transform: scale(1.1);
        }
        .withDropcap .dropcap:before,
        .withDropcap .dropcap:after {
          content: "";
          display: block;
        }
        .withDropcap .dropcap:before {
          margin-bottom: 0.275rem;
        }
        .withDropcap .dropcap:after {
          margin-top: -0.175rem;
        }
        .endnote-link{
          color: inherit;
          text-decoration: none;
          padding-left: 0.2rem;
        }
        .endnotes{
          font-size: 0.9rem !important;
          padding-top: 1rem !important;
          margin-top: 1rem !important;
          margin-bottom: 2rem !important;
          page-break-inside: avoid;
          break-inside: avoid;
        }
        .endnotes-separator{
          height:0.175rem;
          background-color:#000000;
          margin-bottom: 2rem !important;
        }
        .endnote-backlink{
          color: inherit;
          text-decoration: none;
        }
        a{
          text-decoration: none;
          color: inherit;
        }
      `
    ) : ""}
  `;
  return styles;
};

export const getStylesForFont = (font: string, size: number) => {
  const f = find(printFonts, {_id: font}); 
  const pos = { 
    a: f?.after !== undefined ? f.after : -0.5,
    b: f?.before !== undefined ? f.before : 0,
  }; 
  return `
    .withDropcap .dropcap {
      font-size: ${size * 3}px!important;
      margin-right: ${size / 150}em;
      max-height: ${size * 1.5}pt;
      line-height: 1em;
      text-transform: uppercase;
      webkit-initial-letter: 2;
    }
    .withDropcap .dropcap:before,
    .withDropcap .dropcap:after {
      content: "";
      display: block;
    }
    .withDropcap .dropcap:after {
      margin-top: ${pos.a}rem;
    }
    .withDropcap .dropcap:before  {
      margin-bottom: ${pos.b}rem;
    }
  `;
};