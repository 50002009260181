import React, { ReactNode } from "react";
import { DeserializeHtml, SlatePlugin } from "@udecode/slate-plugins-core";
import { getRenderLeaf, getLeafDeserializer, Deserialize, getOnHotkeyToggleMark, setDefaults } from "@udecode/slate-plugins";
import { SmallcapsPluginOptions, SmallcapsLeafOptions, SmallcapsDeserializeOptions, SmallcapsRenderLeafOptions } from "./types";
import {DEFAULTS_SMALLCAPS} from "./defaults";


export const renderLeafSmallCaps = (options?: SmallcapsRenderLeafOptions) => {
  const keyOptions = setDefaults(options, DEFAULTS_SMALLCAPS)["smallcaps"];
  return getRenderLeaf(keyOptions);
};

export const deserializeSmallCap = (
  options : SmallcapsDeserializeOptions
): DeserializeHtml => {
  const { smallcaps }  = setDefaults(options, DEFAULTS_SMALLCAPS);
  return {
    leaf: getLeafDeserializer({
      type: smallcaps.type,
      rules: [
        { nodeNames: ["smallcaps"] },
        {
          style: {
            fontVariant: ["all-small-caps"],
            fontWeight: ["600", "bold"],
          },
        },
      ],
      ...options?.smallcaps?.deserialize,
    }),
  };
};

export const SmallcapsPlugin = (options: SmallcapsPluginOptions): SlatePlugin => {
  return {
    renderLeaf: renderLeafSmallCaps(options),
    deserialize: deserializeSmallCap(options),
    onKeyDown: getOnHotkeyToggleMark({ type: "smallcaps", hotkey: "cmd+shift+h" })
  };
};



export const SmallcapsComponent = (props: any) => {
  return (
    <span {...props.attributes}>{props.children}</span>
  );
};