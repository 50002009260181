import React, { FunctionComponent, useState, useEffect } from "react";
import { observer } from "mobx-react";
import * as querystring from "query-string";

// 3rd party Components
import { Formik } from "formik";
import { Form, Input, Button, message } from "antd";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";

// api
import { AtticusClient } from "../../api/atticus.api";

// stores
import useRootStore from "../../store/useRootStore";

// components
import Loading from "../Shared/Loading";

// types

interface InviteDetails{
  firstName: string;
  lastName: string;
}

interface SignUpForm{
  inviteId: string;
  inviteCode: string;
  firstName: string;
  lastName: string;
  password: string;
}

type FormErrors = Partial<SignUpForm>;

interface Props{
  modal: boolean;
}

export const SignUp: FunctionComponent<Props> = ({ modal }: Props) => {
	const [ loading, setLoading ] = useState(true);
	const [ inviteDetails, setInviteDetails ] = useState<InviteDetails | null>(null); 
	const { updateToken, token } = useRootStore().authStore;

	const location = useLocation<Location>();
	const history = useHistory();

	const queryParams = querystring.parse(location.search);

	const { inviteId, inviteCode } = queryParams;

	const [agree, setAgree] = useState(false);

	const checkboxHandler = () => {
		setAgree(!agree);
	};


	const fetchInvite = async (inviteId: string, inviteCode: string) => {
		try {
			const resp = await AtticusClient.FetchInvite(inviteId, inviteCode);
			setInviteDetails(resp);
			setLoading(false);
		} catch (e) {
			history.push("/");
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchInvite(inviteId, inviteCode);
	}, [inviteId, inviteCode]);

	if (token) return <Redirect to="/" />;
	if (loading) return <Loading />;

	if (!inviteDetails) return <Redirect to="/" />;

	return (
		<div className="center-body">
			<div className="card">
				<div className="margin-bottom">
					<h3>Create your Atticus account</h3>
				</div>
				<Formik
					initialValues={{
						inviteId: queryParams.inviteId,
						inviteCode: queryParams.inviteCode,
						firstName: inviteDetails.firstName,
						lastName: inviteDetails.lastName,
						password: "",
					} as SignUpForm}
					validate={(values) => {
						const errors: FormErrors = {};

						if (!values.inviteCode && !values.inviteCode) {
							errors.inviteCode = "Invalid Invite Code";
							errors.inviteId = "Invalid Invite ID";
						}

						if (!values.firstName) errors.firstName = "Required";
						if (!values.lastName) errors.lastName = "Required";

						if (!values.password) errors.password = "Required";
						else if (values.password.length < 6)
							errors.password = "Your password should have at least 6 characters";

						return errors;
					}}
					onSubmit={async (values, { setSubmitting, setFieldError }) => {
						try {
							const resp = await AtticusClient.SignUp(values);
							updateToken(resp.token);
						} catch (e) {
							if (e.response.status === 406) {
								const validationErrors = e.response.data.errors;
								const validationKeys = Object.keys(validationErrors);

								for (const k of validationKeys) {
									setFieldError(k, validationErrors[k].message);
								}

								message.error("Please fix the highlighted fields", 4);
							} else {
								message.error("Oops... Something went wrong");
							}
							setSubmitting(false);
						}
						setSubmitting(false);
					}}
				>
					{({
						values,
						errors,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
					}) => (
						<Form
							onSubmitCapture={handleSubmit}
							colon={false}
							layout={"vertical"}
							requiredMark
						>
							<Form.Item
								label="First Name"
								validateStatus={errors.firstName ? "error" : "success"}
								help={errors.firstName}
								required
							>
								<Input
									type="text"
									name="firstName"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.firstName}
								/>
							</Form.Item>

							<Form.Item
								label="Last Name"
								validateStatus={errors.lastName ? "error" : "success"}
								help={errors.lastName}
								required
							>
								<Input
									type="text"
									name="lastName"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.lastName}
								/>
							</Form.Item>

							<Form.Item
								label="Password"
								validateStatus={errors.password ? "error" : "success"}
								help={errors.password}
								required
							>
								<Input
									type="password"
									name="password"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.password}
								/>
							</Form.Item>
							<Form.Item>
							<div style={{
									paddingBottom: "1rem",
								}}>
								<input type="checkbox" id="agree" onChange={checkboxHandler} />
								<label htmlFor="agree"> I agree to the <b><a target="_blank" href="https://www.atticus.io/terms-conditions" rel="noreferrer">Terms and Conditions</a></b></label>
							</div>
								<Button disabled={!agree} type="primary" htmlType="submit" loading={isSubmitting} block>
                                    Create Account
								</Button>
							</Form.Item>
							<div
								style={{
									padding: "1rem 0rem",
								}}
							>
                                    Already have an account? 
								{modal ? 
									<Link to={{ hash: "#signin" }}> Sign In</Link> :
									<Link to="/auth/sign-in"> Sign In</Link>
								}
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default observer(SignUp);
